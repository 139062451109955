import GoogleMapReact from 'google-map-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../api/api'
import { LocationData } from '../../components/admin/LocationCard'
import BackButton from '../../components/BackButton'
import NavigationMenu from '../../components/common/NavigationMenu'
import SplashScreen from '../../components/SplashScreen'
import { ReactComponent as ListIcon } from '../../icons/list-alternative.svg'
import { ReactComponent as ReturnPinIcon } from '../../icons/return-pin.svg'
import { ReactComponent as PartnerPinIcon } from '../../icons/partner-pin.svg'
import { ReactComponent as ExternalLinkIcon } from '../../icons/link-external.svg'
import { ReactComponent as SearchIcon } from '../../icons/search.svg'
import { MainModal } from '../../components/modal/Modals'
import { getDistance } from 'geolib'

type AddressData = {
  address?: string | undefined
  city?: string | undefined
  state?: string | undefined
  postalCode?: string | undefined
  country?: string | undefined
}

type MerchantData = {
  businessName: string
  businessAddress?: AddressData | undefined
  profileImageURL?: string | undefined
  merchantWebsiteURL?: string | undefined
  coordinates: {
    latitude?: number | null
    longitude?: number | null
  }
  distance?: number
}

const MapMarkerReturns = ({ text, user, zoomLvl }: any) => (
  <div className={user ? 'map-marker user ' : 'map-marker'}>
    <ReturnPinIcon />
    <label style={{ opacity: zoomLvl > 13 ? 100 : 0 }}>{text}</label>
  </div>
)

const MapMarkerPartners = ({ text, user, zoomLvl }: any) => (
  <div className={user ? 'map-marker user ' : 'map-marker'}>
    <PartnerPinIcon />
    <label style={{ opacity: zoomLvl > 13 ? 100 : 0 }}>{text}</label>
  </div>
)

const defaultLocation = { latitude: 45.5009162, longitude: -73.5660632 }

const HomeMap = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [showPartners, setShowPartners] = useState(true)
  const [showList, setShowList] = useState(false)
  const [, setMap] = useState<HTMLElement | null>(null)

  let defaultZoomLvl = 14
  const [zoomLvl, setZoomLvl] = useState(defaultZoomLvl)

  const [locations, setLocations] = useState<LocationData[]>([])
  const [partners, setPartners] = useState<MerchantData[]>([])

  const [searchTerm, setSearchTerm] = useState('')
  const [searchedPartners, setSearchedPartners] = useState<MerchantData[]>([])
  const [searchedLocations, setSearchedLocations] = useState<LocationData[]>([])

  let { currentPosition, getCurrentPosition, wrappedExtendedQuery } = useAPI()

  useEffect(() => {
    async function onLoad() {
      await getCurrentPosition()
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onLocationSet() {
      const query = `query {dropOffLocationsOpen(filter: {status: "active"}) {_id name status capacity quantity address workTime website coordinates { latitude longitude }}
      merchantsPublic(filter: {displayed: true}) {businessName profileImageURL merchantWebsiteURL businessAddress {address city state} coordinates { longitude latitude}}}`
      const factory = (result: any): any => {
        return {
          returnPoints: result.dropOffLocationsOpen
            .map((l: any) => ({
              distance: currentPosition ? getDistance(currentPosition, l.coordinates) : getDistance(defaultLocation, l.coordinates),
              freeCapacity: l.capacity - l.quantity,
              ...l,
            }))
            .sort((a: any, b: any) => (a.distance > b.distance ? 1 : -1)),
          merchants: result.merchantsPublic
            .map((m: any) => ({
              distance: currentPosition
                ? getDistance(currentPosition, m.coordinates.latitude !== null ? m.coordinates : { latitude: 45.5009162, longitude: -73.5660632 })
                : getDistance(defaultLocation, m.coordinates.latitude !== null ? m.coordinates : { latitude: 45.5009162, longitude: -73.5660632 }),
              ...m,
              coordinates: {
                latitude: m.coordinates.latitude,
                longitude: m.coordinates.longitude,
              },
            }))
            .sort((a: any, b: any) => (a.distance > b.distance ? 1 : -1)),
        }
      }

      const response = await wrappedExtendedQuery(query, factory)

      if (response) {
        setLocations(response.returnPoints)
        setPartners(response.merchants)
      }
    }
    onLocationSet().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition])

  const handleZoomChange = (zoom: number) => {
    setZoomLvl(zoom)
  }

  const onClickPartners = () => {
    setShowPartners(true)
  }
  const onClickReturnPoints = () => {
    setShowPartners(false)
  }

  const onShowList = () => {
    setShowList(true)
  }

  const onListClose = () => {
    setShowList(false)
    setSearchTerm('')
  }

  useEffect(() => {
    if (showPartners) {
      if (searchTerm.length > 0) {
        const results = partners.filter(function (partner) {
          partner.businessName && partner.businessName.toLowerCase().includes(searchTerm)
          if (partner.businessName && partner.businessName.toLowerCase().includes(searchTerm)) {
            return true
          } else if (
            partner.businessAddress?.address?.toLowerCase().includes(searchTerm) ||
            partner.businessAddress?.city?.toLowerCase().includes(searchTerm) ||
            partner.businessAddress?.state?.toLowerCase().includes(searchTerm) ||
            partner.businessAddress?.country?.toLowerCase().includes(searchTerm)
          ) {
            return true
          } else return false
        })
        setSearchedPartners(results)
      } else {
        setSearchedPartners(partners)
      }
    } else {
      if (searchTerm.length > 0) {
        const results = locations.filter(function (location) {
          location._id && location.name.toLowerCase().includes(searchTerm)
          if (location._id && location.name.toLowerCase().includes(searchTerm)) {
            return true
          } else if (location.address && location.address.toLowerCase().includes(searchTerm)) {
            return true
          } else return false
        })
        setSearchedLocations(results)
      } else {
        setSearchedLocations(locations)
      }
    }
  }, [searchTerm, locations, partners, showPartners])

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value.toLowerCase())
  }

  return (
    <main className="mainHome">
      {isLoading ? (
        <SplashScreen />
      ) : (
        <>
          <div className="screen-header">
            <div className="back-and-langauge-container">
              <BackButton />
            </div>
            <h1>{t('bo-locations')}</h1>
          </div>
          <div className="bopaqs-content">
            <div className="new-map" ref={setMap}>
              <div className="map-toggle">
                <div className="map-toggle-container">
                  <div className={`map-toggle-btn ${showPartners && 'active'}`} onClick={onClickPartners}>
                    {t('partners')}
                  </div>
                  <div className={`map-toggle-btn ${!showPartners && 'active'}`} onClick={onClickReturnPoints}>
                    {t('return-points')}
                  </div>
                </div>
                <div className="map-display-list" onClick={onShowList}>
                  <ListIcon />
                </div>
              </div>
              <GoogleMapReact
                bootstrapURLKeys={{ key: process.env.REACT_APP_GMAPS_KEY || 'AIzaSyDjf0EaTnMLSYZpQq-v0VLrMbaN8GwldBY' }}
                defaultCenter={{
                  lat: currentPosition?.latitude || defaultLocation.latitude,
                  lng: currentPosition?.longitude || defaultLocation.longitude,
                }}
                defaultZoom={8}
                center={{
                  lat: currentPosition?.latitude || defaultLocation.latitude,
                  lng: currentPosition?.longitude || defaultLocation.longitude,
                }}
                options={() => {
                  return {
                    gestureHandling: 'greedy',
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: false,
                  }
                }}
                onZoomAnimationStart={(zoom: number) => handleZoomChange(zoom)}
                zoom={zoomLvl}
              >
                {showPartners
                  ? partners.map((partner, index) => {
                      if (partner.coordinates.latitude !== null)
                        return <MapMarkerPartners key={`${partner.businessName} + ${index}`} lat={partner.coordinates.latitude} lng={partner.coordinates.longitude} text={partner.businessName} zoomLvl={zoomLvl} />
                      else return null
                    })
                  : locations.map(location => <MapMarkerReturns key={location._id} lat={location.coordinates.latitude} lng={location.coordinates.longitude} text={location.name} zoomLvl={zoomLvl} />)}
              </GoogleMapReact>
            </div>
          </div>
        </>
      )}
      {showList && (
        <MainModal
          data={{
            position: 'top',
            cancelHook: onListClose,
            submitHook: onListClose,
            show: showList,
            hideButton: true,
            className: 'modal-over-everything partner-list',
            title: showPartners ? t('bo-partners-near-you') : t('return-points-near-you'),
            content: showPartners ? (
              <div className="partner-list-container">
                <div className="custom-input-container standalone-input">
                  <SearchIcon />
                  <input value={searchTerm} placeholder={t('search-returns')} onChange={handleSearchChange} />
                </div>
                {searchedPartners.length > 0 ? (
                  searchedPartners.map((partner, index) => (
                    <div key={`${partner.businessName} + ${index}`} className="partner-list-element">
                      {partner.profileImageURL && <img alt={partner.businessName + 'picture'} src={partner.profileImageURL} />}
                      <div className="partner-list-element-info">
                        <label>{partner.businessName}</label>
                        <label>
                          {partner.businessAddress?.address} {partner.businessAddress?.city} {partner.businessAddress?.country}
                        </label>
                      </div>
                      {partner.merchantWebsiteURL && (
                        <ExternalLinkIcon
                          className="partner-list-external-link"
                          onClick={e => {
                            e.preventDefault()
                            window.open(partner.merchantWebsiteURL, '_blank')
                          }}
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="empty-list-msg">{t('search-results-empty')}</div>
                )}
              </div>
            ) : (
              <div className="partner-list-container">
                <div className="custom-input-container standalone-input">
                  <SearchIcon />
                  <input value={searchTerm} placeholder={t('search-returns')} onChange={handleSearchChange} />
                </div>
                {searchedLocations.length > 0 ? (
                  searchedLocations.map((location, index) => (
                    <div key={`${location.name} + ${index}`} className="partner-list-element">
                      <div className="partner-list-element-info">
                        <label>{location.name}</label>
                        <label>{location.address}</label>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="empty-list-msg">{t('search-results-empty')}</div>
                )}
              </div>
            ),
          }}
        />
      )}
      <NavigationMenu />
    </main>
  )
}

export { HomeMap as default }
