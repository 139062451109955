import Navbar from '../../components/Navbar'
import ActionBar from '../../components/customer/ActionBar'
import ImpactWidget from '../../components/stats/ImpactWidget'

const ImpactStats = () => {
  return (
    <>
      <Navbar></Navbar>
      <ImpactWidget />
      <ActionBar></ActionBar>
    </>
  )
}

export { ImpactStats as default }
