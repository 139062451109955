import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { useAPI } from '../../api/api'
import { APIError } from '../../api/response'
import { LocationData } from '../../components/admin/LocationCard'
import BackButton from '../../components/BackButton'
import AddAndCompleteElement from '../../components/buttons/AddAndCompleteElement'
import CardWithRemove from '../../components/CardWithRemove'
import ErrorElement from '../../components/ErrorElement'
import { FailIcon } from '../../components/Icons'
import { QrScanner } from '../../components/QrScanner'
import SuccessElement from '../../components/SuccessElement'
import { ItemToAssignData } from '../merchant/AssignItems'
import { LocationCoords } from './ReturnItemLocation'

const ReturnItem = () => {
  const { t } = useTranslation()

  let history = useHistory()

  let { submitting, errors, setErrors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const [msgs, setMsgs] = useState<APIError[]>([])

  let location = useLocation()

  const auth = localStorage.getItem('auth')
  let clientBoId: string
  if (auth) {
    clientBoId = JSON.parse(auth).boId
  }

  const dropOffPoint: LocationData = location.state as any as LocationData

  const [locationId, setLocationId] = useState<string | undefined>(undefined)
  const [disabled, setDisabled] = useState(true)
  const [dropOff, setDropOff] = useState<LocationData>(dropOffPoint)
  const [isFull, setIsFull] = useState(dropOffPoint.freeCapacity > 0 ? false : true)

  const [returnItems, setReturnItems] = useState<ItemToAssignData[]>([])

  const [showScanner, setShowScanner] = useState(false)

  useEffect(() => {
    async function onLoad() {
      setLocationId(dropOffPoint._id)
      setDropOff(dropOffPoint)
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onQrCode = async (code: string) => {
    setShowScanner(false)

    if (!code.includes('items/')) {
      setErrors([{ msg: t('invalid-item-qr-code') }])
      if (returnItems.length === 0) setDisabled(true)
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    const query = `query { itemsById (filter : {uuid : "${qr}"}) { _id uuid status itemType { _id name iconUrl depositPrice } client {boId} merchant {name} }}`
    const factory = (result: any): ItemToAssignData => {
      return Array.isArray(result.itemsById) && result.itemsById.length > 0
        ? result.itemsById.map((o: any) => ({ id: o._id, label: o.itemType.name, depositPrice: o.itemType.depositPrice / 100, iconUrl: o.itemType.iconUrl, uuid: o.uuid, status: o.status, client: o.client, merchant: o.merchant }))[0]
        : undefined
    }

    const item = await wrappedExtendedQueryAuth(query, factory)

    if (!item) {
      setErrors([{ msg: t('item-does-not-exist') }])
      if (returnItems.length === 0) setDisabled(true)
      return
    }

    switch (item.status) {
      case 'unassigned':
      case 'returned':
      case 'deposit-returned':
      case 'deposit':
        setErrors([{ msg: `${t('customer-return.unassigned')}` }])
        break
      case 'assigned':
        if (clientBoId !== item.client.boId) {
          setErrors([{ msg: `${t('customer-return.assigned-someone-else')}` }])
        }
        break
      case 'pending-purchase':
        setErrors([{ msg: `${t('customer-return.pending-purchase')}` }])
        break
      case 'purchased':
        setErrors([{ msg: `${t('customer-return.purchased')}` }])
        break
      case 'written-off':
      case 'lost':
        setErrors([{ msg: `${t('customer-return.lost')}` }])
        break
      case 'on-consignment':
        setErrors([{ msg: `${t('customer-return.on-consignment', { name: item.merchant.name, price: item.depositPrice })}` }])
        if (returnItems.length === 0) setDisabled(true)
        break
    }

    const alreadyExist = returnItems.find(q => q.id === item.id)
    if (alreadyExist) {
      setErrors([{ msg: t('item-aready-added') }])
      if (returnItems.length === 0) setDisabled(true)
      return
    }
    setReturnItems(returnItems.concat(item))
  }

  useEffect(() => {
    if (returnItems.length > 0) setDisabled(false)
    else setDisabled(true)

    if (returnItems.length >= dropOff.freeCapacity) {
      setIsFull(true)
    }
  }, [returnItems, dropOff])

  const submitToServer = async (coords: LocationCoords) => {
    if (!locationId || returnItems.length < 1) {
      setErrors([{ msg: `${t('unknown-error-occured')}` }])
      return
    }
    setErrors([])

    let labelsSuccess: string[] = []
    let labelsFail: string[] = []

    const ids: string[] = []
    returnItems.forEach(item => {
      ids.push(item.id)
    })

    const mutation = `mutation {returnItemsToLocation(input: {ids: ${JSON.stringify(ids)} locationId: "${locationId}" currentLocation: {latitude:${coords.latitude}  longitude:${
      coords.longitude
    }  }}) {itemsReturned {_id serialNumber itemType {name}}}}`

    const response = await wrappedExtendedMutateAuth(mutation)

    response.returnItemsToLocation.itemsReturned.forEach((item: any) => {
      let index = -1
      labelsSuccess.push(item.itemType.name)
      index = returnItems.findIndex(i => {
        return i.id === item._id
      })

      if (index > -1) returnItems.splice(index, 1)
    })

    returnItems.forEach((item: any) => {
      labelsFail.push(item.label)
    })

    if (labelsSuccess.length > 0) {
      const labelsString = labelsSuccess.join(', ')
      setMsgs([{ msg: `${labelsString} ${t('successfully-returned')}` }])
      setDropOff({ ...dropOff, freeCapacity: dropOff.freeCapacity - labelsSuccess.length })
    }

    if (labelsFail.length > 0) {
      const failsString = labelsFail.join(', ')
      setErrors([{ msg: `${failsString} ${t('containers-failed-return')}` }])
    }

    setReturnItems([])
  }

  const onSubmit = async (e: any) => {
    e.preventDefault()
    setDisabled(true)

    navigator.geolocation.getCurrentPosition(
      async function (position) {
        if (position) {
          submitToServer(position.coords)
        } else {
          submitToServer({ latitude: 0, longitude: 0 })
        }
      },
      function (error) {
        submitToServer({ latitude: 0, longitude: 0 })
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    )
  }

  useEffect(() => {
    async function onLoad() {
      setIsFull(dropOff.freeCapacity > 0 ? false : true)
    }
    onLoad()
  }, [dropOff])

  const handleScanCode = (e: any) => {
    e.preventDefault()
    if (dropOffPoint.freeCapacity > 0) {
      setMsgs([])
      setErrors([])
      setShowScanner(true)
    }
  }

  const onCancel = () => {
    if (returnItems.length === 0) {
      history.goBack()
    }
    setShowScanner(false)
  }

  const handleRemoveItem = (id: string) => {
    setReturnItems(returnItems.filter(i => i.id !== id))
    setMsgs([])
    setErrors([])
    setIsFull(false)
  }

  return (
    <>
      {showScanner && (
        <section className="qrScanner">
          <QrScanner callback={onQrCode} callbackCancel={onCancel}></QrScanner>
        </section>
      )}
      {!showScanner && (
        <>
          <form onSubmit={onSubmit} className="object-form">
            <BackButton />
            <div className="Header">
              <h2>
                {t('returning-at')}&nbsp;
                {dropOffPoint.name}
              </h2>
              <label>{dropOffPoint.address}</label>
              <br />
              <label className="multi-line">{dropOffPoint.workTime}</label>
              <br />
              <div className="location-capacity-space">{isFull ? t('locations.location-full') : `${t('free-capacity', { freeCapacity: dropOff.freeCapacity })}`}</div>
              {!isFull && returnItems.length < dropOff.freeCapacity ? <p></p> : <p className="empty-list-msg">{t('max-return-number')}</p>}
              <ErrorElement errors={errors} />
              <SuccessElement msgs={msgs} />
            </div>
            <div className="added-cards">
              {returnItems.length === 0 ? (
                <p className="empty-list-msg">{t('empty.assigned-items')}</p>
              ) : (
                returnItems
                  .map(item => item)
                  .reverse()
                  .map(i => (
                    <CardWithRemove
                      key={i.id}
                      data={{
                        _id: i.id,
                        iconUrl: i.iconUrl,
                        labelMain: i.label,
                        labelBotRight: t('remove'),
                        iconBotRight: <FailIcon />,
                        onClick: () => handleRemoveItem(i.id),
                      }}
                    />
                  ))
              )}
            </div>
            <div className="buttonDock"></div>
            <AddAndCompleteElement data={{ submitting, disabledAdd: isFull, disabledComplete: disabled, onClickAdd: handleScanCode }} />
          </form>
        </>
      )}
    </>
  )
}

export { ReturnItem as default }
