type ImgFigureData = {
  name: string
  src: string
  alt: string
  caption: JSX.Element | string
}

const ImgFigure = ({ data, className }: { data: ImgFigureData; className?: string }) => {
  return (
    <figure>
      <img src={data.src} alt={data.alt} className={className} />
      <figcaption>{data.caption}</figcaption>
    </figure>
  )
}

export { ImgFigure as default }
export type { ImgFigureData }
