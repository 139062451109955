import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CloseIcon } from '../../icons/x.svg'
import { TutorialEng, TutorialFr } from '../WelcomeIntroContents'
import './Modals.css'

type Data = {
  show: boolean
  title: string
  className?: string | undefined
  position?: 'top' | 'center' | 'bottom'
  content?: JSX.Element
  hideButton?: boolean | undefined
  buttonText?: string
  cancelHook: (e: any) => void
  submitHook?: (e: any) => void
}

const MainModal = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  return (
    <>
      {data.show && (
        <section className={`modalWrapper ${data.className && data.className} ${data.position === 'top' && 'modal-top'}`} onClick={data.cancelHook}>
          <div className="modalContainer" onClick={(e: any) => e.stopPropagation()}>
            <div className="modalTitleContainer">
              <CloseIcon className="modalClose" onClick={data.cancelHook} />
              <h1>{data.title}</h1>
            </div>
            <div className="modalContent">{data.content && data.content}</div>
            {!!!data.hideButton && (
              <div>
                <button className="button-medium main-button modalButton" type="button" onClick={data.submitHook}>
                  {data.buttonText || t('continue')}
                </button>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  )
}

const DepositHowBoWorksModal = ({ data }: { data: Data }) => {
  const { i18n, t } = useTranslation()

  const [step, setStep] = useState(0)
  const [tutorial, setTutorial] = useState<JSX.Element[]>([])

  const selectedLanguage = i18n.language
  useEffect(() => {
    const onLoad = () => {
      selectedLanguage.indexOf('en') === -1 ? setTutorial(TutorialFr.slice(1)) : setTutorial(TutorialEng.slice(1))
    }

    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage])

  const onNext = () => {
    setStep(step + 1)
  }

  const onBack = () => {
    setStep(step - 1)
  }

  return (
    <>
      {data.show && (
        <section className="modalWrapper deposit-tutorial" onClick={data.cancelHook}>
          <div className="modalContainer" onClick={(e: any) => e.stopPropagation()}>
            <div className="welcome-section">
              {tutorial[step]}
              <div className="welcome-footer">
                <div className="carousel-page-indicators">
                  {tutorial.map((element, index) => {
                    return <div key={index} className={`${index === step ? 'currentStep' : 'step'}`}></div>
                  })}
                </div>
                <div className="buttons-forward-back">
                  {step === 0 && (
                    <>
                      <button className="button-medium main-button" onClick={onNext}>
                        {t('next')}
                      </button>
                      <button className="button-medium naked-button" onClick={data.cancelHook}>
                        {t('close')}
                      </button>
                    </>
                  )}
                  {step !== tutorial.length - 1 && step !== 0 && (
                    <>
                      <button className="button-medium main-button" onClick={onNext}>
                        {t('next')}
                      </button>
                      <button className="button-medium naked-button" onClick={onBack}>
                        {t('back')}
                      </button>
                    </>
                  )}
                  {step === tutorial.length - 1 && (
                    <>
                      <button className="button-medium main-button" onClick={data.cancelHook}>
                        {t('close')}
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export { MainModal, DepositHowBoWorksModal }
