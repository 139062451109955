import Logo from './Logo'
import './SplashScreen.css'

const SplashScreen = ({ show = true, withFooter = true }: { show?: boolean; withFooter?: boolean }) => {
  const className = withFooter ? 'splash-screen with-footer' : 'splash-screen'
  return (
    <>
      {show && (
        <div className={className}>
          <Logo />
        </div>
      )}
    </>
  )
}

export { SplashScreen as default }
