import { CSSProperties } from 'react'

interface MyCustomCSS extends CSSProperties {
  '--progress': string
}

type StepCounterData = {
  text: string
  percentage: number
  className?: string
}

const StepCounter = (data: StepCounterData) => {
  return <div className={`step-counter ${data.className ? data.className : ''}`} data-progress={data.text} style={{ '--progress': `${data.percentage}deg` } as MyCustomCSS}></div>
}

export { StepCounter as default }
