import { useEffect, useState } from 'react'
import { useAPI } from '../../api/api'
import SplashScreen from '../../components/SplashScreen'
import CheckItem from './CheckItem'
import InternalReturn from './InternalReturn'

const CheckItemRender = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isInternalEcosystem, setIsInternalEcosystem] = useState(false)
  const [loaded, setLoaded] = useState(false)

  let { wrappedExtendedQueryAuth } = useAPI()

  useEffect(() => {
    const internalEcosystemCheck = async () => {
      const query = `query {isInternalEcosystem}`

      const response = await wrappedExtendedQueryAuth(query, undefined)

      setIsInternalEcosystem(response.isInternalEcosystem)
      setLoaded(true)
    }

    internalEcosystemCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (loaded) setIsLoading(false)
  }, [loaded])

  return isLoading ? <SplashScreen withFooter={false} /> : isInternalEcosystem ? <InternalReturn /> : <CheckItem />
}

export { CheckItemRender as default }
