import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import SuccessImage from '../../images/success.png'
import './Deposit.css'

const DepositSuccess = () => {
  let { t } = useTranslation()
  let history = useHistory()

  const onClick = () => {
    history.push('/')
  }

  return (
    <main className="mainHome">
      <div className="screen-header">
        <img src={SuccessImage} alt="Green tick" />
        <h1>{t('return-successful')}</h1>
      </div>
      <div className="success-complete">
        <p className="general-paragraph">
          <label>{t('account-created-thank-you')}</label>
        </p>
        <p className="general-paragraph">
          <Trans i18nKey="return-successful-contact-text" />
        </p>
      </div>
      <div className="footer-button-section">
        <button className="button-medium main-button" onClick={onClick}>
          {t('go-home')}
        </button>
      </div>
    </main>
  )
}

export { DepositSuccess as default }
