import { ReactComponent as Icon } from '../icons/active.svg'

import './Icons.css'

const SuccessIcon = () => {
  return <Icon className="bo-success" />
}
const FailIcon = () => {
  return <Icon className="bo-fail" />
}
const PendingIcon = () => {
  return <Icon className="bo-pending" />
}

export { SuccessIcon, FailIcon, PendingIcon }
