import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'
import BackButton from '../components/BackButton'
import ChangePassword from '../components/ChangePassword'
import UpdateCreditCard from '../components/common/UpdateCreditCard'
import CustomerEditProfile from '../components/customer/CustomerEditProfile'
import { LanguageRow } from '../components/LanguageSelector'
import Logo from '../components/Logo'
import './EditUser.css'

const EditUser = () => {
  const { t } = useTranslation()
  let auth = JSON.parse(localStorage.getItem('auth') || '')
  let role = auth.role

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')

  return (
    <main className="justify-normal edit-user">
      <BackButton />
      <Logo />
      <LanguageRow />
      <ChangePassword />
      {role === 'client' && (
        <>
          <h2>{t('edit-cc')}</h2>
          <Elements stripe={stripePromise}>
            <UpdateCreditCard />
          </Elements>
          <h2>{t('edit-profile-info')}</h2>
          <CustomerEditProfile />
        </>
      )}
    </main>
  )
}

export { EditUser as default }
