import { useState } from 'react'
import { LocationCoords } from '../containers/customer/ReturnItemLocation'
import apiUtils from './apiUtils'
import { APIError } from './response'

const useAPI = () => {
  const [fetching, setFetching] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState<APIError[]>([])
  const [currentPosition, setCurrentPosition] = useState<LocationCoords>()

  const { extendedQueryAuth, extendedQuery, extendedMutateAuth, extendedMutate } = apiUtils()

  const wrappedExtendedQueryAuth = async (query: string, factory: any) => {
    return await extendedQueryAuth(query, setErrors, setFetching, factory)
  }

  const wrappedExtendedMutateAuth = async (query: string, factory?: any) => {
    return await extendedMutateAuth(query, setErrors, setSubmitting, factory)
  }

  const wrappedExtendedQuery = async (query: string, factory: any) => {
    return await extendedQuery(query, setErrors, setFetching, factory)
  }

  const wrappedExtendedMutate = async (query: string, factory?: any) => {
    return await extendedMutate(query, setErrors, setFetching, factory)
  }

  const getCurrentPosition = async () => {
    console.debug('Getting position')

    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.debug(position)

        if (position) {
          setCurrentPosition({ latitude: position.coords.latitude, longitude: position.coords.longitude })
        } else {
          setCurrentPosition(undefined)
        }
      },
      function (error) {
        setErrors(errors)
        setCurrentPosition(undefined)
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      },
    )
  }

  const apiPost = async (endpoint: string, body: string) => {
    let bearer_token: string = ''
    const auth = localStorage.getItem('auth')
    if (auth) {
      bearer_token = JSON.parse(auth).token
    }

    const url = `${process.env.REACT_APP_API_URL}/${endpoint}`

    let bearer = 'Bearer ' + bearer_token

    const options = {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: bearer,
      },
      body,
    }

    const response = await fetch(url, options)
    // .then(response => response.json())
    // .then(data => {
    //   console.log(data);
    // })

    return response
  }

  return {
    fetching,
    setFetching,
    submitting,
    setSubmitting,
    errors,
    currentPosition,
    getCurrentPosition,
    setErrors,
    extendedQueryAuth,
    wrappedExtendedQueryAuth,
    wrappedExtendedQuery,
    extendedMutateAuth,
    wrappedExtendedMutateAuth,
    extendedMutate,
    wrappedExtendedMutate,
    apiPost,
  }
}

export { useAPI }
