import { Menu, MenuItem } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAPI } from '../../api/api'
import ApiUtils from '../../api/apiUtils'
import { ReactComponent as MenuIcon } from '../../icons/menu.svg'

import './MainMenu.css'

const MainMenu = () => {
  let history = useHistory()
  const { t } = useTranslation()
  let { removeAuthFromLocalStorage } = ApiUtils()
  let { wrappedExtendedMutateAuth } = useAPI()

  const handleLogout = async () => {
    const mutation = `mutation {logout}`

    await wrappedExtendedMutateAuth(mutation)

    await removeAuthFromLocalStorage()
    history.push('/')
  }

  const handleContact = () => {
    history.push('/contact')
  }

  const handleProfile = () => {
    history.push('/edit/profile')
  }

  return (
    <Menu menuButton={<MenuIcon className="menu-icon" />} transition align="end" direction="bottom" position="anchor">
      <MenuItem onClick={handleProfile}>{t('edit-profile')}</MenuItem>
      <MenuItem onClick={handleContact}>{t('contact')}</MenuItem>
      <MenuItem onClick={handleLogout}>{t('log-out')}</MenuItem>
    </Menu>
  )
}

export { MainMenu as default }
