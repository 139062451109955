import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import apiUtils from '../api/apiUtils'
import { APIError } from '../api/response'
import ErrorElement from './ErrorElement'
import { LoadingSubmitButton } from './LoadingButton'
import { ConfirmPasswordInput, PasswordInput, PwChecklist, ShowPasswordCheck } from './PasswordInput'
import SuccessElement from './SuccessElement'

const ChangePassword = () => {
  const { t } = useTranslation()

  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState<APIError[]>([])

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [isDifferentPassword, setIsDifferentPassword] = useState(true)
  const [isValidPw, setIsValidPw] = useState(false)
  const [showPw, setShowPw] = useState(false)
  const [msgs, setMsgs] = useState<APIError[]>([])

  const { extendedMutateAuth, setAuthToLocalStorage } = apiUtils()

  useEffect(() => {
    if (newPassword && newPassword === oldPassword) {
      setIsDifferentPassword(false)
    } else {
      setIsDifferentPassword(true)
    }
  }, [oldPassword, newPassword])

  const onSubmitPassword = async (e: FormEvent) => {
    setMsgs([])
    e.preventDefault()

    if (!isDifferentPassword) {
      setErrors([{ msg: t('password.same-as-last') }])
      return
    }

    const mutation = `mutation {updatePassword (input: {
      oldPassword: "${oldPassword}"
      newPassword: "${newPassword}"
    }) {token registrationState email role boId name}}`

    const response = await extendedMutateAuth(mutation, setErrors, setSubmitting)

    if (response) {
      setMsgs([{ msg: t('password.changed') }])
      setOldPassword('')
      setNewPassword('')
      setConfirmNewPassword('')
      setAuthToLocalStorage(response.updatePassword)
    }
  }

  const handleShowPwChange = () => {
    setShowPw(!showPw)
  }

  const handlePwChecklist = (isValid: boolean) => {
    setIsValidPw(isValid)
  }

  return (
    <form onSubmit={onSubmitPassword}>
      <ErrorElement errors={errors} />
      <SuccessElement msgs={msgs} />
      <h2>{t('password.change-password')}</h2>
      <PasswordInput label={t('password.old-password')} value={oldPassword} showPw={showPw} onChange={e => setOldPassword(e.target.value)} />
      <PasswordInput label={t('password.main')} value={newPassword} showPw={showPw} onChange={e => setNewPassword(e.target.value)} />
      <PwChecklist value={newPassword} onChange={handlePwChecklist} valueAgain={confirmNewPassword} />
      <ConfirmPasswordInput label={t('password.confirm')} value={confirmNewPassword} showPw={showPw} onChange={e => setConfirmNewPassword(e.target.value)} />
      <ShowPasswordCheck checked={showPw} onChange={handleShowPwChange} />
      <LoadingSubmitButton data={{ loading: submitting, text: t('save'), disabled: !isValidPw }} />
    </form>
  )
}

export { ChangePassword as default }
