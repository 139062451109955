import { Trans } from 'react-i18next'

type AddItemTypeRowData = {
  index: number
  label1: string
  label2: string | undefined
  quantity: number | ''
  unitPrice: number | ''
  callbackQuantity: any
  callbackPriceChange: any
  remove?: any
}

const AddOrderRow = ({ data }: { data: AddItemTypeRowData }) => {
  return (
    <div className="AddItemTypeRow" key={data.index}>
      <div>
        <div className="label1">{data.label1}</div>
        <div className="label2">{data.label2}</div>
        {data.remove && (
          <label className="yellow-link" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={e => data.remove(data.index)}>
            <Trans i18nKey="remove" />
          </label>
        )}
      </div>
      <div>
        <input type="number" onChange={e => data.callbackQuantity(data.index, e.target.value)} value={data.quantity || 0}></input>
      </div>
      <div>
        <input type="number" onChange={e => data.callbackPriceChange(data.index, e.target.value)} value={data.unitPrice || 0} step="0.01"></input>
      </div>
    </div>
  )
}

export { AddOrderRow }
