import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ActionBar from '../../../components/admin/ActionBar'
import { LocationData } from '../../../components/admin/LocationCard'
import Card from '../../../components/Card'
import ErrorElement from '../../../components/ErrorElement'
import Navbar from '../../../components/Navbar'
import Searchbar from '../../../components/Searchbar'
import { FailIcon, SuccessIcon } from '../../../components/Icons'

import { ReactComponent as AddIcon } from '../../../icons/plus.svg'
import '../../CategoryHome.css'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import SplashScreen from '../../../components/SplashScreen'
import moment from 'moment'
import IconButton from '../../../components/buttons/IconButton'

type BoStatsData = {
  assigns: number
  returns: number
}

type ReturnsData = {
  locations: LocationData[]
  stats: BoStatsData
}

const Locations = () => {
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const { t } = useTranslation()

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  const [locations, setLocations] = useState<LocationData[]>([])
  const [stats, setStats] = useState<BoStatsData>()

  useEffect(() => {
    async function onLoad() {
      const query = `query { 
        dropOffLocations { _id name status quantity address firstReturnOn }
        boStats {assigns returns} 
      }`
      const factory = (result: any): ReturnsData => {
        return {
          locations: result.dropOffLocations.map((l: any) => ({ ...l })),
          stats: result.boStats,
        }
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      response.locations
        .sort((a: LocationData, b: LocationData) => {
          const distantFuture = new Date(8640000000000000)
          let dateA = a.firstReturnOn ? new Date(a.firstReturnOn) : distantFuture
          let dateB = b.firstReturnOn ? new Date(b.firstReturnOn) : distantFuture
          return dateA.getTime() - dateB.getTime()
        })
        .sort((a: LocationData, b: LocationData) => {
          if (a.status && b.status) {
            return a.status.localeCompare(b.status)
          }
          return 0
        })

      if (response) {
        setLocations(response.locations)
        setStats(response.stats)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickAdd = () => {
    history.push('/admin/locations/add')
  }

  return (
    <>
      <Navbar></Navbar>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <section className="HomeSection WithNavbar">
          <header>
            <h1>{t('locations.locations')}</h1>
            <IconButton data={{ className: 'add-button', type: 'button', icon: <AddIcon />, onClick: onClickAdd }} />
          </header>
          <Searchbar></Searchbar>
          <hr />
          <ErrorElement errors={errors} />
          {stats && (
            <>
              <p>
                <strong>Lifetime stats:</strong> <strong>{stats.assigns}</strong> assigned and <strong>{stats.returns}</strong> returned containers
              </p>
            </>
          )}
          <hr />
          {locations.length === 0 ? (
            <p className="empty-list-msg">{t('empty.locations')}</p>
          ) : (
            locations.map(location => (
              <Card
                key={location._id}
                data={{
                  labelMain: location.name,
                  labelTopRight: `${location.quantity} ${t('piece')}`,
                  labelSub1: `${t('locations.first-return-on')}: ${location.firstReturnOn ? moment.utc(location.firstReturnOn).format('YYYY-MM-DD') : 'N/A'}`,
                  labelSub2: `${location.address}`,
                  labelBotRight: location.status && t(`status.${location.status}`),
                  iconBotRight: location.status === 'active' ? <SuccessIcon /> : <FailIcon />,
                  linkTo: '/admin/locations/' + location._id,
                }}
              />
            ))
          )}
        </section>
      )}
      <ActionBar></ActionBar>
    </>
  )
}

export { Locations as default }
