import moment from 'moment'
import { useEffect, useState } from 'react'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAPI } from '../../api/api'
import CardWithIcon from '../../components/CardWithIcon'
import CardAccordionPanel from '../../components/common/CardAccordionPanel'
import AccountSuspendedNotice from '../../components/customer/AccountSuspendedNotice'
import ActionBar from '../../components/customer/ActionBar'
import { CustomerItemData } from '../../components/CustomerItem'
import ErrorElement from '../../components/ErrorElement'
import Navbar from '../../components/Navbar'
import { ReactComponent as IDIcon } from '../../icons/id-icon.svg'
import { ReactComponent as AssignIcon } from '../../icons/arrows-right.svg'
import { ReactComponent as ContainerIcon } from '../../icons/inventory.svg'
import { CustomerPaths } from '../../routes/RoutePaths'
import QRCode from 'qrcode.react'

import '../CategoryHome.css'
import i18n from '../../i18n'
import 'moment/locale/fr'
import SplashScreen from '../../components/SplashScreen'
import { CustomDialog } from '../../components/Dialog'

const Items = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  let history = useHistory()
  let lang = i18n.language

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  const [customerItems, setCustomerItems] = useState<CustomerItemData[]>([])
  const [boId, setBoId] = useState('')
  const [showQRcode, setShowQRcode] = useState(false)

  useEffect(() => {
    async function onLoad() {
      const auth = localStorage.getItem('auth')

      if (auth) {
        setBoId(JSON.parse(auth).boId)
      }

      const query = `query { clientDueDates { _id itemType { _id name capacity iconUrl dimensions { w h l } } dueDate invoiceUrl } }`
      const factory = (result: any): CustomerItemData[] => {
        return result.clientDueDates.map((i: any) => ({
          _id: i._id,
          itemCount: 0,
          dueDate: i.dueDate,
          dueDates: [],
          itemType: i.itemType,
          invoiceUrl: i.invoiceUrl,
        }))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        const result: any = []
        if (response) {
          for (const dueDate of response) {
            let index = result.findIndex((r: any) => r.itemType._id === dueDate.itemType._id && !r.invoiceUrl)

            if (index < 0) {
              result.push(dueDate)
              index = result.length - 1
            }

            result[index].dueDates.push(dueDate.dueDate)
            result[index].itemCount++
          }
        }

        setCustomerItems(result)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onInvoiceClick = (url: string | undefined) => {
    if (url) {
      window.open(url, '_blank')
    }
  }

  const dialogCancel = (e: any) => {
    setShowQRcode(false)
  }
  const dialogSubmit = (e: any) => {
    setShowQRcode(false)
  }

  return (
    <>
      <Navbar></Navbar>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <section className="HomeSection homeUsers WithNavbar">
          <div>
            <header>
              <h1>{t('inventory')}</h1>
            </header>
            <div className="bo-id">
              <div className="home-bo-id">
                <label>{t('bo-id')}</label>
                <div>
                  <label>{boId}</label>
                  <div className="show-qr-button" onClick={e => setShowQRcode(true)}>
                    <IDIcon className="barcode-icon" />
                  </div>
                </div>
              </div>
              {showQRcode && (
                <CustomDialog
                  data={{
                    cancelHook: dialogCancel,
                    submitHook: dialogSubmit,
                    show: showQRcode,
                    title: t('bo-id'),
                    content: (
                      <div className="customer-qr-code">
                        <QRCode className="qr-code" value={boId} renderAs="canvas" size={256} />
                      </div>
                    ),
                    position: 'top',
                  }}
                />
              )}
            </div>
            <AccountSuspendedNotice />
            <ErrorElement errors={errors} />
            <Accordion allowZeroExpanded allowMultipleExpanded>
              {customerItems.length === 0 ? (
                <p className="empty-list-msg">{t('empty.inventory')}</p>
              ) : (
                customerItems.map(item => (
                  <AccordionItem key={item.invoiceUrl ? item.invoiceUrl : item.itemType._id}>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        <CardWithIcon
                          data={{
                            iconUrl: item.itemType.iconUrl,
                            labelMain: item.itemType.name,
                            labelTopRight: item.invoiceUrl ? t('expired') + moment.utc(item.dueDate).format('YYYY-MM-DD') : t('return-by') + moment.utc().locale(lang).to(moment(item.dueDate)),
                            labelSub1: `${item.itemCount} ${t('piece')}`,
                            invoiceUrl: item.invoiceUrl,
                            onInvoiceClick: onInvoiceClick,
                          }}
                        />
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    {!item.invoiceUrl && (
                      <AccordionItemPanel>
                        {item.dueDates && item.itemCount > 1 ? <CardAccordionPanel data={{ heading: t('more-due-dates'), mappedRightLabels: item.dueDates }} /> : <CardAccordionPanel data={{ rightLabel: t('no-due-dates') }} />}
                      </AccordionItemPanel>
                    )}
                  </AccordionItem>
                ))
              )}
            </Accordion>
          </div>
          <div className="homeMainButtons userMainButtons">
            <div className="homeMainButton borrow" onClick={e => history.push(CustomerPaths.Borrow)}>
              <AssignIcon />
              <label>{t('borrow-containers')}</label>
            </div>
            <div className="homeMainButton return" onClick={e => history.push(CustomerPaths.CheckItem)}>
              <ContainerIcon />
              <label>{t('check-container-status')}</label>
            </div>
          </div>
        </section>
      )}
      <ActionBar></ActionBar>
    </>
  )
}

export { Items as default }
