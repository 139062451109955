import { QrScannerExpanded } from '../../components/QRScannerExpanded'
import SplashScreen from '../../components/SplashScreen'
import { ItemToAssignData } from '../merchant/AssignItems'
import { ReactComponent as ArrowLeftIcon } from '../../icons/arrow-short-left.svg'
import { ReactComponent as ScanIcon } from '../../icons/qr_code.svg'
import { ReactComponent as ListIcon } from '../../icons/list.svg'
import { ReactComponent as BinIcon } from '../../icons/bin.svg'
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg'
import { Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../api/api'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment'
import i18n from '../../i18n'

type ItemListData = {
  _id: string
  itemTypeIcon: string
  items: ItemToAssignData[]
}

const Borrow = () => {
  let { t } = useTranslation(['translation', 'errors'])
  let history = useHistory()
  const lang = i18n.language

  let { errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const [isLoading, setIsLoading] = useState(true)
  const [ended, setEnded] = useState(false)
  const [showRemoveScanner, setShowRemoveScanner] = useState(false)
  const [showReturnOverview, setShowReturnOverview] = useState(false)

  const [isScanning, setIsScanning] = useState('false')
  const [activeTab, setActiveTab] = useState(1)
  const [scannerActive, setScannerActive] = useState(true)
  const [scannedItems, setScannedItems] = useState<ItemToAssignData[]>([])
  const [itemListByType, setItemListByType] = useState<ItemListData[]>([])
  const [itemTypeExpanded, setItemTypeExpanded] = useState<ItemToAssignData[]>([])
  const [lastScannedItem, setLastScannedItem] = useState<ItemToAssignData | undefined>()

  useEffect(() => {
    setIsLoading(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const onErrors = () => {
      if (ended) {
        if (errors.length > 0) {
          errors.forEach(error => {
            toast.error(t(error.msg, { ns: 'errors' }))
          })
        }
      }
    }
    return () => {
      onErrors()
      setEnded(false)
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended])

  const onScanToRemove = () => {
    setShowRemoveScanner(true)
  }

  const onScanToRemoveQRCode = async (code: string) => {
    if (!code.includes('items/')) {
      toast.error(t('invalid-item-qr-code'))
      return
    }

    const url = new URL(code)
    const uuid = url.pathname.split('/').pop() || ''
    const index = scannedItems.findIndex(r => r.uuid === uuid)
    if (index < 0) {
      toast.error(t('item-not-added'))
      return
    }

    let tempType = ''

    setScannedItems(
      scannedItems.filter(item => {
        if (item.uuid === uuid) tempType = item.label
        return item.uuid !== uuid
      }),
    )

    let tempItemListByType: ItemListData[] = []

    for (const type of itemListByType) {
      const y = type.items.filter(item => item.uuid !== uuid)
      tempItemListByType.push({ _id: type._id, itemTypeIcon: type.itemTypeIcon, items: y })
    }

    toast.info(`${tempType} ${t('removed-from-list')}`)
    setItemListByType(tempItemListByType)
  }

  useEffect(() => {
    if (!lastScannedItem) return
    const alreadyExist = scannedItems.find(q => q.id === lastScannedItem.id)
    if (alreadyExist) {
      toast.warning(t('item-aready-added'))
      return
    }

    toast.success(`${lastScannedItem.label} ${t('added-successfully')}`)
    addToList(lastScannedItem)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScannedItem])

  const addToList = async (data: any) => {
    let index = itemListByType.findIndex(r => r._id === data.itemTypeId)
    if (index < 0) {
      setItemListByType(itemListByType => {
        return [...itemListByType, { _id: data.itemTypeId, itemTypeIcon: data.iconUrl, items: [data] }]
      })
      setScannedItems(scannedItems => {
        return [...scannedItems, data]
      })
    } else {
      let itemIndex = itemListByType[index].items.findIndex(i => i.id === data._id)
      if (itemIndex < 0) {
        const tempItemList = itemListByType
        tempItemList[index].items.push(data)
        setItemListByType(tempItemList)
        setScannedItems(scannedItems => [...scannedItems, data])
      } else {
        toast.error(t('item-aready-added'))
      }
    }
  }

  const onClickToRemove = async (uuid: string) => {
    let tempType = ''

    setScannedItems(
      scannedItems.filter(item => {
        if (item.uuid === uuid) tempType = item.label
        return item.uuid !== uuid
      }),
    )

    setItemTypeExpanded(itemTypeExpanded.filter(item => item.uuid !== uuid))

    let tempItemListByType: ItemListData[] = []

    for (const type of itemListByType) {
      const y = type.items.filter(item => item.uuid !== uuid)
      tempItemListByType.push({ _id: type._id, itemTypeIcon: type.itemTypeIcon, items: y })
    }

    toast.info(`${tempType} ${t('removed-from-list')}`)
    setItemListByType(tempItemListByType)
  }

  const onScanToRemoveCancecl = () => {
    setShowRemoveScanner(false)
  }

  useEffect(() => {
    const onLoad = async () => {
      if (scannedItems.length < 1) {
        setActiveTab(1)
        setScannerActive(true)
      }
    }
    onLoad()
  }, [scannedItems])

  const onFinishScanning = () => {
    setShowReturnOverview(true)
  }

  const onClickScanner = () => {
    setActiveTab(1)
    setScannerActive(true)
  }

  const onClickList = () => {
    setActiveTab(2)
    setScannerActive(false)
  }

  const onItemTypeClick = (items: ItemToAssignData[]) => {
    if (items.length > 0) {
      setItemTypeExpanded(items)
    }
  }

  const onQrCode = async (code: string) => {
    onQrCodeRead(code).then(() => setIsScanning('false'))
  }

  const onQrCodeRead = async (code: string) => {
    setLastScannedItem(undefined)

    if (!code.includes('items/')) {
      toast.error(t('invalid-item-qr-code'))
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    const query = `query { itemsByIdSelfAssign (filter : {uuid : "${qr}"}) {assignableItems { _id uuid serialNumber itemType { _id name iconUrl capacity dimensions {w h l} }} nonAssignableItems {_id} }}`
    const factory = (result: any): any => {
      const assignableItems: ItemToAssignData = result.itemsByIdSelfAssign.assignableItems.map((o: any) => ({
        id: o._id,
        serialNumber: o.serialNumber || null,
        label: o.itemType.name,
        itemTypeId: o.itemType._id,
        iconUrl: o.itemType.iconUrl,
        uuid: o.uuid,
        itemTypeName: o.itemType.name,
        capacity: o.itemType.capacity || 0,
        dimensions: {
          w: o.itemType.dimensions.w || 0,
          h: o.itemType.dimensions.h || 0,
          l: o.itemType.dimensions.l || 0,
        },
      }))[0]

      const nonAssignableItems: string | null = result.itemsByIdSelfAssign.nonAssignableItems[0]?._id

      return { assignableItems, nonAssignableItems }
    }

    const response = await wrappedExtendedQueryAuth(query, factory)

    if (response.nonAssignableItems && response.nonAssignableItems.length > 0) {
      toast.error(t('already-in-inventory'))
      return
    }

    const item = response.assignableItems

    if (!item) {
      toast.error(t('item-does-not-exist'))
      return
    }

    setLastScannedItem(item)
  }

  const onConfirmAndSubmit = async () => {
    setIsLoading(true)
    if (scannedItems.length < 1) {
      toast.error(t('unknown-error-occured'))
      return
    }

    let itemIds: string = ''
    scannedItems.forEach(item => {
      itemIds += `"${item.id}",`
    })

    const mutation = `mutation {
      selfAssignItems(input: {
            itemIds: [${itemIds}]
        }) {failedToAssignItems previouslyAssignedToUserItems}
    }`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (!response) {
      setIsLoading(false)
      toast.error(t('unknown-error-occured'))
    }

    history.replace('/customer/borrow/success', { itemListByType: itemListByType })
  }

  const onCancel = () => {
    history.push('/customer/items')
  }

  return (
    <>
      {isLoading ? (
        <SplashScreen withFooter={false} />
      ) : showReturnOverview ? (
        <main className="mainHome">
          <div className="screen-header">
            <Link to="#" replace className="back" onClick={() => setShowReturnOverview(false)}>
              <ArrowLeftIcon className="bo-back" />
              {t('back')}
            </Link>
            <h1>{t('order-overview')}</h1>
          </div>
          <div className="items-overview">
            <div className="items-overview-header">
              <label>{t('item')}</label>
              <label>{t('quantity')}</label>
            </div>
            <div className="items-overview-listed">
              {itemListByType.map(type => (
                <div key={type._id} className="items-overview-item-type">
                  <div className="items-overview-item-type-info">
                    <label>{t(type.items[0].label)}</label>
                    <label>{type.items.length}</label>
                  </div>
                  <div className="custom-divider"></div>
                </div>
              ))}
            </div>
            <div className="items-overview-totals">
              <label>{t('orders.total')}</label>
              <label>{scannedItems.length}</label>
            </div>
            <div className="borrow-reminder">
              <label>{t('self-borrow-reminder')}</label>
              <label>{t('14-days')}</label>
            </div>
          </div>
          <div className="buttonDock-150"></div>
          <div className="floating-buttons">
            <button className="button-medium main-button" onClick={onConfirmAndSubmit}>
              {t('confirm-and-borrow')}
            </button>
            <button className="button-medium naked-button" onClick={onCancel}>
              {t('cancel')}
            </button>
          </div>
        </main>
      ) : showRemoveScanner ? (
        <section className="qrScanner qr-scanner-expanded">
          <QrScannerExpanded callback={onScanToRemoveQRCode} callbackCancel={onScanToRemoveCancecl} heading={t('scan-remove')} text={t('scan-remove-text')} cancelBtnText={t('finish-removing')} cancelBtnClassName="circled-naked-button" />
        </section>
      ) : itemTypeExpanded.length > 0 ? (
        <main className="mainHome">
          <div className="screen-header">
            <Link to="#" replace className="back" onClick={() => setItemTypeExpanded([])}>
              <ArrowLeftIcon className="bo-back" />
              {t('back')}
            </Link>
            <div className="item-type-info">
              <img className="item-icon" alt="item-icon" src={itemTypeExpanded[0].iconUrl || t('no-image-available')} />
              <label>{itemTypeExpanded[0].label}</label>
            </div>
          </div>
          <div className="scanned-items-container">
            <label>
              {itemTypeExpanded.length} {t('scanned-items')}
            </label>
            {itemTypeExpanded.map(item => (
              <div key={item.uuid} className="scanned-item-label">
                {item.serialNumber && (
                  <>
                    <div className="label-with-bin">
                      <label>{t('serial-number')}</label>
                      <BinIcon onClick={() => onClickToRemove(item.uuid)} />
                    </div>
                    <label>{item.serialNumber}</label>
                  </>
                )}
              </div>
            ))}
          </div>
        </main>
      ) : (
        <>
          {scannedItems.length < 1 ? (
            <></>
          ) : (
            <section className={`custom-tab-header ${scannerActive ? 'custom-tab-scanner-active' : ''}`}>
              <div className={`custom-tab ${activeTab === 1 ? 'active' : ''}`} onClick={onClickScanner}>
                <ScanIcon />
                <label>{t('scanner')}</label>
              </div>
              <div className={`custom-tab ${activeTab === 2 ? 'active' : ''}`} onClick={onClickList}>
                <ListIcon />
                <label>
                  {t('scanned-items')} ({scannedItems.length})
                </label>
              </div>
            </section>
          )}
          {activeTab === 1 && (
            <div key={isScanning} className={`custom-tab-panel ${activeTab === 1 && 'active-tab-panel'}`}>
              <section className="qrScanner qr-scanner-expanded">
                <QrScannerExpanded
                  callback={onQrCode}
                  callbackCancel={onCancel}
                  onContinueBtn={onFinishScanning}
                  heading={t('scan-containers')}
                  text={t('scan-containers-text')}
                  showContinueBtn={true}
                  continueBtnText={t('finish-scanning')}
                  className="tabbed-scanner"
                  continueBtnDisabled={scannedItems.length > 0 ? false : true}
                />
              </section>
            </div>
          )}
          {activeTab === 2 && (
            <div className={`custom-tab-panel ${activeTab === 2 && 'active-tab-panel'}`}>
              {lastScannedItem && (
                <div className="last-scanned-item">
                  <label>{t('last-scanned')}</label>
                  <div className="last-scanned-info-container">
                    <div className="last-scanned-item-type-info">
                      <img className="item-icon" alt="item-icon" src={lastScannedItem.iconUrl || t('no-image-available')} />
                      <label>{lastScannedItem.label}</label>
                    </div>
                    <div className="last-scanned-info">
                      <div className="last-scanned-info-item">
                        {lastScannedItem.serialNumber && (
                          <>
                            <label>{t('serial-number')}</label>
                            <label>{lastScannedItem.serialNumber}</label>
                          </>
                        )}
                      </div>
                      <div className="last-scanned-info-item">
                        {lastScannedItem.capacity > 0 ? (
                          <>
                            <label>{t('capacity')}</label>
                            <label>{lastScannedItem.capacity} oz.</label>
                          </>
                        ) : (
                          <></>
                        )}
                        {lastScannedItem.dimensions.w && lastScannedItem.dimensions.h && lastScannedItem.dimensions.l ? (
                          <>
                            <label>{t('dimensions')}</label>
                            <label>
                              {lastScannedItem.dimensions.h} x {lastScannedItem.dimensions.l} x {lastScannedItem.dimensions.w} in
                            </label>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="last-scanned-info-item">
                        <label>{t('due')}</label>
                        <label>{moment.utc().add(14, 'days').locale(lang).format('MMM Do YYYY')}</label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="deposit-item-list">
                <div className="deposit-item-list-header">
                  <label>{t('item-list')}</label>
                  <label className="scan-to-remove" onClick={onScanToRemove}>
                    <BinIcon />
                    {t('scan-remove')}
                  </label>
                </div>
                <div className="items-categorized">
                  {itemListByType &&
                    itemListByType.length > 0 &&
                    itemListByType.map(
                      (type, index) =>
                        type.items &&
                        type.items.length > 0 && (
                          <div key={type.items[0].label}>
                            <div className="categorized-item" onClick={() => onItemTypeClick(type.items)}>
                              <div className="categorized-item-info">
                                <div className="categorized-item-type-info">
                                  <img className="item-icon" alt="item-icon" src={type.itemTypeIcon || t('no-image-available')} />
                                  <label>{type.items[0].label}</label>
                                </div>
                                <div className="item-type-count">
                                  <label>({type.items.length})</label>
                                  <ArrowRightIcon />
                                </div>
                              </div>
                            </div>
                            <div className="custom-divider"></div>
                          </div>
                        ),
                    )}
                </div>
              </div>
              <div className="buttonDock-150"></div>
              <div className="floating-buttons">
                <button className="button-medium main-button" onClick={onFinishScanning}>
                  {t('finish-scanning')}
                </button>
                <button className="button-medium naked-button" onClick={onCancel}>
                  {t('cancel')}
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}

export { Borrow as default }
