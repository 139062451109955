import { FormEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import BackButton from '../../../components/BackButton'
import statusOptions, { SelectOptions } from '../MainStatuses'
import { LocationData } from '../../../components/admin/LocationCard'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import ErrorElement from '../../../components/ErrorElement'
import SelectMenu from '../../../components/SelectMenu'
import { useAPI } from '../../../api/api'
import { setNotification } from '../../../components/Notification'
import SplashScreen from '../../../components/SplashScreen'

const EditLocation = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  const history = useHistory()

  let { submitting, errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const { id }: any = useParams()

  const [location, setLocation] = useState<LocationData>()
  const [name, setName] = useState('')
  const [capacity, setCapacity] = useState(0)
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [address, setAddress] = useState('')
  const [workTime, setWorkTime] = useState('')
  const [website, setWebsite] = useState<string | undefined>('')
  const [status, setStatus] = useState('')

  const [selectValue, setSelectValue] = useState<SelectOptions>()
  const [newStatus, setNewStatus] = useState('')

  useEffect(() => {
    async function onLoad() {
      const query = `query { dropOffLocations (filter: { _id: "${id}"}) { _id name status capacity address workTime website coordinates { latitude longitude } } }`
      const factory = (result: any): LocationData[] => {
        return result.dropOffLocations && Array.isArray(result.dropOffLocations) && result.dropOffLocations.length > 0 ? result.dropOffLocations[0] : null
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) setLocation(response)
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onLocationLoad() {
      if (!location) {
        return
      }
      if (location.status) {
        setNewStatus(location.status)
        setStatus(location.status)
      }
      setSelectValue(statusOptions.find(s => s.value === location.status))
      setName(location.name)
      setCapacity(location.capacity)
      setLatitude(location.coordinates.latitude)
      setLongitude(location.coordinates.longitude)
      setAddress(location.address)
      setWorkTime(location.workTime)
      setWebsite(location.website)
    }
    onLocationLoad()
  }, [location])

  const handleStatusChange = (value: any, action: any) => {
    if (action.action === 'select-option') {
      setNewStatus(value.value)
      setSelectValue(value)
    }
  }

  useEffect(() => {
    async function onSelectChange() {
      setStatus(newStatus)
    }
    onSelectChange()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue])

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const mutation = `mutation { updateDropOffLocation ( input: { _id: "${id}" name: "${name}" status: "${status}" capacity: ${capacity} address: "${address}" workTime: """${workTime}""" website: "${website}" coordinates: { latitude: ${latitude} longitude: ${longitude} } } ) { _id } }`

    const response = await wrappedExtendedMutateAuth(mutation)
    if (response) {
      setNotification('locations.edited')
      history.goBack()
    }
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <form onSubmit={onSubmit} className="object-form">
      <BackButton />
      <h2>{t('locations.edit')}</h2>
      <ErrorElement errors={errors} />
      <label>{t('status.status')}</label>
      {statusOptions && (
        <SelectMenu
          data={{
            options: statusOptions.map(function (s) {
              return {
                value: s.value,
                label: t(`status.${s.value}`),
              }
            }),
            handleChange: handleStatusChange,
            value: { label: t(selectValue?.label!), value: selectValue?.value },
          }}
        />
      )}
      <label>{t('name')}</label>
      <input value={name} onChange={e => setName(e.target.value)} required={true}></input>
      <label>{t('capacity')}</label>
      <input type="number" value={capacity} onChange={e => setCapacity(parseInt(e.target.value))} required={true}></input>
      <label>{t('address')}</label>
      <input value={address} onChange={e => setAddress(e.target.value)} required={true}></input>
      <label>{t('website')}</label>
      <input value={website} onChange={e => setWebsite(e.target.value)} required={true}></input>
      <label>{t('work-time')}</label>
      <textarea value={workTime} onChange={e => setWorkTime(e.target.value)} required={true}></textarea>
      <label>{t('coordinates.latitude')}</label>
      <input type="number" value={latitude} onChange={e => setLatitude(parseFloat(e.target.value))} required={true}></input>
      <label>{t('coordinates.longitude')}</label>
      <input type="number" value={longitude} onChange={e => setLongitude(parseFloat(e.target.value))} required={true}></input>
      <LoadingSubmitButton data={{ loading: submitting, text: t('save') }} />
    </form>
  )
}

export { EditLocation as default }
