import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import QRCode from 'qrcode.react'
import BackButton from '../../../components/BackButton'
import ItemTypeRowTable, { ItemTypeRowTableHeader } from '../../../components/ItemTypeRowTable'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import { BatchCardData } from './Batches'
import ErrorElement from '../../../components/ErrorElement'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import { setNotification } from '../../../components/Notification'

export const SubmitBatch = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { id }: any = useParams()

  let { submitting, errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const [batch, setBatch] = useState<BatchCardData>()

  useEffect(() => {
    async function onLoad() {
      const query = `query { batches (filter: {_id: "${id}"}){ _id fileUrl itemType { name } numberOfItems createdAt fileUrl } }`
      const factory = (result: any): BatchCardData[] => {
        return Array.isArray(result.batches) && result.batches.length > 0 ? result.batches.map((o: any) => ({ ...o, itemTypeName: o.itemType.name }))[0] : undefined
      }
      const response = await wrappedExtendedQueryAuth(query, factory)
      if (response) {
        setBatch(response)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (e: any) => {
    e.preventDefault()

    const mutation = ` mutation { submitItemBatch(input: "${id}") }`
    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setNotification('batches.validated')
      history.goBack()
    }
  }

  return (
    <>
      {batch && (
        <form className="object-form" onSubmit={onSubmit}>
          <BackButton />
          <h2>{t('batches.validate')}</h2>
          <ErrorElement errors={errors} />
          <div className="edit left">
            {batch.fileUrl && (
              <a href={batch.fileUrl} target="_blank" rel="noopener noreferrer">
                {t('batches.file')}
              </a>
            )}
          </div>
          <ItemTypeRowTableHeader />
          {ItemTypeRowTable({ data: { index: id, label: batch.itemTypeName, value: batch.numberOfItems } })}
          <hr />
          <QRCode className="qr-code" value={`https://app.bopaq.com/batches/${id}`} renderAs="canvas" size={256} />
          <LoadingSubmitButton data={{ loading: submitting, text: t('validate') }} />
        </form>
      )}
    </>
  )
}
