import { getDistance } from 'geolib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { toast } from 'react-toastify'
import { useAPI } from '../../api/api'
import { LocationData } from '../../components/admin/LocationCard'
import { QrScannerExpanded } from '../../components/QRScannerExpanded'
import SplashScreen from '../../components/SplashScreen'
import './Deposit.css'

type DepositData = {
  magicToken: string
  depositId: string
  supportCode: string
}

const DepositLocation = () => {
  let location = useLocation()
  const passedData: DepositData = location.state as any as DepositData
  let magicToken: string | undefined = undefined
  let depositId: string | undefined = undefined
  let supportCode: string | undefined = undefined

  if (!!passedData) {
    magicToken = passedData.magicToken || undefined
    depositId = passedData.depositId || undefined
    supportCode = passedData.supportCode || undefined
  }

  let history = useHistory()

  let { t } = useTranslation(['translation', 'errors'])

  const [locations, setLocations] = useState<LocationData[]>([])
  const [ended, setEnded] = useState(false)

  let { errors, currentPosition, getCurrentPosition, wrappedExtendedQuery } = useAPI()

  useEffect(() => {
    async function onLoad() {
      if (!!!magicToken) {
        history.replace('/failed-deposit')
        return
      }
      await getCurrentPosition()
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onLocationSet() {
      const query = `query { dropOffLocationsOpen (filter: {status: "active"}) { _id name status capacity quantity address workTime coordinates { latitude longitude } } }`
      const factory = (result: any): LocationData[] => {
        return result.dropOffLocationsOpen
          .map((l: any) => ({
            distance: currentPosition ? getDistance(currentPosition, l.coordinates) : null,
            freeCapacity: l.capacity - l.quantity,
            ...l,
          }))
          .sort((a: any, b: any) => (a.distance > b.distance ? 1 : -1))
      }

      const response = await wrappedExtendedQuery(query, factory)

      if (response) {
        setLocations(response)
      }

      setEnded(true)
    }
    onLocationSet()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition])

  const onQrCode = async (code: string) => {
    if (!code.includes('locations/')) {
      toast.error(t('invalid-location-qr-code'))
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    const dropoff = locations.filter((l: any) => l._id === qr)[0]

    if (!dropoff) {
      toast.error(t('invalid-location-qr-code'))
      return
    }

    if (dropoff.freeCapacity < 1) {
      toast.error(t('locations.location-full'))
      return
    }

    if (magicToken && dropoff) {
      history.replace('/deposit/scan-items', { magicToken: magicToken, dropoff: dropoff, depositId: depositId, supportCode: supportCode })
    }
  }

  useEffect(() => {
    const onErrors = () => {
      if (ended) {
        if (errors.length > 0) {
          errors.forEach(error => {
            toast.error(t(error.msg, { ns: 'errors' }))
          })
        }
      }
    }
    return () => {
      onErrors()
      setEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended])

  const onCancel = () => {
    history.push('/')
  }

  return (
    <>
      {locations.length > 0 ? (
        <section className="qrScanner qr-scanner-expanded">
          <QrScannerExpanded callback={onQrCode} callbackCancel={onCancel} heading={t('scan-location')} text={t('deposit-scan-location-text')} showContinueBtn={false} />
        </section>
      ) : (
        <SplashScreen withFooter={false} />
      )}
    </>
  )
}

export { DepositLocation as default }
