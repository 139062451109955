import QrScannerEngine from '../libs/qr-scanner'
import { HTMLProps, useEffect, useState } from 'react'

QrScannerEngine.WORKER_PATH = `${window.location.protocol}//${window.location.host}/qr-scanner-worker.min.js`

export type CameraProps = HTMLProps<HTMLVideoElement> & {
  onVideo: (video: HTMLVideoElement) => void
  constraints?: MediaStreamConstraints
}

export function Camera(props: CameraProps) {
  const { onVideo, constraints, ...videoProps } = props
  const [video, setVideo] = useState<HTMLVideoElement | null>(null)

  useEffect(() => {
    if (video) onVideo(video)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video])

  return <video {...videoProps} ref={setVideo}></video>
}

export type VideoQrScannerProps = {
  onQrCode: (qrCode: string) => void
  video: HTMLVideoElement
  cameraId: string
  onScanAreaUpdated: (area: ScanArea) => Promise<void>
  onCameraList: (cameras: any[]) => Promise<string>
}

export type ScanArea = {
  x: number
  y: number
  width: number
  height: number
  downScaledWidth: number
  downScaledHeight: number
}

export function VideoQrScanner(props: VideoQrScannerProps) {
  const { cameraId, video, onQrCode, onScanAreaUpdated, onCameraList } = props
  const [qrScanner, setQrScanner] = useState<QrScannerEngine>()

  const _calculateScanRegion = (video: any): ScanArea => {
    console.debug('Video', video)
    // Default scan region calculation. Note that this can be overwritten in the constructor.
    const smallestDimension = Math.min(video.videoWidth, video.videoHeight)
    const scanRegionSize = Math.round((2 / 3) * smallestDimension)

    console.debug('Dimensions', video.videoWidth, video.videoHeight)
    console.debug('Scan region size', scanRegionSize)

    const result = {
      x: Math.round((video.videoWidth - scanRegionSize) / 2),
      y: Math.round((video.videoHeight - scanRegionSize) / 2),
      width: scanRegionSize,
      height: scanRegionSize,
      downScaledWidth: 400,
      downScaledHeight: 400,
    }

    onScanAreaUpdated(result)

    return result
  }

  useEffect(() => {
    const qrScanner = new QrScannerEngine(video, onQrCode, undefined, _calculateScanRegion, cameraId)
    setQrScanner(qrScanner)
    console.debug('VideoQrScanner start', qrScanner, video)
    qrScanner
      .start()
      .catch((e: any) => alert(e))
      .then(() => QrScannerEngine.listCameras(false))
      .then(cameras => onCameraList(cameras))
    return () => {
      console.debug('VideoQrScanner end', qrScanner)
      qrScanner.stop()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video])

  useEffect(() => {
    console.debug('Changing camera', cameraId)

    if (qrScanner) qrScanner.setCamera(cameraId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraId])

  return <></>
}

export type QrScannerProps = Omit<CameraProps, 'onVideo'> & Omit<VideoQrScannerProps, 'video'>

export function ContinuousQrScanner(props: QrScannerProps) {
  const { onQrCode, constraints, onScanAreaUpdated, onCameraList, cameraId } = props
  const [video, setVideo] = useState<HTMLVideoElement | null>(null)
  return (
    <>
      <Camera onVideo={setVideo} constraints={constraints} />
      {video && <VideoQrScanner video={video} onQrCode={onQrCode} cameraId={cameraId} onScanAreaUpdated={onScanAreaUpdated} onCameraList={onCameraList} />}
    </>
  )
}

export function OneOffQrScanner(props: QrScannerProps) {
  const { onQrCode, ...videoProps } = props
  const [hasShown, setHasShown] = useState(false)
  function oneOffOnQrCode(qrCode: string) {
    onQrCode(qrCode)
    setHasShown(true)
  }
  if (hasShown) {
    return <></>
  }
  return <ContinuousQrScanner onQrCode={oneOffOnQrCode} {...videoProps} />
}

export default OneOffQrScanner
