import { useHistory } from 'react-router'
import { ReactComponent as CancelIcon } from '../../icons/cancel.svg'

const InfographicsPage = ({ src }: { src?: string }) => {
  let history = useHistory()
  return (
    <main className="how-to-bo">
      <img className="how-to-img" src={src} alt="infographics" />
      <button className="cancel" onClick={() => history.goBack()}>
        <CancelIcon></CancelIcon>
      </button>
    </main>
  )
}

export { InfographicsPage as default }
