import { SmallLogo } from './Logo'
import './Navbar.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainMenu from './menu/MainMenu'

const Navbar = () => {
  const { t } = useTranslation()

  const [name, setName] = useState('')

  useEffect(() => {
    async function onLoad() {
      const authRaw = localStorage.getItem('auth')

      if (!authRaw) return

      let auth = JSON.parse(authRaw)
      let role = auth.role
      let name

      switch (role) {
        case 'client':
          name = auth.name.split(' ')[0]
          break
        case 'merchant':
          name = auth.name
          break
        default:
          name = 'Bo'
          break
      }

      setName(name || 'Bo')
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <nav>
      <SmallLogo></SmallLogo>
      <label>
        {t('hi')}, {name}
      </label>
      <MainMenu />
    </nav>
  )
}

export { Navbar as default }
