import { MouseEventHandler } from 'react'

type AddAndComplete = {
  type: 'button' | 'submit' | 'reset'
  className: string
  submitting?: boolean
  disabled?: boolean
  onClick?: MouseEventHandler
  icon: JSX.Element
}

const IconButton = ({ data }: { data: AddAndComplete }) => {
  return (
    <button type={data.type} className={data.className} disabled={data.submitting || data.disabled} onClick={data.onClick}>
      {data.icon}
    </button>
  )
}

export default IconButton
