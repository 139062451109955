import { useTranslation } from 'react-i18next'
import './ItemTypeRow.css'

type ItemTypeRowTableData = {
  index: string
  label: string
  value: string | number
}

const ItemTypeRowTableHeader = () => {
  const { t } = useTranslation()
  
  return (
    <div className="ItemTypeRow Header">
      <label className="general-label">{t('item')}</label>
      <label className="general-label">{t('quantity')}</label>
    </div>
  )
}

const ItemTypeRowTable = ({ data }: { data: ItemTypeRowTableData }) => {
  return (
    <div key={data.index} className="ItemTypeRow">
      <label>{data.label}</label>
      <label>{data.value}</label>
    </div>
  )
}

export { ItemTypeRowTable as default, ItemTypeRowTableHeader }
