import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { useAPI } from '../../api/api'
import { QrScannerExpanded } from '../../components/QRScannerExpanded'
import SplashScreen from '../../components/SplashScreen'
import { ItemToAssignData } from '../merchant/AssignItems'

type LocationData = {
  _id: string
  name: string
  address: string
  workTime: string
}

const Scan = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)

  let { errors, wrappedExtendedQuery } = useAPI()
  const [ended, setEnded] = useState(false)
  const [showScanner, setShowScanner] = useState(true)

  const [locations, setLocations] = useState<LocationData[]>([])
  const [returnPoint, setReturnPoint] = useState<LocationData | undefined>(undefined)
  const [item, setItem] = useState<any>()

  useEffect(() => {
    async function onLocationSet() {
      const query = `query {dropOffLocationsOpen(filter: {status: "active"}) {_id name address workTime}}`
      const factory = (result: any): any => {
        return result.dropOffLocationsOpen
      }

      const response = await wrappedExtendedQuery(query, factory)

      if (response) {
        setLocations(response)
      }
    }
    onLocationSet().then(() => {
      setIsLoading(false)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const onErrors = () => {
      if (ended) {
        if (errors.length > 0) {
          errors.forEach(error => {
            toast.error(t(error.msg, { ns: 'errors' }))
          })
        }
      }
    }
    return () => {
      onErrors()
      setEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended])

  const onCancel = () => {
    history.goBack()
  }

  const onQrCode = async (code: string) => {
    setReturnPoint(undefined)
    setItem(undefined)
    if (code.includes('locations/')) {
      const url = new URL(code)
      const qr = url.pathname.split('/').pop() || ''

      const dropoff = locations.filter((l: any) => l._id === qr)[0]

      if (!dropoff) {
        toast.error(t('invalid-qr-code'))
        return
      }
      setReturnPoint(dropoff)
      setShowScanner(false)
    } else if (code.includes('items/')) {
      const url = new URL(code)
      const qr = url.pathname.split('/').pop() || ''

      const query = `query { itemsByIdOpen (filter : {uuid : "${qr}"}) { _id uuid status serialNumber itemType { name iconUrl capacity dimensions {w h l}} merchant {name}}}`
      const factory = (result: any): ItemToAssignData => {
        return Array.isArray(result.itemsByIdOpen) && result.itemsByIdOpen.length > 0 ? result.itemsByIdOpen[0] : undefined
      }

      const response = await wrappedExtendedQuery(query, factory)
      setItem(response)
      setShowScanner(false)
    } else {
      toast.error(t('invalid-qr-code'))
    }
  }

  const onScanAnother = () => {
    setShowScanner(true)
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : showScanner ? (
    <section className="qrScanner qr-scanner-expanded">
      <QrScannerExpanded callback={onQrCode} callbackCancel={onCancel} heading={t('scan-qr-code')} showContinueBtn={false} />
    </section>
  ) : (
    <main className="mainHome">
      {returnPoint && (
        <>
          <div className="scanned-info-container">
            <h1>{t('return-point-details')}</h1>
            <div className="scanned-info">
              <h2>{returnPoint.name}</h2>
              {returnPoint.address && (
                <div className="scanned-info-property">
                  <label>{t('address')}</label>
                  <p>{returnPoint.address}</p>
                </div>
              )}
              {returnPoint.workTime && (
                <div className="scanned-info-property">
                  <label>{t('work-time')}</label>
                  <p>{returnPoint.workTime}</p>
                </div>
              )}
            </div>
          </div>
          <div className="buttons-forward-back" style={{ width: '100%' }}>
            <button className="button-medium main-button" onClick={onScanAnother}>
              {t('scan-another')}
            </button>
            <button className="button-medium naked-button" onClick={onCancel}>
              {t('cancel')}
            </button>
          </div>
        </>
      )}
      {item && (
        <>
          <div className="scanned-info-container">
            <h1>{t('container-details')}</h1>
            <div className="scanned-info">
              <div className="scanned-info-item">
                <img className="item-icon" alt="item-icon" src={item.itemType?.iconUrl || t('no-image-available')} />
                <div>
                  <h2>{item.itemType?.name}</h2>
                  {item.status === 'deposit' ? <div className="item-refund-status refundable">{t('refundable')}</div> : <div className="item-refund-status">{t('non-refundable')}</div>}
                </div>
              </div>
              {item.serialNumber && (
                <div className="scanned-info-property">
                  <label>{t('serial-number')}</label>
                  <p>{item.serialNumber}</p>
                </div>
              )}
              {item.itemType?.dimensions?.w !== 0 && (
                <div className="scanned-info-property">
                  <label>{t('dimensions')}</label>
                  <p>
                    {item.itemType?.dimensions?.w} x {item.itemType?.dimensions?.h} x {item.itemType?.dimensions?.l} in
                  </p>
                </div>
              )}
              {item.itemType?.capacity !== 0 && (
                <div className="scanned-info-property">
                  <label>{t('capacity')}</label>
                  <p>{item.itemType?.capacity} oz</p>
                </div>
              )}
            </div>
          </div>
          <div className="buttons-forward-back" style={{ width: '100%' }}>
            <button className="button-medium main-button" onClick={onScanAnother}>
              {t('scan-another')}
            </button>
            <button className="button-medium naked-button" onClick={onCancel}>
              {t('cancel')}
            </button>
          </div>
        </>
      )}
    </main>
  )
}

export { Scan as default }
