import { Redirect } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Register from '../containers/Register'
import { reroutesOnLoad } from '../api/utils'

const ReroutesRegister = () => {
  const [authRole, setAuthRole] = useState('')
  const [homePath, setHomePath] = useState('')

  useEffect(() => {
    async function onLoad() {
      const response = await reroutesOnLoad()
      if (response) {
        setAuthRole(response.role)
        setHomePath(response.homePath)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {
        {
          '': <Register />,
          customer: <Redirect to={homePath} />,
          merchant: <Redirect to={homePath} />,
          admin: <Redirect to={homePath} />,
        }[authRole]
      }
    </>
  )
}

export { ReroutesRegister as default }
