import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAPI } from '../../../api/api'
import ActionBar from '../../../components/admin/ActionBar'
import { ItemTypeCardData } from '../../../components/admin/ItemTypeCard'
import IconButton from '../../../components/buttons/IconButton'
import CardWithIcon from '../../../components/CardWithIcon'
import ErrorElement from '../../../components/ErrorElement'
import { FailIcon, SuccessIcon } from '../../../components/Icons'
import Navbar from '../../../components/Navbar'
import Searchbar from '../../../components/Searchbar'
import SplashScreen from '../../../components/SplashScreen'

import { ReactComponent as AddIcon } from '../../../icons/plus.svg'
import { ReactComponent as QRIcon } from '../../../icons/batches.svg'
import '../../CategoryHome.css'
import { AdminPaths } from '../../../routes/RoutePaths'

const ItemTypes = () => {
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const { t } = useTranslation()

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  const [itemTypes, setItemTypes] = useState<ItemTypeCardData[]>([])

  useEffect(() => {
    async function onLoad() {
      const query = `query { allItemTypes { _id name status capacity quantity iconUrl dimensions { w h l } } }`
      const factory = (result: any): ItemTypeCardData[] => {
        return result.allItemTypes
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setItemTypes(response)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClickAdd = () => {
    history.push('/admin/itemTypes/add')
  }

  return (
    <>
      <Navbar></Navbar>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <section className="HomeSection WithNavbar">
          <header>
            <h1>{t('item-types.item-types')}</h1>
            <IconButton data={{ className: 'add-button', type: 'button', icon: <AddIcon />, onClick: onClickAdd }} />
          </header>
          <Searchbar></Searchbar>
          <div className="check-status">
            <label className="yellow-link check-item" onClick={e => history.push(AdminPaths.CheckItem)}>
              <QRIcon fill="var(--color-primary)" />
              {t('check-bo-status')}
            </label>
          </div>
          <hr />
          <ErrorElement errors={errors} />
          {itemTypes.length === 0 ? (
            <p className="empty-list-msg">{t('empty.item-types')}</p>
          ) : (
            itemTypes.map(data => (
              <CardWithIcon
                key={data._id}
                data={{
                  iconUrl: data.iconUrl,
                  labelMain: data.name,
                  labelTopRight: `${data.quantity} ${t('piece')}`,
                  labelSub1: data.dimensions && data.dimensions.w !== 0 && data.dimensions.h !== 0 && data.dimensions.l !== 0 ? `${data.dimensions.w} x ${data.dimensions.h} x ${data.dimensions.l} in` : undefined,
                  labelSub2: data.capacity ? `${data.capacity} oz` : undefined,
                  labelBotRight: data.status && t(`status.${data.status}`),
                  iconBotRight: data.status === 'active' ? <SuccessIcon /> : <FailIcon />,
                  linkTo: '/admin/itemTypes/edit/' + data._id,
                }}
              />
            ))
          )}
        </section>
      )}
      <ActionBar></ActionBar>
    </>
  )
}

export { ItemTypes as default }
