import { Fragment } from 'react'
import { Redirect, Route } from 'react-router'
import { NotificationBanner } from './Notification'

const PrivateRoute = (props: any) => {
  let role = props.path.split('/')[1]

  if (!role) {
    return <Redirect to="/" />
  }

  let pathRole = '/' + role

  const { component: Component, ...rest } = props

  const authRaw = localStorage.getItem('auth')
  let auth = null
  if (authRaw && authRaw !== 'undefined') {
    auth = JSON.parse(localStorage.getItem('auth') || '')
  }

  if (!auth) {
    return <Redirect to={'/login'} />
  }

  let authRole

  switch (auth.role) {
    case 'client':
      authRole = 'customer'
      break
    case 'merchant':
      authRole = 'merchant'
      break
    case 'admin':
      authRole = 'admin'
      break
    case 'employee':
      authRole = 'admin'
      break
    case 'warehouse':
      authRole = 'admin'
      break
    default:
      break
  }

  if (auth.registrationState === 0 && props.path !== pathRole + '/register/profile') {
    return <Redirect to={pathRole + '/register/profile'} />
  }

  if (auth.registrationState === 1 && props.path !== pathRole + '/register/cc' && props.path !== pathRole + '/cc-for') {
    return <Redirect to={pathRole + '/register/cc'} />
  }

  if (authRole === 'merchant' && auth.registrationState === 2 && props.path !== '/merchant/register/approve_by_admin' && props.path !== '/merchant/register/cc') {
    return <Redirect to={pathRole + '/register/approve_by_admin'} />
  }

  if (authRole !== role && props.path !== '/edit/profile') {
    return <Redirect to="/forbidden" />
  }

  return (
    <Route
      {...rest}
      render={props => (
        <Fragment>
          <NotificationBanner />
          <Component {...props} />
        </Fragment>
      )}
    />
  )
}

export { PrivateRoute as default }
