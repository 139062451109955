import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { useAPI } from '../../api/api'
import BackButton from '../../components/BackButton'
import { CustomInput } from '../../components/common/CustomInput'
import { LoadingSubmitButton } from '../../components/LoadingButton'
import { DepositHowBoWorksModal, MainModal } from '../../components/modal/Modals'
import { TermsHtmlEng, TermsHtmlFr } from '../../components/TermsAndConditions'
import i18n from '../../i18n'
import './Deposit.css'

const Deposit = () => {
  let history = useHistory()
  let { t } = useTranslation(['translation', 'errors'])
  const selectedLanguage = i18n.language
  const [email, setEmail] = useState('')
  const [showTutorial, setShowTutorial] = useState(false)
  const [ended, setEnded] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showToS, setShowToS] = useState(true)

  let { submitting, errors, wrappedExtendedMutate } = useAPI()

  const createDeposit = async () => {
    const mutation = `mutation { createDeposit ( input: { email: "${email}"} ) { email } }`
    const response = await wrappedExtendedMutate(mutation)

    setEnded(true)

    const responseEmail = response.createDeposit.email || ''

    if (response && responseEmail) {
      setShowConfirm(true)
    }
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    await createDeposit()
  }

  useEffect(() => {
    const onErrors = () => {
      if (ended) {
        if (errors.length > 0) {
          errors.forEach(error => {
            toast.error(t(error.msg, { ns: 'errors' }))
          })
        }
      }
    }
    return () => {
      onErrors()
      setEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended])

  const onHowBoWorks = () => {
    setShowTutorial(true)
  }

  const onHowBoClose = () => {
    setShowTutorial(false)
  }

  // const onSkipEmail = () => {
  //   history.push('/deposit/info')
  // }

  const onHome = () => {
    history.push('/')
  }

  const onResendEmail = async () => {
    setShowConfirm(false)
  }

  const onChangeAddress = () => {
    setEmail('')
    setShowConfirm(false)
  }

  const onAcceptTerms = () => {
    setShowToS(false)
  }

  const onCancelToS = () => {
    history.replace('/')
  }

  return (
    <>
      {showConfirm ? (
        <main className="mainHome">
          <div className="screen-header">
            <h1>{t('confirm-email')}</h1>
          </div>
          <div className="deposit-content">
            <p>
              <span>{t('email-sent-to')}</span>
              <label className="deposit-email">{email}</label>
            </p>
            <p>{t('please-confirm-email')}</p>
            <p>
              <span>{t('check-spam')}</span>
              <label className="reset-email" onClick={onResendEmail}>
                {t('reset-email')}
              </label>
            </p>
          </div>
          <div className="footer-button-section">
            <button className="button-medium main-button" onClick={onHome}>
              {t('go-home')}
            </button>
            <button className="button-medium naked-button" onClick={onChangeAddress}>
              {t('change-address')}
            </button>
          </div>
        </main>
      ) : (
        <main className="mainHome">
          <BackButton />
          <h1 className="h1-small">{t('continue-as-guest')}</h1>
          <form className="form-general" onSubmit={onSubmit}>
            <div className="main-form-section">
              <div className={`form-input-section ${errors.length > 0 ? 'form-invalid' : ''}`}>
                <CustomInput type="email" label={t('email')} placeholder={t('enter-email')} value={email} setValue={setEmail} note={t('guest-email-note')} />
              </div>
            </div>
            <div className="deposit-button-section">
              <LoadingSubmitButton data={{ disabled: !!!email, loading: submitting, text: t('continue'), className: 'button-medium main-button' }} />
              {/* TODO Logic
              <label className="skip-label" onClick={onSkipEmail}>
                {t('deposit-skip-email')}
              </label> */}
            </div>
          </form>
          <label className="tos-pp">
            <span onClick={onHowBoWorks}>{t('deposit-how-bo-works')}</span>
          </label>
          {showTutorial && (
            <DepositHowBoWorksModal
              data={{
                show: showTutorial,
                title: t('deposit-how-bo-works'),
                cancelHook: onHowBoClose,
                submitHook: onHowBoClose,
              }}
            />
          )}
        </main>
      )}
      {showToS && (
        <MainModal
          data={{
            position: 'center',
            cancelHook: onCancelToS,
            submitHook: onAcceptTerms,
            buttonText: t('accept-and-continue'),
            show: showToS,
            title: t('terms-of-service'),
            content: selectedLanguage.indexOf('en') === -1 ? <TermsHtmlFr className="modalTerms" showHeading={false} /> : <TermsHtmlEng className="modalTerms" showHeading={false} />,
          }}
        />
      )}
    </>
  )
}

export { Deposit as default }
