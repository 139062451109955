import { Redirect, Route } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'
import ApprovalPending from '../containers/merchant/ApprovalPending'
import MerchantAssignItem from '../containers/merchant/AssignItemCustomer'
import AssignItems from '../containers/merchant/AssignItems'
import MerchantHome from '../containers/merchant/MerchantHome'
import MerchantRegister from '../containers/merchant/MerchantRegister'
import MerchantRegisterCC from '../containers/merchant/MerchantRegisterCC'
import MerchantRegisterProfile from '../containers/merchant/MerchantRegisterProfile'
import Orders from '../containers/merchant/orders/Orders'
import Order from '../containers/merchant/orders/Order'
import { MerchantPaths } from './RoutePaths'
import ImpactStats from '../containers/merchant/ImpactStats'
import CheckItemRender from '../containers/merchant/CheckItemRender'
import ReturnToInventory from '../containers/merchant/ReturnToInventory'

const RedirectToInventory = () => {
  return <Redirect to={MerchantPaths.Assign} />
}

const routes = [
  <PrivateRoute key={MerchantPaths.Home} exact path={MerchantPaths.Home} component={RedirectToInventory} />,
  <Route key={MerchantPaths.Register} exact path={MerchantPaths.Register} component={MerchantRegister} />,
  <PrivateRoute key={MerchantPaths.Inventory} exact path={MerchantPaths.Inventory} component={MerchantHome} />,
  <PrivateRoute key={MerchantPaths.RegisterProfile} exact path={MerchantPaths.RegisterProfile} component={MerchantRegisterProfile} />,
  <PrivateRoute key={MerchantPaths.RegisterCC} exact path={MerchantPaths.RegisterCC} component={MerchantRegisterCC} />,
  <PrivateRoute key={MerchantPaths.ApprovalPending} exact path={MerchantPaths.ApprovalPending} component={ApprovalPending} />,
  <PrivateRoute key={MerchantPaths.Assign} exact path={MerchantPaths.Assign} component={MerchantAssignItem} />,
  <PrivateRoute key={MerchantPaths.AssignItems} exact path={MerchantPaths.AssignItems} component={AssignItems} />,
  <PrivateRoute key={MerchantPaths.Orders} exact path={MerchantPaths.Orders} component={Orders} />,
  <PrivateRoute key={MerchantPaths.Order} exact path={MerchantPaths.Order} component={Order} />,
  <PrivateRoute key={MerchantPaths.CheckItem} exact path={MerchantPaths.CheckItem} component={CheckItemRender} />,
  <PrivateRoute key={MerchantPaths.MyImpact} exact path={MerchantPaths.MyImpact} component={ImpactStats} />,
  <PrivateRoute key={MerchantPaths.ReturnToInventory} exact path={MerchantPaths.ReturnToInventory} component={ReturnToInventory} />,
]

export default routes
