export enum FilePathEnum {
  PROFILE_IMAGES = 'images/profile-images',
  USER_PROFILE_IMAGES = 'images/user-profile-images',
  ITEM_TYPE_IMAGES = 'images/item-type-images',
}

export async function uploadImage(image: any, name: string, filePath: FilePathEnum): Promise<string | undefined> {
  try {
    let bearer_token: string = ''
    const auth = localStorage.getItem('auth')
    if (auth) {
      bearer_token = JSON.parse(auth).token
    }

    let bearer = 'Bearer ' + bearer_token

    const formData = new FormData()
    formData.append('file', image)
    formData.append('fileName', name)
    formData.append('imageType', filePath)

    const url = `${process.env.REACT_APP_API_URL}/files`

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: bearer,
      },
      body: formData,
    })

    const result = await response.text()

    return result
  } catch (e) {
    console.debug(e)
    return undefined
  }
}
