import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BackButton from '../components/BackButton'
import { ToSPPLabel } from '../components/common/TosPPLabels'
import BoPartner from '../images/bo-partner.png'
import RegularUser from '../images/regular_user.png'

const Register = () => {
  const { t } = useTranslation()

  let history = useHistory()

  const onClickBoPartner = () => {
    history.push('/merchant/register')
  }

  const onClickRegularUser = () => {
    history.push('/customer/register')
  }

  return (
    <main className="screen-container">
      <div className="screen-header">
        <div className="back-and-langauge-container">
          <BackButton />
        </div>
        <h1>{t('bo-account')}</h1>
        <h2>{t('register-label')}</h2>
      </div>
      <div className="register-content">
        <div className="pick-account-type">
          <div className="account-type-container" onClick={onClickBoPartner}>
            <label className="account-type-header">{t('bo-partner')}</label>
            <img src={BoPartner} alt="food stall" />
          </div>
          <div className="account-type-container" onClick={onClickRegularUser}>
            <label className="account-type-header">{t('regular-user')}</label>
            <img src={RegularUser} alt="recycle bin" />
          </div>
        </div>
      </div>
      <ToSPPLabel />
    </main>
  )
}

export { Register as default }
