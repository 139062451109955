import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import ActionBar from '../../../components/admin/ActionBar'
import Card from '../../../components/Card'
import ErrorElement from '../../../components/ErrorElement'
import Navbar from '../../../components/Navbar'
import Searchbar from '../../../components/Searchbar'

import '../../CategoryHome.css'

type Customer = {
  _id: string
  firstName: string
  lastName: string
  boId: string
}

const Customers = () => {
  const { t } = useTranslation()

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  const [customers, setCustomers] = useState<Customer[]>([])

  useEffect(() => {
    async function onLoad() {
      const query = `query { clients { _id boId firstName lastName}}`
      const factory = (result: any): Customer[] => {
        return result.clients.map((c: any) => ({
          _id: c._id,
          firstName: c.firstName,
          lastName: c.lastName,
          boId: c.boId,
        }))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setCustomers(response)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar></Navbar>
      <section className="HomeSection WithNavbar">
        <header>
          <h1>{t('customers.customers')}</h1>
        </header>
        <Searchbar></Searchbar>
        <hr />
        <ErrorElement errors={errors} />
        {customers.length === 0 ? (
          <p className="empty-list-msg">{t('empty.customers')}</p>
        ) : (
          customers.map(customer => (
            <Card
              key={customer._id}
              data={{
                labelMain: `${customer.boId}`,
                labelTopRight: `${customer.firstName} ${customer.lastName}`,
                linkTo: `/admin/customers/${customer._id}`,
              }}
            />
          ))
        )}
      </section>
      <ActionBar></ActionBar>
    </>
  )
}

export { Customers as default }
export type { Customer }
