import moment from 'moment'

export function formatDate(date: number): string {
  return moment.unix(date).format('YYYY-MM-DD')
}

export function formatDistance(distance: number, text: string): string {
  return (distance >= 1000 ? `${(distance / 1000).toFixed(1).toString()} km` : `${distance.toString()} m`) + ` ${text}`
}

export enum Roles {
  Admin = 'admin',
  Employee = 'employee',
  Client = 'client',
  Merchant = 'merchant',
  Warehouse = 'warehouse',
}

export function roleFromId(role: string): string {
  switch (role) {
    case Roles.Admin:
      return 'Admin'
    case Roles.Employee:
      return 'Employee'
    case Roles.Client:
      return 'Client'
    case Roles.Merchant:
      return 'Merchant'
    case Roles.Warehouse:
      return 'Warehouse'
    default:
      return 'Unknown Role'
  }
}

export function statusFromId(status: number): string {
  switch (status) {
    case -1:
      return 'Disabled'
    case 0:
      return 'Registered Email'
    case 1:
      return 'Registered Profile'
    case 2:
      return 'Awaiting Approval'
    case 3:
      return 'Active'
    default:
      return 'Unknown Status'
  }
}

export const reroutesOnLoad = async () => {
  let auth
  const authRaw = localStorage.getItem('auth')
  if (authRaw && authRaw !== 'undefined') {
    auth = JSON.parse(localStorage.getItem('auth') || '')
    let authRole = auth.role

    let role = ''

    switch (authRole) {
      case 'client':
        role = 'customer'
        break
      case 'merchant':
        role = 'merchant'
        break
      case 'admin':
        role = 'admin'
        break
      case 'employee':
        role = 'admin'
        break
      case 'warehouse':
        role = 'admin'
        break
      default:
        break
    }
    let homePath
    if (authRole === 'warehouse') {
      homePath = `/${role}/orders`
    } else {
      homePath = `/${role}`
    }
    return { role, homePath }
  }
  return null
}
