import { Link, useLocation } from 'react-router-dom'

import '../admin/ActionBar.css'

import { ReactComponent as ItemsIcon } from '../../icons/items.svg'
import { ReactComponent as MerchantsIcon } from '../../icons/merchants.svg'
import { ReactComponent as OrdersIcon } from '../../icons/orders.svg'
import { ReactComponent as TreeIcon } from '../../icons/tree.svg'
import { useTranslation } from 'react-i18next'

const ActionBar = () => {
  const { t } = useTranslation()

  const location = useLocation()
  const activeTab = location.pathname.split('/')[2].toLowerCase()

  return (
    <section className="BottomNavbar">
      <Link className={activeTab === 'assign' ? 'active' : ''} to="/merchant/assign" replace>
        <MerchantsIcon />
        {t('assign')}
      </Link>
      <Link className={activeTab === 'inventory' ? 'active' : ''} to="/merchant/inventory" replace>
        <ItemsIcon />
        {t('inventory')}
      </Link>
      <Link className={activeTab === 'orders' ? 'active' : ''} to="/merchant/orders" replace>
        <OrdersIcon />
        {t('orders.orders')}
      </Link>
      <Link className={activeTab === 'impact' ? 'active' : ''} to="/merchant/impact" replace>
        <TreeIcon />
        {t('impact')}
      </Link>
    </section>
  )
}

export { ActionBar as default }
