import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import ApiUtils from '../../api/apiUtils'
import apiUtils from '../../api/apiUtils'
import { APIError } from '../../api/response'
import StepCounter from '../../components/common/StepCounter'
import { CustomerPaths } from '../../routes/RoutePaths'
import { ReactComponent as Back } from '../../icons/arrow-short-left.svg'
import { CustomerRegisterAddress, CustomerRegisterContact } from './CustomerRegisterProfileSteps'
import { toast } from 'react-toastify'

const CustomerRegisterProfile = () => {
  const { t } = useTranslation(['translation', 'errors'])
  let history = useHistory()
  let { removeAuthFromLocalStorage } = ApiUtils()

  const [registrationStep, setRegistrationStep] = useState(1)
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState<APIError[]>([])
  const [ended, setEnded] = useState(false)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [addressState, setAddressState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('Canada')

  const { extendedMutateAuth, setAuthToLocalStorage } = apiUtils()

  const onSubmit = async () => {
    const mutation = `mutation {
      registerClientProfile( input: { 
        firstName: "${firstName}" lastName: "${lastName}" phoneNumber: "${phoneNumber}" 
        address: { address: "${address}" country: "CA" state: "${addressState}" postalCode: "${postalCode}" city: "${city}" } 
      }) { token registrationState email role boId name }}`

    const response = await extendedMutateAuth(mutation, setErrors, setSubmitting)

    setEnded(true)

    if (response) {
      await setAuthToLocalStorage(response.registerClientProfile)
      history.push(CustomerPaths.RegisterCC)
    }
  }

  useEffect(() => {
    const onErrors = () => {
      if (ended) {
        if (errors.length > 0) {
          errors.forEach(error => {
            toast.error(t(error.msg, { ns: 'errors' }))
          })
        }
      }
    }
    return () => {
      onErrors()
      setEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended])

  const onCancel = async () => {
    await removeAuthFromLocalStorage()
    history.push('/')
  }

  const onClickBackButton = async () => {
    if (registrationStep === 1) {
      await removeAuthFromLocalStorage()
      history.goBack()
    } else if (registrationStep === 2) {
      setRegistrationStep(1)
    }
  }

  return (
    <main className="screen-container">
      {
        {
          1: (
            <>
              <div className="screen-header">
                <StepCounter className="solo-step-counter" text={registrationStep === 1 ? '2/4' : '3/4'} percentage={registrationStep === 1 ? 180 : 270} />
                <h1>{t('contact-info')}</h1>
              </div>
              <CustomerRegisterContact
                firstName={firstName}
                lastName={lastName}
                phoneNumber={phoneNumber}
                setFirstName={setFirstName}
                setLastName={setLastName}
                setPhoneNumber={setPhoneNumber}
                errors={errors}
                onCancel={onCancel}
                setRegistrationStep={setRegistrationStep}
                submitting={submitting}
              />
            </>
          ),
          2: (
            <>
              <div className="screen-header">
                <div className="back-and-langauge-container">
                  <>
                    <Link to="#" replace className="back" onClick={onClickBackButton}>
                      <Back className="bo-back" />
                      {t('back')}
                    </Link>
                  </>
                  <StepCounter text={registrationStep === 1 ? '2/4' : '3/4'} percentage={registrationStep === 1 ? 180 : 270} />
                </div>
                <h1>{t('address')}</h1>
              </div>
              <CustomerRegisterAddress
                country={country}
                address={address}
                city={city}
                addressState={addressState}
                postalCode={postalCode}
                setCountry={setCountry}
                setAddress={setAddress}
                setCity={setCity}
                setAddressState={setAddressState}
                setPostalCode={setPostalCode}
                errors={errors}
                onCancel={onCancel}
                onSubmit={onSubmit}
                submitting={submitting}
              />
            </>
          ),
        }[registrationStep]
      }
    </main>
  )
}

export { CustomerRegisterProfile as default }
