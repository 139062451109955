import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useAPI } from '../api/api'
import ErrorElement from '../components/ErrorElement'
import { LoadingSubmitButton } from '../components/LoadingButton'
import Logo from '../components/Logo'
import { ConfirmPasswordInput, PasswordInput, PwChecklist, ShowPasswordCheck } from '../components/PasswordInput'

const PasswordReset = () => {
  const { t } = useTranslation()

  const { submitting, errors, setErrors, wrappedExtendedMutate } = useAPI()

  const { token }: any = useParams()
  const history = useHistory()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isNewPassword, setIsNewPassword] = useState(false)
  const [isValidPw, setIsValidPw] = useState(false)
  const [showPw, setShowPw] = useState(false)

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const mutation = `mutation { changePassword ( input: { token: "${token}" password: "${password}" } ) }`

    const response = await wrappedExtendedMutate(mutation)

    if (response && response.changePassword) {
      setIsNewPassword(true)
    } else {
      setErrors([{ msg: t('password.reset-expired') }])
    }
  }

  const onClick = () => {
    history.push('/login')
  }

  const handleShowPwChange = () => {
    setShowPw(!showPw)
  }

  const handlePwChecklist = (isValid: boolean) => {
    setIsValidPw(isValid)
  }

  return (
    <>
      <Logo></Logo>
      <h2>{t('password.change-password')}</h2>
      <ErrorElement errors={errors} />
      {!isNewPassword && (
        <form onSubmit={onSubmit}>
          <PasswordInput label={t('password.main')} showPw={showPw} value={password} onChange={e => setPassword(e.target.value)} />
          <PwChecklist value={password} onChange={handlePwChecklist} valueAgain={confirmPassword} />
          <ConfirmPasswordInput label={t('password.confirm')} showPw={showPw} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
          <ShowPasswordCheck checked={showPw} onChange={handleShowPwChange} />
          <LoadingSubmitButton data={{ disabled: !isValidPw, loading: submitting, text: t('continue'), className: 'continueBtn' }} />
        </form>
      )}
      {isNewPassword && (
        <>
          <label>{t('password.set')}</label>
          <button className="continueBtn" onClick={onClick}>
            {t('sign-in')}
          </button>
        </>
      )}
    </>
  )
}

export { PasswordReset as default }
