import './ErrorElement.css'
import { ReactComponent as ErrorIcon } from '../icons/error.svg'
import { useTranslation } from 'react-i18next'

const ErrorElement = ({ errors, className }: any) => {
  const { t } = useTranslation(['errors'])
  return (
    <>
      {Array.isArray(errors) && errors.length > 0 && (
        <div className={`errorWrapper ${className ? className : ''}`}>
          <ErrorIcon className="bo-fail" />
          <label>{Array.isArray(errors) ? errors.map(e => t(e.msg)).join(',') : errors}</label>
        </div>
      )}
    </>
  )
}

export { ErrorElement as default }
