import './ErrorElement.css'
import { ReactComponent as CloseIcon } from '../icons/x.svg'

import './Dialog.css'
import { useTranslation } from 'react-i18next'

type Data = {
  show: boolean
  title: string
  position?: 'top' | 'center' | 'bottom'
  text?: string
  content?: JSX.Element
  buttonText?: string
  cancelHook: (e: any) => void
  submitHook?: (e: any) => void
}

const CustomDialog = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  const className = data.position ? 'dialogWrapper ' + data.position : 'dialogWrapper'
  return (
    <>
      {data.show && (
        <section className={className} onClick={data.cancelHook}>
          <div className="card" onClick={(e: any) => e.stopPropagation()}>
            <CloseIcon className="cancel" onClick={data.cancelHook} />
            <p className="title">{data.title}</p>
            {data.text && <p className="text">{data.text}</p>}
            {data.content && data.content}
            {data.submitHook && (
              <div className="buttonWraper">
                <button type="button" onClick={data.submitHook}>
                  {data.buttonText || t('continue')}
                </button>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  )
}

const ConfirmDialog = ({ data }: { data: Data }) => {
  const { t } = useTranslation()
  return (
    <>
      {data.show && (
        <section className="dialogWrapper confirm" onClick={data.cancelHook}>
          <div className="card" onClick={(e: any) => e.stopPropagation()}>
            <CloseIcon className="cancel" onClick={data.cancelHook} />
            <p className="title">{data.title}</p>
            <p className="text">{data.text}</p>
            <div className="buttonWraper">
              <button type="button" onClick={data.submitHook}>
                {data.buttonText || t('continue')}
              </button>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export { ConfirmDialog as default, CustomDialog }
