import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useAPI } from '../../api/api'
import { ItemTypeCardData } from '../../components/admin/ItemTypeCard'
import BackButton from '../../components/BackButton'
import NavigationMenu from '../../components/common/NavigationMenu'
import SplashScreen from '../../components/SplashScreen'

const Bopaqs = () => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)

  let { errors, wrappedExtendedQuery } = useAPI()
  const [ended, setEnded] = useState(false)

  const [itemTypes, setItemTypes] = useState<ItemTypeCardData[]>([])

  useEffect(() => {
    async function onLoad() {
      const query = `query { itemTypesPublic { name capacity iconUrl dimensions { w h l } } }`
      const factory = (result: any): ItemTypeCardData[] => {
        return result.itemTypesPublic
      }

      const response = await wrappedExtendedQuery(query, factory)

      if (response) {
        setItemTypes(response)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const onErrors = () => {
      if (ended) {
        if (errors.length > 0) {
          errors.forEach(error => {
            toast.error(t(error.msg, { ns: 'errors' }))
          })
        }
      }
    }
    return () => {
      onErrors()
      setEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended])

  return (
    <main className="mainHome">
      {isLoading ? (
        <SplashScreen />
      ) : (
        <>
          <div className="screen-header">
            <div className="back-and-langauge-container">
              <BackButton />
            </div>
            <h1>{t('bopaqs')}</h1>
          </div>
          <div className="bopaqs-content">
            <div className="bopaqs-catalogue">
              {/* <label>{t('our-catalogue')}</label> */}
              <div className="bopaqs-catalogue-content">
                {itemTypes.map(type => (
                  <div key={type.name}>
                    <div className="item-type-container">
                      <div className="item-type-container-info">
                        <img className="item-icon" alt="item icon" src={type.iconUrl || t('no-image-available')} />
                        <div className="item-type-container-info-labels">
                          <label>{type.name}</label>
                          {type.capacity ? (
                            <label>{type.capacity} oz.</label>
                          ) : type.dimensions && type.dimensions.h && type.dimensions.l && type.dimensions.w ? (
                            <label>
                              {type.dimensions.h}x{type.dimensions.l}x{type.dimensions.w} in
                            </label>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="custom-divider"></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
      <div className="mainDock"></div>
      <NavigationMenu />
    </main>
  )
}

export { Bopaqs as default }
