import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAPI } from '../../api/api'
import BackButton from '../../components/BackButton'
import AddAndCompleteElement from '../../components/buttons/AddAndCompleteElement'
import CardWithIcon from '../../components/CardWithIcon'
import ConfirmDialog, { CustomDialog } from '../../components/Dialog'
import ErrorElement from '../../components/ErrorElement'
import { SuccessIcon } from '../../components/Icons'
import { QrScanner } from '../../components/QrScanner'
import SplashScreen from '../../components/SplashScreen'
import { ReactComponent as ErrorIcon2 } from '../../icons/error-2.svg'

type Data = {
  _id: string
  uuid: string
  itemTypeName: string
  itemTypeId: string
  client: any
  merchant: any
  depositPrice: any
  status: string
}

type ItemListData = {
  _id: string
  iconUrl: string
  items: Data[]
}

const InternalReturn = () => {
  const { t } = useTranslation()
  let history = useHistory()
  let { submitting, errors, setErrors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()
  const [isLoading, setIsLoading] = useState(false)
  const [showScanner, setShowScanner] = useState(true)
  const [isFirstScan, setIsFirstScan] = useState(true)
  const [showReturnItemDialog, setShowReturnItemDialog] = useState(false)
  const [msg, setMsg] = useState('')
  const [showContent, setShowContent] = useState(true)

  const [data, setData] = useState<Data | undefined | null>(undefined)
  const [itemTypeName, setItemTypeName] = useState('')
  const [uuid, setUuid] = useState('')
  const [client, setClient] = useState('')
  const [itemStatus, setItemStatus] = useState('')
  const [clientBoId, setClientBoId] = useState('')
  const [merchantBoId, setMerchantBoId] = useState('')
  const [isScanTypeRemove, setIsScanTypeRemove] = useState(false)

  const [itemList, setItemList] = useState<Data[]>([])
  const [itemListByType, setItemListByType] = useState<ItemListData[]>([])
  const [border, setBorder] = useState('')

  const [itemTypeDialogData, setItemTypeDialogData] = useState<JSX.Element | undefined>(undefined)
  const [itemTypeDialogTitle, setItemTypeDialogTitle] = useState('')
  const [showItemTypeDialog, setShowItemTypeDialog] = useState(false)

  useEffect(() => {
    const auth = localStorage.getItem('auth')
    if (auth) {
      setMerchantBoId(JSON.parse(auth).boId)
    }
  }, [])

  const onCancel = (e: any) => {
    if (isFirstScan) history.goBack()
    else setShowScanner(false)
  }

  const onQrCode = async (code: string) => {
    setShowContent(true)
    setData(undefined)
    setShowScanner(false)
    setIsFirstScan(false)

    if (!code.includes('items/')) {
      setErrors([{ msg: t('invalid-item-qr-code') }])
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    const query = `query { itemsById (filter : {uuid : "${qr}"}) { _id uuid status itemType { _id name depositPrice iconUrl } client { boId firstName lastName } merchant { _id boId name }}}`
    const factory = (result: any): Data => {
      return Array.isArray(result.itemsById) && result.itemsById.length > 0
        ? result.itemsById.map((o: any) => ({
            _id: o._id,
            itemTypeName: o.itemType.name,
            itemTypeId: o.itemType._id,
            depositPrice: o.itemType.depositPrice / 100,
            iconUrl: o.itemType.iconUrl,
            uuid: o.uuid,
            status: o.status,
            client: o.client,
            merchant: o.merchant,
          }))[0]
        : null
    }

    const response = await wrappedExtendedQueryAuth(query, factory)
    if (!response) {
      setErrors([{ msg: t('item-does-not-exist') }])
      return
    }

    setItemTypeName(response.itemTypeName)
    setUuid(response.uuid)
    if (response.client && response.merchant && response.merchant.boId === merchantBoId) {
      setClient(response.client.firstName + ' ' + response.client.lastName)
      setClientBoId(response.client.boId)
    } else {
      setClient('')
      setClientBoId('')
    }

    switch (response.status) {
      case 'unassigned':
      case 'returned':
      case 'deposit':
      case 'deposit-returned':
        setData(response)
        setItemStatus(t('merchant-check-status.available'))
        setBorder('2px solid #ffbb01')
        break
      case 'assigned':
        if (response.merchant && response.merchant.boId === merchantBoId) {
          setData(response)
          addToList(response)
          setBorder('2px solid #43be40')
          setItemStatus(t('merchant-check-status.returnable'))
        } else {
          setErrors([{ msg: t('merchant-check-status.assigned-another-merchant') }])
          setBorder('2px solid #ec4242')
          setItemStatus(t('merchant-check-status.not-returnable'))
        }
        break
      case 'pending-purchase':
      case 'purchased':
        setErrors([{ msg: t('merchant-check-status.purchased') }])
        setBorder('2px solid #ec4242')
        setItemStatus(t('merchant-check-status.not-returnable'))
        return
      case 'written-off':
        setErrors([{ msg: t('merchant-check-status.written-off') }])
        setBorder('2px solid #ec4242')
        setItemStatus(t('merchant-check-status.not-returnable'))
        return
      case 'lost':
        setErrors([{ msg: t('merchant-check-status.lost') }])
        setBorder('2px solid #ec4242')
        setItemStatus(t('merchant-check-status.not-returnable'))
        return
      case 'on-consignment':
        if (response.merchant && response.merchant.name) {
          setData(response)
          setErrors([{ msg: t('merchant-check-status.on-consignment', { name: response.merchant.name, price: response.depositPrice }) }])
          setBorder('2px solid #ec4242')
          setItemStatus(t('merchant-check-status.not-returnable'))
        }
        return
    }
  }

  const addToList = async (data: any) => {
    let index = itemListByType.findIndex(r => r._id === data.itemTypeId)
    if (index < 0) {
      setItemListByType(itemListByType.concat({ _id: data.itemTypeId, iconUrl: data.iconUrl, items: [data] }))
      setItemList(itemList.concat(data))
    } else {
      let itemIndex = itemListByType[index].items.findIndex(i => i._id === data._id)
      if (itemIndex < 0) {
        const tempItemList = itemListByType
        tempItemList[index].items.push(data)
        setItemListByType(tempItemList)
        setItemList(itemList.concat(data))
      } else {
        setErrors([{ msg: t('item-aready-added') }])
      }
    }
  }

  const returnItems = async (e: any) => {
    e.preventDefault()

    if (itemList.length < 1) {
      return
    }

    setShowReturnItemDialog(true)
  }

  const submitItems = async () => {
    setIsLoading(true)

    let itemIds: string = ''
    itemList.forEach(item => {
      itemIds += `"${item._id}",`
    })

    const mutation = `mutation { internalEcosystemMerchantReturn ( input: { ids: [${itemIds}] } ) { _id } }`
    const response = await wrappedExtendedMutateAuth(mutation)
    if (response) {
      setMsg(t('successfully-returned-inventory'))
      setItemTypeName('')
      setUuid('')
      setData(undefined)
      setItemList([])
      setItemListByType([])
      setClient('')
      setItemStatus('')
      setClientBoId('')
      setShowContent(false)
    } else {
      setItemTypeName('')
      setUuid('')
      setData(undefined)
      setItemList([])
      setItemListByType([])
      setClient('')
      setItemStatus('')
      setClientBoId('')
      setShowContent(false)
      setErrors([{ msg: t('unsuccessfully-returned-inventory') }])
    }

    setShowReturnItemDialog(false)
  }

  const dialogSubmit = async (e: any) => {
    if (!itemList) {
      return
    }
    submitItems().then(() => setIsLoading(false))
  }

  const dialogCancel = (e: any) => {
    setShowReturnItemDialog(false)
  }

  const handleScanAnother = (e: any) => {
    e.preventDefault()
    setMsg('')
    setIsScanTypeRemove(false)
    setShowScanner(true)
  }

  const handleRemoveFromList = async (uuid: string) => {
    setErrors([])
    setMsg('')
    setItemList(itemList.filter(item => item.uuid !== uuid))

    let x: ItemListData[] = []

    for (const type of itemListByType) {
      const y = type.items.filter(item => item.uuid !== uuid)
      x.push({ _id: type._id, iconUrl: type.iconUrl, items: y })
    }

    setItemListByType(x)
    setShowScanner(false)
  }

  const handleRemoveFromListByScan = async (code: string) => {
    setMsg('')
    if (!code.includes('items/')) {
      setErrors([{ msg: t('invalid-item-qr-code') }])
      return
    }

    const url = new URL(code)
    const uuid = url.pathname.split('/').pop() || ''
    const index = itemList.findIndex(r => r.uuid === uuid)
    if (index < 0) {
      setErrors([{ msg: t('item-not-added') }])
      setShowScanner(false)
      return
    }

    setItemList(itemList.filter(item => item.uuid !== uuid))
    let tempItemListByType: ItemListData[] = []

    for (const type of itemListByType) {
      const y = type.items.filter(item => item.uuid !== uuid)
      tempItemListByType.push({ _id: type._id, iconUrl: type.iconUrl, items: y })
    }
    setMsg(t('item-removed-list'))

    setItemListByType(tempItemListByType)
    setShowScanner(false)
  }

  const scanToRemove = () => {
    setErrors([])
    setMsg('')
    setIsScanTypeRemove(true)
    setShowScanner(true)
  }

  const onItemTypeClick = (items: Data[]) => {
    if (items.length > 0) {
      setItemTypeDialogTitle(items[0].itemTypeName)
      setShowItemTypeDialog(true)
    } else {
      setItemTypeDialogTitle('')
      setShowItemTypeDialog(false)
    }
  }

  useEffect(() => {
    const itemsByType = itemListByType.find(i => {
      if (i.items.length > 0 && i.items[0].itemTypeName === itemTypeDialogTitle) return true
      else return false
    })
    if (itemsByType) {
      const tempData = () => {
        return (
          <div className="type-cards">
            {itemsByType.items.map((i: any) => (
              <div className="scanned-item-uuid" key={i.uuid}>
                <label>{i.uuid}</label>
                <ErrorIcon2 fill="red" height={12} onClick={() => handleRemoveFromList(i.uuid)} />
              </div>
            ))}
          </div>
        )
      }
      setItemTypeDialogData(tempData)
    } else {
      setItemTypeDialogData(undefined)
      setShowItemTypeDialog(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTypeDialogTitle, itemListByType, showItemTypeDialog])

  const itemTypeDialogCancel = () => {
    setItemTypeDialogData(undefined)
    setItemTypeDialogTitle('')
    setShowItemTypeDialog(false)
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <>
      {showScanner && (
        <section className="qrScanner">
          <QrScanner callback={isScanTypeRemove ? handleRemoveFromListByScan : onQrCode} callbackCancel={onCancel}></QrScanner>
        </section>
      )}
      {!showScanner && (
        <form className="object-form" onSubmit={returnItems}>
          <BackButton />
          <div className="Header">
            <ErrorElement errors={errors} />
            {msg && (
              <p>
                <SuccessIcon /> {msg}
              </p>
            )}
            {showContent && (
              <>
                <label className="total-scanned">
                  {t('total-items-added')}: <strong>{itemList.length}</strong>
                </label>
                <fieldset style={{ border }}>
                  <legend style={{ fontSize: 25, fontStyle: 'bold' }}>{t('last-scanned-item')}</legend>
                  <table>
                    <tbody>
                      <tr>
                        <td>{t('item-types.item-type')}:</td>
                        <td>{itemTypeName}</td>
                      </tr>
                      <tr>
                        <td>{t('status.status')}:</td>
                        <td>{itemStatus}</td>
                      </tr>
                      <tr>
                        <td>ID:</td>
                        <td>{uuid}</td>
                      </tr>
                      {client && (
                        <tr>
                          <td>{t('assigned-to')}:</td>
                          <td>{client}</td>
                        </tr>
                      )}
                      {client && (
                        <tr>
                          <td>{t('client-bo-id')}:</td>
                          <td>{clientBoId}</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </fieldset>
                {itemList.length > 0 && (
                  <label className="scan-to-remove" onClick={scanToRemove}>
                    {t('scan-to-remove')}
                  </label>
                )}
              </>
            )}
          </div>
          {itemListByType &&
            itemListByType.length > 0 &&
            itemListByType.map(t => t.items && t.items.length > 0 && <CardWithIcon key={t._id} data={{ labelMain: `${t.items[0].itemTypeName}`, count: t.items.length, iconUrl: t.iconUrl, onClick: () => onItemTypeClick(t.items) }} />)}
          <div className="buttonDock"></div>
          <AddAndCompleteElement data={{ submitting, disabledComplete: itemList.length > 0 ? false : true, onClickAdd: handleScanAnother }} />
        </form>
      )}
      {data && data.client && (
        <ConfirmDialog
          data={{
            title: t('return-to-inventory'),
            cancelHook: dialogCancel,
            submitHook: dialogSubmit,
            text: itemList.length > 1 ? 'Are you sure you want to return these items to your inventory?' : t('confirm-return'),
            show: showReturnItemDialog,
          }}
        />
      )}
      <CustomDialog data={{ cancelHook: itemTypeDialogCancel, show: showItemTypeDialog, position: 'center', title: itemTypeDialogTitle ? itemTypeDialogTitle : '', content: itemTypeDialogData ? itemTypeDialogData : undefined }} />
    </>
  )
}

export { InternalReturn as default }
