import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import apiUtils from '../../api/apiUtils'
import LogoutButton from '../../components/buttons/LogoutButton'
import Logo from '../../components/Logo'
import { MerchantPaths } from '../../routes/RoutePaths'
import CardSetupForm from './CardSetupForm'

const MerchantRegisterCC = () => {
  const history = useHistory()

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '')

  const { t } = useTranslation()

  const { setAuthToLocalStorage } = apiUtils()

  const onSubmit = (data: any) => {
    setAuthToLocalStorage(data.registerMerchantConfirmCC)
    history.push(MerchantPaths.Home)
  }

  return (
    <main>
      <Logo></Logo>
      <h2>{t('tell-us-more')}</h2>
      <Elements stripe={stripePromise}>
        <CardSetupForm onSuccessCallback={onSubmit} />
      </Elements>
      <LogoutButton text="Cancel" />
    </main>
  )
}

export { MerchantRegisterCC as default }
