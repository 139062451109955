import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../api/api'
import { ReactComponent as GreenrLogo } from '../../icons/greenr-logo.svg'
import AccountSuspendedNotice from '../customer/AccountSuspendedNotice'
import ErrorElement from '../ErrorElement'
import SplashScreen from '../SplashScreen'
import './ImpactWidget.css'
import { EnergyFigure, MainFigure, NatureFigure, SavedCO2Figure } from './StatFigures'
import { AnimalTiersData, TiersData, UserStatsData } from './statsTypes'
import { AnimalTiers, getAnimalTier, getEnergyTier } from './statsUtils'
import Switch from 'react-switch'

const ImpactWidget = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation(['impact'])

  const { errors, wrappedExtendedQueryAuth } = useAPI()

  const [stats, setStats] = useState<UserStatsData>()
  const [animalTiers, setAnimalTiers] = useState<AnimalTiersData>()
  const [energyTiers, setEnergyTiers] = useState<TiersData>()

  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    async function onLoad() {
      const query = `{ userStats { boQuantity carbonSaved plasticSaved}}`
      const factory = (result: any): UserStatsData => {
        return result.userStats
      }
      const response = await wrappedExtendedQueryAuth(query, factory)
      if (response) {
        setStats(response)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onLoad() {
      if (stats) {
        let resultEnergyTiers = await getEnergyTier(stats.carbonSaved)
        let resultAnimalTiers = await getAnimalTier(stats.plasticSaved)

        setEnergyTiers(resultEnergyTiers)
        setAnimalTiers(resultAnimalTiers)
      }
    }
    onLoad()
  }, [stats])

  const onGreenrClick = () => {
    window.open('https://www.greenr.com', '_blank')
  }

  const EnergyText = () => {
    return <label className="impact-toggle-label">{t('energy')}</label>
  }
  const MassText = () => {
    return <label className="impact-toggle-label">{t('nature')}</label>
  }

  return isLoading ? (
    <SplashScreen />
  ) : (
    <section className="HomeSection WithNavbar impact">
      <header>
        <h1>{t('impact')}</h1>
        <GreenrLogo className="greenrLogo" onClick={onGreenrClick} />
      </header>
      <AccountSuspendedNotice />
      <ErrorElement errors={errors} />
      <div className="impact-toggle">
        <Switch
          onChange={() => {
            setToggle(!toggle)
          }}
          className="impact-switch"
          checked={toggle}
          offColor="#ffbb01"
          onColor="#ffbb01"
          activeBoxShadow="0 0 2px 3px #fff"
          height={40}
          width={150}
          uncheckedIcon={<EnergyText />}
          checkedIcon={<MassText />}
        />
      </div>
      {stats && stats.boQuantity > 0 ? (
        !toggle ? (
          <>
            <MainFigure quantity={stats.boQuantity} />
            <SavedCO2Figure carbonSaved={stats.carbonSaved} />
            {energyTiers ? <EnergyFigure tier={energyTiers.tier} quantity={energyTiers.quantity} /> : <></>}
          </>
        ) : (
          <>
            {animalTiers && animalTiers.mainTier ? <NatureFigure plasticSaved={stats.plasticSaved} quantity={animalTiers.mainTier.quantity} tier={animalTiers.mainTier.tier} /> : <></>}
            {animalTiers && animalTiers.secondaryTier && animalTiers.secondaryTier.quantity !== 0 ? (
              <NatureFigure plasticSaved={stats.plasticSaved} quantity={animalTiers.secondaryTier.quantity} tier={animalTiers.secondaryTier.tier} secondaryTier />
            ) : (
              <></>
            )}
            {animalTiers && animalTiers.mainTier.tier !== AnimalTiers.NoTier ? (
              <NatureFigure className="next-tier-img" plasticSaved={stats.plasticSaved} quantity={animalTiers.mainTier.quantity} tier={animalTiers.mainTier.tier} unlockTier={true} />
            ) : (
              <></>
            )}
          </>
        )
      ) : (
        <p className="empty-list-msg">{t('zero-quantity-msg')}</p>
      )}
    </section>
  )
}

export { ImpactWidget as default }
