import { useEffect, useState } from 'react'
import './WelcomeIntro.css'
import { ReactComponent as ArrowLeft } from '../icons/arrow-short-left.svg'
import { TutorialEng, TutorialFr } from './WelcomeIntroContents'
import { useTranslation } from 'react-i18next'
import { MainModal } from './modal/Modals'
import { TermsHtmlEng, TermsHtmlFr } from './TermsAndConditions'
import PrivacyPolicyEng from '../containers/common/PrivacyPolicies/PrivacyPolicyEng'
import PrivacyPolicyFr from '../containers/common/PrivacyPolicies/PrivacyPolicyFr'
import CookiePolicyEng from '../containers/common/CookiePolicies/CookiePolicyEng'
import CookiePolicyFr from '../containers/common/CookiePolicies/CookiePolicyFr'
import { LanguageSelect } from './LanguageSelector'
import StepCounter from './common/StepCounter'

type Data = {
  onFinishWelcome: (e: any) => void
}

const WelcomeIntro = ({ data }: { data: Data }) => {
  const [showToS, setShowToS] = useState(false)
  const [showPP, setShowPP] = useState(false)
  const [showCP, setShowCP] = useState(false)

  const [step, setStep] = useState(0)
  const [tutorial, setTutorial] = useState<JSX.Element[]>([])
  const { i18n, t } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

  useEffect(() => {
    const onLoad = () => {
      selectedLanguage.indexOf('en') === -1 ? setTutorial(TutorialFr) : setTutorial(TutorialEng)
    }

    onLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage])

  const onNext = () => {
    setStep(step + 1)
  }

  const onPreviousStep = () => {
    setStep(step - 1)
  }

  const onToSClick = () => {
    setShowToS(true)
  }

  const onToSClose = () => {
    setShowToS(false)
  }

  const onPPClick = () => {
    setShowPP(true)
  }

  const onPPClose = () => {
    setShowPP(false)
  }

  // const onCPClick = () => {
  //   setShowCP(true)
  // }

  const onCPClose = () => {
    setShowCP(false)
  }

  return (
    <section className={`welcome-wrapper`}>
      {step === 0 && <div className="welcome-start"></div>}
      {showToS && (
        <MainModal
          data={{
            position: 'center',
            cancelHook: onToSClose,
            submitHook: onToSClose,
            buttonText: t('accept-and-continue'),
            show: showToS,
            title: t('terms-of-service'),
            content: selectedLanguage.indexOf('en') === -1 ? <TermsHtmlFr className="modalTerms" showHeading={false} /> : <TermsHtmlEng className="modalTerms" showHeading={false} />,
          }}
        />
      )}
      {showPP && (
        <MainModal
          data={{
            position: 'center',
            cancelHook: onPPClose,
            submitHook: onPPClose,
            buttonText: t('accept-and-continue'),
            show: showPP,
            title: t('privacy-policy'),
            content: selectedLanguage.indexOf('en') === -1 ? <PrivacyPolicyFr className="modalTerms" showHeading={false} /> : <PrivacyPolicyEng className="modalTerms" showHeading={false} />,
          }}
        />
      )}
      {showCP && (
        <MainModal
          data={{
            position: 'center',
            cancelHook: onCPClose,
            submitHook: onCPClose,
            buttonText: t('accept-and-continue'),
            show: showCP,
            title: t('cookie-policy'),
            content: selectedLanguage.indexOf('en') === -1 ? <CookiePolicyFr className="modalTerms" showHeading={false} /> : <CookiePolicyEng className="modalTerms" showHeading={false} />,
          }}
        />
      )}
      <div className="welcome-section">
        {step === 0 ? (
          <LanguageSelect className="welcomeLanguageSelect" setValue={setSelectedLanguage} />
        ) : (
          <div className="back-with-step">
            <div className="previous-step" onClick={onPreviousStep}>
              <ArrowLeft />
              <label>{t('back')}</label>
            </div>
            {step >= 2 && <StepCounter className="solo-step-counter" text={`${step - 1}/4`} percentage={(360 / (tutorial.length - 2)) * (step - 1)} />}
          </div>
        )}
        {tutorial[step]}
        <div className="welcome-footer">
          <div className="carousel-page-indicators">
            {tutorial.map((element, index) => {
              return <div key={index} className={`${index === step ? 'currentStep' : 'step'}`}></div>
            })}
          </div>
          <div className="buttons-forward-back">
            {step === 0 && (
              <>
                <button className="button-medium main-button" onClick={onNext}>
                  {t('get-started')}
                </button>
                {step === 0 && (
                  <label className="linkable-span">
                    {selectedLanguage.indexOf('en') !== -1 ? (
                      <>
                        By continuing you agree to Bopaq’s <span onClick={onToSClick}>Terms of Service</span>. We will handle the information as described in our <span onClick={onPPClick}>Privacy Policy</span>.
                        {/* and{' '}
                        <span onClick={onCPClick}>Cookie Policy</span>. */}
                      </>
                    ) : (
                      <>
                        En cliquant sur «Accepter et continuer», vous acceptez les <span onClick={onToSClick}>Conditions d'utilisation</span> de Bopaq. Nous allons traiter votre information selon notre{' '}
                        <span onClick={onPPClick}>Politique de confidentialité</span>.{/* et notre <span onClick={onCPClick}>Politique de cookies</span>. */}
                      </>
                    )}
                  </label>
                )}
              </>
            )}
            {step !== 0 && step !== tutorial.length - 1 && (
              <>
                <button className="button-medium main-button" onClick={onNext}>
                  {t('next')}
                </button>
                <button className="button-medium naked-button" onClick={data.onFinishWelcome}>
                  {t('skip-this')}
                </button>
              </>
            )}
            {step === tutorial.length - 1 && (
              <>
                <button className="button-medium main-button" onClick={data.onFinishWelcome}>
                  {t('finish-tutorial')}
                </button>
                <button className="button-medium naked-button" onClick={() => setStep(0)}>
                  {t('replay')}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export { WelcomeIntro as default }
