import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import apiUtils from '../../api/apiUtils'
import BackButton from '../../components/BackButton'
import { SmallLogo } from '../../components/Logo'

const ApprovalPending = () => {
  const { t } = useTranslation()
  const { removeAuthFromLocalStorage } = apiUtils()

  useEffect(() => {
    removeAuthFromLocalStorage()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main>
      <BackButton />
      <SmallLogo />
      <h2>{t('thank-you')}</h2>
      <label>{t('approval-pending')}</label>
    </main>
  )
}

export { ApprovalPending as default }
