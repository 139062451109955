import './ErrorElement.css'
import { ReactComponent as SuccessIcon } from '../icons/success.svg'

const SuccessElement = ({ msgs }: any) => {
  return (
    <>
      {Array.isArray(msgs) && msgs.length > 0 && (
        <div className="errorWrapper">
          <SuccessIcon className="bo-success" />
          <label>{Array.isArray(msgs) ? msgs.map(e => e.msg).join(',') : msgs}</label>
        </div>
      )}
    </>
  )
}

export { SuccessElement as default }
