import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PasswordChecklist from 'react-password-checklist'
import { ReactComponent as CheckMarkIcon } from '../icons/check_mark.svg'
import { ReactComponent as XMarkIcon } from '../icons/x_mark.svg'

const ShowPasswordCheck = ({ checked, onChange }: { checked: boolean; onChange: (e: React.MouseEvent<HTMLInputElement>) => void }) => {
  const { t } = useTranslation()

  return (
    <div className="show-password" onClick={onChange}>
      <input
        type="checkbox"
        checked={checked}
        onChange={() => {
          checked = !checked
        }}
      />
      <label>{t('password.show')}</label>
    </div>
  )
}

const PasswordInput = ({ value, onChange, label, showPw = false }: { value: string; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; label: string; showPw?: boolean }) => {
  const [inputType, setInputType] = useState('password')
  const { t } = useTranslation()

  useEffect(() => {
    if (showPw) {
      setInputType('text')
    } else {
      setInputType('password')
    }
  }, [showPw])

  return (
    <>
      <label>{label}</label>
      <input value={value} type={inputType} onChange={onChange} required={true} autoComplete={'off'} title={t('password.rules')}></input>
    </>
  )
}

const ConfirmPasswordInput = ({ value, onChange, label, showPw = false }: { value: string; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; label: string; showPw?: boolean }) => {
  const [inputType, setInputType] = useState('password')

  useEffect(() => {
    if (showPw) {
      setInputType('text')
    } else {
      setInputType('password')
    }
  }, [showPw])

  return (
    <>
      <label>{label}</label>
      <input value={value} type={inputType} autoComplete={'off'} onChange={onChange} required={true}></input>
    </>
  )
}

const PwChecklist = ({ value, valueAgain, onChange }: { value: string; valueAgain?: string; onChange: any }) => {
  const { t } = useTranslation()

  const specialChars: RegExp = /[@$!%*?\-&]/

  const messages = {
    minLength: t('password.rule.min-length'),
    specialChar: t('password.rule.special-char'),
    number: t('password.rule.number'),
    capital: t('password.rule.capital'),
    lowercase: t('password.rule.lowercase'),
    match: t('password.rule.match'),
  }
  return value ? (
    <PasswordChecklist
      className="password-checklist"
      rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase', 'match']}
      minLength={7}
      specialCharsRegex={specialChars}
      value={value}
      valueAgain={valueAgain}
      messages={messages}
      onChange={isValid => {
        onChange(isValid)
      }}
    />
  ) : (
    <></>
  )
}

const PasswordCheck = ({ value, onChange }: { value: string; onChange: any }) => {
  const { t } = useTranslation()

  const specialChars: RegExp = /[@$!%*?\-&]/

  const messages = {
    minLength: t('password.rule.min-length'),
    specialChar: t('password.rule.special-char'),
    number: t('password.rule.number'),
    capital: t('password.rule.capital'),
    lowercase: t('password.rule.lowercase'),
  }
  return value ? (
    <div className="password-check">
      <label>{t('password-requirements')}</label>
      <PasswordChecklist
        className="password-checklist"
        rules={['minLength', 'specialChar', 'number', 'capital', 'lowercase']}
        specialCharsRegex={specialChars}
        minLength={7}
        value={value}
        messages={messages}
        onChange={isValid => {
          onChange(isValid)
        }}
        iconComponents={{
          ValidIcon: <CheckMarkIcon />,
          InvalidIcon: <XMarkIcon />,
        }}
      />
    </div>
  ) : (
    <></>
  )
}

export { PasswordInput, ConfirmPasswordInput, ShowPasswordCheck, PwChecklist, PasswordCheck }
