export enum Status {
  Active = 'active',
  Inactive = 'inactive',
}

type SelectOptions = {
  label: string | undefined
  value: string | undefined
}

const statusOptions: SelectOptions[] = [
  { label: 'status.active', value: Status.Active },
  { label: 'status.inactive', value: Status.Inactive },
]

export { statusOptions as default }

export type { SelectOptions }
