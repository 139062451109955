import { useTranslation } from 'react-i18next'

type OrderDocumentTotalData = {
  totalAmount: number
  totalDiscount: number
}

const OrderDocumentTotals = ({ data }: { data: OrderDocumentTotalData }) => {
  const { t } = useTranslation()

  let totalAmount = data.totalAmount
  let totalDiscount = data.totalDiscount
  let taxedAmount = totalAmount - totalDiscount
  let qst = parseFloat((taxedAmount * 0.09975).toFixed(2))
  let gst = parseFloat((taxedAmount * 0.05).toFixed(2))
  let total = (taxedAmount + qst + gst).toFixed(2)

  return (
    <div className="totals">
      <div>
        <label>{t('orders.subtotal')}</label>
        <label>${totalAmount.toFixed(2)}</label>
      </div>
      {totalDiscount !== 0 && (
        <div>
          <label className="opacity-60">{t('orders.discount')}</label>
          <label>-${totalDiscount.toFixed(2)}</label>
        </div>
      )}
      <div>
        <label className="opacity-60">{t('orders.qst')}</label>
        <label>${qst}</label>
      </div>
      <div>
        <label className="opacity-60">{t('orders.gst')}</label>
        <label>${gst}</label>
      </div>
      <div>
        <label>{t('orders.total')}</label>
        <label>${total}</label>
      </div>
    </div>
  )
}

export default OrderDocumentTotals
