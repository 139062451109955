import { Trans, useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import './Deposit.css'
import FailImage from '../../images/fail.png'
import { useEffect, useState } from 'react'

type DepositData = {
  supportCode: string
}

const DepositFailed = () => {
  let history = useHistory()
  let location = useLocation()
  const passedData: DepositData = location.state as any as DepositData
  let { t } = useTranslation(['translation', 'errors'])
  const [, setIsLoading] = useState(false)
  const [supportCode, setSupportCode] = useState('')

  const onClick = () => {
    history.replace('/')
  }

  useEffect(() => {
    async function onLoad() {
      if (!!passedData && !!passedData.supportCode) {
        setSupportCode(passedData.supportCode)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className="mainHome">
      <div className="screen-header">
        <img src={FailImage} alt="Red X" />
        <h1>{t('oops-something-wrong')}</h1>
      </div>
      <div className="success-complete">
        <p className="general-paragraph">
          <label>{t('theres-been-a-problem')}</label>
        </p>
        <p className="general-paragraph">
          <label>
            {t('problem-explanation[0]')} {supportCode} {t('problem-explanation[1]')}
          </label>
        </p>
        <p className="general-paragraph">
          <Trans i18nKey="return-successful-contact-text" />
        </p>
      </div>
      <div className="footer-button-section">
        <button className="button-medium main-button" onClick={onClick}>
          {t('go-home')}
        </button>
      </div>
    </main>
  )
}

export { DepositFailed as default }
