import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useAPI } from '../../../api/api'
import { roleFromId, statusFromId } from '../../../api/utils'
import ActionBar from '../../../components/admin/ActionBar'
import Card from '../../../components/Card'
import ErrorElement from '../../../components/ErrorElement'
import { FailIcon, SuccessIcon } from '../../../components/Icons'
import Navbar from '../../../components/Navbar'
import Searchbar from '../../../components/Searchbar'

import { ReactComponent as Plus } from '../../../icons/plus.svg'
import '../../CategoryHome.css'

type Employee = {
  _id: string
  email: string
  boId: string
  role: string
  registrationState: number
}

const Employees = () => {
  const { t } = useTranslation()

  let { errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const [employees, setEmployees] = useState<Employee[]>([])

  useEffect(() => {
    async function onLoad() {
      const query = `query { employees { _id email boId role registrationState } }`
      const factory = (result: any): Employee[] => {
        return result.employees
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setEmployees(response)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = async (id: string, registrationState: number) => {
    if (!id || !registrationState) {
      return
    }

    let mutation = undefined
    let newState = undefined
    if (registrationState === -1) {
      mutation = `mutation { activateEmployee (id: "${id}") }`
      newState = 3
    } else if (registrationState === 3) {
      mutation = `mutation { deactivateEmployee (id: "${id}") }`
      newState = -1
    }

    if (!mutation || !newState) {
      return
    }

    const response = await wrappedExtendedMutateAuth(mutation)
    if (!response) {
      return
    }

    const existing = employees.find(e => e._id === id)
    if (!existing) {
      return
    }

    const updated = { ...existing, registrationState: newState }

    setEmployees([...employees.filter(e => e._id !== id), updated])
  }

  return (
    <>
      <Navbar></Navbar>
      <section className="HomeSection WithNavbar">
        <header>
          <h1>{t('employees.employees')}</h1>
          <Link to="/admin/employees/add">
            <Plus className="add-object" />
          </Link>
        </header>
        <Searchbar></Searchbar>
        <hr />
        <ErrorElement errors={errors} />
        {employees.length === 0 ? (
          <p className="empty-list-msg">{t('empty.employees')}</p>
        ) : (
          employees.map(data => (
            <Card
              key={data._id}
              data={{
                labelMain: data.email,
                labelSub2: roleFromId(data.role),
                labelSub1: data.boId,
                labelBotRight: statusFromId(data.registrationState),
                iconBotRight: data.registrationState === 3 ? <SuccessIcon /> : <FailIcon />,
                onClick: (e: any) => onClick(data._id, data.registrationState),
              }}
            />
          ))
        )}
      </section>
      <ActionBar></ActionBar>
    </>
  )
}

export { Employees as default }
export type { Employee }
