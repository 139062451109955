import { FormEvent, useEffect, useState } from 'react'
import { ShortenedItemTypeCardData } from '../../../components/admin/ItemTypeCard'
import BackButton from '../../../components/BackButton'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import { useHistory } from 'react-router'
import ErrorElement from '../../../components/ErrorElement'
import SelectMenu from '../../../components/SelectMenu'
import { SelectOptions } from '../MainStatuses'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import { setNotification } from '../../../components/Notification'
import { AddOrderRow } from '../../../components/orderDocument/AddOrderRow'
import '../../../components/orderDocument/AddOrder.css'
import { CustomDialog } from '../../../components/Dialog'

export enum OrderType {
  Initial = 'initial',
  Restock = 'restock',
  Rental = 'rental',
}

type OrderItem = {
  itemType: string
  quantity: number
}

type OrderData = {
  merchant: string
  orderItems: OrderItem[]
}

type UpdateOrderStatusData = {
  _id: string
  status: string
}

type Merchant = {
  _id: string
  email: string
  businessName: string
  returnSite: string
}

type ItemTypeData = {
  itemType: ShortenedItemTypeCardData
  quantity: number | ''
  unitPrice: number | '' | undefined
}

const AddOrder = () => {
  const { t } = useTranslation()

  const history = useHistory()

  let { submitting, errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const [isLoading, setIsLoading] = useState(false)
  const [orderTypeChange, setOrderTypeChange] = useState(false)
  const [merchants, setMerchants] = useState<Merchant[]>([])
  const [orderItemTypes, setOrderItemTypes] = useState<ItemTypeData[]>([])
  const [orderItemsData, setOrderItemsData] = useState<ItemTypeData[]>([])
  const [tempOrderItemsData, setTempOrderItemsData] = useState<ItemTypeData[]>([])

  const [merchantId, setMerchantId] = useState('')
  const [merchant, setMerchant] = useState<Merchant | undefined>()
  const [orderType, setOrderType] = useState('restock')
  const [showDialog, setShowDialog] = useState(false)
  const [addItemType, setAddItemType] = useState<ItemTypeData | undefined>(undefined)

  const merchantOptions: SelectOptions[] = merchants.map(merchant => ({
    value: merchant._id,
    label: merchant.businessName,
  }))

  const addItemTypeOptions: SelectOptions[] = orderItemTypes.map(type => ({
    value: type.itemType._id,
    label: type.itemType.name,
  }))

  const orderTypes: SelectOptions[] = [
    { value: 'initial', label: 'Initial' },
    { value: 'restock', label: 'Restock' },
    { value: 'rental', label: 'Rental' },
  ]

  useEffect(() => {
    setIsLoading(true)
    async function onLoad() {
      const query = `
      query {
        itemTypes { _id name quantity pricing {wholesale, retail, restock, restockSpecial, onboarding, rental} } 
        merchants (filter: { isApproved: true }) { _id email businessName isApproved returnSite}
      }`

      const factory = (result: any): any => {
        const merchants = result.merchants
        const types = result.itemTypes.map((x: any) => ({
          itemType: {
            _id: x._id,
            name: x.name,
            quantity: x.quantity,
            pricing: {
              restock: x.pricing.restock / 100,
              retail: x.pricing.retail / 100,
              wholesale: x.pricing.wholesale / 100,
              onboarding: x.pricing.onboarding / 100,
              rental: x.pricing.rental / 100,
              restockSpecial: x.pricing.restockSpecial / 100,
            },
          } as ShortenedItemTypeCardData,
        }))

        return { merchants, types }
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setOrderItemTypes(response.types)

        setOrderItemsData(
          response.types.filter((t: ItemTypeData) => {
            if (!t.itemType.name.toLowerCase().includes('pilote')) return true
            else return false
          }),
        )

        setMerchants(response.merchants)
      }
    }
    onLoad().then(() => setIsLoading(!isLoading))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsLoading(true)
    const settingMerchant = async () => {
      const tempMerchant = merchants.find(m => m._id === merchantId)
      setMerchant(tempMerchant ? tempMerchant : undefined)
    }
    settingMerchant().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantId])

  const getUnitPrice = (orderItem: ItemTypeData) => {
    let unitPrice: number | undefined = 0
    switch (orderType) {
      case OrderType.Initial:
        if (orderItem.itemType.name.toLowerCase().includes('pilote')) {
          unitPrice = orderItem?.itemType.pricing?.onboarding
        } else {
          unitPrice = orderItem?.itemType.pricing?.wholesale
        }
        break
      case OrderType.Restock:
        if (merchant?.returnSite) {
          unitPrice = orderItem?.itemType.pricing?.restockSpecial
        } else {
          unitPrice = orderItem?.itemType.pricing?.restock
        }
        break
      case OrderType.Rental:
        unitPrice = orderItem?.itemType.pricing?.rental
        break
    }
    return unitPrice
  }

  useEffect(() => {
    setIsLoading(true)

    const setUnitPrices = async () =>
      orderItemsData.forEach(orderItem => {
        const currentItemType = orderItemTypes.find(t => t.itemType._id === orderItem.itemType._id)
        if (currentItemType) orderItem.unitPrice = getUnitPrice(currentItemType)
      })

    setUnitPrices().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchant, orderTypeChange])

  useEffect(() => {
    setIsLoading(true)
    async function onLoad() {
      if (orderType === 'initial') {
        setOrderItemsData(orderItemTypes)
      } else {
        setOrderItemsData(
          orderItemTypes.filter((t: ItemTypeData) => {
            if (!t.itemType.name.toLowerCase().includes('pilote')) return true
            else return false
          }),
        )
      }
    }
    onLoad().then(() => {
      setIsLoading(false)
      setOrderTypeChange(!orderTypeChange)
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType])

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    let orderItems: string = ''
    for (const o of orderItemsData.map(d => ({ itemType: d.itemType._id!, quantity: d.quantity || 0, unitPrice: d.unitPrice || 0 }))) {
      if (o.quantity > 0) {
        let item: string = `{ itemType: "${o.itemType}", quantity: ${o.quantity}, unitPrice: ${parseFloat((o.unitPrice * 100).toFixed(2))}}`
        orderItems += item
      }
    }

    const mutation = `mutation { createOrder ( input: { merchant: "${merchantId}" orderItems: [${orderItems}] orderType: "${orderType}" } ) { _id } }`

    const response = await wrappedExtendedMutateAuth(mutation)
    if (response) {
      setNotification('orders.created')
      history.goBack()
    }
  }

  const handleMerchantChange = (value: any, action: any) => {
    if (action.action === 'select-option') {
      setMerchantId(value.value)
    }
  }

  const handleTypeChange = (value: any, action: any) => {
    if (action.action === 'select-option') {
      setOrderType(value.value)
    }
  }

  const handleItemTypeChange = (index: number, value: string) => {
    let toSet: '' | number = ''
    if (value !== '' && !isNaN(parseInt(value))) {
      toSet = parseInt(value)
    }

    const orderItems = [...orderItemsData]
    orderItems[index].quantity = toSet

    setOrderItemsData(orderItems)
  }

  const handleItemTypeUnitPriceChange = (index: number, value: string) => {
    let toSet: '' | number = ''
    if (value !== '' && !isNaN(parseFloat(value))) {
      toSet = parseFloat(value)
    }

    const orderItems = [...orderItemsData]
    orderItems[index].unitPrice = toSet

    setOrderItemsData(orderItems)
  }

  const handleAddItemType = async (value: any, action: any) => {
    if (action.action === 'select-option') {
      const selectedItemType = orderItemTypes.find((t: ItemTypeData) => t.itemType._id === value.value)
      if (selectedItemType) {
        const price = getUnitPrice(selectedItemType)
        setAddItemType({ itemType: selectedItemType.itemType, quantity: 0, unitPrice: price })
      }
    }
  }

  const handleItemTypeRemove = (index: number) => {
    let temp = orderItemsData
    temp.splice(index, 1)

    setTempOrderItemsData(temp)
  }

  useEffect(() => {
    setIsLoading(true)
    async function onOrderLoad() {
      if (tempOrderItemsData.length > 0) {
        setOrderItemsData(tempOrderItemsData)
        setTempOrderItemsData([])
      }
    }
    onOrderLoad().then(() => setIsLoading(false))
  }, [tempOrderItemsData])

  const dialogCancel = (e: any) => {
    setShowDialog(false)
  }

  const dialogSubmit = async (e: any) => {
    if (addItemType) {
      setOrderItemsData([...orderItemsData, addItemType])
    }

    setAddItemType(undefined)
    setShowDialog(false)
  }

  const dialogContent = <SelectMenu data={{ options: addItemTypeOptions, handleChange: handleAddItemType }} />

  return (
    <form onSubmit={onSubmit} className="object-form">
      <BackButton />
      <h2>{t('orders.add')}</h2>
      <ErrorElement errors={errors} />
      <label>{t('roles.merchant')}</label>
      <SelectMenu data={{ options: merchantOptions, handleChange: handleMerchantChange }} />
      <label>{t('type')}</label>
      <SelectMenu data={{ options: orderTypes, handleChange: handleTypeChange, defaultValue: orderTypes[1] }} />
      {orderType && merchantId && (
        <>
          <div className="AddOrderHeader">
            <label>{t('item')}</label>
            <label>{t('quantity')}</label>
            <label>{t('orders.unit-price')}</label>
          </div>
          {orderItemsData.map((orderItem, index) =>
            AddOrderRow({
              data: {
                index,
                label1: orderItem.itemType.name,
                label2: `${orderItem.itemType.quantity} ${t('available')}`,
                quantity: orderItem.quantity,
                unitPrice: typeof orderItem.unitPrice === 'number' ? orderItem.unitPrice : parseFloat(orderItem.unitPrice || ''),
                callbackQuantity: handleItemTypeChange,
                callbackPriceChange: handleItemTypeUnitPriceChange,
                remove: handleItemTypeRemove,
              },
            }),
          )}
          <hr />
          <button type="button" onClick={e => setShowDialog(true)}>
            {t('item-types.add')}
          </button>
          <LoadingSubmitButton data={{ loading: submitting, text: t('save'), disabled: !merchantId || !orderType || orderItemsData.length === 0 }} />
        </>
      )}
      <CustomDialog data={{ title: t('item-types.add'), content: dialogContent, position: 'center', buttonText: t('add'), cancelHook: dialogCancel, submitHook: dialogSubmit, show: showDialog }} />
    </form>
  )
}

export { AddOrder as default }

export type { OrderData, UpdateOrderStatusData, ItemTypeData }
