import { Link } from 'react-router-dom'
import './Card.css'

type CardData = {
  _id?: string
  labelMain: string
  labelTopRight?: string | 0
  labelSub1?: string
  labelSub2?: string
  labelBotRight?: string
  labelBotRightUp?: string
  iconBotRight?: any
  iconBotRightUp?: any
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  linkTo?: string
  iconUrl?: string
  invoiceUrl?: string
  onInvoiceClick?: any
  count?: number
}

const Card = ({ data }: { data: CardData }) => {
  const renderMain = () => {
    return (
      <>
        <h3 className="name">{data.labelMain}</h3>
        {data.labelTopRight && <label className="quantity">{data.labelTopRight}</label>}
        {(data.labelSub1 || data.labelSub2 || data.labelBotRight || data.iconBotRight) && <hr />}
        {data.labelSub1 && <label className="dimensions">{data.labelSub1}</label>}
        {data.labelSub2 && <label className="capacity multi-line">{data.labelSub2}</label>}
        <div className="status">
          <div>
            {data.labelBotRightUp && <label>{data.labelBotRightUp}</label>}
            {data.iconBotRightUp}
          </div>
          <div>
            {data.labelBotRight && <label>{data.labelBotRight}</label>}
            {data.iconBotRight}
          </div>
        </div>
      </>
    )
  }
  return data.linkTo ? (
    <Link to={data.linkTo} className="card">
      {renderMain()}
    </Link>
  ) : data.onClick ? (
    <div className="card" onClick={e => data.onClick!(e)}>
      {renderMain()}
    </div>
  ) : (
    <div className="card">{renderMain()}</div>
  )
}

export { Card as default }
export type { CardData }
