import { ApolloError } from '@apollo/client'
import { APIError } from './response'

const extractErrorsFromApolloError = (apolloError: ApolloError): APIError[] | undefined => {
  console.debug('Error', JSON.parse(JSON.stringify(apolloError)))

  if (apolloError.networkError) {
    return [
      {
        msg: 'network-error',
      },
    ]
  }

  const errors: string[] = []
  if (apolloError.graphQLErrors) {
    apolloError.graphQLErrors.forEach((gqlError: any) => {
      const msgs = gqlError.message || []
      errors.push(...msgs)
    })
  }

  if (apolloError.message && errors.length === 0) {
    errors.push(apolloError.message)
  }

  return errors.map(error => {
    const split = error.split(':')
    return {
      msg: split[0] || 'unknown-error',
      value: split[1] || undefined,
      target: split[2] || undefined,
    }
  })
}

export { extractErrorsFromApolloError as default }
