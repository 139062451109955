import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import BackButton from '../../../components/BackButton'
import ErrorElement from '../../../components/ErrorElement'

import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion'
import CardWithIcon from '../../../components/CardWithIcon'
import CardAccordionPanel from '../../../components/common/CardAccordionPanel'
import moment from 'moment'
import ArchiveUser from '../../../components/admin/ArchiveUser'
import i18n from '../../../i18n'
import 'moment/locale/fr'
import SplashScreen from '../../../components/SplashScreen'

type CustomerProfileData = {
  _id: string
  registrationState: number
  email: string
  boId: string
  profile: {
    firstName: string
    lastName: string
    phoneNumber: string
  }
  address: {
    address: string
    city: string
    state: string
    postalCode: string
    country: string
  }
}

type CustomerData = {
  customer: CustomerProfileData
  clientDueDates: any
}

const ViewCustomer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  let lang = i18n.language

  let { errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const { id }: any = useParams()
  const [customer, setCustomer] = useState<CustomerData>()

  const loadList = async () => {
    if (!id) {
      return
    }

    const query = `query { 
      clients (id: "${id}"){_id email boId firstName lastName phoneNumber address {address city state postalCode country}}
      clientDueDatesFor (id: "${id}"){ _id itemType { _id name iconUrl } dueDate invoiceUrl }
    }`
    const factory = (result: any): CustomerData => {
      return {
        customer: result.clients.map((c: any) => ({
          _id: c._id,
          email: c.email,
          boId: c.boId,
          profile: {
            firstName: c.firstName,
            lastName: c.lastName,
            phoneNumber: c.phoneNumber,
          },
          address: c.address,
        }))[0],
        clientDueDates: result.clientDueDatesFor.map((i: any) => ({
          _id: i._id,
          itemCount: 0,
          dueDate: i.dueDate,
          dueDates: [],
          itemType: i.itemType,
          invoiceUrl: i.invoiceUrl,
        })),
      }
    }

    const response = await wrappedExtendedQueryAuth(query, factory)

    if (response) {
      const result: any = []
      if (response.clientDueDates) {
        for (const dueDate of response.clientDueDates) {
          let index = result.findIndex((r: any) => r.itemType._id === dueDate.itemType._id && !r.invoiceUrl)

          if (index < 0) {
            result.push(dueDate)
            index = result.length - 1
          }

          result[index].dueDates.push(dueDate.dueDate)
          result[index].itemCount++
        }
      }

      setCustomer({ ...response, clientDueDates: result })
    }
  }

  useEffect(() => {
    async function onLoad() {
      await loadList()
    }
    onLoad().then(() => setIsLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleWriteOff = async (itemType: string) => {
    if (customer) {
      const mutation = `mutation { writeOffDueDate ( input: {client: "${customer.customer._id}", itemType: "${itemType}"} ) }`
      await wrappedExtendedMutateAuth(mutation)
    }
    await loadList()
  }

  return (
    <>
      {isLoading ? (
        <SplashScreen withFooter={false} />
      ) : (
        <>
          <section className="HomeSection Merchant">
            <BackButton></BackButton>
            {customer && (
              <>
                <div className="edit">
                  <h2 className="name">{customer.customer.boId}</h2>
                </div>
                <ErrorElement errors={errors} />
                <div className="InfoRow">
                  <div>
                    <div>{t('contact')}</div>
                    <label>
                      {customer.customer.profile.firstName} {customer.customer.profile.lastName}
                    </label>
                    <label> {customer.customer.email}</label>
                    <label> {customer.customer.profile.phoneNumber}</label>
                  </div>
                  <div>
                    <div>{t('address')}</div>
                    <label>{customer.customer.address.address} </label>
                    <label>
                      {customer.customer.address.city}, {customer.customer.address.state}
                    </label>
                    <label> {customer.customer.address.postalCode}</label>
                  </div>
                </div>
                <Accordion allowZeroExpanded allowMultipleExpanded>
                  {customer.clientDueDates.length === 0 ? (
                    <p className="empty-list-msg">{t('empty.customer-inventory')}</p>
                  ) : (
                    customer.clientDueDates.map((item: any, index: any) => (
                      <AccordionItem key={item.itemType._id + index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <CardWithIcon
                              data={{
                                iconUrl: item.itemType.iconUrl,
                                labelMain: item.itemType.name,
                                labelTopRight: item.invoiceUrl ? t('expired') + moment.utc(item.dueDate).format('YYYY-MM-DD') : t('return-by') + moment.utc().locale(lang).to(moment(item.dueDate)),
                                labelSub1: `${item.itemCount} ${t('piece')}`,
                                invoiceUrl: item.invoiceUrl,
                              }}
                            />
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          {item.dueDates && item.itemCount > 1 ? (
                            <CardAccordionPanel data={{ heading: t('more-due-dates'), mappedRightLabels: item.dueDates, actionLabel: t('write-off'), action: () => handleWriteOff(item.itemType._id) }} />
                          ) : (
                            <CardAccordionPanel data={{ rightLabel: t('no-due-dates'), actionLabel: t('write-off'), action: () => handleWriteOff(item.itemType._id) }} />
                          )}
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))
                  )}
                </Accordion>
              </>
            )}
          </section>
          <ArchiveUser id={id} />
        </>
      )}
    </>
  )
}

export { ViewCustomer as default }
