import { useState } from 'react'
import { useIMask } from 'react-imask'

const PhoneNumberInput = ({ value, onChange, required, placeholder, disabled }: any) => {
  const [opts] = useState({
    mask: '{+1} (000) 000-0000',
    unmask: true,
  })
  const { ref }: any = useIMask(opts, {
    onComplete: (value: string, mask: any) => {
      console.debug('accept value', value, mask, mask._unmaskedValue)
      onChange(value)
    },
  })
  return <input placeholder={placeholder} ref={ref} defaultValue={value} required={required} disabled={disabled} />
}

export { PhoneNumberInput as default }
