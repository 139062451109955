import { Link, useLocation } from 'react-router-dom'

import './NavigationMenu.css'

import { ReactComponent as HomeIcon } from '../../icons/home.svg'
import { ReactComponent as HomeActiveIcon } from '../../icons/home_active.svg'
import { ReactComponent as InventoryIcon } from '../../icons/inventory.svg'
import { ReactComponent as InventoryActiveIcon } from '../../icons/inventory_active.svg'
import { ReactComponent as ScanIcon } from '../../icons/qr_code.svg'
import { ReactComponent as MapIcon } from '../../icons/map_inactive.svg'
import { ReactComponent as MapActiveIcon } from '../../icons/map_active.svg'
import { ReactComponent as ImpactIcon } from '../../icons/impact.svg'
import { ReactComponent as ImpactActiveIcon } from '../../icons/impact_active.svg'
import { useTranslation } from 'react-i18next'

const NavigationMenu = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const activeTab = location.pathname.split('/')[1].toLowerCase()

  return (
    <section className="navigationMenuContainer">
      <Link className={activeTab === '' ? 'active' : ''} to="/">
        {activeTab === '' ? <HomeActiveIcon /> : <HomeIcon />}
        {t('home')}
      </Link>
      <Link className={activeTab === 'bopaqs' ? 'active' : ''} to="/bopaqs">
        {activeTab === 'bopaqs' ? <InventoryActiveIcon /> : <InventoryIcon />}
        {t('bopaqs')}
      </Link>
      <Link className="navigationScanButton" to="/scan">
        <ScanIcon />
        {t('scan')}
      </Link>
      <Link className={activeTab === 'map' ? 'active' : ''} to="/map">
        {activeTab === 'map' ? <MapActiveIcon /> : <MapIcon />}
        {t('map')}
      </Link>
      <Link className={activeTab === 'impact' ? 'active' : ''} to="/login">
        {activeTab === 'impact' ? <ImpactActiveIcon /> : <ImpactIcon />}
        {t('impact')}
      </Link>
    </section>
  )
}

export { NavigationMenu as default }
