import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormEvent } from 'react-qr-barcode-scanner/node_modules/@types/react'
import { useHistory, useParams } from 'react-router'
import { useAPI } from '../../../api/api'
import BackButton from '../../../components/BackButton'
import ErrorElement from '../../../components/ErrorElement'
import ItemTypeRow from '../../../components/ItemTypeRow'
import { ItemTypeRowTableHeader } from '../../../components/ItemTypeRowTable'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import { setNotification } from '../../../components/Notification'
import SplashScreen from '../../../components/SplashScreen'

type UpdateMerchantsInventory = {
  merchantId: string
  inventoryItems: ItemTypeData[]
}

type ItemTypeData = {
  _id: string
  name: string
  quantity: number | ''
}

const EditMerchantInventory = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  const history = useHistory()

  let { submitting, errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const { id }: any = useParams()

  const [newMerchantInventoryData, setNewMerchantInventoryData] = useState<ItemTypeData[]>([])
  const [businessName, setBusinessName] = useState<string>('')

  useEffect(() => {
    async function onLoad() {
      if (!id) {
        return
      }

      const query = `query {  
        merchants (filter: {_id: "${id}" }) {businessName}
        itemTypes { _id name quantity }
        merchantInventories(filter: { merchant: "${id}" }) { inventoryItems { itemType { _id name } quantity } } 
      }`

      const factory = (result: any): any => {
        if (!result) {
          return undefined
        }

        const businessName = result.merchants[0].businessName

        const data = result.itemTypes.map((t: any) => ({ ...t, quantity: 0 }))

        if (result.merchantInventories.length === 0) return { businessName, data }

        const merchantInventory = result.merchantInventories[0]

        merchantInventory.inventoryItems.forEach((i: any) => {
          const row = data.find((d: any) => i.itemType._id === d._id)
          if (row) {
            row.quantity = i.quantity
          } else {
            data.push({ _id: i.itemType._id, name: i.itemType.name, quantity: i.quantity })
          }
        })

        return {
          businessName,
          data,
        }
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setNewMerchantInventoryData(response.data)
        setBusinessName(response.businessName)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const mutation = `mutation { 
      updateMerchantInventory ( input: { merchant: "${id}" inventoryItems: [${newMerchantInventoryData.map(i => `{ itemType: "${i._id}", quantity: ${i.quantity} },`)}]}) 
      { merchant { _id email } inventoryItems {itemType {_id name } quantity } } 
    }`

    const response = await wrappedExtendedMutateAuth(mutation)
    if (response) {
      setNotification('merchants.updated-inventory')
      history.goBack()
    }
  }

  const handleItemTypeChange = (index: number, value: string) => {
    let toSet: '' | number = ''
    if (value !== '' && !isNaN(parseInt(value))) {
      toSet = parseInt(value)
    }

    const newMerchantInventory = [...newMerchantInventoryData]
    newMerchantInventory[index].quantity = toSet

    setNewMerchantInventoryData(newMerchantInventory)
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <form onSubmit={onSubmit} className="object-form">
      <BackButton />
      <h2 className="edit-header">{t('merchants.edit-inventory')}</h2>
      <ErrorElement errors={errors} />
      <h4 className="business-label">{businessName}</h4>
      <ItemTypeRowTableHeader />
      {newMerchantInventoryData.map((inventoryItem, index) =>
        ItemTypeRow({
          data: {
            index,
            label1: inventoryItem.name,
            label2: undefined,
            value: inventoryItem.quantity,
            callback: handleItemTypeChange,
          },
        }),
      )}
      <LoadingSubmitButton data={{ loading: submitting, text: t('save') }} />
    </form>
  )
}
export { EditMerchantInventory as default }

export type { UpdateMerchantsInventory }
