import { ReactComponent as BoIcon } from '../icons/bo-icon.svg'
import BorrowImage from '../images/borrow.png'
import ReturnImage from '../images/return.png'
import BorrowContainerImage from '../images/borrow-container.png'
import ScanContainerImage from '../images/scan-container.png'
import CashbackContainerImage from '../images/cashback-container.png'

const step0 = (
  <div className="welcome-content welcome-content-main">
    <BoIcon />
    <h1>Welcome</h1>
    <label>Borrow reusable containers for your takeout directly from our restaurant partners.</label>
  </div>
)

const step0fr = (
  <div className="welcome-content welcome-content-main">
    <BoIcon />
    <h1>Bienvenue</h1>
    <label>Empruntez des contenants réutilisables pour votre commande à emporter directement auprès de nos restaurants participants/partenaires restaurateurs</label>
  </div>
)

const step1 = (
  <div className="welcome-content">
    <h2>How to Bo?</h2>
    <div className="first-step-container">
      <div>
        <div className="first-step-container-element">
          <div>
            <div>1</div>
            <label>Borrow</label>
          </div>
          <img src={BorrowImage} alt="Returning a container" />
        </div>
        <div className="first-step-container-element">
          <div>
            <div>2</div>
            <label>Find a bin</label>
          </div>
          <img src={ReturnImage} alt="Returning a container" />
        </div>
      </div>
      <div>
        <div className="first-step-container-element">
          <div>
            <div>3</div>
            <label>Scan</label>
          </div>
          <img src={ScanContainerImage} alt="Returning a container" />
        </div>
        <div className="first-step-container-element">
          <div>
            <div>4</div>
            <label>Cashback</label>
          </div>
          <img src={CashbackContainerImage} alt="Returning a container" />
        </div>
      </div>
    </div>
  </div>
)

const step1fr = (
  <div className="welcome-content">
    <h2>Comment ça marche avec Bo?</h2>
    <div className="first-step-container">
      <div>
        <div className="first-step-container-element">
          <div>
            <div>1</div>
            <label>Emprunter</label>
          </div>
          <img src={BorrowImage} alt="Returning a container" />
        </div>
        <div className="first-step-container-element">
          <div>
            <div>2</div>
            <label>Chercher un point</label>
          </div>
          <img src={ReturnImage} alt="Returning a container" />
        </div>
      </div>
      <div>
        <div className="first-step-container-element">
          <div>
            <div>3</div>
            <label>Scanner</label>
          </div>
          <img src={ScanContainerImage} alt="Returning a container" />
        </div>
        <div className="first-step-container-element">
          <div>
            <div>4</div>
            {/* TODO TRANSLATION*/}
            <label>Cashback</label>
          </div>
          <img src={CashbackContainerImage} alt="Returning a container" />
        </div>
      </div>
    </div>
  </div>
)

const step2 = (
  <div className="welcome-content">
    <img src={BorrowContainerImage} alt="Returning a container" />
    <div className="welcome-content-text">
      <h1>Borrow</h1>
      <div>
        <span>
          <strong>Find participating restaurants</strong> on the Bo app and ask to have your takeout packed in a reusable container.
        </span>
        <span className="text-note">Some restaurants will charge a small refundable deposit per container & others will allow you to borrow for free if you sign up for a Bo account.</span>
      </div>
    </div>
  </div>
)

const step2fr = (
  <div className="welcome-content">
    <img src={BorrowContainerImage} alt="Returning a container" />
    <div className="welcome-content-text">
      <h1>Emprunter</h1>
      <div>
        <span>
          <strong>Trouvez des restaurants participants</strong> sur l’application Bo et demandez à ce que votre commande à emporter soit préparée dans un contenant Bo.
        </span>
        <span className="text-note">Certains restaurants factureront/chargeront un petit dépôt/montant remboursable par contenant, et d’autres vous laisseront emprunter gratuitement si vous créez un compte Bo.</span>
      </div>
    </div>
  </div>
)

const step3 = (
  <div className="welcome-content">
    <img src={ReturnImage} alt="Returning a container" />
    <div className="welcome-content-text">
      <h1>Find a return bin</h1>
      <div>
        <span>
          Once you are done using the container, <strong>locate a Bo return bin</strong> at a drop-off site using our app.
        </span>
      </div>
    </div>
  </div>
)

const step3fr = (
  <div className="welcome-content">
    <img src={ReturnImage} alt="Returning a container" />
    <div className="welcome-content-text">
      <h1>Chercher un point de chute</h1>
      <div>
        <span>
          Une fois que vous avez terminé de savourer votre plat, <strong>trouvez un point de chute Bo</strong> dans l'appli!
        </span>
      </div>
    </div>
  </div>
)

const step4 = (
  <div className="welcome-content">
    <img src={ScanContainerImage} alt="Returning a container" />
    <div className="welcome-content-text">
      <h1>Scan & Drop item</h1>
      <div>
        <span>When you get to the location:</span>
        <ol>
          <li>Scan the QR code on the self-service bin.</li>
          <li>Scan the QR code on each container then drop them in the bin.</li>
          <li>Confirm your email address where we will refund your deposit.</li>
        </ol>
      </div>
    </div>
  </div>
)

const step4fr = (
  <div className="welcome-content">
    <img src={ScanContainerImage} alt="Returning a container" />
    <div className="welcome-content-text">
      <h1>Scanner et retourner</h1>
      <div>
        <span>Une fois que vous trouvez un point de chute, il suffit de:</span>
        <ol>
          <li>Scannez le code QR sur notre bac en libre-service.</li>
          <li>Scannez le code QR sur chaque contenant et déposez-les dans le bac.</li>
          <li>Confirmez votre adresse courriel où nous vous rembourserons votre dépôt.</li>
        </ol>
      </div>
    </div>
  </div>
)

const step5 = (
  <div className="welcome-content">
    <img src={CashbackContainerImage} alt="Returning a container" />
    <div className="welcome-content-text">
      <h1>That’s it…</h1>
      <div>
        <span>Our team at LavaBo will scan each container to confirm the return.</span>
        <span>Your balance will be available for cash out within two weeks.</span>
      </div>
    </div>
  </div>
)

const step5fr = (
  <div className="welcome-content">
    <img src={CashbackContainerImage} alt="Returning a container" />
    <div className="welcome-content-text">
      <h1>Et c’est tout…</h1>
      <div>
        <span>Notre équipe chez Lavabo scannera chaque contenant pour confirmer le retour.</span>
        <span>Votre solde sera disponible pour un retrait à l’intérieur de deux semaines.</span>
      </div>
    </div>
  </div>
)

const TutorialEng = [step0, step1, step2, step3, step4, step5]

const TutorialFr = [step0fr, step1fr, step2fr, step3fr, step4fr, step5fr]

export { TutorialEng, TutorialFr }
