import { Link, useLocation } from 'react-router-dom'

import '../admin/ActionBar.css'

import { ReactComponent as ItemsIcon } from '../../icons/items.svg'
import { ReactComponent as MapIcon } from '../../icons/map.svg'
import { ReactComponent as MerchantsIcon } from '../../icons/merchants.svg'
import { ReactComponent as TreeIcon } from '../../icons/tree.svg'
import { useTranslation } from 'react-i18next'

const ActionBar = () => {
  const { t } = useTranslation()

  const location = useLocation()
  const activeTab = location.pathname.split('/')[2].toLowerCase()

  return (
    <section className="BottomNavbar">
      <Link className={activeTab === 'items' ? 'active' : ''} to="/customer/items" replace>
        <ItemsIcon />
        {t('inventory')}
      </Link>
      <Link className={activeTab === 'map' ? 'active' : ''} to="/customer/map" replace>
        <MapIcon />
        {t('locations.locations')}
      </Link>
      <Link className={activeTab === 'merchants' ? 'active' : ''} to="/customer/merchants" replace>
        <MerchantsIcon />
        {t('merchants.merchants')}
      </Link>
      <Link className={activeTab === 'impact' ? 'active' : ''} to="/customer/impact" replace>
        <TreeIcon />
        {t('impact')}
      </Link>
    </section>
  )
}

export { ActionBar as default }
