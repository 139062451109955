import './Card.css'
import { ReactComponent as ExternalLinkIcon } from '../icons/external-link.svg'

const ratio = 21 / 9
const width = 360

type CardWithPictureData = {
  _id?: string
  labelMain: string
  labelSub1?: string
  labelSub2?: string
  imageUrl?: string
  externalLink?: string
}

const CardWithPicture = ({ data }: { data: CardWithPictureData }) => {
  const onClick = () => {
    window.open(data.externalLink, '_blank')
  }
  return (
    <div className="card padding-0">
      {data.imageUrl && <img alt={data.labelMain} src={data.imageUrl} className="banner" width={`${width}px`} height={`${width / ratio}px`} loading="lazy" />}
      <div className="card-insides">
        <h3 className="name">{data.labelMain}</h3>
        {data.externalLink && <ExternalLinkIcon className="bo-external-link" onClick={onClick} width={20} />}
        {/* {(data.labelSub1 || data.labelSub2) && <hr />}
        {data.labelSub1 && <label className="dimensions">{data.labelSub1}</label>}
        {data.labelSub2 && <label className="capacity multi-line">{data.labelSub2}</label>} */}
      </div>
    </div>
  )
}

export { CardWithPicture as default }
