import { FormEvent, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ItemTypeCardData } from '../../../components/admin/ItemTypeCard'
import BackButton from '../../../components/BackButton'
import statusOptions, { SelectOptions } from '../MainStatuses'
import ErrorElement from '../../../components/ErrorElement'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import SelectMenu from '../../../components/SelectMenu'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import { setNotification } from '../../../components/Notification'
import SplashScreen from '../../../components/SplashScreen'
import { FilePathEnum, uploadImage } from '../../../api/upload-image'

const EditItemType = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  const history = useHistory()

  let { submitting, setSubmitting, errors, setErrors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const { id }: any = useParams()

  const [itemType, setItemType] = useState<ItemTypeCardData>()
  const [name, setName] = useState('')
  const [capacity, setCapacity] = useState(0)
  const [w, setWidth] = useState(0)
  const [h, setHeight] = useState(0)
  const [l, setLength] = useState(0)
  const [iconUrl, setIconUrl] = useState<string | undefined>('')

  const [description, setDescription] = useState('')

  const [status, setStatus] = useState('')

  const [wholesalePrice, setWholesalePrice] = useState(0)
  const [retailPrice, setRetailPrice] = useState(0)
  const [restockPrice, setRestockPrice] = useState(0)
  const [restockSpecialPrice, setRestockSpecialPrice] = useState(0)
  const [onboarding, setOnboarding] = useState(0)
  const [rentalPrice, setRentalPrice] = useState(0)
  const [depositPrice, setDepositPrice] = useState(0)

  const [selectValue, setSelectValue] = useState<SelectOptions>()
  const [newStatus, setNewStatus] = useState('')

  const [selectedFile, setSelectedFile] = useState()

  useEffect(() => {
    async function onLoad() {
      const query = `query { allItemTypes (filter: {_id: "${id}"}) { _id name description status capacity iconUrl pricing { wholesale retail restock restockSpecial onboarding rental deposit } dimensions { w h l } } }`
      const factory = (result: any): ItemTypeCardData[] => {
        return result.allItemTypes && Array.isArray(result.allItemTypes) && result.allItemTypes.length > 0 ? result.allItemTypes[0] : null
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) setItemType(response)
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onItemTypeLoad() {
      if (!itemType) {
        return
      }
      if (itemType.status) {
        setNewStatus(itemType.status)
        setStatus(itemType.status)
      }
      setSelectValue(statusOptions.find(s => s.value === itemType.status))
      setName(itemType.name)
      setDescription(itemType.description)
      setIconUrl(itemType.iconUrl)
      setCapacity(itemType.capacity)
      if (itemType.dimensions) {
        setWidth(itemType.dimensions.w)
        setHeight(itemType.dimensions.h)
        setLength(itemType.dimensions.l)
      }
      //TODO Update this when API support is done
      setWholesalePrice(itemType.pricing.wholesale / 100)
      setRetailPrice(itemType.pricing.retail / 100)
      setRestockPrice(itemType.pricing.restock / 100)
      if (itemType.pricing.restockSpecial) setRestockSpecialPrice(itemType.pricing.restockSpecial / 100)
      if (itemType.pricing.onboarding) setOnboarding(itemType.pricing.onboarding / 100)
      if (itemType.pricing.rental) setRentalPrice(itemType.pricing.rental / 100)
      if (itemType.pricing.deposit) setDepositPrice(itemType.pricing.deposit / 100)
    }
    onItemTypeLoad()
  }, [itemType])

  const handleStatusChange = (value: any, action: any) => {
    if (action.action === 'select-option') {
      setNewStatus(value.value)
      setSelectValue(value)
    }
  }

  useEffect(() => {
    async function onSelectChange() {
      setStatus(newStatus)
    }
    onSelectChange()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue])

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    let imageURL: string | undefined = ''

    if (selectedFile) {
      imageURL = await uploadImage(selectedFile, name, FilePathEnum.ITEM_TYPE_IMAGES)
      if (!imageURL) {
        setSubmitting(false)
        setErrors([{ msg: 'Error while uploading image' }])
        return
      }
    } else imageURL = iconUrl
    const mutation = `mutation { updateItemType ( input: { _id: "${id}" name: "${name}" capacity: ${capacity} status: "${status}" description: "${description}" iconUrl: "${imageURL}"
    pricing: { wholesale: ${parseFloat((wholesalePrice * 100).toFixed(2))} retail: ${parseFloat((retailPrice * 100).toFixed(2))} restock: ${parseFloat((restockPrice * 100).toFixed(2))}
    restockSpecial: ${parseFloat((restockSpecialPrice * 100).toFixed(2))} onboarding: ${parseFloat((onboarding * 100).toFixed(2))} rental: ${parseFloat((rentalPrice * 100).toFixed(2))} deposit: ${parseFloat(
      (depositPrice * 100).toFixed(2),
    )}} 
    dimensions: { w: ${w}, h: ${h}, l: ${l} } } ) { _id name capacity dimensions {w,l,h} } }`

    const response = await wrappedExtendedMutateAuth(mutation)
    if (response) {
      setNotification('items.edited')
      history.goBack()
    }
  }

  const onImageChange = (event: any) => {
    setSelectedFile(event.target.files[0])
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <form onSubmit={onSubmit} className="object-form">
      <BackButton />
      <h2>{t('item-types.edit')}</h2>
      <ErrorElement errors={errors} />
      <label>{t('status.status')}</label>
      {statusOptions && (
        <SelectMenu
          data={{
            options: statusOptions.map(function (s) {
              return {
                value: s.value,
                label: t(`status.${s.value}`),
              }
            }),
            handleChange: handleStatusChange,
            value: { label: t(selectValue?.label!), value: selectValue?.value },
          }}
        />
      )}
      <label>{t('name')}</label>
      <input value={name} onChange={e => setName(e.target.value)} required={true}></input>
      <label>{t('description')}</label>
      <input value={description} onChange={e => setDescription(e.target.value)} required={true}></input>
      <label>
        {t('capacity')} ({t('oz')})
      </label>
      <input type="number" value={capacity} onChange={e => setCapacity(parseInt(e.target.value))} required={true}></input>
      <label>
        {t('dimensions')} ({t('dimensions-break')} {t('inches')})
      </label>
      <div className="dimensions-input">
        <input type="number" value={w} onChange={e => setWidth(parseFloat(e.target.value))} required={true}></input>
        <label>x</label>
        <input type="number" value={h} onChange={e => setHeight(parseFloat(e.target.value))} required={true}></input>
        <label>x</label>
        <input type="number" value={l} onChange={e => setLength(parseFloat(e.target.value))} required={true}></input>
      </div>
      <hr />
      <label>{t('wholesale-price')}</label>
      <input type="number" value={wholesalePrice} onChange={e => setWholesalePrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('retail-price')}</label>
      <input type="number" value={retailPrice} onChange={e => setRetailPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('restock-price')}</label>
      <input type="number" value={restockPrice} onChange={e => setRestockPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('restock-special')}</label>
      <input type="number" value={restockSpecialPrice} onChange={e => setRestockSpecialPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('onboarding')}</label>
      <input type="number" value={onboarding} onChange={e => setOnboarding(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('rental')}</label>
      <input type="number" value={rentalPrice} onChange={e => setRentalPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('deposit')}</label>
      <input type="number" value={depositPrice} onChange={e => setDepositPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      {iconUrl && (
        <>
          <label>{t('image.current-image')}</label>
          <br />
          <img className="profile-image" src={iconUrl} alt="item type icon" />
        </>
      )}
      <hr />
      <input type="file" name="file" accept="image/png, image/jpeg" onChange={onImageChange} />
      <hr />
      <LoadingSubmitButton data={{ loading: submitting, text: t('save') }} />
    </form>
  )
}

export { EditItemType as default }
