const CookiePolicyEng = ({ className, showHeading = true }: { className?: string; showHeading?: boolean }) => {
  return (
    <div className={`${className} privacy-policy`}>
      {showHeading && <h1>Cookie Policy</h1>}
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum a libero nisl. Nullam convallis arcu id est euismod, eu cursus lacus iaculis. Morbi elit magna, mollis vitae tellus vel, aliquam vulputate quam. Maecenas mollis
        ullamcorper eros, sed gravida ex condimentum eu. Curabitur ut eros eu purus vestibulum aliquet a quis nisl. Praesent lectus ex, egestas id efficitur a, sodales ac velit. Phasellus volutpat purus suscipit dolor imperdiet, vitae
        venenatis nunc tristique. Aliquam sagittis id purus quis viverra.
      </p>
    </div>
  )
}

export { CookiePolicyEng as default }
