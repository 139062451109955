const adminPrefix = '/admin'
const merchantPrefix = '/merchant'
const customerPrefix = '/customer'

export const AdminPaths = {
  Home: `${adminPrefix}/`,
  Users: `${adminPrefix}/users`,
  Customers: `${adminPrefix}/customers`,
  Customer: `${adminPrefix}/customers/:id`,
  Merchants: `${adminPrefix}/merchants`,
  MerchantInventory: `${adminPrefix}/merchants/:id/inventory`,
  MerchantQuantities: `${adminPrefix}/merchants/:id/quantities`,
  MerchantEdit: `${adminPrefix}/merchants/:id/edit`,
  Merchant: `${adminPrefix}/merchants/:id`,
  Locations: `${adminPrefix}/locations`,
  AddLocation: `${adminPrefix}/locations/add`,
  EditLocation: `${adminPrefix}/locations/edit/:id`,
  Location: `${adminPrefix}/locations/:id`,
  ItemTypes: `${adminPrefix}/itemTypes`,
  AddItemType: `${adminPrefix}/itemTypes/add`,
  EditItemType: `${adminPrefix}/itemTypes/edit/:id`,
  Orders: `${adminPrefix}/orders`,
  AddOrder: `${adminPrefix}/orders/add`,
  EditOrder: `${adminPrefix}/orders/edit/:id`,
  Order: `${adminPrefix}/orders/:id`,
  Batches: `${adminPrefix}/batches`,
  ValidateBatch: `${adminPrefix}/batches/validate/:id`,
  Employees: `${adminPrefix}/employees`,
  AddEmployee: `${adminPrefix}/employees/add`,
  CheckItem: `${adminPrefix}/check`,
  Deposit: `${adminPrefix}/deposits/:id`,
}
export const MerchantPaths = {
  Home: `${merchantPrefix}/`,
  Register: `${merchantPrefix}/register`,
  RegisterProfile: `${merchantPrefix}/register/profile`,
  RegisterCC: `${merchantPrefix}/register/cc`,
  ApprovalPending: `${merchantPrefix}/register/approve_by_admin`,
  Inventory: `${merchantPrefix}/inventory`,
  Assign: `${merchantPrefix}/assign`,
  AssignItems: `${merchantPrefix}/assign/items`,
  Orders: `${merchantPrefix}/orders`,
  Order: `${merchantPrefix}/orders/:id`,
  CheckItem: `${merchantPrefix}/check`,
  MyImpact: `${merchantPrefix}/impact`,
  ReturnToInventory: `${merchantPrefix}/return`,
}
export const CustomerPaths = {
  Home: `${customerPrefix}/`,
  Register: `${customerPrefix}/register`,
  RegisterProfile: `${customerPrefix}/register/profile`,
  RegisterCC: `${customerPrefix}/register/cc`,
  Items: `${customerPrefix}/items`,
  ReturnItemLocation: `${customerPrefix}/return-item`,
  ReturnItem: `${customerPrefix}/return-item/items`,
  ReturnMap: `${customerPrefix}/map`,
  Merchants: `${customerPrefix}/merchants`,
  CheckItem: `${customerPrefix}/check`,
  MyImpact: `${customerPrefix}/impact`,
  Borrow: `${customerPrefix}/borrow`,
  BorrowSuccess: `${customerPrefix}/borrow/success`,
}
