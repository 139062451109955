import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../api/api'
import ActionBar from '../../components/customer/ActionBar'
import ErrorElement from '../../components/ErrorElement'
import Navbar from '../../components/Navbar'

import '../CategoryHome.css'
import AccountSuspendedNotice from '../../components/customer/AccountSuspendedNotice'
import CardWithPicture from '../../components/CardWithPicture'
import SplashScreen from '../../components/SplashScreen'

type Merchant = {
  _id: string
  businessName: string
  streetAddress: string
  restOfAddress: string
  profileImageURL?: string
  merchantWebsiteURL?: string
}

const Merchants = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  const [merchants, setMerchants] = useState<Merchant[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState<Merchant[]>([])

  useEffect(() => {
    async function onLoad() {
      const query = `query { merchants (filter: {isApproved: true displayed: true}) { _id businessName profileImageURL merchantWebsiteURL businessAddress {address city state postalCode country} } }`
      const factory = (result: any): Merchant[] => {
        return result.merchants.map((m: any) => ({
          _id: m._id,
          businessName: m.businessName,
          streetAddress: `${m.businessAddress.address}`,
          restOfAddress: `${m.businessAddress.city} ${m.businessAddress.state} ${m.businessAddress.postalCode} ${m.businessAddress.country}`,
          profileImageURL: m.profileImageURL,
          merchantWebsiteURL: m.merchantWebsiteURL,
        }))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setMerchants(response)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const results = merchants.filter(function (merchant) {
      merchant && merchant.businessName.toLowerCase().includes(searchTerm)
      if (merchant && merchant.businessName.toLowerCase().includes(searchTerm)) {
        return true
      } else return false
    })
    setSearchResults(results)
  }, [searchTerm, merchants])

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value.toLowerCase())
  }

  return (
    <>
      <Navbar></Navbar>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <section className="HomeSection WithNavbar">
          <header>
            <h1>{t('merchants.merchants')}</h1>
          </header>
          <AccountSuspendedNotice />
          <section className="Searchbar">
            <label className="general-label">{t('search')}</label>
            <input type="text" placeholder="Search" value={searchTerm} onChange={handleChange}></input>
          </section>
          <ErrorElement errors={errors} />
          {searchResults.length === 0 ? (
            <p className="empty-list-msg">{t('empty.merchants')}</p>
          ) : (
            searchResults.map(merchant => (
              <CardWithPicture
                key={merchant._id}
                data={{
                  labelMain: merchant.businessName,
                  labelSub1: merchant.streetAddress,
                  labelSub2: merchant.restOfAddress,
                  imageUrl: merchant.profileImageURL,
                  externalLink: merchant.merchantWebsiteURL,
                }}
              />
            ))
          )}
        </section>
      )}
      <ActionBar></ActionBar>
    </>
  )
}

export { Merchants as default }
