import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAPI } from '../../api/api'
import apiUtils from '../../api/apiUtils'
import ErrorElement from '../../components/ErrorElement'
import { LanguageRow } from '../../components/LanguageSelector'
import { LoadingSubmitButton } from '../../components/LoadingButton'
import Logo from '../../components/Logo'
import { ConfirmPasswordInput, PasswordInput, PwChecklist, ShowPasswordCheck } from '../../components/PasswordInput'
import Terms from '../../components/TermsAndConditions'
import TermsAndConditionsCheck from '../../components/TermsAndConditionsCheck'
import { MerchantPaths } from '../../routes/RoutePaths'

const MerchantRegister = () => {
  const { t } = useTranslation()
  const history = useHistory()

  const { submitting, errors, wrappedExtendedMutate } = useAPI()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidPw, setIsValidPw] = useState(false)
  const [showTerms, setShowTerms] = useState(false)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [showPw, setShowPw] = useState(false)

  const { setAuthToLocalStorage } = apiUtils()

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const mutation = `mutation { registerMerchantEmail(input: { email: "${email}", password: "${password}" }) { token registrationState role email boId name }}`

    const factory = (result: any): any => result.registerMerchantEmail

    const response = await wrappedExtendedMutate(mutation, factory)

    if (response) {
      await setAuthToLocalStorage(response)
      history.push(MerchantPaths.RegisterProfile)
    }
  }

  const onAcceptTerms = () => {
    setAcceptedTerms(!acceptedTerms)
  }

  const onClick = () => {
    setShowTerms(!showTerms)
  }

  const handleShowPwChange = () => {
    setShowPw(!showPw)
  }

  const onReadAndAcceptTerms = () => {
    setAcceptedTerms(true)
  }

  const handlePwChecklist = (isValid: boolean) => {
    setIsValidPw(isValid)
  }

  return (
    <main>
      {!showTerms ? (
        <>
          <Logo></Logo>
          <h1>{t('register')}</h1>
          <h4>{t('welcome.title')}</h4>
          <form onSubmit={onSubmit}>
            <ErrorElement errors={errors} />
            <label>{t('email')}</label>
            <input id="emailField" value={email} type="email" onChange={e => setEmail(e.target.value)} required={true}></input>
            <PasswordInput label={t('password.main')} showPw={showPw} value={password} onChange={e => setPassword(e.target.value)} />
            <PwChecklist value={password} onChange={handlePwChecklist} valueAgain={confirmPassword} />
            <ConfirmPasswordInput label={t('password.confirm')} showPw={showPw} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
            <ShowPasswordCheck checked={showPw} onChange={handleShowPwChange} />
            <TermsAndConditionsCheck data={{ onClick, onAcceptTerms, acceptedTerms }} />
            <LoadingSubmitButton data={{ disabled: !acceptedTerms || !isValidPw, loading: submitting, text: t('continue'), className: 'continueBtn' }} />
            <LanguageRow />
          </form>
        </>
      ) : (
        <Terms onClick={onClick} onReadAndAcceptTerms={onReadAndAcceptTerms} />
      )}
    </main>
  )
}

export { MerchantRegister as default }
