import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import ActionBar from '../../../components/admin/ActionBar'
import Card from '../../../components/Card'
import ErrorElement from '../../../components/ErrorElement'
import { FailIcon, SuccessIcon } from '../../../components/Icons'
import Navbar from '../../../components/Navbar'
import Searchbar from '../../../components/Searchbar'

import '../../CategoryHome.css'

type Merchant = {
  _id: string
  businessName: string
  isApproved: boolean
  quantity: number
}

const Merchants = () => {
  const { t } = useTranslation()

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  const [merchants, setMerchants] = useState<Merchant[]>([])

  useEffect(() => {
    async function onLoad() {
      const query = `query { merchants { _id businessName isApproved } merchantInventories { merchant { _id }, inventoryItems { quantity } } }`
      const factory = (result: any): Merchant[] => {
        const inventoryItems = result.merchantInventories.map((i: any) => ({
          merchant: i.merchant,
          quantity: i.inventoryItems.map((i: any) => i.quantity || 0).reduce((total: number, num: number) => total + num, 0),
        }))

        return result.merchants.map((m: any) => ({
          _id: m._id,
          businessName: m.businessName,
          isApproved: m.isApproved,
          quantity: inventoryItems.find((i: any) => i.merchant._id === m._id)?.quantity || 0,
        }))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setMerchants(response)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar></Navbar>
      <section className="HomeSection WithNavbar">
        <header>
          <h1>{t('merchants.merchants')}</h1>
        </header>
        <Searchbar></Searchbar>
        <hr />
        <ErrorElement errors={errors} />
        {merchants.length === 0 ? (
          <p className="empty-list-msg">{t('empty.merchants')}</p>
        ) : (
          merchants.map(merchant => (
            <Card
              key={merchant._id}
              data={{
                labelMain: merchant.businessName,
                labelTopRight: `${merchant.quantity} items`,
                linkTo: `/admin/merchants/${merchant._id}`,
                labelBotRight: !merchant.isApproved ? t('merchants.not-approved') : t('merchants.approved'),
                iconBotRight: !merchant.isApproved ? <FailIcon /> : <SuccessIcon />,
              }}
            />
          ))
        )}
      </section>
      <ActionBar></ActionBar>
    </>
  )
}

export { Merchants as default }
export type { Merchant }
