import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Logo from '../../components/Logo'

const PageForbidden = () => {
  let history = useHistory()
  const { t } = useTranslation()

  return (
    <main>
      <Logo />
      <h1>403</h1>
      <h2>{t('403-page.forbidden')}</h2>
      <label>{t('403-page.sorry')}</label>
      <hr />
      <Link
        className="yellow-link"
        to="#"
        onClick={e => {
          history.goBack()
        }}
      >
        {t('go-back')}
      </Link>
      <Link className="yellow-link" to="/">
      {t('go-to-homepage')}
      </Link>
    </main>
  )
}

export { PageForbidden as default }
