import './QrScanner.css'

import { ContinuousQrScanner, ScanArea } from './QrScannerImpl'
import { useEffect, useState } from 'react'
import { SelectOptions } from '../containers/admin/MainStatuses'
import SelectMenu from './SelectMenu'
import { useTranslation } from 'react-i18next'

const CAMERA_KEY = 'selectedCamera'

type QrScannerExpandedData = {
  callback: (code: string) => void
  callbackCancel: (e: any) => void
  onContinueBtn?: () => void | undefined
  heading?: string | undefined
  text?: string | undefined
  showContinueBtn?: boolean | true
  continueBtnText?: string | undefined
  cancelBtnText?: string | undefined
  className?: string | undefined
  cancelBtnClassName?: string | undefined
  continueBtnDisabled?: boolean | false
}

export const QrScannerExpanded = (data: QrScannerExpandedData) => {
  const { t } = useTranslation()

  const selectedCamera = localStorage.getItem(CAMERA_KEY) || 'environment'

  const [options, setOptions] = useState<SelectOptions[]>([])
  const [cameraId, setCameraId] = useState<string>(selectedCamera)
  const [, setScanArea] = useState<ScanArea | undefined>()

  const sleep = (ms: any) => new Promise(r => setTimeout(r, ms))

  useEffect(() => {
    async function onLoad() {}
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onQrCode = (qrCode: any) => {
    if (qrCode) data.callback(qrCode)
  }

  const onCameraList = async (cameras: any[]): Promise<string> => {
    const options: SelectOptions[] = cameras.map(camera => ({
      value: camera.id,
      label: camera.label,
    }))

    setOptions(options)
    if (options.length < 2 && options[0].value) {
      setCameraId(options[0].value)
      localStorage.setItem(CAMERA_KEY, options[0].value)
    }

    return cameraId
  }

  const handleOptionChange = async (selectedOption: any, action: any) => {
    if (action.action === 'select-option') {
      setCameraId(selectedOption.value)
      localStorage.setItem(CAMERA_KEY, selectedOption.value)
    }
  }

  const onScanAreaUpdated = async (scanArea: ScanArea) => {
    console.debug('Updating scan area', scanArea)

    if (scanArea.width > 0 && scanArea.height > 0) setScanArea(scanArea)
  }

  return (
    <>
      <div className={`scanner-container ${data.className ? data.className : ''}`}>
        <div className="scanner-heading">
          {options.length > 1 && (
            <div className="device-selector">
              <SelectMenu data={{ options, handleChange: handleOptionChange, value: options.find(option => option.value === cameraId) }} placeholder={t('select-camera')} />
            </div>
          )}
          <h1>{data.heading}</h1>
          <label>{data.text}</label>
        </div>
        <div className="qr-scan-border" />
        <div className="scanner-buttons">
          {data.showContinueBtn && (
            <button className="button-medium main-button" onClick={data.onContinueBtn ? data.onContinueBtn : data.callbackCancel} disabled={data.continueBtnDisabled}>
              {data.continueBtnText ? data.continueBtnText : t('finish-scanning')}
            </button>
          )}
          <button className={`button-medium naked-button ${data.cancelBtnClassName ? data.cancelBtnClassName : ''}`} onClick={data.callbackCancel}>
            {data.cancelBtnText ? data.cancelBtnText : t('cancel')}
          </button>
        </div>
      </div>
      <div className="qr-scanner">
        <ContinuousQrScanner
          onQrCode={async qrCode => {
            onQrCode(qrCode)
            await sleep(1000)
          }}
          cameraId={cameraId}
          onScanAreaUpdated={onScanAreaUpdated}
          onCameraList={onCameraList}
        />
      </div>
    </>
  )
}
