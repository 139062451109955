import { getDistance } from 'geolib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useAPI } from '../../api/api'
import { formatDistance } from '../../api/utils'
import { LocationData } from '../../components/admin/LocationCard'
import Card from '../../components/Card'
import AccountSuspendedNotice from '../../components/customer/AccountSuspendedNotice'
import ActionBar from '../../components/customer/ActionBar'
import ErrorElement from '../../components/ErrorElement'
import { LoadingSubmitButton } from '../../components/LoadingButton'
import Navbar from '../../components/Navbar'
import { QrScanner } from '../../components/QrScanner'
import { CustomerPaths } from '../../routes/RoutePaths'

type LocationCoords = {
  latitude: number
  longitude: number
}

const ReturnItemLocation = () => {
  const { t } = useTranslation()
  let history = useHistory()

  let { errors, setErrors, currentPosition, getCurrentPosition, wrappedExtendedQueryAuth } = useAPI()

  const [showScanner, setShowScanner] = useState(false)
  const [locations, setLocations] = useState<LocationData[]>([])

  useEffect(() => {
    async function onLoad() {
      await getCurrentPosition()
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onLocationSet() {
      const query = `query { dropOffLocations (filter: {status: "active"}) { _id name status capacity quantity address workTime coordinates { latitude longitude } } }`
      const factory = (result: any): LocationData[] => {        
        return result.dropOffLocations
          .map((l: any) => ({
            distance: currentPosition ? getDistance(currentPosition, l.coordinates) : null,
            freeCapacity: l.capacity - l.quantity,
            ...l,
          }))
          .sort((a: any, b: any) => (a.distance > b.distance ? 1 : -1))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setLocations(response)
      }
    }
    onLocationSet()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition])

  const onClick = () => {
    setShowScanner(true)
  }

  const onHowToClick = () => {
    history.push('/customer/how-to-return')
  }

  const onQrCode = async (code: string) => {
    setShowScanner(false)

    if (!code.includes('locations/')) {
      setErrors([{ msg: t('invalid-location-qr-code') }])
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    console.debug('QR', qr)

    const loc = locations.filter((l: any) => l._id === qr)[0]

    if (loc.freeCapacity > 0) {
      history.push(CustomerPaths.ReturnItem, { ...loc })
    } else {
      setErrors([{ msg: t('locations.location-full') }])
    }
  }

  return (
    <>
      {showScanner && (
        <section className="qrScanner">
          <QrScanner callback={onQrCode} callbackCancel={(e: any) => setShowScanner(false)}></QrScanner>
        </section>
      )}
      {!showScanner && (
        <>
          <Navbar></Navbar>
          <section className="HomeSection WithNavbar">
            <header>
              <h1>{t('return')}</h1>
              <Link className="yellow-link" to="#" onClick={onHowToClick} replace>
                {t('how-to-return')}
              </Link>
            </header>
            <AccountSuspendedNotice />
            <ErrorElement errors={errors} />
            <LoadingSubmitButton data={{ className: 'midBtn', text: t('scan-drop-off-qr-code'), onClick: onClick }} />
            <hr />
            <label className="general-label">{t('nearest-drop-off-points')}</label>
            {locations.length === 0 ? (
              <p className="empty-list-msg">{t('empty.locations')}</p>
            ) : (
              locations.map(location => (
                <Card
                  key={location._id}
                  data={{
                    labelMain: location.name,
                    labelTopRight: location.distance && formatDistance(location.distance, t('away')),
                    labelSub1: location.address,
                    labelSub2: location.workTime,
                    labelBotRight: location.freeCapacity && location.freeCapacity > 0 ? `${t('free-capacity', { freeCapacity: location.freeCapacity })}` : t('locations.location-full'),
                  }}
                />
              ))
            )}
          </section>
          <ActionBar></ActionBar>
        </>
      )}
    </>
  )
}

export { ReturnItemLocation as default }
export type { LocationCoords }
