import { useState } from 'react'
import BackButton from '../../components/BackButton'
import { QrScanner } from '../../components/QrScanner'
import '../CategoryHome.css'
import '../merchant/CheckItem.css'
import ErrorElement from '../../components/ErrorElement'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../api/api'
import Logo from '../../components/Logo'
import { ReactComponent as QRIcon } from '../../icons/batches.svg'
import { useHistory } from 'react-router'

type Data = {
  _id: string
  uuid: string
  itemTypeName: string
  depositPrice: number
  client: any
  merchant: any
  status?: string
}

const CheckItem = () => {
  const { t } = useTranslation()

  let history = useHistory()

  let { errors, setErrors, wrappedExtendedQueryAuth } = useAPI()

  const [msg, setMsg] = useState('')
  const [uuid, setUuid] = useState('')
  const [itemType, setItemType] = useState('')
  const [isFirstScan, setIsFirstScan] = useState(true)

  const auth = localStorage.getItem('auth')
  let customerBoId: string

  if (auth) {
    customerBoId = JSON.parse(auth).boId
  }

  const [showScanner, setShowScanner] = useState(true)

  const onQrCode = async (code: string) => {
    setShowScanner(false)
    setIsFirstScan(false)

    if (!code.includes('items/')) {
      setErrors([{ msg: t('invalid-item-qr-code') }])
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    const query = `query { itemsById (filter : {uuid : "${qr}"}) { _id uuid status itemType { name depositPrice } client { boId } merchant {name}}}`
    const factory = (result: any): Data => {
      return Array.isArray(result.itemsById) && result.itemsById.length > 0
        ? result.itemsById.map((o: any) => ({ _id: o._id, itemTypeName: o.itemType.name, depositPrice: o.itemType.depositPrice / 100, uuid: o.uuid, status: o.status, client: o.client, merchant: o.merchant }))[0]
        : null
    }

    const response = await wrappedExtendedQueryAuth(query, factory)

    if (!response) {
      setMsg(t('item-does-not-exist'))
      return
    }

    switch (response.status) {
      case 'unassigned':
      case 'returned':
        setMsg(t('customer-check-status.unassigned'))
        break
      case 'assigned':
        if (customerBoId === response.client.boId) setMsg(t('customer-check-status.assigned'))
        else setMsg(t('customer-check-status.assigned-someone-else'))
        break
      case 'pending-purchase':
      case 'purchased':
        setMsg(t('customer-check-status.purchased'))
        break
      case 'written-off':
        setMsg(t('customer-check-status.written-off'))
        break
      case 'lost':
        setMsg(t('customer-check-status.lost'))
        break
      case 'on-consignment':
        if (response.merchant && response.merchant.name) {
          setMsg(t('customer-check-status.on-consignment', { name: response.merchant.name, price: response.depositPrice }))
        }
        break

      //TODO MESSAGE
      case 'deposit':
      case 'deposit-returned':
        setMsg(t(''))
        break
    }
    setItemType(response.itemTypeName)
    setUuid(response.uuid)
  }

  const onCancel = (e: any) => {
    if (isFirstScan) history.goBack()
    else setShowScanner(false)
  }

  const handleScanAnother = (e: any) => {
    e.preventDefault()
    setShowScanner(true)
  }

  return (
    <>
      {showScanner && (
        <section className="qrScanner">
          <QrScanner callback={onQrCode} callbackCancel={onCancel}></QrScanner>
        </section>
      )}
      {!showScanner && (
        <main className="justify-normal">
          <BackButton />
          <Logo></Logo>
          <h1>{t('item-status')}</h1>
          <div>
            <ErrorElement errors={errors} />
            {msg && (
              <>
                <h3>{itemType}</h3>
                <h3>{uuid}</h3>
                <hr />
                <p className="not-exist">{msg}</p>
              </>
            )}
          </div>
          <div className="edit another">
            <label className="scan-another" onClick={handleScanAnother}>
              <QRIcon fill="black" />
              {t('scan-another')}
            </label>
          </div>
        </main>
      )}
    </>
  )
}

export { CheckItem as default }
