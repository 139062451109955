import { ReactComponent as LogoIcon } from '../icons/bo-icon.svg'

const Logo = () => {
  return <LogoIcon className="bo-logo" />
}

const SmallLogo = ({ className }: { className?: string }) => {
  return <LogoIcon className={`bo-logo small ${className}`} />
}

export { Logo as default, SmallLogo }
