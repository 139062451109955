import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { LoadingSubmitButton } from '../LoadingButton'
import PhoneNumberInput from '../PhoneNumberInput'
import CountrySelector from '../CountrySelector'

type CustomerProfile = {
  _id?: string
  firstName: string
  lastName: string
  phoneNumber: string
  address: string
  city: string
  state: string
  postalCode: string
  country: string
}

const SetCustomerProfile = ({ profile, onSubmit, submitting }: any) => {
  const { t } = useTranslation()

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [address, setAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setAddressState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState('')

  useEffect(() => {
    async function onLoad() {
      if (profile) {
        setFirstName(profile.firstName)
        setLastName(profile.lastName)
        setPhoneNumber(profile.phoneNumber)
        setAddress(profile.address.address)
        setCity(profile.address.city)
        setAddressState(profile.address.state)
        setPostalCode(profile.address.postalCode)
        setCountry(profile.address.country)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitRaw = (e: any) => {
    e.preventDefault()
    onSubmit({
      firstName,
      lastName,
      phoneNumber,
      address,
      city,
      state,
      postalCode,
      country,
    })
  }

  const onCountryChange = (value: any) => {
    setCountry(value)
  }

  return (
    <form onSubmit={onSubmitRaw} className="profile">
      <label>{t('first-name')}</label>
      <input value={firstName} onChange={e => setFirstName(e.target.value)} required={true}></input>
      <label>{t('last-name')}</label>
      <input value={lastName} onChange={e => setLastName(e.target.value)} required={true}></input>
      <label>{t('phone-number')}</label>
      <PhoneNumberInput value={phoneNumber} onChange={setPhoneNumber} />
      <div className="full-column">
        <label>{t('address')}</label>
        <input value={address} onChange={e => setAddress(e.target.value)} required={true}></input>
      </div>
      <div className="half-column">
        <label>{t('city')}</label>
        <input value={city} onChange={e => setCity(e.target.value)} required={true}></input>
      </div>
      <div className="half-column">
        <label>{t('state')}</label>
        <input value={state} onChange={e => setAddressState(e.target.value)} required={true}></input>
      </div>
      <div className="half-column">
        <label>{t('postal-code')}</label>
        <input value={postalCode} onChange={e => setPostalCode(e.target.value)} required={true}></input>
      </div>
      <div className="half-column">
        <label>{t('country')}</label>
        <CountrySelector onMainChange={onCountryChange} className="mb62" />
      </div>
      <LoadingSubmitButton data={{ loading: submitting, text: t('save') }} />
    </form>
  )
}

export { SetCustomerProfile as default }
export type { CustomerProfile }
