import { Route, Switch } from 'react-router-dom'

import ForgotPassword from '../containers/ForgotPassword'
import PasswordReset from '../containers/PasswordReset'

import AdminRoutes from './RoutesAdmin'
import MerchantRoutes from './RoutesMerchant'
import CustomerRoutes from './RoutesCustomer'
import PrivateRoute from '../components/PrivateRoute'
import EditUser from '../containers/EditUser'
import PageNotFound from '../containers/common/PageNotFound'
import PageForbidden from '../containers/common/PageForbidden'
import ReroutesHome from './ReroutesHome'
import ReroutesLogin from './ReroutesLogin'
import ReroutesRegister from './ReroutesRegister'
import CCFor from '../containers/customer/CCFor'
import InfographicsPage from '../containers/common/InfographicsPage'
import { useTranslation } from 'react-i18next'
import ScannedItem from '../containers/common/ScannedItem'
import ScannedLocation from '../containers/common/ScannedLocation'
import ContactPage from '../containers/common/ContactPage'
import PrivacyPolicyPage from '../containers/common/PrivacyPolicyPage'
import TermsPage from '../containers/common/TermsPage'
import Deposit from '../containers/guest/Deposit'
import CompleteDeposit from '../containers/guest/CompleteDeposit'
import FailedDeposit from '../containers/guest/FailedDeposit'
import DepositLocation from '../containers/guest/DepositLocation'
import DepositItems from '../containers/guest/DepositItems'
import DepositSuccess from '../containers/guest/DepositSuccess'
import DepositFailed from '../containers/guest/DepositFailed'
import Bopaqs from '../containers/common/Bopaqs'
import HomeMap from '../containers/common/HomeMap'
import Scan from '../containers/common/Scan'

const Routes = () => {
  const { t } = useTranslation()
  return (
    <Switch>
      <Route exact path="/" component={ReroutesHome} />
      <Route exact path="/login" component={ReroutesLogin} />
      <Route exact path="/register" component={ReroutesRegister} />
      <Route exact path="/bopaqs" component={Bopaqs} />
      <Route exact path="/map" component={HomeMap} />
      <Route exact path="/scan" component={Scan} />
      <Route exact path="/deposit" component={Deposit} />
      <Route exact path="/complete-deposit/:token" component={CompleteDeposit} />
      <Route exact path="/failed-deposit" component={FailedDeposit} />
      <Route exact path="/deposit/scan-location" component={DepositLocation} />
      <Route exact path="/deposit/scan-items" component={DepositItems} />
      <Route exact path="/deposit/success" component={DepositSuccess} />
      <Route exact path="/deposit/fail" component={DepositFailed} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/password-reset/:token" component={PasswordReset} />
      <PrivateRoute exact path="/edit/profile" component={EditUser} />
      <Route exact path="/customer/how-to-bo" component={() => <InfographicsPage src={t('infographics.how-to-customer')} />} />
      <Route exact path="/merchant/how-to-bo" component={() => <InfographicsPage src={t('infographics.how-to-merchant')} />} />
      <Route exact path="/customer/how-to-return" component={() => <InfographicsPage src={t('infographics.how-to-return')} />} />
      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/faq" component={() => <InfographicsPage src={t('infographics.faq')} />} />
      <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
      <Route exact path="/terms-and-conditions" component={TermsPage} />
      {AdminRoutes}
      {MerchantRoutes}
      {CustomerRoutes}
      <PrivateRoute exact path="/customer/cc-for" component={CCFor} />
      <Route exact path="/items/:id" component={ScannedItem} />
      <Route exact path="/locations/:id" component={ScannedLocation} />
      <Route exact path="/forbidden" component={PageForbidden} />
      <Route component={PageNotFound} />
    </Switch>
  )
}

export { Routes as default }
