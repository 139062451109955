import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../api/api'
import BackButton from '../components/BackButton'
import ErrorElement from '../components/ErrorElement'
import { LoadingSubmitButton } from '../components/LoadingButton'

const ForgotPassword = () => {
  const { t } = useTranslation()

  const { submitting, errors, wrappedExtendedMutate } = useAPI()

  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const mutation = `mutation { generatePasswordReset(email: "${email}") }`

    const response = await wrappedExtendedMutate(mutation)
    if (response) {
      setSent(true)
    }
  }

  return (
    <>
      <section className="HomeSection">
        <BackButton />
        <h3>{t('password.reset')}</h3>
        {!sent ? <label>{t('password.reset-link-will-be-sent')}</label> : <label>{t('password.reset-link-has-been-sent')}</label>}
        <hr />
      </section>
      {!sent && (
        <form onSubmit={onSubmit}>
          <ErrorElement errors={errors} />
          <label id="errors" className="errorLabel"></label>
          <label>{t('email')}</label>
          <input type="email" required={true} value={email} onChange={e => setEmail(e.target.value)}></input>
          <LoadingSubmitButton data={{ loading: submitting, text: t('password.reset') }} />
        </form>
      )}
    </>
  )
}

export { ForgotPassword as default }
