import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../api/api'
import apiUtils from '../../api/apiUtils'
import { useHistory } from 'react-router'
import { LoadingSubmitButton } from '../../components/LoadingButton'
import { CustomerPaths } from '../../routes/RoutePaths'
import BackButton from '../../components/BackButton'
import { CustomInput, CustomPasswordInput } from '../../components/common/CustomInput'
import { ReactComponent as MailIcon } from '../../icons/mail.svg'
import { ReactComponent as LockIcon } from '../../icons/lock.svg'
import StepCounter from '../../components/common/StepCounter'
import { toast } from 'react-toastify'

const CustomerRegister = () => {
  const { t } = useTranslation(['translation', 'errors'])
  const history = useHistory()

  const { submitting, errors, wrappedExtendedMutate } = useAPI()
  const [ended, setEnded] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isValidPw, setIsValidPw] = useState(false)

  const { setAuthToLocalStorage } = apiUtils()

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const mutation = `mutation { registerClientEmail(input: { email: "${email}", password: "${password}" }) {token registrationState role email boId name }}`

    const factory = (result: any): any => result.registerClientEmail

    const response = await wrappedExtendedMutate(mutation, factory)

    setEnded(true)

    if (response) {
      await setAuthToLocalStorage(response)
      history.push(CustomerPaths.RegisterProfile)
    }
  }

  useEffect(() => {
    const onErrors = () => {
      if (ended) {
        if (errors.length > 0) {
          errors.forEach(error => {
            toast.error(t(error.msg, { ns: 'errors' }))
          })
        }
      }
    }
    return () => {
      onErrors()
      setEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended])

  const handlePwChecklist = (isValid: boolean) => {
    setIsValidPw(isValid)
  }

  const onCancel = async () => {
    history.push('/')
  }

  return (
    <main className="screen-container">
      <div className="screen-header">
        <div className="back-and-langauge-container">
          <BackButton />
          <StepCounter text="1/4" percentage={90} />
        </div>
        <h1>{t('create-account')}</h1>
        <h2>{t('create-account-start')}</h2>
      </div>
      <form className="form-general" onSubmit={onSubmit}>
        <div className="main-form-section">
          <div className={`form-input-section ${errors.length > 0 ? 'form-invalid' : ''}`}>
            <CustomInput autoFocus={true} type="email" label={t('email')} placeholder={t('enter-email')} value={email} setValue={setEmail} placeHolderIcon={<MailIcon />} />
            {/* handlePwChecklist={(isValid: boolean) => handlePwChecklist(isValid)} */}
            <CustomPasswordInput label={t('password.main')} placeholder={t('enter-password')} value={password} setValue={setPassword} placeHolderIcon={<LockIcon />} showPwChecklist={true} handlePwChecklist={handlePwChecklist} />
          </div>
        </div>
        <div className="buttons-forward-back">
          <LoadingSubmitButton data={{ disabled: !isValidPw || !!!email, loading: submitting, text: t('next'), className: 'button-medium main-button' }} />
          <button type="button" onClick={onCancel} className="button-medium naked-button">
            {t('cancel')}
          </button>
        </div>
      </form>
    </main>
  )
}

export { CustomerRegister as default }
