import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import BackButton from '../../components/BackButton'
import Logo from '../../components/Logo'

const ContactPage = () => {
  const { t } = useTranslation()
  let history = useHistory()

  const onFaqClick = () => {
    history.push('/faq')
  }

  const onPPClick = () => {
    history.push('/privacy-policy')
  }

  const onTermsClick = () => {
    history.push('/terms-and-conditions')
  }

  return (
    <main className="justify-normal">
      <BackButton />
      <Logo />
      <h1>{t('contact')}</h1>
      <hr />
      <label>{t('contact-page.general-info')}</label>
      <h3>info@bopaq.com</h3>
      <hr />
      <label>{t('contact-page.technical-info')}</label>
      <h3>support@bopaq.com</h3>
      <hr />
      <section className="help-links">
        <Link className="yellow-link" to="#" onClick={onFaqClick} replace>
          {t('faq')}
        </Link>
        <Link className="yellow-link" to="#" onClick={onPPClick} replace>
          {t('privacy-policy')}
        </Link>
        <Link className="yellow-link" to="#" onClick={onTermsClick} replace>
          {t('terms')}
        </Link>
      </section>
    </main>
  )
}

export { ContactPage as default }
