import { useHistory } from 'react-router'
import ApiUtils from '../../api/apiUtils'

const LogoutButton = (data: any) => {
  let history = useHistory()
  let { removeAuthFromLocalStorage } = ApiUtils()

  const handleLogout = async () => {
    await removeAuthFromLocalStorage()
    history.push('/')
  }
  return (
    <button className="logout-button" onClick={handleLogout}>
      {data.text}
    </button>
  )
}

export default LogoutButton
