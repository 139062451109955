import { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import '../../CategoryHome.css'
import '../../../components/orderDocument/OrderDocument.css'
import { SelectOptions } from '../MainStatuses'
import { OrderCardData } from './Orders'
import availableStatuses from '../../../api/OrderStatuses'
import ErrorElement from '../../../components/ErrorElement'
import { formatDate } from '../../../api/utils'
import SelectMenu from '../../../components/SelectMenu'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import { setNotification } from '../../../components/Notification'
import OrderDocumentHeader from '../../../components/orderDocument/OrderDocumentHeader'
import OrderDocumentTableRow from '../../../components/orderDocument/OrderDocumentTableRow'
import OrderDocumentTotals from '../../../components/orderDocument/OrderDocumentTotals'
import { FailIcon, SuccessIcon } from '../../../components/Icons'
import { ReactComponent as EditIcon } from '../../../icons/edit.svg'
import SplashScreen from '../../../components/SplashScreen'

const Order = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  let history = useHistory()

  let { submitting, errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const { id }: any = useParams()

  const auth = localStorage.getItem('auth')
  let role: string = ''

  if (auth) {
    role = JSON.parse(auth).role
  }

  const [order, setOrder] = useState<OrderCardData | undefined>(undefined)
  const [availableStatusOptions, setAvailableStatusOptions] = useState<SelectOptions[]>([])
  const [newStatus, setNewStatus] = useState('')
  const [selectValue, setSelectValue] = useState<SelectOptions>()
  const [totalAmount, setTotalAmount] = useState(0)

  const factory = (result: any): OrderCardData => {
    return Array.isArray(result.orders) && result.orders.length > 0
      ? result.orders.map((o: any) => ({
          _id: o._id,
          orderType: o.orderType,
          merchant: {
            id: o.merchant._id,
            name: o.merchant.businessName,
          },
          status: o.status,
          paid: o.paid,
          createdAt: o.createdAt / 1000,
          totalDiscount: o.totalDiscount / 100,
          orderItems: o.orderItems.map((j: any) => ({
            id: j.itemType._id,
            name: j.itemType.name,
            quantity: j.quantity,
            price: j.price / 100,
          })),
        }))[0]
      : undefined
  }

  useEffect(() => {
    async function onLoad() {
      const query = `query { orders ( filter: { _id: "${id}" } ) { _id createdAt status orderType paid merchant { _id email businessName } totalDiscount orderItems { itemType { _id name } quantity price }} }`

      const response = await wrappedExtendedQueryAuth(query, factory)
      if (response) {
        setOrder(response)

        let amount = 0
        for (const item of response.orderItems) {
          amount += item.quantity * item.price
        }

        setTotalAmount(amount)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onOrderLoad() {
      if (order) {
        const options = availableStatuses(order.status)
        if (options) {
          setAvailableStatusOptions(options)
        }
      }
    }
    onOrderLoad()
  }, [order])

  useEffect(() => {
    async function onOptionsLoad() {
      if (order && availableStatusOptions) {
        const oldOptions = availableStatusOptions.find(o => o.value === order.status)
        setSelectValue(oldOptions)
      }
    }
    onOptionsLoad()
  }, [availableStatusOptions, order])

  const handleOrderStatusChange = (value: any, action: any) => {
    if (action.action === 'select-option') {
      setNewStatus(value.value)
      setSelectValue(value)
    }
  }

  const onClick = async (e: any) => {
    e.preventDefault()

    const mutation = `mutation { updateOrderStatus( input: { _id: "${id}" status: "${newStatus}" } ) { _id } }`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setNotification('orders.status-changed')
      history.goBack()
    }
  }

  const handleEditOrder = async (e: any) => {
    e.preventDefault()

    history.push('/admin/orders/edit/' + id)
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <>
      {order && (
        <>
          <section className="HomeSection Order">
            <BackButton></BackButton>
            <header>
              <h2 className="name">#BO-{order._id.toUpperCase().slice(0, 8)}</h2>
              <div>
                <label>{order.paid ? t('orders.paid') : t('orders.not-paid')}</label>
                {order.paid ? <SuccessIcon /> : <FailIcon />}
              </div>
            </header>
            <ErrorElement errors={errors} />
            {order.status === 'pending' && (
              <label className="edit-link" onClick={handleEditOrder}>
                <EditIcon fill="black" />
                {t('merchants.edit')}
              </label>
            )}
            <hr />
            {availableStatusOptions && (
              <SelectMenu
                data={{
                  options: availableStatusOptions.map(function (s) {
                    return {
                      value: s.value,
                      label: t(`status.${s.value}`),
                    }
                  }),
                  handleChange: handleOrderStatusChange,
                  value: { label: t(selectValue?.label!), value: selectValue?.value },
                  isDisabled: availableStatusOptions.length < 2,
                }}
              />
            )}
            <hr />
            <div className="InfoRow">
              <div>
                <div>{t('roles.merchant')}</div>
                {role !== 'warehouse' ? <Link to={'/admin/merchants/' + order.merchant.id}>{order.merchant.name}</Link> : <label>{order.merchant.name}</label>}
              </div>
              <div>
                <div>{t('date')}</div>
                <label className="dueDate">{formatDate(order.createdAt)}</label>
              </div>
              <div>
                <div>{t('orders.order-type')}</div>
                <label>{t(`orders.${order.orderType}`)}</label>
              </div>
            </div>
            <article className="orderDocument">
              <OrderDocumentHeader />
              {order.orderItems.map((item, index) => {
                if (item.quantity !== 0) {
                  return OrderDocumentTableRow({ data: { index: item.id + index, label: item.name, quantity: item.quantity, price: item.price } })
                }
                return null
              })}
              <OrderDocumentTotals data={{ totalAmount, totalDiscount: order.totalDiscount }} />
            </article>
            <hr />
            {newStatus && order.status !== newStatus && <LoadingSubmitButton data={{ loading: submitting, text: t('orders.change-status'), onClick: onClick }} />}
          </section>
        </>
      )}
    </>
  )
}

export { Order as default }
