import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useAPI } from '../../../api/api'
import { FilePathEnum, uploadImage } from '../../../api/upload-image'
import BackButton from '../../../components/BackButton'
import ErrorElement from '../../../components/ErrorElement'
import MerchantSetProfile from '../../../components/merchant/MerchantSetProfile'
import SplashScreen from '../../../components/SplashScreen'
import { MerchantProfileData } from './ViewMerchant'

const EditMerchantInfo = () => {
  const [isLoading, setIsLoading] = useState(true)
  let { submitting, errors, setSubmitting, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()
  const { t } = useTranslation()
  let history = useHistory()

  const [merchant, setMerchant] = useState<MerchantProfileData>()

  const { id }: any = useParams()

  useEffect(() => {
    async function onLoad() {
      if (!id) {
        return
      }
      const query = `query {
        merchants (filter: {_id : "${id}"}){_id email businessName phoneNumber isApproved returnSite isInternalEcosystem displayed profileImageURL merchantWebsiteURL  
        minQuantity maxQuantity totalQuantity frequency primaryContact { firstName lastName phoneNumber } businessAddress { address city state postalCode country } }
      }`
      const factory = (result: any): MerchantProfileData => {
        const displayed = result.merchants[0].displayed === null ? true : result.merchants[0].displayed
        return { ...result.merchants[0], displayed }
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setMerchant(response)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (profile: MerchantProfileData) => {
    let imageUrl: string | undefined = ''

    if (profile.image) {
      setSubmitting(true)
      imageUrl = await uploadImage(profile.image, profile.businessName, FilePathEnum.PROFILE_IMAGES)
      profile.profileImageURL = imageUrl
    }

    if (merchant) {
      const mutation = `mutation { boAdminUpdateMerchantProfile(merchantId:"${merchant._id}" 
      input: {businessName: "${profile.businessName}" merchantWebsiteURL: "${profile.merchantWebsiteURL}"
      profileImageURL: "${profile.profileImageURL}" phoneNumber: "${profile.phoneNumber}"
    returnSite: ${profile.returnSite} isInternalEcosystem: ${profile.isInternalEcosystem} displayed: ${profile.displayed} minQuantity: ${profile.minQuantity} maxQuantity: ${profile.maxQuantity} totalQuantity: ${profile.totalQuantity} frequency: ${profile.frequency}
    businessAddress: {address: "${profile.businessAddress.address}" city: "${profile.businessAddress.city}" state: "${profile.businessAddress.state}" 
    postalCode: "${profile.businessAddress.postalCode}" country: "${profile.businessAddress.country}"} 
    primaryContact: { firstName: "${profile.primaryContact.firstName}" lastName: "${profile.primaryContact.lastName}" phoneNumber: "${profile.primaryContact.phoneNumber}" }
  }) {businessName}}`

      const result = await wrappedExtendedMutateAuth(mutation)
      if (result) history.goBack()
    }
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <main className="justify-normal">
      <BackButton />
      <div className="Header">
        <h2>{t('editing-merchant')}</h2>
      </div>
      <ErrorElement errors={errors} />
      <MerchantSetProfile key={merchant?._id} profile={merchant} onSubmit={onSubmit} submitting={submitting} isAdmin={true} />
    </main>
  )
}
export { EditMerchantInfo as default }
