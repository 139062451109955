import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useAPI } from '../../api/api'
import SplashScreen from '../../components/SplashScreen'
import SuccessImage from '../../images/success.png'
import './Deposit.css'

const CompleteDeposit = () => {
  let history = useHistory()
  let { t } = useTranslation(['translation', 'errors'])

  const { token }: any = useParams()

  let { wrappedExtendedMutate } = useAPI()
  const [isLoading, setIsLoading] = useState(true)
  const [depositId, setDepositId] = useState('')
  const [supportCode, setSupportCode] = useState('')

  useEffect(() => {
    async function onLoad() {
      if (!!!token) {
        history.replace('/failed-deposit')
        return
      }

      const mutation = `mutation {confirmDepositEmail(magicToken:"${token}") {id supportCode}}`

      const response = await wrappedExtendedMutate(mutation)

      if (!response) {
        history.replace('/failed-deposit')
      }

      setDepositId(response.confirmDepositEmail.id)
      setSupportCode(response.confirmDepositEmail.supportCode)
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = () => {
    history.push('/deposit/scan-location', { magicToken: token, depositId: depositId, supportCode: supportCode })
  }

  return (
    <>
      {!isLoading ? (
        <main className="mainHome">
          <div className="screen-header">
            <img src={SuccessImage} alt="Green tick" />
            <h1>{t('email-confirmed')}</h1>
          </div>
          <p className="general-paragraph">
            <label>{t('deposit-thank-you')}</label>
          </p>
          <div className="footer-button-section">
            <button className="button-medium main-button" onClick={onClick}>
              {t('start-scanning')}
            </button>
          </div>
        </main>
      ) : (
        <SplashScreen withFooter={false} />
      )}
    </>
  )
}

export { CompleteDeposit as default }
