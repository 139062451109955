import './QrScanner.css'

import { ReactComponent as CancelIcon } from '../icons/cancel.svg'

import OneOffQrScanner, { ScanArea } from './QrScannerImpl'
import { useEffect, useState } from 'react'
import { SelectOptions } from '../containers/admin/MainStatuses'
import SelectMenu from './SelectMenu'
import { useTranslation } from 'react-i18next'

const CAMERA_KEY = 'selectedCamera'

export const QrScanner = ({ callback, callbackCancel }: any) => {
  const { t } = useTranslation()

  const selectedCamera = localStorage.getItem(CAMERA_KEY) || 'environment'

  const [options, setOptions] = useState<SelectOptions[]>([])
  const [cameraId, setCameraId] = useState<string>(selectedCamera)
  const [scanArea, setScanArea] = useState<ScanArea | undefined>()

  let isScanned = false

  useEffect(() => {
    async function onLoad() {}
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onQrCode = (qrCode: any) => {
    if (!isScanned && qrCode) {
      isScanned = true
      callback(qrCode)
    }
  }

  const onCameraList = async (cameras: any[]): Promise<string> => {
    const options: SelectOptions[] = cameras.map(camera => ({
      value: camera.id,
      label: camera.label,
    }))

    setOptions(options)

    return cameraId
  }

  const handleOptionChange = async (selectedOption: any, action: any) => {
    if (action.action === 'select-option') {
      setCameraId(selectedOption.value)
      localStorage.setItem(CAMERA_KEY, selectedOption.value)
    }
  }

  const onScanAreaUpdated = async (scanArea: ScanArea) => {
    console.debug('Updating scan area', scanArea)

    if (scanArea.width > 0 && scanArea.height > 0) setScanArea(scanArea)
  }

  return (
    <div className="qr-scanner">
      <div className="device-selector">
        <SelectMenu data={{ options, handleChange: handleOptionChange, value: options.find(option => option.value === cameraId) }} placeholder={t('select-camera')} />
      </div>

      {scanArea && <div className="qr-scan-border" />}

      <OneOffQrScanner onQrCode={onQrCode} cameraId={cameraId} onScanAreaUpdated={onScanAreaUpdated} onCameraList={onCameraList} />
      <button className="cancel" onClick={e => callbackCancel(e)}>
        <CancelIcon></CancelIcon>
      </button>
    </div>
  )
}
