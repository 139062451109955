import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import SplashScreen from '../../components/SplashScreen'

const Deposits = () => {
  const { id }: any = useParams()
  let { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function onLoad() {
      console.log('test')
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoading ? (
        <SplashScreen withFooter={false} />
      ) : (
        <main>
          <h1>Deposit</h1>
          <label>{id}</label>
          <label>{t('lorem-ipsum')}</label>
        </main>
      )}
    </>
  )
}

export { Deposits as default }
