import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { ReactComponent as Back } from '../icons/arrow-short-left.svg'

type BackButtonData = {
  path?: string | undefined
}

const BackButton = (data: BackButtonData) => {
  const { t } = useTranslation()
  let history = useHistory()

  const onClick = (e: any) => {
    e.preventDefault()
    if (data.path) {
      history.push(data.path)
    } else history.goBack()
  }

  return (
    <>
      <Link to="#" replace className="back" onClick={onClick}>
        <Back className="bo-back" />
        {t('back')}
      </Link>
    </>
  )
}

export { BackButton as default }
