import './Searchbar.css'
import sort from '../icons/sort.svg'
import filter from '../icons/filter.svg'
import { useTranslation } from 'react-i18next'

const Searchbar = () => {
  const { t } = useTranslation()
  return (
    <section className="Searchbar2">
      <label className="general-label">{t('search')}</label>
      <img src={filter} alt="Filter" className="bo-filter" onClick={() => alert('Filter')} />
      <img src={sort} alt="Sort" className="bo-sort" onClick={() => alert('Sort')} />
      <input></input>
    </section>
  )
}

export { Searchbar as default }
