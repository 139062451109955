import moment from 'moment'
import { Link } from 'react-router-dom'

type AccordionPanel = {
  heading?: string
  rightLabel?: string
  mappedRightLabels?: string[]
  actionLabel?: string
  action?: any
}

const CardAccordionPanel = ({ data }: { data: AccordionPanel }) => {
  return (
    <div className="card accordion-panel">
      <h3 className="name">{data.heading}</h3>
      <div>
        {data.mappedRightLabels &&
          data.mappedRightLabels.map((label, index) => (
            <div key={label + index} className="mapped-labels">
              {moment.utc(label).format('YYYY-MM-DD')}
            </div>
          ))}
      </div>
      {data.rightLabel && <div className="centered-label">{data.rightLabel}</div>}
      {data.actionLabel && data.action && (
        <>
          <hr />
          <Link className="yellow-link write-off" to="#" onClick={data.action} replace>
            {data.actionLabel}
          </Link>
        </>
      )}
    </div>
  )
}
export { CardAccordionPanel as default }
