import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const TermsAndConditionsCheck = ({ data }: any) => {
  const { t } = useTranslation(['terms'])

  return (
    <div className="accept-terms">
      <input type="checkbox" checked={data.acceptedTerms} onChange={data.onAcceptTerms} />
      <label onClick={data.onAcceptTerms}>{t('i-accept')}</label>
      <Link className="yellow-link" to="#" onClick={data.onClick}>
        {t('terms-and-conditions')}
      </Link>
    </div>
  )
}

export { TermsAndConditionsCheck as default }
