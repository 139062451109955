import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import NavigationMenu from '../components/common/NavigationMenu'
import WelcomeIntro from '../components/WelcomeIntro'
import { ReactComponent as UserIcon } from '../icons/user.svg'
import { ReactComponent as BorrowIcon } from '../icons/borrow.svg'
import { ReactComponent as ReturnIcon } from '../icons/return.svg'
import MapImage from '../images/map.png'
import ImpactImage from '../images/impact_placeholder.png'
import { LanguageSelect } from '../components/LanguageSelector'
import { useTranslation } from 'react-i18next'

const Home = () => {
  let history = useHistory()
  const { t } = useTranslation()

  const [showWelcome, setShowWelcome] = useState(true)

  useEffect(() => {
    const onLoad = () => {
      const show = localStorage.getItem('showWelcome')
      if (show === 'false') {
        setShowWelcome(false)
      }
    }

    onLoad()
  }, [])

  const onLogin = () => {
    history.push('/login')
  }

  const onReturnContainer = () => {
    history.push('/deposit')
  }

  const onMapClick = () => {
    history.push('/map')
  }

  const onFinishWelcome = () => {
    localStorage.setItem('showWelcome', 'false')
    setShowWelcome(false)
  }

  return (
    <>
      {showWelcome ? (
        <WelcomeIntro data={{ onFinishWelcome }} />
      ) : (
        <>
          <main className="mainHome">
            <div className="homeHeading">
              <LanguageSelect />
              <div className="loginLink" onClick={onLogin}>
                {t('register-login')}
                <UserIcon />
              </div>
            </div>
            <div className="homeMainButtons">
              <div className="homeMainButton borrow" onClick={onLogin}>
                <BorrowIcon />
                <label>{t('borrow-containers')}</label>
              </div>
              <div className="homeMainButton return" onClick={onReturnContainer}>
                <ReturnIcon />
                <label>{t('return-containers')}</label>
              </div>
            </div>
            <div className="homeMap">
              {t('find-partners')}
              <img src={MapImage} alt="map" onClick={onMapClick} />
            </div>
            <div className="homeImpact" onClick={onLogin}>
              <div className="impactHeader">
                <h2>{t('make-impact')}</h2>
                <label>{t('impact-create-account')}</label>
              </div>
              <img src={ImpactImage} alt="Impact stats placeholder" onClick={onLogin} />
            </div>
            <div className="mainDock"></div>
            <NavigationMenu />
          </main>
        </>
      )}
    </>
  )
}

export { Home as default }
