import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import { formatDate } from '../../../api/utils'
import ActionBar from '../../../components/admin/ActionBar'
import Card from '../../../components/Card'
import ErrorElement from '../../../components/ErrorElement'
import Navbar from '../../../components/Navbar'
import Searchbar from '../../../components/Searchbar'
import SplashScreen from '../../../components/SplashScreen'

import '../../CategoryHome.css'

type BatchCardData = {
  _id: string
  itemTypeName: string
  numberOfItems: number
  createdAt: number
  fileUrl?: string
}

const Batches = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  const [batches, setBatches] = useState<BatchCardData[]>([])

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  useEffect(() => {
    async function onLoad() {
      const query = `query { batches { _id itemType { name } numberOfItems createdAt } }`
      const factory = (result: any): BatchCardData[] => {
        return result.batches.map((o: any) => ({ ...o, itemTypeName: o.itemType.name }))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setBatches(response)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar></Navbar>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <section className="HomeSection WithNavbar">
          <header>
            <h1>{t('batches.batches')}</h1>
          </header>
          <Searchbar></Searchbar>
          <hr />
          <ErrorElement errors={errors} />
          {batches.length === 0 ? (
            <p className="empty-list-msg">{t('empty.batches')}</p>
          ) : (
            batches.map(batch => (
              <Card
                key={batch._id}
                data={{
                  _id: batch._id,
                  labelMain: batch.itemTypeName,
                  labelTopRight: formatDate(batch.createdAt / 1000),
                  labelSub1: `${batch.numberOfItems} ${t('piece')}`,
                  linkTo: '/admin/batches/validate/' + batch._id,
                }}
              />
            ))
          )}
        </section>
      )}
      <ActionBar></ActionBar>
    </>
  )
}

export { Batches as default }
export type { BatchCardData }
