import { Link, useLocation } from 'react-router-dom'

import './ActionBar.css'

import { ReactComponent as ItemsIcon } from '../../icons/items.svg'
import { ReactComponent as UsersIcon } from '../../icons/merchants.svg'
import { ReactComponent as BatchesIcon } from '../../icons/batches.svg'
import { ReactComponent as OrdersIcon } from '../../icons/orders.svg'
import { ReactComponent as LocationsIcon } from '../../icons/locations.svg'
import { useTranslation } from 'react-i18next'

const ActionBar = () => {
  const { t } = useTranslation()

  const auth = localStorage.getItem('auth')
  let role: string = ''

  if (auth) {
    role = JSON.parse(auth).role
  }

  const location = useLocation()
  const activeTab = location.pathname.split('/')[2].toLowerCase()

  return (
    <section className="BottomNavbar">
      {role !== 'warehouse' && (
        <>
          <Link className={activeTab === 'itemtypes' ? 'active' : ''} to="/admin/itemtypes/" replace>
            <ItemsIcon />
            {t('items')}
          </Link>
          <Link className={activeTab === 'users' ? 'active' : ''} to="/admin/users/" replace>
            <UsersIcon />
            {t('users')}
          </Link>
          <Link className={activeTab === 'batches' ? 'active' : ''} to="/admin/batches/" replace>
            <BatchesIcon />
            {t('batches.batches')}
          </Link>
          <Link className={activeTab === 'orders' ? 'active' : ''} to="/admin/orders/" replace>
            <OrdersIcon />
            {t('orders.orders')}
          </Link>
          <Link className={activeTab === 'locations' ? 'active' : ''} to="/admin/locations/" replace>
            <LocationsIcon />
            {t('locations.locations')}
          </Link>
        </>
      )}
    </section>
  )
}

export { ActionBar as default }
