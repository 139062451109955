import { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import ImgFigure, { ImgFigureData } from '../common/ImgFigure'
import { AnimalTiers, EnergyTiers, weightConvert } from './statsUtils'

const MainFigure = ({ quantity }: { quantity: number }) => {
  let data: ImgFigureData = {
    name: 'main',
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/bo+stack.png',
    alt: 'stack of BO containers',
    caption: <Trans ns="impact" i18nKey="captions.main" values={{ quantity }} />,
  }
  return <ImgFigure data={data} />
}

const SavedCO2Figure = ({ carbonSaved }: { carbonSaved: number }) => {
  let data: ImgFigureData = {
    name: 'savedCO2',
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/clouds.png',
    alt: 'clouds illustration',
    caption: <Trans ns="impact" i18nKey="captions.saved-co2" values={{ carbonSaved: weightConvert(carbonSaved) }} />,
  }
  return <ImgFigure data={data} />
}

const EnergyFigure = ({ quantity, tier }: { quantity: number; tier: string }) => {
  const { t } = useTranslation(['impact'])
  const [figure, setFigure] = useState<ImgFigureData | undefined>(undefined)

  const noTier: ImgFigureData = {
    name: EnergyTiers.NoTier,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/boiling+water.png',
    alt: 'boiling water illustration',
    caption: <Trans ns="impact" i18nKey="captions.energy.no-tier" />,
  }
  const water: ImgFigureData = {
    name: EnergyTiers.Water,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/boiling+water.png',
    alt: 'boiling water illustration',
    caption: <Trans ns="impact" i18nKey="captions.energy.water" values={{ quantity: quantity * 34 }} />,
  }
  const plasticBag: ImgFigureData = {
    name: EnergyTiers.PlasticBag,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/bag.png',
    alt: 'plastic bag illustration',
    caption: <Trans ns="impact" i18nKey="captions.energy.plastic-bag" values={{ quantity: quantity, tier: `${quantity > 1 ? t('energy-tiers.bags') : t('energy-tiers.bag')}` }} />,
  }
  const home: ImgFigureData = {
    name: EnergyTiers.Home,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/house.png',
    alt: 'home illustration',
    caption: <Trans ns="impact" i18nKey="captions.energy.home" values={{ quantity: quantity, tier: `${quantity > 1 ? t('energy-tiers.homes') : t('energy-tiers.home')}` }} />,
  }
  const tree: ImgFigureData = {
    name: EnergyTiers.Tree,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/trees.png',
    alt: 'trees illustration',
    caption: <Trans ns="impact" i18nKey="captions.energy.tree" values={{ quantity: quantity, tier: `${quantity > 1 ? t('energy-tiers.trees') : t('energy-tiers.tree')}` }} />,
  }

  const energyFigures: ImgFigureData[] = [noTier, water, plasticBag, home, tree]

  useEffect(() => {
    async function onLoad() {
      var obj = energyFigures.find((f: any) => f.name === tier)

      if (obj) setFigure(obj)
      else setFigure(noTier)
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, tier])

  return figure ? <ImgFigure data={figure} /> : <></>
}

const NatureFigure = ({ plasticSaved, quantity, tier, secondaryTier = false, unlockTier = false, className }: { plasticSaved: number; quantity: number; tier: string; secondaryTier?: boolean; unlockTier?: boolean; className?: string }) => {
  const { t } = useTranslation(['impact'])
  const [figure, setFigure] = useState<ImgFigureData | undefined>(undefined)
  const [convertedPlasticSaved, setConvertedPlasticSaved] = useState(weightConvert(plasticSaved))

  const noAnimal: ImgFigureData = {
    name: AnimalTiers.NoTier,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/kilifish.png',
    alt: 'killifish illustration',
    caption: <Trans ns="impact" i18nKey="captions.nature.no-tier" values={{ animal: t('animals.killifish') }} />,
  }
  const killifish: ImgFigureData = {
    name: AnimalTiers.Killifish,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/kilifish.png',
    alt: 'killifish illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.killifishes') : t('animals.killifish')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.killifishes') : t('animals.killifish')} />
        )}
      </>
    ),
  }
  const sardine: ImgFigureData = {
    name: AnimalTiers.Sardine,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/sardine.png',
    alt: 'sardine illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.sardines') : t('animals.sardine')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.sardines') : t('animals.sardine')} />
        )}
      </>
    ),
  }
  const clownfish: ImgFigureData = {
    name: AnimalTiers.Clownfish,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/clown+fish.png',
    alt: 'clownfish illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.clownfishes') : t('animals.clownfish')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.clownfishes') : t('animals.clownfish')} />
        )}
      </>
    ),
  }
  const blueTangFish: ImgFigureData = {
    name: AnimalTiers.BlueTangFish,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/blue+tang+fish.png',
    alt: 'blue tang fish illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.blue-tang-fishes') : t('animals.blue-tang-fish')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.blue-tang-fishes') : t('animals.blue-tang-fish')} />
        )}
      </>
    ),
  }
  const crab: ImgFigureData = {
    name: AnimalTiers.Crab,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/crab.png',
    alt: 'crab illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.crabs') : t('animals.crab')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.crabs') : t('animals.crab')} />
        )}
      </>
    ),
  }
  const lobster: ImgFigureData = {
    name: AnimalTiers.Lobster,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/lobster.png',
    alt: 'lobster illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.lobsters') : t('animals.lobster')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.lobsters') : t('animals.lobster')} />
        )}
      </>
    ),
  }
  const starfish: ImgFigureData = {
    name: AnimalTiers.Starfish,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/starfish.png',
    alt: 'starfish illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.starfishes') : t('animals.starfish')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.starfishes') : t('animals.starfish')} />
        )}
      </>
    ),
  }
  const octopus: ImgFigureData = {
    name: AnimalTiers.Octopus,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/octopus.png',
    alt: 'octopus illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.octopuses') : t('animals.octopus')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.octopuses') : t('animals.octopus')} />
        )}
      </>
    ),
  }
  const stingray: ImgFigureData = {
    name: AnimalTiers.Stingray,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/stingray.png',
    alt: 'stingray illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.stingrays') : t('animals.stingray')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.stingrays') : t('animals.stingray')} />
        )}
      </>
    ),
  }
  const penguin: ImgFigureData = {
    name: AnimalTiers.Penguin,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/penguin.png',
    alt: 'penguin illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.penguins') : t('animals.penguin')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.penguins') : t('animals.penguin')} />
        )}
      </>
    ),
  }
  const barracuda: ImgFigureData = {
    name: AnimalTiers.Barracuda,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/baracuda.png',
    alt: 'barracuda illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.barracudas') : t('animals.barracuda')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.barracudas') : t('animals.barracuda')} />
        )}
      </>
    ),
  }
  const turtle: ImgFigureData = {
    name: AnimalTiers.Turtle,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/turtle.png',
    alt: 'turtle illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.turtles') : t('animals.turtle')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.turtles') : t('animals.turtle')} />
        )}
      </>
    ),
  }
  const dolphin: ImgFigureData = {
    name: AnimalTiers.Dolphin,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/dolphin.png',
    alt: 'dolphin illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.dolphins') : t('animals.dolphin')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.dolphins') : t('animals.dolphin')} />
        )}
      </>
    ),
  }
  const greatWhiteShark: ImgFigureData = {
    name: AnimalTiers.GreatWhiteShark,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/great+white+shark.png',
    alt: 'great white shark illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.great-white-sharks') : t('animals.great-white-shark')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.great-white-sharks') : t('animals.great-white-shark')} />
        )}
      </>
    ),
  }
  const narwhal: ImgFigureData = {
    name: AnimalTiers.Narwhal,
    src: 'https://bopaq-files.s3.amazonaws.com/images/impact-images/narwhal.png',
    alt: 'narwhal illustration',
    caption: (
      <>
        {secondaryTier ? (
          <SecondaryAnimalCaption quantity={quantity} animal={quantity > 1 ? t('animals.narwhals') : t('animals.narwhal')} />
        ) : (
          <MainAnimalCaption plasticSaved={convertedPlasticSaved} quantity={quantity} animal={quantity > 1 ? t('animals.narwhals') : t('animals.narwhal')} />
        )}
      </>
    ),
  }

  const animalFigures: ImgFigureData[] = [noAnimal, killifish, sardine, clownfish, blueTangFish, crab, lobster, starfish, octopus, stingray, penguin, barracuda, turtle, dolphin, greatWhiteShark, narwhal]

  useEffect(() => {
    async function onLoad() {
      const conversionResult = weightConvert(plasticSaved)
      setConvertedPlasticSaved(conversionResult)

      let obj: ImgFigureData | undefined = undefined
      if (!unlockTier) {
        obj = animalFigures.find((f: any) => f.name === tier)
      } else {
        let index = animalFigures.findIndex((f: any) => f.name === tier)
        let animal = animalFigures[index + 1]
        let caption = <></>
        if (animal) {
          caption = <Trans ns="impact" i18nKey="captions.nature.unlock-animal" />
          obj = { ...animal, caption }
        } else {
          caption = <Trans ns="impact" i18nKey="captions.nature.all-unlocked" />
          obj = { name: 'unlockedAllTier', src: 'https://cdn-icons-png.flaticon.com/512/1115/1115673.png', alt: 'congratulations', caption }
        }
      }

      if (obj) setFigure(obj)
      else setFigure(noAnimal)
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plasticSaved, quantity, tier, secondaryTier])

  return figure ? <ImgFigure className={className} data={figure} /> : <></>
}

const MainAnimalCaption = ({ plasticSaved, quantity, animal }: { plasticSaved: string; quantity: number; animal: string }) => {
  return <Trans ns="impact" i18nKey="captions.nature.main-animal" values={{ plasticSaved, quantity, animal }} />
}

const SecondaryAnimalCaption = ({ quantity, animal }: { quantity: number; animal: string }) => {
  return <Trans ns="impact" i18nKey="captions.nature.secondary-animal" values={{ quantity, animal }} />
}

export { MainFigure, SavedCO2Figure, EnergyFigure, NatureFigure }
