type OrderDocumentRowData = {
  index: string
  label: string
  quantity: number
  price: number
}

const OrderDocumentTableRow = ({ data }: { data: OrderDocumentRowData }) => {
  return (
    <div key={data.index} className="orderRow">
      <label>{data.label}</label>
      <label>{data.quantity}</label>
      <label>${data.price.toFixed(2)}</label>
      <label>${(data.price * data.quantity).toFixed(2)}</label>
    </div>
  )
}

export default OrderDocumentTableRow
