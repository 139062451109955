import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import { useAPI } from '../api/api'
import apiUtils from '../api/apiUtils'
import { Roles } from '../api/utils'
import { CustomInput, CustomPasswordInput } from '../components/common/CustomInput'
import { LanguageSelect } from '../components/LanguageSelector'
import { LoadingSubmitButton } from '../components/LoadingButton'
import { AdminPaths, CustomerPaths, MerchantPaths } from '../routes/RoutePaths'
import './Login.css'
import { ReactComponent as MailIcon } from '../icons/mail.svg'
import { ReactComponent as LockIcon } from '../icons/lock.svg'
import BackButton from '../components/BackButton'
import { ToSPPLabel } from '../components/common/TosPPLabels'
import { toast } from 'react-toastify'

const Login = () => {
  const { t } = useTranslation(['translation', 'errors'])

  let history = useHistory()

  let { submitting, errors, wrappedExtendedMutate } = useAPI()
  const [ended, setEnded] = useState(false)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const { setAuthToLocalStorage } = apiUtils()

  const moveAfterLogin = (login: any) => {
    setAuthToLocalStorage(login)
    let lastPage: string = '/'
    switch (login.role) {
      case Roles.Client:
        lastPage = CustomerPaths.Home
        break
      case Roles.Merchant:
        lastPage = MerchantPaths.Home
        break
      case Roles.Admin:
      case Roles.Employee:
        lastPage = AdminPaths.Home
        break
      case Roles.Warehouse:
        lastPage = `${AdminPaths.Home}orders`
        break
      default:
        break
    }
    history.push(lastPage)
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const mutation = `mutation { login ( input: { email: "${email}", password: "${password}" }) { token registrationState role email boId name } }`

    const response = await wrappedExtendedMutate(mutation)

    setEnded(true)

    if (response && response.login) {
      moveAfterLogin(response.login)
    }
  }

  useEffect(() => {
    const onErrors = () => {
      if (ended) {
        if (errors.length > 0) {
          errors.forEach(error => {
            toast.error(t(error.msg, { ns: 'errors' }))
          })
        }
      }
    }
    return () => {
      onErrors()
      setEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended])

  return (
    <main className="screen-container">
      <div className="screen-header">
        <div className="back-and-langauge-container">
          <BackButton path="/" />
          <LanguageSelect />
        </div>
        <h1>{t('log-in')}</h1>
        <h2>{t('log-in-text')}</h2>
      </div>
      <form className="form-general" onSubmit={onSubmit}>
        <div className="main-form-section">
          <div className={`form-input-section ${errors.length > 0 ? 'form-invalid' : ''}`}>
            <CustomInput autoFocus={true} type="email" label={t('email')} placeholder={t('enter-email')} value={email} setValue={setEmail} placeHolderIcon={<MailIcon />} />
            <CustomPasswordInput label={t('password.main')} placeholder={t('enter-password')} value={password} setValue={setPassword} placeHolderIcon={<LockIcon />} />
          </div>
          <Link className="forgot-password link" to="/forgot-password">
            {t('password.forgot-password')}
          </Link>
        </div>
        <div className="screen-footer-section">
          <LoadingSubmitButton data={{ disabled: !!!email || !!!password, loading: submitting, text: t('log-in'), className: 'button-medium main-button' }} />
          <span className="small-label">
            {t('new-user')}{' '}
            <Link className="link" style={{ textDecoration: 'underline' }} to="/register">
              {t('new-user-create')}!
            </Link>
          </span>
        </div>
      </form>
      <ToSPPLabel />
    </main>
  )
}

export { Login as default }
