import { MouseEventHandler } from 'react'
import IconButton from './IconButton'
import { ReactComponent as CompleteIcon } from '../../icons/check.svg'
import { ReactComponent as AddIcon } from '../../icons/plus.svg'

import './Buttons.css'

type AddAndComplete = {
  disabledAdd?: boolean
  disabledComplete?: boolean
  submitting: boolean
  type?: 'button' | 'submit' | 'reset'
  onClickAdd: MouseEventHandler
  onClickSubmit?: MouseEventHandler
}

const AddAndCompleteElement = ({ data }: { data: AddAndComplete }) => {
  return (
    <div className="add-complete-element">
      <IconButton data={{ className: 'add-button', type: 'button', icon: <AddIcon />, submitting: data.submitting, disabled: data.disabledAdd, onClick: data.onClickAdd }} />
      <IconButton data={{ className: 'complete-button', type: data.type || 'submit', icon: <CompleteIcon />, submitting: data.submitting, disabled: data.disabledComplete, onClick: data.onClickSubmit }} />
    </div>
  )
}

export default AddAndCompleteElement
