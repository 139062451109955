import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAPI } from '../../../api/api'
import { FilePathEnum, uploadImage } from '../../../api/upload-image'
import BackButton from '../../../components/BackButton'
import ErrorElement from '../../../components/ErrorElement'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import { setNotification } from '../../../components/Notification'

const AddItemType = () => {
  const { t } = useTranslation()
  const history = useHistory()

  let { submitting, setSubmitting, errors, setErrors, wrappedExtendedMutateAuth } = useAPI()

  const [name, setName] = useState('')
  const [capacity, setCapacity] = useState(0)
  const [w, setWidth] = useState(0)
  const [h, setHeight] = useState(0)
  const [l, setLength] = useState(0)
  const [selectedFile, setSelectedFile] = useState()

  const [description, setDescription] = useState('')

  const [wholesalePrice, setWholesalePrice] = useState(0)
  const [retailPrice, setRetailPrice] = useState(0)
  const [restockPrice, setRestockPrice] = useState(0)
  const [restockSpecialPrice, setRestockSpecialPrice] = useState(0)
  const [onboarding, setOnboarding] = useState(0)
  const [rentalPrice, setRentalPrice] = useState(0)
  const [depositPrice, setDepositPrice] = useState(0)

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    let imageUrl: string | undefined = ''

    if (selectedFile) {
      imageUrl = await uploadImage(selectedFile, name, FilePathEnum.ITEM_TYPE_IMAGES)
      if (!imageUrl) {
        setSubmitting(false)
        setErrors([{ msg: 'Error while uploading image' }])
        return
      }
    }

    const mutation = `mutation { createItemType( input: { name: "${name}" capacity: ${capacity} description: "${description}" iconUrl: "${imageUrl}"
    pricing: { wholesale: ${parseFloat((wholesalePrice * 100).toFixed(2))} retail: ${parseFloat((retailPrice * 100).toFixed(2))} restock: ${parseFloat((restockPrice * 100).toFixed(2))} 
    restockSpecial: ${parseFloat((restockSpecialPrice * 100).toFixed(2))} onboarding: ${parseFloat((onboarding * 100).toFixed(2))} rental: ${parseFloat((rentalPrice * 100).toFixed(2))} 
    deposit: ${parseFloat((depositPrice * 100).toFixed(2))}} 
    dimensions: { w: ${w} h: ${h} l: ${l} } } ) { _id} }`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setNotification('items.added')
      history.goBack()
    }
  }

  const onImageChange = (event: any) => {
    setSelectedFile(event.target.files[0])
  }

  return (
    <form onSubmit={onSubmit} className="object-form">
      <BackButton />
      <h2>{t('item-types.add')}</h2>
      <ErrorElement errors={errors} />
      <label>{t('name')}</label>
      <input value={name} onChange={e => setName(e.target.value)} required={true}></input>
      <label>{t('description')}</label>
      <input value={description} onChange={e => setDescription(e.target.value)} required={true}></input>
      <label>
        {t('capacity')} ({t('oz')})
      </label>
      <input type="number" value={capacity} onChange={e => setCapacity(parseInt(e.target.value))} required={true}></input>
      <label>
        {t('dimensions')} ({t('dimensions-break')} {t('inches')})
      </label>
      <div className="dimensions-input">
        <input type="number" value={w} onChange={e => setWidth(parseFloat(e.target.value))} required={true}></input>
        <label>x</label>
        <input type="number" value={h} onChange={e => setHeight(parseFloat(e.target.value))} required={true}></input>
        <label>x</label>
        <input type="number" value={l} onChange={e => setLength(parseFloat(e.target.value))} required={true}></input>
      </div>{' '}
      <hr />
      <label>{t('wholesale-price')}</label>
      <input type="number" value={wholesalePrice} onChange={e => setWholesalePrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('retail-price')}</label>
      <input type="number" value={retailPrice} onChange={e => setRetailPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('restock-price')}</label>
      <input type="number" value={restockPrice} onChange={e => setRestockPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('restock-special')}</label>
      <input type="number" value={restockSpecialPrice} onChange={e => setRestockSpecialPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('onboarding')}</label>
      <input type="number" value={onboarding} onChange={e => setOnboarding(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('rental')}</label>
      <input type="number" value={rentalPrice} onChange={e => setRentalPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('deposit')}</label>
      <input type="number" value={depositPrice} onChange={e => setDepositPrice(parseFloat(e.target.value))} required={true} step="0.01"></input>
      <label>{t('image.add')}</label>
      <input type="file" name="file" accept="image/png, image/jpeg" onChange={onImageChange} />
      <hr />
      <LoadingSubmitButton data={{ loading: submitting, text: t('save') }} />
    </form>
  )
}

export { AddItemType as default }
