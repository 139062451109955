import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAPI } from '../../api/api'
import ErrorElement from '../ErrorElement'
import { LoadingSubmitButton } from '../LoadingButton'

const UpdateCreditCard = () => {
  const history = useHistory()
  const { t } = useTranslation()

  const stripe = useStripe()
  const elements = useElements()

  const { errors, submitting, setErrors, setSubmitting, wrappedExtendedQueryAuth, apiPost } = useAPI()

  const [stripeSecret, setStripeSecret] = useState('')
  const [fullName, setFullName] = useState('')

  useEffect(() => {
    async function onLoad() {
      let auth = JSON.parse(localStorage.getItem('auth') || '')
      let role = auth.role
      let query
      let factory

      switch (role) {
        case 'client':
          query = `query { clientStripeSecret }`
          factory = (result: any): string => {
            return result.clientStripeSecret
          }
          break
        case 'merchant':
          query = `query { merchantStripeSecret }`
          factory = (result: any): string => {
            return result.merchantStripeSecret
          }
          break
        default:
          return
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setStripeSecret(response)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }
    const card = elements.getElement(CardElement)
    if (!card) {
      return
    }

    setSubmitting(true)

    const result = await stripe.confirmCardSetup(stripeSecret, {
      payment_method: {
        card,
        billing_details: {
          name: fullName,
        },
      },
    })

    if (result.error && result.error.message) {
      setErrors([{ msg: result.error.message }])
      setSubmitting(false)
      return
    }

    if (result.setupIntent) {
      const endpoint = `stripe/remove-old-payment-methods`

      const body = JSON.stringify({
        newPaymentMethodId: `${result.setupIntent.payment_method}`,
      })

      const response = await apiPost(endpoint, body)

      if (response) {
        history.goBack()
      }
    }
  }

  return (
    <>
      <form onSubmit={onSubmit}>
        <ErrorElement errors={errors} />
        <label>{t('cc-name')}</label>
        <input value={fullName} onChange={e => setFullName(e.target.value)} required={true}></input>
        <label>{t('cc-details')}</label>
        <CardElement />
        <LoadingSubmitButton data={{ loading: submitting, text: t('save') }} />
      </form>
    </>
  )
}

export { UpdateCreditCard as default }
