import './ItemTypeRow.css'

type ItemTypeRowData = {
  index: number
  label1: string
  label2: string | undefined
  value: number | ''
  callback: any
}

const ItemTypeRow = ({ data }: { data: ItemTypeRowData }) => {
  const changeValue = (value: number) => {
    data.callback(data.index, Math.max(0, (data.value || 0) + value))
  }

  return (
    <div className="ItemTypeRow" key={data.index}>
      <div>
        <div className="label1">{data.label1}</div>
        <div className="label2">{data.label2}</div>
      </div>
      <div>
        <button type="button" className="ModifyInput" onClick={e => changeValue(-1)}>
          -
        </button>
        <input type="number" onChange={e => data.callback(data.index, e.target.value)} value={data.value}></input>
        <button type="button" className="ModifyInput" onClick={e => changeValue(1)}>
          +
        </button>
      </div>
    </div>
  )
}

export { ItemTypeRow as default }
export type { ItemTypeRowData }
