import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ErrorElement from '../../components/ErrorElement'
import { LoadingSubmitButton } from '../../components/LoadingButton'
import { useAPI } from '../../api/api'
import { Link } from 'react-router-dom'

const CardSetupForm = ({ onSuccessCallback }: any) => {
  const { t } = useTranslation()

  const { submitting, errors, setErrors, setSubmitting, wrappedExtendedMutateAuth, wrappedExtendedQueryAuth } = useAPI()

  const stripe = useStripe()
  const elements = useElements()

  const [merchantSecret, setMerchantSecret] = useState('')
  const [fullName, setFullName] = useState('')

  useEffect(() => {
    async function onLoad() {
      const query = `query { merchantStripeSecret }`
      const factory = (result: any): string => {
        return result.merchantStripeSecret
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setMerchantSecret(response)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const card = elements.getElement(CardElement)
    if (!card) {
      return
    }

    setSubmitting(true)

    const result = await stripe.confirmCardSetup(merchantSecret, {
      payment_method: {
        card,
        billing_details: {
          name: fullName,
        },
      },
    })

    if (result.error && result.error.message) {
      setErrors([{ msg: result.error.message }])
      setSubmitting(false)
      return
    }
    const mutation = `mutation { registerMerchantConfirmCC { token registrationState email role boId name } }`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response && onSuccessCallback) {
      onSuccessCallback(response)
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <ErrorElement errors={errors} />
      <label>{t('cc-name')}</label>
      <input value={fullName} onChange={e => setFullName(e.target.value)} required={true}></input>
      <label>{t('cc-details')}</label>
      <CardElement />
      <Link to="/merchant/" className="yellow-link">{t('skip')}</Link>
      <LoadingSubmitButton data={{ loading: submitting, text: t('save') }} />
    </form>
  )
}

export { CardSetupForm as default }
