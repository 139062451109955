import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import apiUtils from '../../api/apiUtils'
import { APIError } from '../../api/response'
import { FilePathEnum, uploadImage } from '../../api/upload-image'
import LogoutButton from '../../components/buttons/LogoutButton'
import ErrorElement from '../../components/ErrorElement'
import { SmallLogo } from '../../components/Logo'
import MerchantSetProfile, { MerchantProfile } from '../../components/merchant/MerchantSetProfile'
import { MerchantPaths } from '../../routes/RoutePaths'

const MerchantRegisterProfile = () => {
  const { t } = useTranslation()
  let history = useHistory()

  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState<APIError[]>([])

  const { extendedMutateAuth, setAuthToLocalStorage } = apiUtils()

  const onSubmit = async (profile: MerchantProfile) => {
    setSubmitting(true)

    const imageUrl: string | undefined = await uploadImage(profile.image, profile.businessName, FilePathEnum.PROFILE_IMAGES)

    if (!imageUrl) {
      setSubmitting(false)
      setErrors([{ msg: 'Error while uploading image' }])
      return
    }

    console.log(profile.merchantWebsiteURL)

    const mutation = `mutation { registerMerchantProfile (input: {businessName: "${profile.businessName}" ${profile.merchantWebsiteURL && `merchantWebsiteURL: "${profile.merchantWebsiteURL}"`}
    profileImageURL: "${imageUrl}" phoneNumber: "${profile.phoneNumber}" returnSite: ${profile.returnSite} displayed: ${profile.displayed}
    businessAddress: {address: "${profile.businessAddress.address}" city: "${profile.businessAddress.city}" state: "${profile.businessAddress.state}" 
    postalCode: "${profile.businessAddress.postalCode}" country: "${profile.businessAddress.country}"} 
    primaryContact: { firstName: "${profile.primaryContact.firstName}" lastName: "${profile.primaryContact.lastName}" phoneNumber: "${profile.primaryContact.phoneNumber}" 
    }}) {token registrationState role email boId name}}`

    const response = await extendedMutateAuth(mutation, setErrors, setSubmitting)

    if (response) {
      await setAuthToLocalStorage(response.registerMerchantProfile)
      history.push(MerchantPaths.RegisterCC)
    }
  }

  return (
    <main>
      <SmallLogo></SmallLogo>
      <h2>{t('tell-us-more')}</h2>
      <ErrorElement errors={errors} />
      <MerchantSetProfile onSubmit={onSubmit} submitting={submitting} />
      <LogoutButton text="Cancel" />
    </main>
  )
}

export { MerchantRegisterProfile as default }
