import { FormEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useAPI } from '../../api/api'
import IconButton from '../../components/buttons/IconButton'
import ErrorElement from '../../components/ErrorElement'
import { LoadingSubmitButton } from '../../components/LoadingButton'
import ActionBar from '../../components/merchant/ActionBar'
import Navbar from '../../components/Navbar'
import { QrScanner } from '../../components/QrScanner'
import SplashScreen from '../../components/SplashScreen'
import { ReactComponent as QRIcon } from '../../icons/batches.svg'
import { MerchantPaths } from '../../routes/RoutePaths'
import Select, { components, createFilter } from 'react-select'
import { Config } from 'react-select/src/filters'
import { SelectOptions } from '../admin/MainStatuses'

import '../CategoryHome.css'

export type ClientForSearchData = {
  id: string
  label: string
  boId: string
  name: string
  registrationState: number
}

const MerchantAssignItem = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  const { fetching, errors, setErrors, wrappedExtendedQueryAuth } = useAPI()

  let history = useHistory()

  const [disabled, setDisabled] = useState(false)
  const [clients, setClients] = useState<ClientForSearchData[]>([])
  const [client, setClient] = useState<ClientForSearchData>()
  const [scannedCode, setScannedCode] = useState('')
  const [selectedOption, setSelectedOption] = useState<SelectOptions>()
  const [showScanner, setShowScanner] = useState(false)
  const [isInternalEcosystem, setIsInternalEcosystem] = useState(false)

  const suspendedAccountMsg = [{ msg: t('customer-suspended') }]

  const options = clients.map(client => ({
    value: client.id,
    label: client.label,
  }))

  useEffect(() => {
    async function onLoad() {
      const query = `{ clients { _id boId firstName lastName registrationState }
                      isInternalEcosystem
                    }`
      const factory = (result: any): { clients: ClientForSearchData[]; isInternalEcosystem: boolean } => {
        return {
          clients: result.clients.map((c: any) => ({
            id: c._id,
            boId: c.boId,
            registrationState: c.registrationState,
            name: `${c.firstName} ${c.lastName}`,
            label: `${c.boId} / ${c.firstName} ${c.lastName}`,
          })),
          isInternalEcosystem: result.isInternalEcosystem,
        }
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setClients(response.clients)
        setIsInternalEcosystem(response.isInternalEcosystem)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onClientSelect() {
      if (!client) return
      if (client.registrationState === -1) {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
    onClientSelect()
    setScannedCode('')
  }, [client])

  const handleSelectChange = (value: any, action: any) => {
    if (action.action === 'select-option') {
      setClient(clients.find(c => c.id === value.value))
    }
    if (action.action === 'clear') {
      setClient(undefined)
    }
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (client) {
      history.push(MerchantPaths.AssignItems, { ...client })
    }
  }

  const onHowToClick = () => {
    history.push('/merchant/how-to-bo')
  }

  const handleScanCustomerQr = () => {
    setErrors([])
    setScannedCode('')
    setShowScanner(true)
  }

  const onQrCode = async (code: string) => {
    if (code) {
      setScannedCode(code)
      setShowScanner(false)
    }
  }

  const onCancel = (e: any) => {
    setShowScanner(false)
  }

  const [showOptions, setShowOptions] = useState(false)
  const [inputValue, setInputValue] = useState<string | undefined>('')
  const [tempInputValue, setTempInputValue] = useState<string | undefined>('')
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleInputChange = useCallback(typedOption => {
    switch (true) {
      case typedOption.length < 3:
        setShowOptions(false)
        setMenuIsOpen(false)
        setInputValue(typedOption)
        break
      case typedOption.length === 3:
      case typedOption.length === 7:
        setShowOptions(true)
        setMenuIsOpen(true)
        setInputValue(typedOption + '-')
        break
      default:
        setInputValue(typedOption)
        break
    }
  }, [])

  const handleKeyDown = (e: any) => {
    setSelectedOption(undefined)
    if (e.keyCode === 8) {
      e.preventDefault()
      setTempInputValue(inputValue)
      spliceInput(inputValue)
    } else {
      return true
    }
  }

  const spliceInput = (tempInputValue: string | undefined) => {
    if (tempInputValue)
      switch (true) {
        case tempInputValue.length === 4:
          setTempInputValue(tempInputValue.substring(0, tempInputValue.length - 2))
          setShowOptions(false)
          setMenuIsOpen(false)
          break
        case tempInputValue.length === 8:
          setTempInputValue(tempInputValue.substring(0, tempInputValue.length - 2))
          break
        default:
          setTempInputValue(tempInputValue.substring(0, tempInputValue.length - 1))
          break
      }
  }

  useEffect(() => {
    setInputValue(tempInputValue)
  }, [tempInputValue])

  useEffect(() => {
    if (scannedCode) {
      const option = options.find(o => o.label.startsWith(scannedCode))

      setSelectedOption(option)
      if (scannedCode) {
        const tempClient = clients.find(c => c.boId === scannedCode)
        if (tempClient) {
          setErrors([])
          setClient(tempClient)
        } else {
          setClient(undefined)
          setSelectedOption(undefined)
          setInputValue(undefined)
          setErrors([{ msg: t('customer-not-found') }])
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedCode])

  const NoOptionsMessage = (props: any) => {
    return <components.NoOptionsMessage {...props}>{t('no-search-results')}</components.NoOptionsMessage>
  }
  const filterConfig: Config = { matchFrom: 'start' }

  const Placeholder = (props: any) => {
    return <components.Placeholder {...props}>{t('select')}</components.Placeholder>
  }

  return (
    <>
      {showScanner && (
        <section className="qrScanner">
          <QrScanner callback={onQrCode} callbackCancel={onCancel}></QrScanner>
        </section>
      )}
      <Navbar></Navbar>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <form onSubmit={onSubmit} className="object-form">
          <h1 className="name">{t('assign')}</h1>
          <hr />
          <div className="check-status">
            <label className="yellow-link check-item" onClick={e => history.push(MerchantPaths.CheckItem)}>
              <QRIcon fill="var(--color-primary)" />
              {isInternalEcosystem ? t('return') : t('check-bo-status')}
            </label>
            <Link className="yellow-link how-to-merchant" to="#" onClick={onHowToClick} replace>
              {t('how-to-bo')}
            </Link>
          </div>
          <hr />
          <ErrorElement errors={errors} />
          <label>{t('find-customer')}</label>
          <div className="assign-select-container">
            <Select
              key={'select' + scannedCode + selectedOption}
              className="select"
              filterOption={createFilter(filterConfig)}
              classNamePrefix="select"
              components={{ NoOptionsMessage, Placeholder }}
              options={showOptions ? options : []}
              onChange={(value, action) => handleSelectChange(value, action)}
              onInputChange={handleInputChange}
              isClearable={true}
              backspaceRemovesValue={true}
              inputValue={inputValue}
              onKeyDown={handleKeyDown}
              menuIsOpen={menuIsOpen}
              value={selectedOption && selectedOption}
              onFocus={() => setInputValue('')}
            />
            <IconButton data={{ className: 'add-button barcode', type: 'button', icon: <QRIcon />, onClick: handleScanCustomerQr }} />
          </div>
          {disabled && <ErrorElement errors={suspendedAccountMsg} />}
          {client && <LoadingSubmitButton data={{ loading: fetching, disabled, text: t('scan-qr-code') }} />}
        </form>
      )}
      <ActionBar></ActionBar>
    </>
  )
}

export { MerchantAssignItem as default }
