const LoadingSubmitButton = ({ data }: { data: any }) => {
  return (
    <button className={data.className || 'bottomBtn'} type="submit" disabled={data.loading || data.disabled} onClick={data.onClick}>
      {!data.loading && data.text}
      {data.loading && <i className="fa fa-spinner fa-spin"></i>}
    </button>
  )
}

export { LoadingSubmitButton }
