import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAPI } from '../../api/api'
import ApiUtils from '../../api/apiUtils'
import { CustomInput } from '../../components/common/CustomInput'
import { LoadingSubmitButton } from '../../components/LoadingButton'
import { ReactComponent as CCIcon } from '../../icons/credit_card.svg'

const CardSetupForm = ({ onSuccessCallback }: any) => {
  const { t } = useTranslation(['translation', 'errors'])
  let { removeAuthFromLocalStorage } = ApiUtils()
  let history = useHistory()

  const { errors, submitting, setSubmitting, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()
  const [ended, setEnded] = useState(false)

  const stripe = useStripe()
  const elements = useElements()

  const [clientSecret, setClientSecret] = useState('')
  const [fullName, setFullName] = useState('')

  useEffect(() => {
    async function onLoad() {
      const query = `query { clientStripeSecret }`
      const factory = (result: any): string => {
        return result.clientStripeSecret
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      setEnded(true)

      if (response) {
        setClientSecret(response)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    const cardNumberElement = elements?.getElement(CardNumberElement) as any as StripeCardElement

    setSubmitting(true)

    const result = await stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        card: cardNumberElement,
        billing_details: {
          name: fullName,
        },
      },
    })

    if (result.error && result.error.message) {
      toast.error(result.error.message)
      setSubmitting(false)
      return
    }
    const mutation = `mutation { registerClientConfirmCC { token registrationState email role boId name } }`

    const response = await wrappedExtendedMutateAuth(mutation)

    setEnded(true)

    if (response && onSuccessCallback) {
      onSuccessCallback(response)
    }
  }

  useEffect(() => {
    const onErrors = () => {
      if (ended) {
        if (errors.length > 0) {
          errors.forEach(error => {
            toast.error(t(error.msg, { ns: 'errors' }))
          })
        }
      }
    }
    return () => {
      onErrors()
      setEnded(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ended])

  const onCancel = async () => {
    await removeAuthFromLocalStorage()
    history.push('/')
  }

  const stripeOptions = {
    style: {
      base: {
        color: 'rgba(8, 34, 52, 1)',
      },
    },
  }

  return (
    <form className="form-general" onSubmit={onSubmit}>
      <div className={`form-input-section ${errors.length > 0 ? 'form-invalid' : ''}`}>
        <CustomInput autoFocus={true} label={t('name-cc')} placeholder={t('enter-name-cc')} value={fullName} setValue={setFullName} />
        <fieldset className={`custom-input-wrapper`}>
          <label>{t('cc-number')}</label>
          <div className="custom-input-container">
            <CCIcon />
            <CardNumberElement options={stripeOptions} />
          </div>
        </fieldset>
        <fieldset className="custom-input-wrapper cc-other-inputs">
          <fieldset>
            <label>{t('cc-expiry')}</label>
            <div className="custom-input-container">
              <CardExpiryElement options={stripeOptions} />
            </div>
          </fieldset>
          <fieldset>
            <label>{t('cc-cvc')}</label>
            <div className="custom-input-container">
              <CardCvcElement options={stripeOptions} />
            </div>
          </fieldset>
        </fieldset>
      </div>
      <div className="buttons-forward-back">
        <LoadingSubmitButton data={{ disabled: !!!fullName, loading: submitting, text: t('finish'), className: 'button-medium main-button' }} />
        <button type="button" onClick={onCancel} className="button-medium naked-button">
          {t('cancel')}
        </button>
      </div>
    </form>
  )
}

export { CardSetupForm as default }
