import { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import ActionBar from '../../components/customer/ActionBar'
import { useTranslation } from 'react-i18next'
import AccountSuspendedNotice from '../../components/customer/AccountSuspendedNotice'
import { LocationData } from '../../components/admin/LocationCard'
import { LocationCoords } from './ReturnItemLocation'
import { getDistance } from 'geolib'
import { useAPI } from '../../api/api'
import Card from '../../components/LocationCard'
import ErrorElement from '../../components/ErrorElement'
import { ReactComponent as MapPinIcon } from '../../icons/map-pin.svg'
import './ReturnMap.css'
import { LoadingSubmitButton } from '../../components/LoadingButton'
import { useHistory } from 'react-router'
import { CustomerPaths } from '../../routes/RoutePaths'
import { QrScanner } from '../../components/QrScanner'
import { Link } from 'react-router-dom'
import SplashScreen from '../../components/SplashScreen'

export const MapMarker = ({ text, user, zoomLvl }: any) => (
  <div className={user ? 'map-marker user ' : 'map-marker'}>
    <MapPinIcon />
    <label style={{ opacity: zoomLvl > 13 ? 100 : 0 }}>{text}</label>
  </div>
)

const defaultLocation = { latitude: 45.5009162, longitude: -73.5660632 }

const ReturnMap = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  let history = useHistory()

  let defaultZoomLvl = 14

  let { errors, setErrors, currentPosition, getCurrentPosition, wrappedExtendedQueryAuth } = useAPI()

  const [locations, setLocations] = useState<LocationData[]>([])
  const [selectedLocation, setSelectedLocation] = useState<LocationCoords>()
  const [showScanner, setShowScanner] = useState(false)
  const [map, setMap] = useState<HTMLElement | null>(null)
  const [zoomLvl, setZoomLvl] = useState(defaultZoomLvl)

  useEffect(() => {
    async function onLoad() {
      await getCurrentPosition()
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onLocationSet() {
      const query = `query { dropOffLocations (filter: {status: "active"}) { _id name status capacity quantity address workTime website coordinates { latitude longitude } } }`
      const factory = (result: any): LocationData[] => {
        return result.dropOffLocations
          .map((l: any) => ({
            distance: currentPosition ? getDistance(currentPosition, l.coordinates) : null,
            freeCapacity: l.capacity - l.quantity,
            ...l,
          }))
          .sort((a: any, b: any) => (a.distance > b.distance ? 1 : -1))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setLocations(response)
      }
    }
    onLocationSet().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition])

  const onClick = (e: any) => {
    setShowScanner(true)
  }
  const onHowToClick = () => {
    history.push('/customer/how-to-return')
  }

  const onQrCode = async (code: string) => {
    setShowScanner(false)

    if (!code.includes('locations/')) {
      setErrors([{ msg: t('invalid-location-qr-code') }])
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    console.debug('QR', qr)

    const loc = locations.filter((l: any) => l._id === qr)[0]

    if (loc.freeCapacity > 0) {
      history.push(CustomerPaths.ReturnItem, { ...loc })
    } else {
      setErrors([{ msg: t('locations.location-full') }])
    }
  }

  let defaultProps = {
    center: {
      lat: selectedLocation?.latitude,
      lng: selectedLocation?.longitude,
    },
    zoom: defaultZoomLvl,
  }

  const handleLocationChange = (e: any, { latitude, longitude }: any) => {
    if (map) {
      map.scrollIntoView()
    }
    setSelectedLocation({ latitude, longitude })
    setZoomLvl(defaultZoomLvl)
  }

  const handleZoomChange = (zoom: number) => {
    setZoomLvl(zoom)
  }

  return (
    <>
      {showScanner && (
        <section className="qrScanner">
          <QrScanner callback={onQrCode} callbackCancel={(e: any) => setShowScanner(false)}></QrScanner>
        </section>
      )}
      {!showScanner && (
        <>
          {/* <Navbar /> */}
          {isLoading ? (
            <SplashScreen />
          ) : (
            <>
              <div className="map" ref={setMap}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: process.env.REACT_APP_GMAPS_KEY || 'AIzaSyDjf0EaTnMLSYZpQq-v0VLrMbaN8GwldBY' }}
                  defaultCenter={{
                    lat: currentPosition?.latitude || defaultLocation.latitude,
                    lng: currentPosition?.longitude || defaultLocation.longitude,
                  }}
                  defaultZoom={defaultProps.zoom}
                  center={{
                    lat: selectedLocation?.latitude || currentPosition?.latitude || defaultLocation.latitude,
                    lng: selectedLocation?.longitude || currentPosition?.longitude || defaultLocation.longitude,
                  }}
                  options={() => {
                    return {
                      gestureHandling: 'greedy',
                      zoomControl: false,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                    }
                  }}
                  onZoomAnimationStart={(zoom: number) => handleZoomChange(zoom)}
                  zoom={zoomLvl}
                >
                  {currentPosition && <MapMarker key="user" lat={currentPosition.latitude} lng={currentPosition.longitude} text="Your position" user={true} />}
                  {locations.map(location => (
                    <MapMarker key={location._id} lat={location.coordinates.latitude} lng={location.coordinates.longitude} text={location.name} zoomLvl={zoomLvl} />
                  ))}
                </GoogleMapReact>
              </div>
              <section className="HomeSection WithNavbar OnMap">
                <header className="map-header">
                  <h1>{t('nearest-drop-off-points')}</h1>
                  <Link className="yellow-link" to="#" onClick={onHowToClick} replace>
                    {t('how-to-return')}
                  </Link>
                </header>
                <AccountSuspendedNotice />
                <ErrorElement errors={errors} />
                <LoadingSubmitButton data={{ className: 'midBtn', text: t('scan-drop-off-qr-code'), onClick: onClick }} />
                {locations.length === 0 ? (
                  <p className="empty-list-msg">{t('empty.locations')}</p>
                ) : (
                  locations.map(location => (
                    <Card
                      key={location._id}
                      data={{
                        labelMain: location.name,
                        labelSub1: location.distance && (location.distance >= 1000 ? `${(location.distance / 1000).toFixed(1).toString()} km` : `${location.distance.toString()} m`) + ` ${t('away')}`,
                        labelSub4: location.workTime,
                        labelSub3: location.address,
                        labelSub2: location.freeCapacity && location.freeCapacity > 0 ? `${t('free-capacity', { freeCapacity: location.freeCapacity })}` : t('locations.location-full'),
                        website: location.website,
                        onClick: e => handleLocationChange(e, location.coordinates),
                      }}
                    />
                  ))
                )}
              </section>
            </>
          )}
          <ActionBar />
        </>
      )}
    </>
  )
}

export { ReturnMap as default }
