import { useEffect, useState } from 'react'
import Select from 'react-select'

const CountrySelector = ({ className, onMainChange }: { className?: string; onMainChange: any }) => {
  const [value] = useState([{ label: 'Canada', value: 'CA' }])

  useEffect(() => {
    onMainChange(value[0].value)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return <Select className={`select ${className}`} classNamePrefix="select" isDisabled options={value} value={value} />
}

export { CountrySelector as default }
