import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router'
import { useAPI } from '../../../api/api'
import BackButton from '../../../components/BackButton'
import ErrorElement from '../../../components/ErrorElement'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import { setNotification } from '../../../components/Notification'
import SplashScreen from '../../../components/SplashScreen'

type MerchantQuantitiesData = {
  _id: string
  isApproved: boolean
  minQuantity: number
  maxQuantity: number
  totalQuantity: number
  frequency: number
}

const EditMerchantQuantities = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  let { submitting, errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const [isApproved, setIsApproved] = useState(true)
  const [minQuantity, setMinQuantity] = useState(0)
  const [maxQuantity, setMaxQuantity] = useState(0)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [frequency, setFrequency] = useState(0)

  const { id }: any = useParams()

  let history = useHistory()

  useEffect(() => {
    async function onLoad() {
      if (!id) {
        return
      }

      const query = `query {
        merchants (filter: {_id : "${id}"}){_id email businessName isApproved minQuantity maxQuantity totalQuantity frequency }
      }`
      const factory = (result: any): MerchantQuantitiesData => {
        return {
          _id: result.merchants[0]._id,
          isApproved: result.merchants[0].isApproved,
          minQuantity: result.merchants[0].minQuantity || 0,
          maxQuantity: result.merchants[0].maxQuantity || 0,
          totalQuantity: result.merchants[0].totalQuantity || 0,
          frequency: result.merchants[0].frequency || 0,
        }
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setIsApproved(response.isApproved)
        setMinQuantity(response.minQuantity)
        setMaxQuantity(response.maxQuantity)
        setTotalQuantity(response.totalQuantity)
        setFrequency(response.frequency)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (e: any) => {
    e.preventDefault()
    const mutation = `mutation { approveMerchant( input: { id: "${id}" minQuantity: ${minQuantity} maxQuantity: ${maxQuantity} totalQuantity: ${totalQuantity} frequency: ${frequency} } )}`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setNotification('merchants.updated-quantities')
      history.goBack()
    }
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <form onSubmit={onSubmit} className="object-form">
      <BackButton />
      <h2>{isApproved ? t('merchants.edit-quantities') : t('merchants.approve')}</h2>
      <ErrorElement errors={errors} />
      <label>{t('merchants.minimum-quantity')}</label>
      <input type="number" value={minQuantity} onChange={e => setMinQuantity(parseInt(e.target.value))} required={true}></input>
      <label>{t('merchants.maximum-quantity')}</label>
      <input type="number" value={maxQuantity} onChange={e => setMaxQuantity(parseFloat(e.target.value))} required={true}></input>
      <label>{t('merchants.total-quantity')}</label>
      <input type="number" value={totalQuantity} onChange={e => setTotalQuantity(parseFloat(e.target.value))} required={true}></input>
      <LoadingSubmitButton data={{ loading: submitting, text: t('save') }} />
    </form>
  )
}
export { EditMerchantQuantities as default }
