import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAPI } from '../../api/api'
import ConfirmDialog from '../Dialog'
import { setNotification } from '../Notification'
import './ArchiveUser.css'

const ArchiveUser = ({ id }: { id: string }) => {
  const { t } = useTranslation()
  let history = useHistory()

  let { wrappedExtendedMutateAuth } = useAPI()
  const [showDialog, setShowDialog] = useState(false)

  const handleDelete = () => {
    setShowDialog(true)
  }

  const dialogCancel = (e: any) => {
    setShowDialog(false)
  }

  const dialogSubmit = async (e: any) => {
    const mutation = `mutation {archiveUser(input: "${id}")}`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setNotification(`${t('user-deactivated')}`)
      history.goBack()
    }
  }

  return (
    <div className="archive-user">
      <label onClick={handleDelete}>{t('deactivate-user')}</label>
      <ConfirmDialog data={{ title: t('deactivate-user'), cancelHook: dialogCancel, submitHook: dialogSubmit, text: t('deactivate-user-confirm'), show: showDialog }} />
      <hr />
    </div>
  )
}

export { ArchiveUser as default }
