import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CardData } from './Card'
import './Card.css'

const CardWithIcon = ({ data }: { data: CardData }) => {
  const { t } = useTranslation()
  const renderMain = () => {
    return (
      <>
        <div className="icon-container">
          <img className="item-icon" alt="item-icon" src={data.iconUrl || t('no-image-available')} />
        </div>
        <div className={`card-info info-with-icon ${data.invoiceUrl && 'error-color'}`}>
          <div className="card-row due-date">
            <h3 className="name">{data.labelMain}</h3>
            {data.labelTopRight && <label className="quantity">{data.labelTopRight}</label>}
          </div>
          {(data.labelSub1 || data.labelSub2 || data.labelBotRight || data.iconBotRight) && <hr />}
          {data.labelSub1 && <label className="dimensions">{data.labelSub1}</label>}
          <div className="card-row">
            {data.labelSub2 && <label className="capacity multi-line">{data.labelSub2}</label>}
            {data.labelBotRight && <label className="status">{data.labelBotRight}</label>}
            {data.iconBotRight}
          </div>
          {data.invoiceUrl && (
            <Link to="#" onClick={() => data.onInvoiceClick(data.invoiceUrl)} className="invoice yellow-link">
              {t('invoice')}
            </Link>
          )}
        </div>
        {data.count && <div className="count-circle">{data.count}</div>}
      </>
    )
  }
  return data.linkTo ? (
    <Link to={data.linkTo} className="card card-with-icon">
      {renderMain()}
    </Link>
  ) : data.onClick ? (
    <div className="card card-with-icon" onClick={e => data.onClick!(e)}>
      {renderMain()}
    </div>
  ) : (
    <div className="card card-with-icon">{renderMain()}</div>
  )
}

export { CardWithIcon as default }
