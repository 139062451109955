import { FormEvent, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import BackButton from '../../components/BackButton'
import { QrScanner } from '../../components/QrScanner'

import { ReactComponent as ErrorIcon } from '../../icons/error.svg'
import { ReactComponent as ErrorIcon2 } from '../../icons/error-2.svg'
import { useTranslation } from 'react-i18next'
import { ClientForSearchData } from './AssignItemCustomer'
import { useAPI } from '../../api/api'
import { setNotification } from '../../components/Notification'
import AddAndCompleteElement from '../../components/buttons/AddAndCompleteElement'

import '../CategoryHome.css'
import './AssignItems.css'
import CardWithIcon from '../../components/CardWithIcon'
import { CustomDialog } from '../../components/Dialog'
import SplashScreen from '../../components/SplashScreen'
import { APIError } from '../../api/response'

export type ItemToAssignData = {
  id: string
  itemTypeId: string
  label: string
  iconUrl: string
  uuid: string
  status?: string
  capacity: number
  dimensions: any
  client: any
  merchant: any
  depositPrice: any
  serialNumber?: string | null
  dueDate?: string | null
}

type ItemListData = {
  _id: string
  itemTypeIcon: string
  items: ItemToAssignData[]
}

const AssignItems = () => {
  const { t } = useTranslation()

  let history = useHistory()

  let { submitting, errors, setErrors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const location = useLocation()

  const client: ClientForSearchData = location.state as any as ClientForSearchData

  const auth = localStorage.getItem('auth')
  let merchantBoId: string

  if (auth) {
    merchantBoId = JSON.parse(auth).boId
  }

  const [err, setErr] = useState<APIError[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [clientId, setClientId] = useState<string | undefined>(undefined)
  const [addedItems, setAddedItems] = useState<ItemToAssignData[]>([])
  const [itemListByType, setItemListByType] = useState<ItemListData[]>([])
  const [lastScannedItem, setLastScannedItem] = useState<ItemToAssignData | undefined>()

  const [showScanner, setShowScanner] = useState(true)

  const [isScanTypeRemove, setIsScanTypeRemove] = useState(false)

  const [itemTypeDialogData, setItemTypeDialogData] = useState<JSX.Element | undefined>(undefined)
  const [itemTypeDialogTitle, setItemTypeDialogTitle] = useState('')
  const [showItemTypeDialog, setShowItemTypeDialog] = useState(false)

  const [tryAgain, setTryAgain] = useState(false)
  const [tryAgainDialogData, setTryAgainDialogData] = useState<JSX.Element | undefined>(undefined)
  const [showTryAgainDialog, setShowTryAgainDialog] = useState(false)
  const [border, setBorder] = useState('')

  useEffect(() => {
    async function onLoad() {
      setClientId(client.id)
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onQrCode = async (code: string) => {
    onQrCodeRead(code).then(() => setIsLoading(false))
  }

  const onQrCodeRead = async (code: string) => {
    setIsLoading(true)
    setLastScannedItem(undefined)
    setShowScanner(false)

    if (!code.includes('items/')) {
      setBorder('2px solid #ec4242')
      setErr([{ msg: t('invalid-item-qr-code') }])
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    const query = ` query { 
      itemsById (filter : {uuid : "${qr}"}) { _id uuid status itemType { _id name iconUrl } client { boId } merchant { boId }}
      merchantInventories { inventoryItems { itemType { _id } quantity } } 
  }`
    const factory = (result: any): { item: ItemToAssignData; inventory: any } => {
      return {
        item:
          Array.isArray(result.itemsById) && result.itemsById.length > 0
            ? result.itemsById.map((o: any) => ({ id: o._id, label: o.itemType.name, itemTypeId: o.itemType._id, iconUrl: o.itemType.iconUrl, uuid: o.uuid, status: o.status, client: o.client, merchant: o.merchant }))[0]
            : undefined,
        inventory: Array.isArray(result.merchantInventories) && result.merchantInventories.length > 0 ? result.merchantInventories[0].inventoryItems : [],
      }
    }

    const { item, inventory }: { item: ItemToAssignData; inventory: any } = await wrappedExtendedQueryAuth(query, factory)

    setLastScannedItem(item)

    if (!item) {
      setBorder('2px solid #ec4242')
      setErrors([{ msg: t('item-does-not-exist') }])
      return
    }

    if (
      !inventory.find((i: any) => {
        return i.itemType._id === item.itemTypeId && i.quantity
      })
    ) {
      setBorder('2px solid #ec4242')
      setErrors([{ msg: `${t('merchant-quantity-would-be-below-zero')}` }])
      return
    }

    switch (item.status) {
      case 'unassigned':
      case 'returned':
      case 'deposit':
      case 'on-consignment':
        setBorder('2px solid #43be40')
        break
      case 'assigned':
        if (item.merchant && item.merchant.boId === merchantBoId) {
          setBorder('2px solid #ffbb01')
          setErrors([{ msg: t('merchant-assign.item-assigned') }])
        } else {
          setBorder('2px solid #ec4242')
          setErrors([{ msg: t('merchant-assign.item-assigned-another-merchant') }])
        }
        return
      case 'pending-purchase':
      case 'purchased':
      case 'written-off':
      case 'lost':
      case 'deposit-returned':
        setBorder('2px solid #ec4242')
        setErrors([{ msg: t('merchant-assign.item-inactive') }])
        return
    }

    const alreadyExist = addedItems.find(q => q.id === item.id)
    if (alreadyExist) {
      setBorder('2px solid #ffbb01')
      setErrors([{ msg: t('item-aready-added') }])
      return
    }

    addToList(item)
  }

  const addToList = async (data: any) => {
    let index = itemListByType.findIndex(r => r._id === data.itemTypeId)
    if (index < 0) {
      setItemListByType(itemListByType.concat({ _id: data.itemTypeId, itemTypeIcon: data.iconUrl, items: [data] }))
      setAddedItems(addedItems.concat(data))
    } else {
      let itemIndex = itemListByType[index].items.findIndex(i => i.id === data._id)
      if (itemIndex < 0) {
        const tempItemList = itemListByType
        tempItemList[index].items.push(data)
        setItemListByType(tempItemList)
        setAddedItems(addedItems.concat(data))
      } else {
        setBorder('2px solid #ffbb01')
        setErrors([{ msg: t('item-aready-added') }])
      }
    }
  }

  const assignItemsToCustomer = async (e: FormEvent) => {
    onSubmit(e).then(() => setIsLoading(false))
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    if (!clientId || addedItems.length === 0) {
      setErr([{ msg: `${t('unknown-error-occured')}` }])
      return
    }

    setIsLoading(true)

    const ids: string[] = []
    addedItems.forEach(item => {
      ids.push(item.id)
    })

    const mutation = `mutation {assignItemsToClient(input: {ids: ${JSON.stringify(ids)} clientId: "${clientId}"}) {ids}}`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response.assignItemsToClient.ids.length > 0) {
      setShowTryAgainDialog(true)

      let tempData
      const tempList = addedItems.filter(item => response.assignItemsToClient.ids.includes(item.id))

      if (!tryAgain) {
        tempData = () => {
          return (
            <div className="type-cards">
              <label>{t('bulk-assign-failed')}</label>
              <hr />
              {tempList.map((i: ItemToAssignData) => (
                <div className="scanned-item-uuid" key={i.uuid}>
                  <label>{i.uuid}</label>
                  <ErrorIcon2 fill="red" height={12} onClick={() => handleRemoveFromList(i.uuid)} />
                </div>
              ))}
            </div>
          )
        }
        setAddedItems(tempList)
        setTryAgain(true)
      } else {
        setShowTryAgainDialog(false)
        tempData = () => {
          return (
            <div className="type-cards">
              <label>{t('reset-contact-bo')}</label>
              <hr />
              {tempList.map((i: ItemToAssignData) => (
                <div className="scanned-item-uuid" key={i.uuid}>
                  <label>{i.uuid}</label>
                </div>
              ))}
            </div>
          )
        }
        setTryAgain(false)
        setAddedItems([])
        setItemListByType([])
      }

      setTryAgainDialogData(tempData)

      return
    } else {
      setNotification(t('item-successfully-assigned'))
      history.goBack()
    }
  }

  const onCancel = (e: any) => {
    if (addedItems.length === 0) {
      history.goBack()
    }

    setShowScanner(false)
  }

  const scanNew = () => {
    setIsScanTypeRemove(false)
    setErr([])
    setErrors([])
    setShowScanner(true)
  }

  const onItemTypeClick = (items: ItemToAssignData[]) => {
    if (items.length > 0) {
      setItemTypeDialogTitle(items[0].label)
      setShowItemTypeDialog(true)
    } else {
      setItemTypeDialogTitle('')
      setShowItemTypeDialog(false)
    }
  }

  const handleRemoveFromList = async (uuid: string) => {
    setErr([])
    setErrors([])
    setAddedItems(addedItems.filter(item => item.uuid !== uuid))

    let x: ItemListData[] = []

    for (const type of itemListByType) {
      const y = type.items.filter(item => item.uuid !== uuid)
      x.push({ _id: type._id, itemTypeIcon: type.itemTypeIcon, items: y })
    }

    setItemListByType(x)
    setShowScanner(false)
  }

  useEffect(() => {
    const itemsByType = itemListByType.find(i => {
      if (i.items.length > 0 && i.items[0].label === itemTypeDialogTitle) return true
      else return false
    })
    if (itemsByType) {
      const tempData = () => {
        return (
          <div className="type-cards">
            {itemsByType.items.map((i: any) => (
              <div className="scanned-item-uuid" key={i.uuid}>
                <label>{i.uuid}</label>
                <ErrorIcon2 fill="red" height={12} onClick={() => handleRemoveFromList(i.uuid)} />
              </div>
            ))}
          </div>
        )
      }
      setItemTypeDialogData(tempData)
    } else {
      setItemTypeDialogData(undefined)
      setShowItemTypeDialog(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTypeDialogTitle, itemListByType, showItemTypeDialog])

  const scanToRemove = () => {
    setErr([])
    setErrors([])
    setIsScanTypeRemove(true)
    setShowScanner(true)
  }

  const handleRemoveFromListByScan = async (code: string) => {
    if (!code.includes('items/')) {
      setErr([{ msg: t('invalid-item-qr-code') }])
      return
    }

    const url = new URL(code)
    const uuid = url.pathname.split('/').pop() || ''
    const index = addedItems.findIndex(r => r.uuid === uuid)
    if (index < 0) {
      setErr([{ msg: t('item-not-added') }])
      setShowScanner(false)
      return
    }

    setAddedItems(addedItems.filter(item => item.uuid !== uuid))
    let tempItemListByType: ItemListData[] = []

    for (const type of itemListByType) {
      const y = type.items.filter(item => item.uuid !== uuid)
      tempItemListByType.push({ _id: type._id, itemTypeIcon: type.itemTypeIcon, items: y })
    }

    setErr([{ msg: t('item-removed-list') }])
    setItemListByType(tempItemListByType)
    setShowScanner(false)
  }

  const itemTypeDialogCancel = () => {
    setItemTypeDialogData(undefined)
    setItemTypeDialogTitle('')
    setShowItemTypeDialog(false)
  }

  const tryAgainDialogCancel = () => {
    setItemTypeDialogData(undefined)
    setTryAgainDialogData(undefined)
    setItemTypeDialogTitle('')
    setShowItemTypeDialog(false)
    setShowTryAgainDialog(false)
    setTryAgain(false)
    setAddedItems([])
    setItemListByType([])
  }

  return (
    <>
      {isLoading ? (
        <SplashScreen withFooter={false} />
      ) : (
        <>
          {showScanner && (
            <section className="qrScanner">
              <QrScanner callback={isScanTypeRemove ? handleRemoveFromListByScan : onQrCode} callbackCancel={onCancel}></QrScanner>
            </section>
          )}
          {!showScanner && (
            <form className="object-form">
              <BackButton />
              <div className="Header">
                {client && (
                  <>
                    <h2>
                      {t('assigning-to')} {client.name}
                    </h2>
                    <label>
                      {t('roles.customer')} {client.boId}
                    </label>
                  </>
                )}
              </div>
              {err.length > 0 ? (
                <>
                  <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginTop: 15 }}>
                    <ErrorIcon className="bo-fail" />
                    {err[err.length - 1].msg}
                  </div>
                  <hr />
                </>
              ) : (
                <hr />
              )}
              {lastScannedItem && (
                <>
                  <fieldset style={{ border }}>
                    <legend style={{ fontSize: 25, fontStyle: 'bold' }}>{t('last-scanned-item')}</legend>
                    <>
                      <hr />
                      <div className="container-info">
                        <div style={{ marginBottom: 10 }}>
                          <label> {lastScannedItem.label} </label>
                          <label>{lastScannedItem.uuid} </label>
                          {errors.length > 0 && (
                            <div style={{ display: 'flex', alignItems: 'center', gap: 5, marginTop: 5 }}>
                              <ErrorIcon className="bo-fail" />
                              {errors[errors.length - 1].msg}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  </fieldset>
                </>
              )}
              {addedItems.length > 0 && (
                <label className="scan-to-remove" onClick={scanToRemove}>
                  {t('scan-to-remove')}
                </label>
              )}
              {itemListByType &&
                itemListByType.length > 0 &&
                itemListByType.map(t => t.items && t.items.length > 0 && <CardWithIcon key={t._id} data={{ labelMain: `${t.items[0].label}`, count: t.items.length, iconUrl: t.itemTypeIcon, onClick: () => onItemTypeClick(t.items) }} />)}
              <div className="buttonDock"></div>
              <AddAndCompleteElement data={{ submitting, disabledComplete: addedItems.length > 0 ? false : true, type: 'button', onClickAdd: scanNew, onClickSubmit: assignItemsToCustomer }} />
            </form>
          )}
          <CustomDialog data={{ cancelHook: itemTypeDialogCancel, show: showItemTypeDialog, position: 'center', title: itemTypeDialogTitle ? itemTypeDialogTitle : '', content: itemTypeDialogData ? itemTypeDialogData : undefined }} />
          <CustomDialog
            data={{ cancelHook: tryAgainDialogCancel, show: showTryAgainDialog, position: 'center', title: t('attention'), content: tryAgainDialogData, buttonText: t('try-again'), submitHook: tryAgain ? assignItemsToCustomer : undefined }}
          />
        </>
      )}
    </>
  )
}

export { AssignItems as default }
