import { useEffect, useState } from 'react'
import { MerchantItemData } from '../../components/MerchantItem'
import Navbar from '../../components/Navbar'
import './MerchantHome.css'
import '../CategoryHome.css'
import ActionBar from '../../components/merchant/ActionBar'
import { useTranslation } from 'react-i18next'
import ErrorElement from '../../components/ErrorElement'
import { useAPI } from '../../api/api'
import CardWithIcon from '../../components/CardWithIcon'
import SplashScreen from '../../components/SplashScreen'
import { ReactComponent as QRIcon } from '../../icons/batches.svg'
import { useHistory } from 'react-router'
import { MerchantPaths } from '../../routes/RoutePaths'

const MerchantHome = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  let history = useHistory()

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  const [inventoryItems, setInventoryItems] = useState<MerchantItemData[]>([])

  useEffect(() => {
    async function onLoad() {
      const query = ` query { merchantInventories { merchant { email } inventoryItems { itemType { _id name iconUrl capacity dimensions { w h l } } quantity } } }`

      const factory = (result: any): MerchantItemData => {
        return result.merchantInventories[0]?.inventoryItems.map((i: any) => ({
          _id: i.itemType._id,
          quantity: i.quantity,
          ...i.itemType,
        }))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setInventoryItems(response)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar></Navbar>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <section className="HomeSection WithNavbar">
          <header>
            <h1>{t('inventory')}</h1>
          </header>
          <div className="check-status">
            <label className="yellow-link check-item" onClick={e => history.push(MerchantPaths.ReturnToInventory)}>
              <QRIcon fill="var(--color-primary)" />
              {t('return-to-inventory')}
            </label>
          </div>
          <ErrorElement errors={errors} />
          {inventoryItems.length === 0 ? (
            <p className="empty-list-msg">{t('empty.inventory')}</p>
          ) : (
            inventoryItems.map(item => (
              <CardWithIcon
                key={item._id}
                data={{
                  iconUrl: item.iconUrl,
                  labelMain: item.name,
                  labelTopRight: `${item.quantity} ${t('piece')}`,
                  labelSub1: item.dimensions && item.dimensions.w !== 0 && item.dimensions.h !== 0 && item.dimensions.l !== 0 ? `${item.dimensions.w} x ${item.dimensions.h} x ${item.dimensions.l} in` : undefined,
                  labelSub2: item.capacity ? `${item.capacity} oz` : undefined,
                }}
              />
            ))
          )}
        </section>
      )}
      <ActionBar></ActionBar>
    </>
  )
}

export { MerchantHome as default }
