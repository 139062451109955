import { Redirect, Route } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'
import Items from '../containers/customer/Items'
import CustomerRegister from '../containers/customer/CustomerRegister'
import CustomerRegisterCC from '../containers/customer/CustomerRegisterCC'
import CustomerRegisterProfile from '../containers/customer/CustomerRegisterProfile'
import Merchants from '../containers/customer/Merchants'
import ReturnItem from '../containers/customer/ReturnItem'
import ReturnItemLocation from '../containers/customer/ReturnItemLocation'
import ReturnMap from '../containers/customer/ReturnMap'
import { CustomerPaths } from './RoutePaths'
import CheckItem from '../containers/customer/CheckItem'
import ImpactStats from '../containers/customer/ImpactStats'
import Borrow from '../containers/customer/Borrow'
import BorrowSuccess from '../containers/customer/BorrowSuccess'

const RedirectToItems = () => {
  return <Redirect to={CustomerPaths.Items} />
}

const routes = [
  <PrivateRoute key={CustomerPaths.Home} exact path={CustomerPaths.Home} component={RedirectToItems} />,
  <Route key={CustomerPaths.Register} exact path={CustomerPaths.Register} component={CustomerRegister} />,
  <PrivateRoute key={CustomerPaths.Items} exact path={CustomerPaths.Items} component={Items} />,
  <PrivateRoute key={CustomerPaths.RegisterProfile} exact path={CustomerPaths.RegisterProfile} component={CustomerRegisterProfile} />,
  <PrivateRoute key={CustomerPaths.RegisterCC} exact path={CustomerPaths.RegisterCC} component={CustomerRegisterCC} />,
  <PrivateRoute key={CustomerPaths.ReturnItemLocation} exact path={CustomerPaths.ReturnItemLocation} component={ReturnItemLocation} />,
  <PrivateRoute key={CustomerPaths.ReturnItem} exact path={CustomerPaths.ReturnItem} component={ReturnItem} />,
  <PrivateRoute key={CustomerPaths.ReturnMap} exact path={CustomerPaths.ReturnMap} component={ReturnMap} />,
  <PrivateRoute key={CustomerPaths.Merchants} exact path={CustomerPaths.Merchants} component={Merchants} />,
  <PrivateRoute key={CustomerPaths.CheckItem} exact path={CustomerPaths.CheckItem} component={CheckItem} />,
  <PrivateRoute key={CustomerPaths.MyImpact} exact path={CustomerPaths.MyImpact} component={ImpactStats} />,
  <PrivateRoute key={CustomerPaths.Borrow} exact path={CustomerPaths.Borrow} component={Borrow} />,
  <PrivateRoute key={CustomerPaths.BorrowSuccess} exact path={CustomerPaths.BorrowSuccess} component={BorrowSuccess} />,
]

export default routes
