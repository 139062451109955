import i18next from 'i18next'
import BackButton from '../../components/BackButton'
import Logo from '../../components/Logo'
import PrivacyPolicyEng from './PrivacyPolicies/PrivacyPolicyEng'
import PrivacyPolicyFr from './PrivacyPolicies/PrivacyPolicyFr'

const PrivacyPolicyPage = () => {
  const lang = i18next.language

  return (
    <main className="justify-normal">
      <BackButton />
      <Logo></Logo>
      {lang.indexOf('en') === -1 ? <PrivacyPolicyFr /> : <PrivacyPolicyEng />}
    </main>
  )
}

export { PrivacyPolicyPage as default }
