import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { LoadingSubmitButton } from '../LoadingButton'
import PhoneNumberInput from '../PhoneNumberInput'
import CountrySelector from '../CountrySelector'

type Address = {
  address: string
  city: string
  state: string
  postalCode: string
  country: string
}

type PrimaryContact = {
  firstName: string
  lastName: string
  phoneNumber: string
  address: Address
}

type MerchantProfile = {
  _id?: string
  businessName: string
  merchantWebsiteURL: string
  image: any
  phoneNumber: string
  businessAddress: Address
  primaryContact: PrimaryContact
  returnSite: boolean
  isInternalEcosystem: boolean
  displayed: boolean
}

const SetMerchantProfile = ({ profile, onSubmit, submitting, isAdmin }: any) => {
  const { t } = useTranslation()

  const [disabled, setDisabled] = useState(true)

  const [businessName, setBusinessName] = useState('')
  const [merchantWebsiteURL, setMerchantWebsiteURL] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [businessAddressAddress, setBusinessAddressAddress] = useState('')
  const [businessAddressCity, setBusinessAddressCity] = useState('')
  const [businessAddressState, setBusinessAddressState] = useState('')
  const [businessAddressPostalCode, setBusinessAddressPostalCode] = useState('')
  const [businessAddressCountry, setBusinessAddressCountry] = useState('')
  const [primaryContactFirstName, setPrimaryContactFirstName] = useState('')
  const [primaryContactLastName, setPrimaryContactLastName] = useState('')
  const [primaryContactPhoneNumber, setPrimaryContactPhoneNumber] = useState('')
  const [selectedFile, setSelectedFile] = useState()
  const [profileImageURL, setProfileImageURL] = useState('')
  const [isReturnSite, setIsReturnSite] = useState(false)
  const [isInternalEcosystem, setIsInternalEcosystem] = useState(false)
  const [displayed, setDisplayed] = useState(false)
  const [minQuantity, setMinQuantity] = useState(0)
  const [maxQuantity, setMaxQuantity] = useState(0)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [frequency, setFrequency] = useState(0)

  useEffect(() => {
    async function onLoad() {
      if (profile) {
        setBusinessName(profile.businessName)
        setMerchantWebsiteURL(profile.merchantWebsiteURL)
        setPhoneNumber(profile.phoneNumber)
        setBusinessAddressAddress(profile.businessAddress.address)
        setBusinessAddressCity(profile.businessAddress.city)
        setBusinessAddressState(profile.businessAddress.state)
        setBusinessAddressPostalCode(profile.businessAddress.postalCode)
        setBusinessAddressCountry(profile.businessAddress.country)
        setPrimaryContactFirstName(profile.primaryContact.firstName)
        setPrimaryContactLastName(profile.primaryContact.lastName)
        setPrimaryContactPhoneNumber(profile.primaryContact.phoneNumber)
        setIsReturnSite(profile.returnSite)
        setIsInternalEcosystem(profile.isInternalEcosystem)
        setProfileImageURL(profile.profileImageURL)
        setMinQuantity(profile.minQuantity)
        setMaxQuantity(profile.maxQuantity)
        setTotalQuantity(profile.totalQuantity)
        setFrequency(profile.frequency)
        setDisplayed(profile.displayed)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onLoad() {
      if (profileImageURL || selectedFile) {
        setDisabled(false)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileImageURL, selectedFile])

  const onSubmitRaw = (e: any) => {
    e.preventDefault()
    onSubmit({
      businessName,
      merchantWebsiteURL,
      profileImageURL,
      phoneNumber,
      businessAddress: {
        address: businessAddressAddress,
        city: businessAddressCity,
        state: businessAddressState,
        postalCode: businessAddressPostalCode,
        country: businessAddressCountry,
      },
      primaryContact: {
        firstName: primaryContactFirstName,
        lastName: primaryContactLastName,
        phoneNumber: primaryContactPhoneNumber,
      },
      image: selectedFile,
      returnSite: isReturnSite,
      isInternalEcosystem,
      minQuantity,
      maxQuantity,
      totalQuantity,
      frequency,
      displayed,
    })
  }

  const onCountryChange = (value: any) => {
    setBusinessAddressCountry(value)
  }

  const onImageChange = (event: any) => {
    setSelectedFile(event.target.files[0])
  }

  return (
    <form onSubmit={onSubmitRaw} className="profile">
      <div className="search-column">
        <label>{t('business-name')}</label>
        <input value={businessName} onChange={e => setBusinessName(e.target.value)} required={true}></input>
      </div>
      <div
        className="show-password"
        onClick={() => {
          setIsReturnSite(!isReturnSite)
        }}
      >
        <label>{t('return-site')}</label>
        <input
          className="show-password"
          type="checkbox"
          checked={isReturnSite}
          onChange={() => {
            return
          }}
        />
      </div>
      <div
        className="show-password"
        onClick={() => {
          setDisplayed(!displayed)
        }}
      >
        <label>{t('display-merchant')}</label>
        <input
          className="show-password"
          type="checkbox"
          checked={displayed}
          onChange={() => {
            return
          }}
        />
      </div>
      <div
        className="show-password"
        onClick={() => {
          setIsInternalEcosystem(!isInternalEcosystem)
        }}
      >
        <label>{t('internal-ecosystem')}</label>
        <input
          className="show-password"
          type="checkbox"
          checked={isInternalEcosystem}
          onChange={() => {
            return
          }}
        />
      </div>
      <h3>{t('business-info')}</h3>
      <div className="full-column">
        <label>{t('website')}</label>
        <input type="url" value={merchantWebsiteURL} onChange={e => setMerchantWebsiteURL(e.target.value)} required={true}></input>
      </div>
      <div className="full-column">
        <label>{t('phone-number')}</label>
        <PhoneNumberInput value={phoneNumber} onChange={setPhoneNumber} required={true} />
      </div>
      <div className="full-column">
        <label>{t('address')}</label>
        <input value={businessAddressAddress} onChange={e => setBusinessAddressAddress(e.target.value)} required={true}></input>
      </div>
      <div className="half-column">
        <label>{t('city')}</label>
        <input value={businessAddressCity} onChange={e => setBusinessAddressCity(e.target.value)} required={true}></input>
      </div>
      <div className="half-column">
        <label>{t('state')}</label>
        <input value={businessAddressState} onChange={e => setBusinessAddressState(e.target.value)} required={true}></input>
      </div>
      <div className="half-column">
        <label>{t('postal-code')}</label>
        <input value={businessAddressPostalCode} onChange={e => setBusinessAddressPostalCode(e.target.value)} required={true}></input>
      </div>
      <div className="half-column">
        <label>{t('country')}</label>
        <CountrySelector onMainChange={onCountryChange} />
      </div>
      <h3>{t('primary-contact-info')}</h3>
      <div className="half-column">
        <label>{t('first-name')}</label>
        <input value={primaryContactFirstName} onChange={e => setPrimaryContactFirstName(e.target.value)} required={true}></input>
      </div>
      <div className="half-column">
        <label>{t('last-name')}</label>
        <input value={primaryContactLastName} onChange={e => setPrimaryContactLastName(e.target.value)} required={true}></input>
      </div>
      <div className="full-column">
        <label>{t('phone-number')}</label>
        <PhoneNumberInput value={primaryContactPhoneNumber} onChange={setPrimaryContactPhoneNumber} required={true} />
      </div>
      {profileImageURL && (
        <div className="full-column">
          <label>{t('image.current-image')}</label>
          <br />
          <img className="profile-image" src={profileImageURL} alt="restaurant cover" />
        </div>
      )}
      <hr />
      <input type="file" name="file" accept="image/png, image/jpeg" onChange={onImageChange} />
      <hr />
      {isAdmin && (
        <>
          <label>{t('merchants.minimum-quantity')}</label>
          <input type="number" value={minQuantity} onChange={e => setMinQuantity(parseInt(e.target.value))} required={true}></input>
          <label>{t('merchants.maximum-quantity')}</label>
          <input type="number" value={maxQuantity} onChange={e => setMaxQuantity(parseFloat(e.target.value))} required={true}></input>
          <label>{t('merchants.total-quantity')}</label>
          <input type="number" value={totalQuantity} onChange={e => setTotalQuantity(parseFloat(e.target.value))} required={true}></input>
        </>
      )}
      <LoadingSubmitButton data={{ loading: submitting, disabled, text: t('save') }} />
    </form>
  )
}

export { SetMerchantProfile as default }
export type { MerchantProfile }
