import i18next from 'i18next'
import BackButton from '../../components/BackButton'
import Logo from '../../components/Logo'
import { TermsHtmlEng, TermsHtmlFr } from '../../components/TermsAndConditions'

const TermsPage = () => {
  const lang = i18next.language

  return (
    <main className="justify-normal">
      <BackButton />
      <Logo></Logo>
      {lang.indexOf('en') === -1 ? <TermsHtmlFr className="privacy-policy" /> : <TermsHtmlEng className="privacy-policy" />}
    </main>
  )
}

export { TermsPage as default }
