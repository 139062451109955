import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import './Deposit.css'
import FailImage from '../../images/fail.png'

const FailedDeposit = () => {
  let history = useHistory()
  let { t } = useTranslation(['translation', 'errors'])

  const onClick = () => {
    history.replace('/')
  }

  return (
    <main className="mainHome">
      <div className="screen-header">
        <img src={FailImage} alt="Red X" />
        <h1>{t('invalid-deposit')}</h1>
      </div>
      <p className="general-paragraph">
        <label>{t('invalid-deposit-description.0')}</label>
        <a href="/contact">{t('invalid-deposit-description.1')}</a>
        <label>{t('invalid-deposit-description.2')}</label>
      </p>
      <div className="footer-button-section">
        <button className="button-medium main-button" onClick={onClick}>
          {t('go-home')}
        </button>
      </div>
    </main>
  )
}

export { FailedDeposit as default }
