import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAPI } from '../../../api/api'
import BackButton from '../../../components/BackButton'
import ErrorElement from '../../../components/ErrorElement'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import { setNotification } from '../../../components/Notification'

const AddLocation = () => {
  const { t } = useTranslation()

  const history = useHistory()

  let { submitting, errors, wrappedExtendedMutateAuth } = useAPI()

  const [name, setName] = useState('')
  const [capacity, setCapacity] = useState(0)
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [address, setAddress] = useState('')
  const [workTime, setWorkTime] = useState('')
  const [website, setWebsite] = useState('')

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    const mutation = `mutation { createDropOffLocation ( input : { name: "${name}" capacity: ${capacity} address: "${address}" workTime: """${workTime}""" website: "${website}" coordinates: { latitude: ${latitude} longitude: ${longitude} } } ) { _id } }`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setNotification('locations.added')
      history.goBack()
    }
  }

  return (
    <form onSubmit={onSubmit} className="object-form">
      <BackButton />
      <h2>{t('locations.add')}</h2>
      <ErrorElement errors={errors} />
      <label>{t('name')}</label>
      <input value={name} onChange={e => setName(e.target.value)} required={true}></input>
      <label>{t('capacity')}</label>
      <input type="number" value={capacity} onChange={e => setCapacity(parseInt(e.target.value))} required={true}></input>
      <label>{t('address')}</label>
      <input value={address} onChange={e => setAddress(e.target.value)} required={true}></input>
      <label>{t('work-time')}</label>
      <textarea value={workTime} onChange={e => setWorkTime(e.target.value)} required={true}></textarea>
      <label>{t('coordinates.latitude')}</label>
      <input type="number" value={latitude} onChange={e => setLatitude(parseFloat(e.target.value))} required={true}></input>
      <label>{t('coordinates.longitude')}</label>
      <input type="number" value={longitude} onChange={e => setLongitude(parseFloat(e.target.value))} required={true}></input>
      <label>{t('website')}</label>
      <input type="url" value={website} onChange={e => setWebsite(e.target.value)} required={true}></input>
      <LoadingSubmitButton data={{ loading: submitting, text: t('save') }} />
    </form>
  )
}

export { AddLocation as default }
