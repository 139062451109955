import { AnimalTiersData, TiersData } from './statsTypes'

export enum EnergyTiers {
  NoTier = 'noTier',
  Water = 'water',
  PlasticBag = 'plasticBag',
  Home = 'home',
  Tree = 'tree',
}

export enum AnimalTiers {
  NoTier = 'noTier',
  Killifish = 'killifish',
  Sardine = 'sardine',
  Clownfish = 'clownfish',
  BlueTangFish = 'blueTangFish',
  Crab = 'crab',
  Lobster = 'lobster',
  Starfish = 'starfish',
  Octopus = 'octopus',
  Stingray = 'stingray',
  Penguin = 'penguin',
  Barracuda = 'barracuda',
  Turtle = 'turtle',
  Dolphin = 'doplhin',
  GreatWhiteShark = 'greatWhiteShark',
  Narwhal = 'narwhal',
}

enum ItemEmissionTiers {
  water = 12,
  plasticBag = 200,
  home = 5400,
  tree = 34500,
}

enum AnimalWeightTiers {
  killifish = 30,
  sardine = 100,
  clownfish = 200,
  blueTangFish = 600,
  crab = 800,
  lobster = 1500,
  starfish = 4000,
  octopus = 6000,
  stingray = 12000,
  penguin = 18000,
  barracuda = 24000,
  turtle = 50000,
  doplhin = 110000,
  greatWhiteShark = 700000,
  narwhal = 940000,
}

const weightConvert = (weight: number) => {
  let newWeight = ''

  if (weight >= 10000000) newWeight = `${Math.round(weight / 1000000)} t`
  else if (weight >= 10000) newWeight = `${Math.round(weight / 1000)} kg`
  else newWeight = `${weight} g`

  return newWeight
}

const getEnergyTier = async (carbonSaved: number): Promise<TiersData> => {
  let tier: string = ''
  let quantity: number = 0

  switch (true) {
    case carbonSaved >= ItemEmissionTiers.tree:
      tier = EnergyTiers.Tree
      quantity = Math.floor(carbonSaved / ItemEmissionTiers.tree)
      break
    case carbonSaved >= ItemEmissionTiers.home:
      tier = EnergyTiers.Home
      quantity = Math.floor(carbonSaved / ItemEmissionTiers.home)
      break
    case carbonSaved >= ItemEmissionTiers.plasticBag:
      tier = EnergyTiers.PlasticBag
      quantity = Math.floor(carbonSaved / ItemEmissionTiers.plasticBag)
      break
    case carbonSaved >= ItemEmissionTiers.water:
      tier = EnergyTiers.Water
      quantity = Math.floor(carbonSaved / ItemEmissionTiers.water)
      break
    default:
      tier = EnergyTiers.NoTier
      quantity = 0
      break
  }

  return { quantity, tier }
}

const getAnimalTier = async (plasticSaved: number): Promise<AnimalTiersData> => {
  let mainTier: TiersData = await calculateAnimalTier(plasticSaved, true)
  let secondaryTier: TiersData = { tier: AnimalTiers.NoTier, quantity: 0 }

  if (mainTier.leftover) secondaryTier = await calculateAnimalTier(mainTier.leftover)

  return { mainTier, secondaryTier }
}

const calculateAnimalTier = async (plasticSaved: number, calcLeftover: boolean = false): Promise<TiersData> => {
  let tier: string = ''
  let quantity: number = 0
  let leftover: number = 0

  switch (true) {
    case plasticSaved >= AnimalWeightTiers.narwhal:
      tier = AnimalTiers.Narwhal
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.narwhal)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.narwhal * quantity
      break
    case plasticSaved >= AnimalWeightTiers.greatWhiteShark:
      tier = AnimalTiers.GreatWhiteShark
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.greatWhiteShark)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.greatWhiteShark * quantity
      break
    case plasticSaved >= AnimalWeightTiers.doplhin:
      tier = AnimalTiers.Dolphin
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.doplhin)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.doplhin * quantity
      break
    case plasticSaved >= AnimalWeightTiers.turtle:
      tier = AnimalTiers.Turtle
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.turtle)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.turtle * quantity
      break
    case plasticSaved >= AnimalWeightTiers.barracuda:
      tier = AnimalTiers.Barracuda
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.barracuda)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.barracuda * quantity
      break
    case plasticSaved >= AnimalWeightTiers.penguin:
      tier = AnimalTiers.Penguin
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.penguin)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.penguin * quantity
      break
    case plasticSaved >= AnimalWeightTiers.stingray:
      tier = AnimalTiers.Stingray
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.stingray)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.stingray * quantity
      break
    case plasticSaved >= AnimalWeightTiers.octopus:
      tier = AnimalTiers.Octopus
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.octopus)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.octopus * quantity
      break
    case plasticSaved >= AnimalWeightTiers.starfish:
      tier = AnimalTiers.Starfish
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.starfish)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.starfish * quantity
      break
    case plasticSaved >= AnimalWeightTiers.lobster:
      tier = AnimalTiers.Lobster
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.lobster)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.lobster * quantity
      break
    case plasticSaved >= AnimalWeightTiers.crab:
      tier = AnimalTiers.Crab
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.crab)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.crab * quantity
      break
    case plasticSaved >= AnimalWeightTiers.blueTangFish:
      tier = AnimalTiers.BlueTangFish
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.blueTangFish)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.blueTangFish * quantity
      break
    case plasticSaved >= AnimalWeightTiers.clownfish:
      tier = AnimalTiers.Clownfish
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.clownfish)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.clownfish * quantity
      break
    case plasticSaved >= AnimalWeightTiers.sardine:
      tier = AnimalTiers.Sardine
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.sardine)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.sardine * quantity
      break
    case plasticSaved >= AnimalWeightTiers.killifish:
      tier = AnimalTiers.Killifish
      quantity = Math.floor(plasticSaved / AnimalWeightTiers.killifish)
      if (calcLeftover) leftover = plasticSaved - AnimalWeightTiers.killifish * quantity
      break
    default:
      tier = AnimalTiers.NoTier
      quantity = 0
      if (calcLeftover) leftover = plasticSaved
      break
  }

  return { tier, quantity, leftover }
}

export { weightConvert, getAnimalTier, getEnergyTier }
