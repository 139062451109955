import { useTranslation } from 'react-i18next'

const OrderDocumentHeader = () => {
  const { t } = useTranslation()

  return (
    <div className="header">
      <label className="general-label">{t('item')}</label>
      <label className="general-label">{t('quantity')}</label>
      <label className="general-label">{t('orders.unit-price')}</label>
      <label className="general-label">{t('orders.amount')}</label>
    </div>
  )
}

export default OrderDocumentHeader
