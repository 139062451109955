import './App.css'
import './components/Select.css'
import './Colors.css'
import './styles/Components.css'
import './styles/Toastify.css'
import Routes from './routes/Routes'
import { Suspense } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import './i18n'
import SplashScreen from './components/SplashScreen'

const App = () => {
  return (
    <div className="App">
      <Routes />
      <ToastContainer position="top-center" icon={false} autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
    </div>
  )
}

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
  return (
    <Suspense fallback={<SplashScreen withFooter={false} />}>
      <App />
    </Suspense>
  )
}
