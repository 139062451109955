import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BackButton from '../../../components/BackButton'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import '../../CategoryHome.css'
import { OrderCardData } from './Orders'
import ErrorElement from '../../../components/ErrorElement'
import { formatDate } from '../../../api/utils'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import { setNotification } from '../../../components/Notification'
import OrderDocumentHeader from '../../../components/orderDocument/OrderDocumentHeader'
import OrderDocumentTableRow from '../../../components/orderDocument/OrderDocumentTableRow'
import OrderDocumentTotals from '../../../components/orderDocument/OrderDocumentTotals'
import SplashScreen from '../../../components/SplashScreen'

const Order = () => {
  const [isLoading, setIsLoading] = useState(true)
  let history = useHistory()
  const { t } = useTranslation()

  let { submitting, errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const { id }: any = useParams()

  const [order, setOrder] = useState<OrderCardData | undefined>(undefined)
  const [totalAmount, setTotalAmount] = useState(0)

  const factory = (result: any): OrderCardData => {
    return Array.isArray(result.orders) && result.orders.length > 0
      ? result.orders.map((o: any) => ({
          _id: o._id,
          orderType: o.orderType,
          status: o.status,
          createdAt: o.createdAt / 1000,
          totalDiscount: o.totalDiscount / 100,
          orderItems: o.orderItems.map((j: any) => ({
            id: j.itemType._id,
            name: j.itemType.name,
            quantity: j.quantity,
            price: j.price / 100,
          })),
        }))[0]
      : undefined
  }

  useEffect(() => {
    async function onLoad() {
      const query = `query { orders ( filter: { _id: "${id}" } ) { _id createdAt status orderType paid totalDiscount orderItems { itemType { _id name } quantity price } } }`

      const response = await wrappedExtendedQueryAuth(query, factory)
      if (response) {
        setOrder(response)

        let amount = 0
        for (const item of response.orderItems) {
          amount += item.quantity * item.price
        }

        setTotalAmount(amount)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onClick = async (e: any) => {
    e.preventDefault()

    const mutation = `mutation { confirmOrderReceived( id: "${id}" ) { _id } }`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setNotification('orders.confirmed')
      history.goBack()
    }
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <>
      {order && (
        <>
          <section className="HomeSection Order">
            <BackButton></BackButton>
            <div className="edit">
              <h2 className="name">#BO-{order._id.toUpperCase().slice(0, 8)}</h2>
            </div>
            <ErrorElement errors={errors} />
            <div className="InfoRow">
              <div>
                <div>{t('date')}</div>
                <label className="dueDate">{formatDate(order.createdAt)}</label>
              </div>
              <div>
                <div>{t('status.status')}</div>
                <label>{t(`status.${order.status}`)}</label>
              </div>
              <div>
                <div>{t('orders.order-type')}</div>
                <label>{t(`orders.${order.orderType}`)}</label>
              </div>
            </div>
            <article className="orderDocument">
              <OrderDocumentHeader />
              {order.orderItems.map((item, index) => {
                if (item.quantity !== 0) {
                  return OrderDocumentTableRow({ data: { index: item.id + index, label: item.name, quantity: item.quantity, price: item.price } })
                }
                return null
              })}
              <OrderDocumentTotals data={{ totalAmount, totalDiscount: order.totalDiscount }} />
            </article>
            <hr />
            {order.status === 'shipped' && <LoadingSubmitButton data={{ loading: submitting, text: t('orders.confirm-order-received'), onClick: onClick }} />}
          </section>
        </>
      )}
    </>
  )
}

export { Order as default }
