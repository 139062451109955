import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import SuccessImage from '../../images/success-stars.png'
import Clock from 'react-live-clock'
import i18n from '../../i18n'
import { useEffect, useState } from 'react'
import { ItemToAssignData } from '../merchant/AssignItems'
import SplashScreen from '../../components/SplashScreen'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

type BorrowedData = {
  itemListByType: ItemListData[]
}

type ItemListData = {
  _id: string
  itemTypeIcon: string
  items: ItemToAssignData[]
}

const BorrowSuccess = () => {
  let { t } = useTranslation()
  let history = useHistory()
  let location = useLocation()

  const lang = i18n.language
  const passedData = location.state as any as BorrowedData

  const [isLoading, setIsLoading] = useState(true)
  const [itemListByType, setItemListByType] = useState<ItemListData[]>([])
  const [sliderValue, setSliderValue] = useState(0)
  const [totalItems, setTotalItems] = useState(0)

  useEffect(() => {
    async function onLoad() {
      if (passedData && passedData.itemListByType && passedData.itemListByType?.length > 0) {
        setItemListByType(passedData.itemListByType)
        let count = 0
        passedData.itemListByType.forEach(itemType => {
          count += itemType.items.length
        })

        setTotalItems(count)
      } else {
        history.replace('/')
      }
    }

    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSlideChange = (e: any) => {
    setSliderValue(e)
  }

  const onSlideChangeComplete = (e: any) => {
    if (e === 100) history.replace('/')
    else setSliderValue(0)
  }

  return (
    <>
      {isLoading ? (
        <SplashScreen withFooter={false} />
      ) : (
        <main className="mainHome">
          <div className="screen-header">
            <img src={SuccessImage} alt="Green tick" />
            <h1>{t('borrow-successful')}</h1>
          </div>
          <div className="support-code-container">
            <div className="support-code-content">
              <div className="timer">
                <Clock className="timer-date" format={'dddd, Do MMM'} ticking={true} locale={lang} />
                <Clock className="timer-time" format={'HH:mm:ss'} ticking={true} />
              </div>
              <div>
                <label>{t('show-to-cashier')}</label>
              </div>
            </div>
          </div>
          <div className="items-overview">
            <div className="items-overview-header">
              <label>{t('item')}</label>
              <label>{t('quantity')}</label>
            </div>
            <div className="items-overview-listed deposit-item-list self-borrow-list">
              {itemListByType.map(type => (
                <div key={type.items[0].label}>
                  <div className="categorized-item">
                    <div className="categorized-item-info">
                      <div className="categorized-item-type-info">
                        <img className="item-icon" alt="item-icon" src={type.itemTypeIcon || t('no-image-available')} />
                        <label>{type.items[0].label}</label>
                      </div>
                      <div className="item-type-count">
                        <label>{type.items.length}</label>
                      </div>
                    </div>
                  </div>
                  <div className="custom-divider"></div>
                </div>
              ))}
            </div>
            <div className="items-overview-totals">
              <label>{t('orders.total')}</label>
              <label>{totalItems}</label>
            </div>
          </div>
          <div className="footer-button-section">
            <Slider className="swipe-button" onChange={e => onSlideChange(e)} onChangeComplete={e => onSlideChangeComplete(e)} value={sliderValue} />
            <label className="swipe-button-label">{t('swipe-to-confirm')}</label>
          </div>
        </main>
      )}
    </>
  )
}

export { BorrowSuccess as default }
