import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../api/api'
import BackButton from '../../components/BackButton'
import AddAndCompleteElement from '../../components/buttons/AddAndCompleteElement'
import CardWithIcon from '../../components/CardWithIcon'
import ConfirmDialog, { CustomDialog } from '../../components/Dialog'
import ErrorElement from '../../components/ErrorElement'
import { QrScanner } from '../../components/QrScanner'
import { ReactComponent as ErrorIcon2 } from '../../icons/error-2.svg'
import './CheckItem.css'

type Data = {
  _id: string
  uuid: string
  dueDate?: string
  itemTypeName: string
  itemTypeId?: string
  itemTypeIcon?: string
  status: string
  client?: {
    boId?: string
    email: string
    firstName?: string
    lastName?: string
  }
  merchant?: {
    boId?: string
    email: string
    name?: string
  }
}

type ItemListData = {
  _id: string
  itemTypeIcon: string
  items: Data[]
}

const CheckItem = () => {
  const { t } = useTranslation()

  let { errors, submitting, setErrors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const [showScanner, setShowScanner] = useState(true)
  const [showDialog, setShowDialog] = useState(false)
  const [isScanTypeRemove, setIsScanTypeRemove] = useState(false)

  const [itemList, setItemList] = useState<Data[]>([])
  const [itemListByType, setItemListByType] = useState<ItemListData[]>([])

  const [itemTypeDialogData, setItemTypeDialogData] = useState<JSX.Element | undefined>(undefined)
  const [itemTypeDialogTitle, setItemTypeDialogTitle] = useState('')
  const [showItemTypeDialog, setShowItemTypeDialog] = useState(false)

  const [tryAgain, setTryAgain] = useState(false)
  const [tryAgainDialogData, setTryAgainDialogData] = useState<JSX.Element | undefined>(undefined)
  const [showTryAgainDialog, setShowTryAgainDialog] = useState(false)

  const [msg, setMsg] = useState('')

  const [data, setData] = useState<Data | undefined | null>(undefined)

  const addToList = async (data: any) => {
    let index = itemListByType.findIndex(r => r._id === data.itemTypeId)
    if (index < 0) {
      setItemListByType(itemListByType.concat({ _id: data.itemTypeId, itemTypeIcon: data.itemTypeIcon, items: [data] }))
      setItemList(itemList.concat(data))
    } else {
      let itemIndex = itemListByType[index].items.findIndex(i => i._id === data._id)
      if (itemIndex < 0) {
        const tempItemList = itemListByType
        tempItemList[index].items.push(data)
        setItemListByType(tempItemList)
        setItemList(itemList.concat(data))
      } else {
        setErrors([{ msg: t('item-aready-added') }])
      }
    }
  }

  const onQrCode = async (code: string) => {
    setShowScanner(false)

    if (!code.includes('items/')) {
      setErrors([{ msg: t('invalid-item-qr-code') }])
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    const query = `query { itemsById (filter : {uuid : "${qr}"}) { _id uuid status dueDate itemType { _id name iconUrl } client { boId firstName lastName email } merchant { boId name email}}}`
    const factory = (result: any): Data => {
      return Array.isArray(result.itemsById) && result.itemsById.length > 0
        ? result.itemsById.map((o: any) => ({
            _id: o._id,
            uuid: o.uuid,
            itemTypeName: o.itemType.name,
            itemTypeId: o.itemType._id,
            itemTypeIcon: o.itemType.iconUrl,
            status: o.status || undefined,
            dueDate: o.dueDate || undefined,
            client: o.client ? { boId: o.client.boId || undefined, email: o.client.email || undefined, firstName: o.client.firstName || undefined, lastName: o.client.lastName || undefined } : undefined,
            merchant: o.merchant ? { boId: o.merchant.boId || undefined, email: o.merchant.email || undefined, name: o.merchant.name || undefined } : undefined,
          }))[0]
        : null
    }

    const response = await wrappedExtendedQueryAuth(query, factory)
    if (!response) {
      setErrors([{ msg: t('item-does-not-exist') }])
      return
    }

    setData(response)
    addToList(response)
  }

  const onCancel = (e: any) => {
    setShowScanner(false)
  }

  const resetContainer = async (e: any) => {
    setErrors([])

    if (!data) {
      return
    }

    setShowDialog(true)
  }

  const dialogCancel = (e: any) => {
    setShowDialog(false)
  }

  const dialogSubmit = async (e: any) => {
    if (!data) {
      return
    }

    const list: string[] = []
    itemList.forEach(item => {
      list.push(item._id)
    })
    const mutation = `mutation { resetItems ( input: { ids: ${JSON.stringify(list)} } ) {ids} }`

    const response = await wrappedExtendedMutateAuth(mutation)

    setShowDialog(false)

    if (response.resetItems.ids.length > 0) {
      setShowTryAgainDialog(true)

      let tempData
      const tempList = itemList.filter(item => response.resetItems.ids.includes(item._id))

      if (!tryAgain) {
        tempData = () => {
          return (
            <div className="type-cards">
              <label>{t('bulk-reset-failed')}</label>
              <hr />
              {tempList.map((i: Data) => (
                <div className="scanned-item-uuid" key={i.uuid}>
                  <label>{i.uuid}</label>
                  <ErrorIcon2 fill="red" height={12} onClick={() => handleRemoveFromList(i.uuid)} />
                </div>
              ))}
            </div>
          )
        }

        setItemList(tempList)
        setTryAgain(true)
      } else {
        tempData = () => {
          return (
            <div className="type-cards">
              <label>{t('reset-contact-devs')}</label>
              <hr />
              {tempList.map((i: Data) => (
                <div className="scanned-item-uuid" key={i.uuid}>
                  <label>{i.uuid}</label>
                </div>
              ))}
            </div>
          )
        }
        setTryAgain(false)
        setItemList([])
        setItemListByType([])
      }

      setTryAgainDialogData(tempData)

      return
    }

    setData(undefined)
    setItemList([])
    setItemListByType([])
    setShowScanner(false)
    setMsg(t('reset-successful'))
  }

  const handleScanAnother = (e: any) => {
    e.preventDefault()
    setErrors([])
    setMsg('')
    setShowScanner(true)
    setIsScanTypeRemove(false)
    setData(undefined)
  }

  const scanToRemove = () => {
    setErrors([])
    setMsg('')
    setIsScanTypeRemove(true)
    setShowScanner(true)
  }

  const onItemTypeClick = (items: Data[]) => {
    if (items.length > 0) {
      setItemTypeDialogTitle(items[0].itemTypeName)
      setShowItemTypeDialog(true)
    } else {
      setItemTypeDialogTitle('')
      setShowItemTypeDialog(false)
    }
  }

  const handleRemoveFromListByScan = async (code: string) => {
    setMsg('')
    if (!code.includes('items/')) {
      setErrors([{ msg: t('invalid-item-qr-code') }])
      return
    }

    const url = new URL(code)
    const uuid = url.pathname.split('/').pop() || ''
    const index = itemList.findIndex(r => r.uuid === uuid)
    if (index < 0) {
      setErrors([{ msg: t('item-not-added') }])
      setShowScanner(false)
      return
    }

    setItemList(itemList.filter(item => item.uuid !== uuid))
    let tempItemListByType: ItemListData[] = []

    for (const type of itemListByType) {
      const y = type.items.filter(item => item.uuid !== uuid)
      tempItemListByType.push({ _id: type._id, itemTypeIcon: type.itemTypeIcon, items: y })
    }
    setMsg(t('item-removed-list'))

    setItemListByType(tempItemListByType)
    setShowScanner(false)
  }

  const handleRemoveFromList = async (uuid: string) => {
    setErrors([])
    setMsg('')
    setItemList(itemList.filter(item => item.uuid !== uuid))

    let x: ItemListData[] = []

    for (const type of itemListByType) {
      const y = type.items.filter(item => item.uuid !== uuid)
      x.push({ _id: type._id, itemTypeIcon: type.itemTypeIcon, items: y })
    }

    setItemListByType(x)
    setShowScanner(false)
  }

  useEffect(() => {
    const itemsByType = itemListByType.find(i => {
      if (i.items.length > 0 && i.items[0].itemTypeName === itemTypeDialogTitle) return true
      else return false
    })
    if (itemsByType) {
      const tempData = () => {
        return (
          <div className="type-cards">
            {itemsByType.items.map((i: any) => (
              <div className="scanned-item-uuid" key={i.uuid}>
                <label>{i.uuid}</label>
                <ErrorIcon2 fill="red" height={12} onClick={() => handleRemoveFromList(i.uuid)} />
              </div>
            ))}
          </div>
        )
      }
      setItemTypeDialogData(tempData)
    } else {
      setItemTypeDialogData(undefined)
      setShowItemTypeDialog(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTypeDialogTitle, itemListByType, showItemTypeDialog])

  const itemTypeDialogCancel = () => {
    setItemTypeDialogData(undefined)
    setTryAgainDialogData(undefined)
    setItemTypeDialogTitle('')
    setShowItemTypeDialog(false)
    setShowTryAgainDialog(false)
  }

  const tryAgainDialogCancel = () => {
    setItemTypeDialogData(undefined)
    setTryAgainDialogData(undefined)
    setItemTypeDialogTitle('')
    setShowItemTypeDialog(false)
    setShowTryAgainDialog(false)
    setTryAgain(false)
    setItemList([])
    setItemListByType([])
  }

  return (
    <>
      {showScanner && (
        <section className="qrScanner">
          <QrScanner callback={isScanTypeRemove ? handleRemoveFromListByScan : onQrCode} callbackCancel={onCancel}></QrScanner>
        </section>
      )}
      {!showScanner && (
        <form className="object-form">
          <BackButton />
          <ErrorElement errors={errors} />
          {data ? (
            <>
              <label className="total-scanned">
                {t('total-items-added')}: <strong>{itemList.length}</strong>
              </label>
              <fieldset style={{ border: '2px solid #43be40' }}>
                <legend style={{ fontSize: 25, fontStyle: 'bold' }}>{t('last-scanned-item')}</legend>
                <>
                  <hr />
                  <div className="container-info">
                    <div>
                      <label>{t('item-info')}</label>
                      <label> {data.itemTypeName} </label>
                      <label> {data.status} </label>
                      <label>{data.uuid} </label>
                    </div>
                    <div>
                      <label>{t('merchant-info')}</label>
                      {data.merchant ? (
                        <>
                          <label>{data.merchant.name} </label>
                          <label>{data.merchant.email} </label>
                          <label>{data.merchant.boId}</label>
                        </>
                      ) : (
                        <label>N/A</label>
                      )}
                    </div>
                    <div>
                      <label>{t('client-info')}</label>
                      {data.client ? (
                        <>
                          <label>
                            {data.client.firstName} {data.client.lastName}{' '}
                          </label>
                          <label>{data.client.email}</label>
                          <label>{data.client.boId}</label>
                        </>
                      ) : (
                        <label>N/A</label>
                      )}
                    </div>
                  </div>
                </>
              </fieldset>
              {itemList.length > 0 && (
                <label className="scan-to-remove" onClick={scanToRemove}>
                  {t('scan-to-remove')}
                </label>
              )}
            </>
          ) : (
            msg && (
              <>
                <p className="not-exist">{msg}</p>
              </>
            )
          )}
          {itemListByType &&
            itemListByType.length > 0 &&
            itemListByType.map(t => t.items && t.items.length > 0 && <CardWithIcon key={t._id} data={{ labelMain: `${t.items[0].itemTypeName}`, count: t.items.length, iconUrl: t.itemTypeIcon, onClick: () => onItemTypeClick(t.items) }} />)}
          <div className="buttonDock"></div>
          <AddAndCompleteElement data={{ submitting, disabledComplete: itemList.length > 0 ? false : true, type: 'button', onClickAdd: handleScanAnother, onClickSubmit: resetContainer }} />
        </form>
      )}
      {data && <ConfirmDialog data={{ title: t('reset-container'), cancelHook: dialogCancel, submitHook: dialogSubmit, text: t('reset-container-confirm'), show: showDialog }} />}
      <CustomDialog data={{ cancelHook: itemTypeDialogCancel, show: showItemTypeDialog, position: 'center', title: itemTypeDialogTitle ? itemTypeDialogTitle : '', content: itemTypeDialogData ? itemTypeDialogData : undefined }} />
      <CustomDialog
        data={{ cancelHook: tryAgainDialogCancel, show: showTryAgainDialog, position: 'center', title: t('attention'), content: tryAgainDialogData, buttonText: t('try-again'), submitHook: tryAgain ? dialogSubmit : undefined }}
      />
    </>
  )
}

export { CheckItem as default }
