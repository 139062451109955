import { useEffect } from 'react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select, { components, createFilter } from 'react-select'
import { Config } from 'react-select/src/filters'
import { SelectOptions } from '../containers/admin/MainStatuses'

type SelectData = {
  options: SelectOptions[]
  handleChange: Function
  value?: SelectOptions
  defaultValue?: SelectOptions | SelectOptions[]
  isDisabled?: boolean
  isMulti?: boolean
  minLength?: number
}

const SelectMenu = ({ data, placeholder }: { data: SelectData; placeholder?: string }) => {
  const { t } = useTranslation()

  const NoOptionsMessage = (props: any) => {
    return <components.NoOptionsMessage {...props}>{t('no-search-results')}</components.NoOptionsMessage>
  }
  const filterConfig: Config = { matchFrom: 'start' }

  const Placeholder = (props: any) => {
    return <components.Placeholder {...props}>{placeholder ? placeholder : t('select')}</components.Placeholder>
  }

  return (
    <Select
      className="select"
      filterOption={createFilter(filterConfig)}
      classNamePrefix="select"
      components={{ NoOptionsMessage, Placeholder }}
      isDisabled={data.isDisabled}
      isMulti={data.isMulti || false}
      value={data.value}
      defaultValue={data.defaultValue}
      options={data.options}
      onChange={(value, action) => data.handleChange(value, action)}
    />
  )
}

const SelectMenuCustomer = ({ data, placeholder }: { data: SelectData; placeholder?: string }) => {
  const { t } = useTranslation()
  const [showOptions, setShowOptions] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [tempInputValue, setTempInputValue] = useState('')
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const handleInputChange = useCallback(typedOption => {
    switch (true) {
      case typedOption.length < 3:
        setShowOptions(false)
        setMenuIsOpen(false)
        setInputValue(typedOption)
        break
      case typedOption.length === 3:
      case typedOption.length === 7:
        setShowOptions(true)
        setMenuIsOpen(true)
        setInputValue(typedOption + '-')
        break
      default:
        setInputValue(typedOption)
        break
    }
  }, [])

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 8) {
      e.preventDefault()
      setTempInputValue(inputValue)
      spliceInput(inputValue)
    } else {
      return true
    }
  }

  const spliceInput = (tempInputValue: string) => {
    switch (true) {
      case tempInputValue.length === 4:
        setTempInputValue(tempInputValue.substring(0, tempInputValue.length - 2))
        setShowOptions(false)
        setMenuIsOpen(false)
        break
      case tempInputValue.length === 8:
        setTempInputValue(tempInputValue.substring(0, tempInputValue.length - 2))
        break
      default:
        setTempInputValue(tempInputValue.substring(0, tempInputValue.length - 1))
        break
    }
  }

  useEffect(() => {
    setInputValue(tempInputValue)
  }, [tempInputValue])

  const NoOptionsMessage = (props: any) => {
    return <components.NoOptionsMessage {...props}>{t('no-search-results')}</components.NoOptionsMessage>
  }
  const filterConfig: Config = { matchFrom: 'start' }

  const Placeholder = (props: any) => {
    return <components.Placeholder {...props}>{placeholder ? placeholder : t('select')}</components.Placeholder>
  }

  return (
    <Select
      className="select"
      filterOption={createFilter(filterConfig)}
      classNamePrefix="select"
      components={{ NoOptionsMessage, Placeholder }}
      isDisabled={data.isDisabled}
      value={data.value}
      options={showOptions ? data.options : []}
      onChange={(value, action) => data.handleChange(value, action)}
      onInputChange={handleInputChange}
      isClearable={true}
      backspaceRemovesValue={true}
      inputValue={inputValue}
      onKeyDown={handleKeyDown}
      menuIsOpen={menuIsOpen}
    />
  )
}

export { SelectMenu as default, SelectMenuCustomer }
