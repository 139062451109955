import { SelectOptions } from '../containers/admin/MainStatuses'

export enum Status {
  Pending = 'pending',
  Shipped = 'shipped',
  Delivered = 'delivered',
  Canceled = 'canceled',
}

const statusOptions: SelectOptions[] = [
  { label: 'status.canceled', value: Status.Canceled },
  { label: 'status.pending', value: Status.Pending },
  { label: 'status.shipped', value: Status.Shipped },
  { label: 'status.delivered', value: Status.Delivered },
]

const availableStatuses = (oldStatus: string): SelectOptions[] | null => {
  let statuses = statusOptions

  if (oldStatus === Status.Canceled || oldStatus === Status.Delivered || oldStatus === Status.Shipped) {
    return statuses.filter(s => s.value === oldStatus)
  }

  if (oldStatus === Status.Pending) {
    return statuses.filter(status => {
      return status.value !== Status.Delivered
    })
  }

  return statuses.filter(status => {
    return status.value !== Status.Delivered && status.value !== Status.Pending
  })
}

export { availableStatuses as default, statusOptions }
