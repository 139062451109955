import { useEffect, useState } from 'react'
import ActionBar from '../../../components/merchant/ActionBar'
import Navbar from '../../../components/Navbar'
import Searchbar from '../../../components/Searchbar'
import '../../admin/orders/Order.css'

import '../../CategoryHome.css'
import Card from '../../../components/Card'
import { formatDate } from '../../../api/utils'
import ErrorElement from '../../../components/ErrorElement'
import { useTranslation } from 'react-i18next'
import { FailIcon, PendingIcon, SuccessIcon } from '../../../components/Icons'
import { useAPI } from '../../../api/api'
import SplashScreen from '../../../components/SplashScreen'

type OrderCardData = {
  _id: string
  orderType: string
  merchant: {
    id: number
    name: string
  }
  createdAt: number
  status: string
  paid: boolean
  totalDiscount: number
  orderItems: [
    {
      id: string
      name: string
      quantity: number
      price: number
    },
  ]
}

const Orders = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  const [orders, setOrders] = useState<OrderCardData[]>([])

  useEffect(() => {
    async function onLoad() {
      const query = `query { orders { _id createdAt status merchant { _id businessName } orderItems { itemType { name } quantity } } }`
      const factory = (result: any): OrderCardData[] => {
        return result.orders.map((o: any) => ({
          _id: o._id,
          merchant: {
            id: o.merchant._id,
            name: o.merchant.businessName,
          },
          status: o.status,
          createdAt: o.createdAt / 1000,
          orderItems: o.orderItems.map((oi: any) => ({
            name: oi.itemType.name,
            quantity: oi.quantity,
          })),
        }))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setOrders(response.reverse())
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Navbar></Navbar>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <section className="HomeSection WithNavbar">
          <header>
            <h1>{t('orders.orders')}</h1>
          </header>
          <Searchbar></Searchbar>
          <hr />
          <ErrorElement errors={errors} />
          {orders.length === 0 ? (
            <p className="empty-list-msg">{t('empty.orders')}</p>
          ) : (
            orders.map(order => (
              <Card
                key={order._id}
                data={{
                  labelMain: `#BO-${order._id.toUpperCase().slice(0, 8)}`,
                  labelTopRight: formatDate(order.createdAt),
                  labelSub2: `${order.orderItems.reduce((prev, current) => prev + current.quantity, 0)} ${t('items')}`,
                  labelBotRight: t(`status.${order.status}`),
                  iconBotRight: order.status === 'delivered' ? <SuccessIcon /> : order.status === 'canceled' ? <FailIcon /> : <PendingIcon />,
                  linkTo: '/merchant/orders/' + order._id,
                }}
              />
            ))
          )}
        </section>
      )}
      <ActionBar></ActionBar>
    </>
  )
}

export { Orders as default }
export type { OrderCardData }
