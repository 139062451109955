import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import './common.css'
import { ReactComponent as ShowIcon } from '../../icons/show.svg'
import { ReactComponent as HideIcon } from '../../icons/hide.svg'
import PhoneNumberInput from '../PhoneNumberInput'
import { PasswordCheck } from '../PasswordInput'

type InputData = {
  className?: string
  required?: boolean | true
  disabled?: boolean | false
  type?: string | 'text'
  autoFocus?: boolean | false
  label: string
  placeholder: string
  value: string
  placeHolderIcon?: JSX.Element
  additionalIcon?: JSX.Element
  setValue: Dispatch<SetStateAction<string>>
  note?: string | undefined
}

interface PasswordInputData extends InputData {
  showPwChecklist?: boolean | false
  handlePwChecklist?: (arg0: boolean) => void | undefined
}

const CustomInput = (data: InputData) => {
  return (
    <fieldset className={`custom-input-wrapper ${data.className ? data.className : ''}`}>
      <label>{data.label}</label>
      <div className="custom-input-container">
        {data.placeHolderIcon && data.placeHolderIcon}
        <input autoFocus={data.autoFocus} disabled={data.disabled} type={data.type} required={data.required} value={data.value} placeholder={data.placeholder} onChange={e => data.setValue(e.target.value)} />
        {data.additionalIcon && data.additionalIcon}
      </div>
      {data.note && (
        <div className="input-note-container">
          <label className="input-note-label">{data.note}</label>
        </div>
      )}
    </fieldset>
  )
}

const CustomPasswordInput = (data: PasswordInputData) => {
  const [inputType, setInputType] = useState('password')
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (showPassword) {
      setInputType('text')
    } else {
      setInputType('password')
    }
  }, [showPassword])

  return (
    <fieldset className={`custom-input-wrapper ${data.className ? data.className : ''}`}>
      <label>{data.label}</label>
      <div className="custom-input-container">
        {data.placeHolderIcon && data.placeHolderIcon}
        <input type={inputType} required={data.required} value={data.value} placeholder={data.placeholder} onChange={e => data.setValue(e.target.value)} autoComplete={'off'} />
        {showPassword ? <ShowIcon className="show-password-icon" onClick={() => setShowPassword(!showPassword)} /> : <HideIcon className="show-password-icon" onClick={() => setShowPassword(!showPassword)} />}
      </div>
      {data.showPwChecklist ? <PasswordCheck value={data.value} onChange={data.handlePwChecklist} /> : <></>}
    </fieldset>
  )
}

const CustomPhoneInput = (data: InputData) => {
  return (
    <fieldset className={`custom-input-wrapper ${data.className ? data.className : ''}`}>
      <label>{data.label}</label>
      <div className="custom-input-container">
        {data.placeHolderIcon && data.placeHolderIcon}
        <PhoneNumberInput disabled={data.disabled} required={data.required} value={data.value} onChange={data.setValue} placeholder={data.placeholder} />
        {data.additionalIcon && data.additionalIcon}
      </div>
    </fieldset>
  )
}

export { CustomInput, CustomPasswordInput, CustomPhoneInput }
