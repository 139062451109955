import { useEffect, useState } from 'react'
import BackButton from '../../components/BackButton'
import { QrScanner } from '../../components/QrScanner'
import { ReactComponent as ErrorIcon2 } from '../../icons/error-2.svg'
import '../CategoryHome.css'
import './CheckItem.css'
import ErrorElement from '../../components/ErrorElement'
import ConfirmDialog from '../../components/Dialog'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../api/api'
import { ReactComponent as QRIcon } from '../../icons/batches.svg'
import { useHistory } from 'react-router'

type Data = {
  _id: string
  uuid: string
  itemTypeName: string
  itemTypeId: string
  client: any
  merchant: any
  depositPrice: any
  status: string
}

const CheckItem = () => {
  const { t } = useTranslation()

  let history = useHistory()

  let { errors, setErrors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const [data, setData] = useState<Data | undefined | null>(undefined)
  const [itemTypeName, setItemTypeName] = useState('')
  const [uuid, setUuid] = useState('')
  const [msg, setMsg] = useState('')
  const [merchantBoId, setMerchantBoId] = useState('')

  const [showScanner, setShowScanner] = useState(true)

  const [showUnassignDialog, setShowUnassignDialog] = useState(false)
  const [showUnconsignDialog, setShowUnconsignDialog] = useState(false)
  const [isFirstScan, setIsFirstScan] = useState(true)

  useEffect(() => {
    const auth = localStorage.getItem('auth')
    if (auth) {
      setMerchantBoId(JSON.parse(auth).boId)
    }
  }, [])

  const onQrCode = async (code: string) => {
    setMsg('')
    setData(undefined)
    setShowScanner(false)
    setIsFirstScan(false)

    if (!code.includes('items/')) {
      setErrors([{ msg: t('invalid-item-qr-code') }])
      return
    }

    const url = new URL(code)
    const qr = url.pathname.split('/').pop() || ''

    const query = `query { itemsById (filter : {uuid : "${qr}"}) { _id uuid status itemType { _id name depositPrice } client { boId firstName lastName } merchant { _id boId name }}}`
    const factory = (result: any): Data => {
      return Array.isArray(result.itemsById) && result.itemsById.length > 0
        ? result.itemsById.map((o: any) => ({
            _id: o._id,
            itemTypeName: o.itemType.name,
            itemTypeId: o.itemType._id,
            depositPrice: o.itemType.depositPrice / 100,
            uuid: o.uuid,
            status: o.status,
            client: o.client,
            merchant: o.merchant,
          }))[0]
        : null
    }

    const response = await wrappedExtendedQueryAuth(query, factory)
    if (!response) {
      setMsg(t('item-does-not-exist'))
      return
    }

    setItemTypeName(response.itemTypeName)
    setUuid(response.uuid)

    switch (response.status) {
      case 'unassigned':
      case 'returned':
        setData(response)
        setMsg(t('merchant-check-status.available'))
        return
      case 'assigned':
        if (response.merchant && response.merchant.boId === merchantBoId) {
          setData(response)
          setMsg(t('merchant-check-status.assigned', { firstName: response.client.firstName, lastName: response.client.lastName, boId: response.client.boId }))
        } else {
          setErrors([{ msg: t('merchant-check-status.assigned-another-merchant') }])
        }
        return
      case 'pending-purchase':
      case 'purchased':
        setErrors([{ msg: t('merchant-check-status.purchased') }])
        return
      case 'written-off':
        setErrors([{ msg: t('merchant-check-status.written-off') }])
        return
      case 'lost':
        setErrors([{ msg: t('merchant-check-status.lost') }])
        return
      case 'on-consignment':
        if (response.merchant && response.merchant.name) {
          setData(response)
          setErrors([{ msg: t('merchant-check-status.on-consignment', { name: response.merchant.name, price: response.depositPrice }) }])
        }
        return
      // TODO MESSAGES
      case 'deposit':
      case 'deposit-returned':
        setErrors(t(''))
        break
    }
  }

  const onCancel = (e: any) => {
    if (isFirstScan) history.goBack()
    else setShowScanner(false)
  }

  const unassign = async (e: any) => {
    if (!data) {
      return
    }

    setShowUnassignDialog(true)
  }

  const unconsign = async (e: any) => {
    if (!data) {
      return
    }

    setShowUnconsignDialog(true)
  }

  const dialogCancel = (e: any) => {
    setShowUnassignDialog(false)
  }

  const dialogCancelUnconsign = (e: any) => {
    setShowUnconsignDialog(false)
  }

  const dialogSubmit = async (e: any) => {
    if (!data) {
      return
    }

    const mutation = `mutation { cancelItemAssignment ( input: { id: "${data._id}" } ) { _id } }`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setMsg(`${t('item-removed-from-customer-inventory')} ${data.client.firstName} ${data.client.lastName} (${data.client.boId})`)
      setItemTypeName('')
      setUuid('')
      setData(undefined)
    }
    setShowUnassignDialog(false)
  }

  const dialogUnconsignSubmit = async (e: any) => {
    if (!data) {
      return
    }

    const mutation = `mutation {returnDepositedItem(input: { id: "${data._id}" itemType: "${data.itemTypeId}"})}`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setMsg(t('item-returned-to-inventory'))
      setItemTypeName('')
      setUuid('')
      setShowUnconsignDialog(false)
      setData(undefined)
    }
  }

  const handleScanAnother = (e: any) => {
    e.preventDefault()
    setShowScanner(true)
  }

  return (
    <>
      {showScanner && (
        <section className="qrScanner">
          <QrScanner callback={onQrCode} callbackCancel={onCancel}></QrScanner>
        </section>
      )}
      {!showScanner && (
        <form className="object-form">
          <BackButton />
          <div className="Header">
            <h2>{itemTypeName}</h2>
            <label>{uuid}</label>
            <hr />
            <ErrorElement errors={errors} />
            {data ? (
              <>
                <p>{msg}</p>
                {data.status === 'assigned' && data.client && (
                  <>
                    <div className="edit unassign">
                      <label onClick={e => unassign(e)}>
                        <ErrorIcon2 />
                        {t('unassign')}
                      </label>
                    </div>
                  </>
                )}
                {data.status === 'on-consignment' && data.merchant && merchantBoId && merchantBoId === data.merchant.boId && (
                  <>
                    <div className="edit unassign">
                      <label onClick={e => unconsign(e)}>
                        <ErrorIcon2 />
                        {t('unconsign')}
                      </label>
                    </div>
                  </>
                )}
              </>
            ) : (
              msg && (
                <>
                  <p className="not-exist">{msg}</p>
                </>
              )
            )}
          </div>
          <div className="edit another">
            <label className="scan-another" onClick={handleScanAnother}>
              <QRIcon fill="black" />
              {t('scan-another')}
            </label>
          </div>
        </form>
      )}
      {data && data.client && (
        <ConfirmDialog
          data={{ title: t('unassign-container'), cancelHook: dialogCancel, submitHook: dialogSubmit, text: t('confirm-unassign', { firstName: data.client.firstName, lastName: data.client.lastName }), show: showUnassignDialog }}
        />
      )}
      {data && data.status === 'on-consignment' && merchantBoId === data.merchant.boId && (
        <ConfirmDialog
          data={{
            title: t('unconsign'),
            cancelHook: dialogCancelUnconsign,
            submitHook: dialogUnconsignSubmit,
            text: t('confirm-unconsign'),
            show: showUnconsignDialog,
          }}
        />
      )}
    </>
  )
}

export { CheckItem as default }
