import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { CardData } from './Card'
import './Card.css'

const CardWithRemove = ({ data }: { data: CardData }) => {
  const { t } = useTranslation()
  const renderMain = () => {
    return (
      <>
        <div>
          <img className="item-icon" alt="item-icon" src={data.iconUrl || t('no-image-available')} />
        </div>
        <h3 className="name remove-name">{data.labelMain}</h3>
        <div className="card-remove" onClick={data.onClick}>
          {data.labelBotRight && <label className="status">{data.labelBotRight}</label>}
          {data.iconBotRight}
        </div>
      </>
    )
  }
  return data.linkTo ? (
    <Link to={data.linkTo} className="card">
      {renderMain()}
    </Link>
  ) : (
    <div className="card">{renderMain()}</div>
  )
}

export { CardWithRemove as default }
