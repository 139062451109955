import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useAPI } from '../../api/api'
import ErrorElement from '../ErrorElement'
import CustomerSetProfile, { CustomerProfile } from './CustomerSetProfile'

const CustomerEditProfile = () => {
  const history = useHistory()
  const { submitting, errors, wrappedExtendedMutateAuth, wrappedExtendedQueryAuth } = useAPI()

  const [profile, setProfile] = useState<CustomerProfile | undefined>()

  useEffect(() => {
    async function onLoad() {
      const query = `query { client { _id firstName lastName phoneNumber address { address city state postalCode country } }}`
      const factory = (result: any): CustomerProfile => {
        return result.client
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setProfile(response)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = async (profile: CustomerProfile) => {
    const mutation = `mutation { updateClientProfile( input: { 
      firstName: "${profile.firstName}" lastName: "${profile.lastName}" phoneNumber: "${profile.phoneNumber}" 
      address: { address: "${profile.address}" country: "${profile.country}" state: "${profile.state}" postalCode: "${profile.postalCode}" city: "${profile.city}" } 
    }){firstName lastName}}`

    const result = await wrappedExtendedMutateAuth(mutation)
    if (result) {
      const authRaw = localStorage.getItem('auth')
      if (authRaw) {
        let auth = JSON.parse(authRaw)
        auth.name = `${result.updateClientProfile.firstName} ${result.updateClientProfile.lastName}`
        localStorage.setItem('auth', JSON.stringify(auth))
      }
      history.goBack()
    }
  }

  return (
    <>
      <ErrorElement errors={errors} />
      <CustomerSetProfile key={profile?._id} profile={profile} onSubmit={onSubmit} submitting={submitting} />
    </>
  )
}

export { CustomerEditProfile as default }
