import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Logo from '../../components/Logo'

const ScannedLocation = () => {
  const { t } = useTranslation()
  const { id }: { id: string } = useParams()

  return (
    <main>
      <Logo></Logo>
      <h3>{t('scan-location')}</h3>
      <label className="uppercase">{id}</label>
      <hr />
      <h4>
        {t('scanned-pre')}{' '}
        <a className="yellow-link" href="https://app.bopaq.com" rel="noopener noreferrer">
          {t('bopaq-app')}
        </a>{' '}
        {t('scanned-post')}
      </h4>
    </main>
  )
}

export { ScannedLocation as default }
