import './Notification.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const NOTIFICATION_KEY = 'notification'
const NOTIFICATION_LENGTH = 2000

const setNotification = (notification_key: string) => {
  localStorage.setItem(NOTIFICATION_KEY, notification_key)
}

const NotificationBanner = () => {
  const { t } = useTranslation(['notifications'])
  const [notification, setNotification] = useState<string | null>()

  const onLoad = async () => {
    const toShowKey = localStorage.getItem(NOTIFICATION_KEY)
    localStorage.removeItem(NOTIFICATION_KEY)
    setNotification(toShowKey)

    setTimeout(() => {
      setNotification(undefined)
    }, NOTIFICATION_LENGTH)
  }

  useEffect(() => {
    onLoad()

    return () => {
      setNotification('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {notification && (
        <section className="notification">
          <div className="card" onClick={(e: any) => e.stopPropagation()}>
            <p className="text">{t(notification)}</p>
          </div>
        </section>
      )}
    </>
  )
}

export { NotificationBanner, setNotification }
