import GoogleMapReact from 'google-map-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAPI } from '../../api/api'
import { LocationData } from '../../components/admin/LocationCard'
import ErrorElement from '../../components/ErrorElement'
import Card from '../../components/LocationCard'
import Logo from '../../components/Logo'
import { LocationCoords } from '../customer/ReturnItemLocation'
import { MapMarker } from '../customer/ReturnMap'

const ScannedItem = () => {
  const { t } = useTranslation()
  const { id }: { id: string } = useParams()

  const defaultLocation = {
    longitude: -73.5673,
    latitude: 45.5017,
  }
  let defaultZoomLvl = 14

  let { errors, setErrors, wrappedExtendedQuery } = useAPI()

  const [locations, setLocations] = useState<LocationData[]>([])
  const [item, setItem] = useState<any>()
  const [msg, setMsg] = useState('')
  const [showMap, setShowMap] = useState(false)
  const [zoomLvl, setZoomLvl] = useState(defaultZoomLvl)
  const [map, setMap] = useState<HTMLElement | null>(null)
  const [selectedLocation, setSelectedLocation] = useState<LocationCoords>()

  useEffect(() => {
    async function onLocationSet() {
      const query = `query {itemsByIdOpen (filter : {uuid : "${id}"}) { _id uuid status itemType { name depositPrice } merchant{name}}}`
      const factory = (result: any): any => {
        return Array.isArray(result.itemsByIdOpen) && result.itemsByIdOpen.length > 0
          ? result.itemsByIdOpen.map((o: any) => ({ _id: o._id, itemTypeName: o.itemType.name, depositPrice: o.itemType.depositPrice / 100, uuid: o.uuid, status: o.status, merchant: o.merchant ? o.merchant.name : null }))[0]
          : null
      }

      const response = await wrappedExtendedQuery(query, factory)

      if (response) {
        setItem(response)
      } else {
        setErrors([{ msg: t('item-does-not-exist') }])
      }
    }
    onLocationSet()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onItemSet() {
      if (locations.length > 0) return
      if (!item) return
      if (!item.status) return

      const query = `query {dropOffLocationsOpen(filter: {status: "active"}) {_id name status capacity quantity address workTime website coordinates { latitude longitude } }}`
      const factory = (result: any): any => {
        return result.dropOffLocationsOpen.map((l: any) => l).sort((a: any, b: any) => (a.distance > b.distance ? 1 : -1))
      }

      const response = await wrappedExtendedQuery(query, factory)

      if (response) {
        setLocations(response)
      }
    }
    onItemSet()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  useEffect(() => {
    if (item) {
      switch (item.status) {
        case 'unassigned':
        case 'returned':
          setMsg(t('scanned-item.unassigned'))
          break
        case 'assigned':
          setMsg(t('scanned-item.assigned'))
          break
        case 'purchased':
        case 'pending-purchase':
        case 'written-off':
        case 'lost':
          setMsg(t('scanned-item.purchased'))
          break
        case 'on-consignment':
          setMsg(t('scanned-item.on-consignment', { name: item.merchant, price: item.depositPrice }))
          break
        //TODO MESSAGE
        case 'deposit':
        case 'deposit-returned':
          setMsg(t(''))
          break
      }

      setShowMap(true)
    }
  }, [item, t])

  const handleZoomChange = (zoom: number) => {
    setZoomLvl(zoom)
  }

  const handleLocationChange = (e: any, { latitude, longitude }: any) => {
    if (map) {
      map.scrollIntoView()
    }
    setSelectedLocation({ latitude, longitude })
    setZoomLvl(defaultZoomLvl)
  }

  return (
    <main className="scanned-item">
      <Logo></Logo>
      <h3>{t('scan-item')}</h3>
      <label>{msg}</label>
      <ErrorElement errors={errors} className="centered" />
      <hr />
      {showMap && (
        <>
          <h3>{t('nearest-drop-off-points')}</h3>
          <div className="map dimensioned" ref={setMap}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GMAPS_KEY || '' }}
              defaultCenter={{
                lat: defaultLocation.latitude,
                lng: defaultLocation.longitude,
              }}
              defaultZoom={defaultZoomLvl}
              center={{
                lat: selectedLocation?.latitude || defaultLocation.latitude,
                lng: selectedLocation?.longitude || defaultLocation.longitude,
              }}
              options={() => {
                return {
                  gestureHandling: 'greedy',
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                }
              }}
              onZoomAnimationStart={(zoom: number) => handleZoomChange(zoom)}
              zoom={zoomLvl}
            >
              {locations.map(location => (
                <MapMarker key={location._id} lat={location.coordinates.latitude} lng={location.coordinates.longitude} text={location.name} zoomLvl={zoomLvl} />
              ))}
            </GoogleMapReact>
            <section className="return-list">
              {locations.length === 0 ? (
                <p className="empty-list-msg">{t('empty.locations')}</p>
              ) : (
                locations.map(location => (
                  <Card
                    key={location._id}
                    data={{
                      labelMain: location.name,
                      labelSub1: location.distance && (location.distance >= 1000 ? `${(location.distance / 1000).toFixed(1).toString()} km` : `${location.distance.toString()} m`) + ` ${t('away')}`,
                      labelSub4: location.workTime,
                      labelSub3: location.address,
                      labelSub2: location.freeCapacity && location.freeCapacity > 0 ? `${t('free-capacity', { freeCapacity: location.freeCapacity })}` : t('locations.location-full'),
                      website: location.website,
                      onClick: e => handleLocationChange(e, location.coordinates),
                    }}
                  />
                ))
              )}
            </section>
          </div>
        </>
      )}
    </main>
  )
}

export { ScannedItem as default }
