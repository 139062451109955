import './Card.css'
import { ReactComponent as ExternalLinkIcon } from '../icons/external-link.svg'

type CardData = {
  _id?: string
  labelMain: any
  website?: any
  labelSub1?: any
  labelSub2?: any
  labelSub3?: any
  labelSub4?: any
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const Card = ({ data }: { data: CardData }) => {
  const onExternalClick = (e: any, url?: string) => {
    e.stopPropagation()
    if (url) window.open(url, '_blank')
  }

  const renderMain = () => {
    return (
      <>
        <h3 className="name">{data.labelMain}</h3>
        {data.website && <ExternalLinkIcon className="bo-external-link" onClick={(e: any) => onExternalClick(e, data.website)} width={20} />}
        <hr />
        {data.labelSub1 && <label className="dimensions">{data.labelSub1}</label>}
        {data.labelSub2 && <label className="dimensions">{data.labelSub2}</label>}
        {data.labelSub3 && <label className="dimensions">{data.labelSub3}</label>}
        <hr />
        {data.labelSub4 && <label className="capacity multi-line">{data.labelSub4}</label>}
      </>
    )
  }
  return data.onClick ? (
    <div className="card" onClick={data.onClick}>
      {renderMain()}
    </div>
  ) : (
    <div className="card">{renderMain()}</div>
  )
}

export { Card as default }
export type { CardData }
