import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { formatDate } from '../../../api/utils'
import BackButton from '../../../components/BackButton'
import Card from '../../../components/Card'
import ErrorElement from '../../../components/ErrorElement'
import ItemTypeRowTable, { ItemTypeRowTableHeader } from '../../../components/ItemTypeRowTable'
import { MerchantItemData } from '../../../components/MerchantItem'
import { ReactComponent as EditIcon } from '../../../icons/edit.svg'
import { OrderCardData } from '../orders/Orders'

import './ViewMerchant.css'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import { FailIcon, PendingIcon, SuccessIcon } from '../../../components/Icons'
import { AdminPaths } from '../../../routes/RoutePaths'
import ArchiveUser from '../../../components/admin/ArchiveUser'
import SplashScreen from '../../../components/SplashScreen'

type MerchantProfileData = {
  _id: string
  email: string
  businessName: string
  isApproved: boolean
  merchantWebsiteURL?: string
  profileImageURL?: string
  image?: any
  returnSite?: boolean
  isInternalEcosystem?: boolean
  phoneNumber: string
  primaryContact: {
    firstName: string
    lastName: string
    phoneNumber: string
  }
  businessAddress: {
    address: string
    city: string
    state: string
    postalCode: string
    country: string
  }
  minQuantity?: number
  maxQuantity?: number
  totalQuantity?: number
  frequency?: number
  displayed?: boolean
}

type MerchantData = {
  merchant: MerchantProfileData
  inventoryItems: MerchantItemData[]
  orders: OrderCardData[]
}

const ViewMerchant = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  let { errors, wrappedExtendedQueryAuth } = useAPI()

  const { id }: any = useParams()
  const [merchant, setMerchant] = useState<MerchantData>()

  let history = useHistory()

  useEffect(() => {
    async function onLoad() {
      if (!id) {
        return
      }

      const query = `query {
        merchants (filter: {_id : "${id}"}){_id email businessName phoneNumber isApproved primaryContact { firstName lastName phoneNumber } businessAddress { address city state postalCode country } }
        merchantInventories (filter: {merchant : "${id}"}) {  merchant { _id  email } inventoryItems { itemType  { _id name } quantity } }
        orders (filter: {merchant: "${id}"}) { _id createdAt status paid merchant { _id email businessName } orderItems { itemType { name } quantity } } 
      }`
      const factory = (result: any): MerchantData => {
        return {
          merchant: result.merchants[0],
          inventoryItems: result.merchantInventories[0]?.inventoryItems.map((i: any) => ({
            _id: i.itemType._id,
            quantity: i.quantity,
            ...i.itemType,
          })),
          orders: result.orders
            .map((o: any) => ({
              _id: o._id,
              merchant: {
                id: o.merchant._id,
                name: o.merchant.businessName,
              },
              status: o.status,
              paid: o.paid,
              createdAt: o.createdAt / 1000,
              orderItems: o.orderItems.map((j: any) => ({
                name: j.itemType.name,
                quantity: j.quantity,
              })),
            }))
            .reverse(),
        }
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        await setMerchant(response)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleApproveMerchant = async (e: any) => {
    e.preventDefault()
    history.push(`${AdminPaths.Merchants}/${id}/quantities/`)
  }

  const handleEditMerchant = async (e: any) => {
    e.preventDefault()
    history.push(`${AdminPaths.Merchants}/${id}/edit/`)
  }

  return (
    <>
      {isLoading ? (
        <SplashScreen withFooter={false} />
      ) : (
        <>
          <section className="HomeSection Merchant">
            <BackButton></BackButton>
            {merchant && (
              <>
                <div className="edit">
                  <h2 className="name">{merchant.merchant.businessName}</h2>
                  {merchant.merchant.isApproved ? (
                    <label onClick={handleEditMerchant}>
                      <EditIcon fill="black" />
                      {t('merchants.edit')}
                    </label>
                  ) : (
                    <label onClick={handleApproveMerchant}>
                      <EditIcon fill="black" />
                      {t('merchants.approve')}
                    </label>
                  )}
                </div>
                <ErrorElement errors={errors} />
                <div className="InfoRow">
                  <div>
                    <div>{t('contact')}</div>
                    <label>
                      {merchant.merchant.primaryContact.firstName} {merchant.merchant.primaryContact.lastName}
                    </label>
                    <label> {merchant.merchant.email}</label>
                    <label> {merchant.merchant.phoneNumber}</label>
                  </div>
                  <div>
                    <div>{t('address')}</div>
                    <label>{merchant.merchant.businessAddress.address} </label>
                    <label>
                      {merchant.merchant.businessAddress.city}, {merchant.merchant.businessAddress.state}
                    </label>
                    <label> {merchant.merchant.businessAddress.postalCode}</label>
                  </div>
                </div>
                <ItemTypeRowTableHeader />
                {merchant.inventoryItems && merchant.inventoryItems.map(item => ItemTypeRowTable({ data: { index: item._id, label: item.name, value: item.quantity } }))}
                <div className="edit">
                  <Link to={`/admin/merchants/${id}/inventory`}>
                    <EditIcon />
                    {t('merchants.edit-inventory')}
                  </Link>
                </div>
                <h5>{t('orders.orders')}</h5>
                {merchant.orders &&
                  merchant.orders.map(order => (
                    <Card
                      key={order._id}
                      data={{
                        labelMain: `#BO-${order._id.toUpperCase().slice(0, 8)}`,
                        labelTopRight: formatDate(order.createdAt),
                        labelSub2: `${order.orderItems.reduce((prev, current) => prev + current.quantity, 0)} ${t('items')}`,
                        labelBotRightUp: order.paid ? t('orders.paid') : t('orders.not-paid'),
                        iconBotRightUp: order.paid ? <SuccessIcon /> : <FailIcon />,
                        labelBotRight: t(`status.${order.status}`),
                        iconBotRight: order.status === 'delivered' ? <SuccessIcon /> : order.status === 'canceled' ? <FailIcon /> : <PendingIcon />,
                        linkTo: '/admin/orders/' + order._id,
                      }}
                    />
                  ))}
              </>
            )}
          </section>
          <ArchiveUser id={id} />
        </>
      )}
    </>
  )
}

export { ViewMerchant as default }
export type { MerchantProfileData }
