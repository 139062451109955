import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { APIError } from '../../api/response'
import ErrorElement from '../ErrorElement'

const AccountSuspendedNotice = () => {
  const { t } = useTranslation(['errors'])
  const [registrationState, setRegistrationState] = useState(0)
  const [accountErrors, setAccountErrors] = useState<APIError[]>([])

  useEffect(() => {
    async function onLoad() {
      const auth = localStorage.getItem('auth')

      if (auth) {
        setRegistrationState(JSON.parse(auth).registrationState)
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onLoad() {
      if (registrationState === -1) {
        setAccountErrors([{ msg: t('unpaid-debt') }])
      }
    }
    onLoad()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationState])

  return <ErrorElement errors={accountErrors} className="error-color suspend-notice" />
}

export { AccountSuspendedNotice as default }
