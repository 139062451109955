import { ApolloClient, ApolloError, gql, InMemoryCache } from '@apollo/client'
import { useHistory } from 'react-router'
import extractErrorsFromApolloError from './error-extractor'
import { APIError, APIResponse } from './response'

const ApiUtils = () => {
  const history = useHistory()
  const uri = `${process.env.REACT_APP_API_URL}/graphql`

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    uri,
  })

  const setAuthToLocalStorage = async (auth: any) => {
    localStorage.setItem('auth', JSON.stringify(auth))
  }

  const removeAuthFromLocalStorage = async () => {
    localStorage.removeItem('auth')
  }

  const mutate = async (mutation: any): Promise<APIResponse> => {
    try {
      const response = await client.mutate({
        mutation,
      })

      return { result: response.data }
    } catch (error) {
      const errors = extractErrorsFromApolloError(error as any as ApolloError)

      return {
        errors,
      }
    }
  }

  const mutateAuth = async (mutation: any): Promise<APIResponse> => {
    try {
      const auth = localStorage.getItem('auth')

      if (auth) {
        const response = await client.mutate({
          mutation,
          context: {
            headers: {
              Authorization: 'Bearer ' + JSON.parse(auth).token,
            },
          },
        })

        return { result: response.data }
      } else {
        return { errors: [{ msg: 'unauthorized' }] }
      }
    } catch (error) {
      const errors = extractErrorsFromApolloError(error as any as ApolloError)

      return {
        errors,
      }
    }
  }

  const queryNoAuth = async (query: any): Promise<APIResponse> => {
    try {
      const response = await client.query({
        query,
      })

      return { result: response.data }
    } catch (error) {
      const errors = extractErrorsFromApolloError(error as any as ApolloError)

      return {
        errors,
      }
    }
  }

  const queryAuth = async (query: any): Promise<APIResponse> => {
    try {
      const auth = localStorage.getItem('auth')

      if (auth) {
        const response = await client.query({
          query,
          context: {
            headers: {
              Authorization: 'Bearer ' + JSON.parse(auth).token,
            },
          },
        })

        return { result: response.data }
      } else {
        return { errors: [{ msg: 'unauthorized' }] }
      }
    } catch (error) {
      const errors = extractErrorsFromApolloError(error as any as ApolloError)

      return {
        errors,
      }
    }
  }

  const extendedCommandAuth = async (fn: any, command: string, errorHook: (errors: APIError[]) => void, inProgressHook: (inProgress: boolean) => void, factory?: (result: any) => any): Promise<any | undefined> => {
    console.debug('Executing', command)
    errorHook([])
    inProgressHook(true)
    const response = await fn(
      gql`
        ${command}
      `,
    )
    inProgressHook(false)
    console.debug('Received', response)
    if (response.errors) {
      if (response.errors.find((e: APIError) => e.msg === 'unauthorized')) {
        removeAuthFromLocalStorage()
        history.push('/login')
        return
      } else if (response.errors.find((e: APIError) => e.msg === 'forbidden')) {
        history.replace('/forbidden')
        return
      }
      errorHook(response.errors)
      return undefined
    }
    return factory ? factory(response.result) : response.result
  }

  const extendedQuery = async (query: string, errorHook: (errors: APIError[]) => void, inProgressHook: (inProgress: boolean) => void, factory?: (result: any) => any): Promise<any | undefined> => {
    return await extendedCommandAuth(queryNoAuth, query, errorHook, inProgressHook, factory)
  }

  const extendedQueryAuth = async (query: string, errorHook: (errors: APIError[]) => void, inProgressHook: (inProgress: boolean) => void, factory?: (result: any) => any): Promise<any | undefined> => {
    return await extendedCommandAuth(queryAuth, query, errorHook, inProgressHook, factory)
  }

  const extendedMutateAuth = async (query: string, errorHook: (errors: APIError[]) => void, inProgressHook: (inProgress: boolean) => void, factory?: (result: any) => any): Promise<any | undefined> => {
    return await extendedCommandAuth(mutateAuth, query, errorHook, inProgressHook, factory)
  }

  const extendedMutate = async (query: string, errorHook: (errors: APIError[]) => void, inProgressHook: (inProgress: boolean) => void, factory?: (result: any) => any): Promise<any | undefined> => {
    return await extendedCommandAuth(mutate, query, errorHook, inProgressHook, factory)
  }

  return {
    client,
    setAuthToLocalStorage,
    removeAuthFromLocalStorage,
    queryAuth,
    extendedQueryAuth,
    extendedQuery,
    extendedMutateAuth,
    extendedMutate,
    mutate,
    mutateAuth,
  }
}

export { ApiUtils as default }
