import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode.react'
import { useParams } from 'react-router'
import moment from 'moment'
import BackButton from '../../../components/BackButton'
import { LocationData } from '../../../components/admin/LocationCard'
import ErrorElement from '../../../components/ErrorElement'

import { ReactComponent as EditIcon } from '../../../icons/edit.svg'
import { useAPI } from '../../../api/api'
import SplashScreen from '../../../components/SplashScreen'

const Location = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()

  let { errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const { id }: any = useParams()

  const [location, setLocation] = useState<LocationData>()
  const [name, setName] = useState('')
  const [capacity, setCapacity] = useState(0)
  const [quantity, setQuantity] = useState(0)
  const [latitude, setLatitude] = useState(0)
  const [longitude, setLongitude] = useState(0)
  const [address, setAddress] = useState<string | undefined>(undefined)
  const [workTime, setWorkTime] = useState<string | undefined>(undefined)
  const [website, setWebsite] = useState<string | undefined>(undefined)
  const [firstReturnOn, setFirstReturnOn] = useState<string | undefined>(undefined)
  const [status, setStatus] = useState('')

  useEffect(() => {
    async function onLoad() {
      const query = `query { dropOffLocations (filter: { _id: "${id}"}) { _id name status capacity quantity address workTime website firstReturnOn coordinates { latitude longitude } } }`
      const factory = (result: any): LocationData[] => {
        return result.dropOffLocations.map((l: any) => ({ ...l }))
      }

      const response = await wrappedExtendedQueryAuth(query, factory)
      if (response && Array.isArray(response) && response.length > 0) setLocation(response[0])
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onLocationLoad() {
      if (!location) {
        return
      }
      if (location.status) {
        setStatus(location.status)
      }
      setName(location.name)
      setLatitude(location.coordinates.latitude)
      setLongitude(location.coordinates.longitude)
      setCapacity(location.capacity)
      setAddress(location.address)
      setWorkTime(location.workTime)
      setQuantity(location.quantity)
      setWebsite(location.website)
      setFirstReturnOn(location.firstReturnOn)
    }
    onLocationLoad()
  }, [location])

  const handleResetQuantity = async (e: any) => {
    e.preventDefault()

    const mutation = `mutation { resetDropOffLocationQuantity ( input: "${id}" ) }`

    const response = await wrappedExtendedMutateAuth(mutation)
    if (response) {
      setQuantity(0)
      setFirstReturnOn(undefined)
    }
  }

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <section className="HomeSection Merchant">
      <BackButton />
      <div className="edit">
        <h2 className="name">{name}</h2>
        <Link to={'/admin/locations/edit/' + id}>
          <EditIcon />
          {t('locations.edit')}
        </Link>
      </div>
      <ErrorElement errors={errors} />
      <div className="InfoRowLocation">
        <label>
          {t('status.status')}: {status && t(`status.${status}`)}
        </label>
        <br />
        <label>
          {t('locations.first-return-on')}: {firstReturnOn ? moment.utc(firstReturnOn).format('YYYY-MM-DD') : 'N/A'}
        </label>
        <br />
        <label>
          {t('capacity')}: {capacity}
        </label>
        <br />
        <div className="location-quantity">
          <label>
            {t('locations.quantity')}: {quantity}
          </label>
          <Link to={'#'} onClick={handleResetQuantity}>
            <EditIcon />
            {t('locations.reset')}
          </Link>
        </div>
        <br />
        <label>
          {t('coordinates.coordinates')}: {latitude} x {longitude}
        </label>
        <br />
        <label>
          {t('address')}: {address}
        </label>
        <br />
        <label>
          {t('website')}: <a href={website}>{website}</a>
        </label>
        <br />
        <div className="work-time">
          <label>{t('work-time')}:</label>
          <label className="multi-line">{workTime}</label>
        </div>
      </div>
      <QRCode className="qr-code" value={`https://app.bopaq.com/locations/${id}`} renderAs="canvas" size={256} />
    </section>
  )
}

export { Location as default }
