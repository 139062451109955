import { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useAPI } from '../../../api/api'
import { roleFromId, Roles } from '../../../api/utils'
import BackButton from '../../../components/BackButton'
import ErrorElement from '../../../components/ErrorElement'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import { setNotification } from '../../../components/Notification'
import { PasswordInput, ConfirmPasswordInput, ShowPasswordCheck, PwChecklist } from '../../../components/PasswordInput'
import SelectMenu from '../../../components/SelectMenu'

const AddEmployee = () => {
  const { t } = useTranslation()
  const history = useHistory()

  let { submitting, errors, wrappedExtendedMutateAuth } = useAPI()

  const [role, setRole] = useState('employee')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isValidPw, setIsValidPw] = useState(false)
  const [showPw, setShowPw] = useState(false)

  const handleRoleChange = (value: any, action: any) => {
    if (action.action === 'select-option') {
      setRole(value.value)
    }
  }

  const options = [Roles.Employee, Roles.Warehouse].map(r => ({ value: r, label: roleFromId(r) }))

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()
    const mutation = `mutation { registerEmployeeEmail( input: { email: "${email}", password: "${password}", role: "${role}" } ) }`

    const response = await wrappedExtendedMutateAuth(mutation)

    if (response) {
      setNotification('employees.registered')
      history.goBack()
    }
  }

  const handleShowPwChange = () => {
    setShowPw(!showPw)
  }

  const handlePwChecklist = (isValid: boolean) => {
    setIsValidPw(isValid)
  }

  return (
    <form onSubmit={onSubmit} className="object-form">
      <BackButton />
      <h2>{t('employees.add')}</h2>
      <ErrorElement errors={errors} />
      <SelectMenu data={{ options, handleChange: handleRoleChange, value: { value: role, label: roleFromId(role) } }} />
      <label>{t('email')}</label>
      <input type="email" value={email} onChange={e => setEmail(e.target.value)} required={true}></input>
      <PasswordInput label={t('password.main')} showPw={showPw} value={password} onChange={e => setPassword(e.target.value)} />
      <PwChecklist value={password} onChange={handlePwChecklist} valueAgain={confirmPassword} />
      <ConfirmPasswordInput label={t('password.confirm')} showPw={showPw} value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
      <ShowPasswordCheck checked={showPw} onChange={handleShowPwChange} />
      <LoadingSubmitButton data={{ loading: submitting, text: t('save'), disabled: !isValidPw }} />
    </form>
  )
}

export { AddEmployee as default }
