import { Redirect } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'
import Locations from '../containers/admin/locations/Locations'
import Location from '../containers/admin/locations/Location'
import AddLocation from '../containers/admin/locations/AddLocation'
import EditLocation from '../containers/admin/locations/EditLocation'
import ItemTypes from '../containers/admin/itemTypes/ItemTypes'
import AddItemType from '../containers/admin/itemTypes/AddItemType'
import EditItemType from '../containers/admin/itemTypes/EditItemType'
import Batches from '../containers/admin/batches/Batches'
import { SubmitBatch } from '../containers/admin/batches/SubmitBatch'
import Orders from '../containers/admin/orders/Orders'
import AddOrder from '../containers/admin/orders/AddOrder'
import Order from '../containers/admin/orders/Order'
import Merchants from '../containers/admin/merchants/Merchants'
import ViewMerchant from '../containers/admin/merchants/ViewMerchant'
import EditMerchantInventory from '../containers/admin/merchants/EditMerchantInventory'
import Employees from '../containers/admin/employees/Employees'
import AddEmployee from '../containers/admin/employees/AddEmployee'
import EditMerchantQuantities from '../containers/admin/merchants/EditMerchantQuantites'
import { AdminPaths } from './RoutePaths'
import Customers from '../containers/admin/customers/Customers'
import ViewCustomer from '../containers/admin/customers/ViewCustomer'
import Users from '../containers/admin/Users'
import EditMerchantInfo from '../containers/admin/merchants/EditMerchantInfo'
import EditOrder from '../containers/admin/orders/EditOrder'
import CheckItem from '../containers/admin/CheckItem'
import Deposit from '../containers/admin/Deposit'

const RedirectToItemTypes = () => {
  return <Redirect to={AdminPaths.ItemTypes} />
}

const routes = [
  <PrivateRoute key={AdminPaths.Home} exact path={AdminPaths.Home} component={RedirectToItemTypes} />,
  <PrivateRoute key={AdminPaths.Users} exact path={AdminPaths.Users} component={Users} />,

  <PrivateRoute key={AdminPaths.Customers} exact path={AdminPaths.Customers} component={Customers} />,
  <PrivateRoute key={AdminPaths.Customer} exact path={AdminPaths.Customer} component={ViewCustomer} />,

  <PrivateRoute key={AdminPaths.Merchants} exact path={AdminPaths.Merchants} component={Merchants} />,
  <PrivateRoute key={AdminPaths.Merchant} exact path={AdminPaths.Merchant} component={ViewMerchant} />,
  <PrivateRoute key={AdminPaths.MerchantInventory} exact path={AdminPaths.MerchantInventory} component={EditMerchantInventory} />,
  <PrivateRoute key={AdminPaths.MerchantQuantities} exact path={AdminPaths.MerchantQuantities} component={EditMerchantQuantities} />,
  <PrivateRoute key={AdminPaths.MerchantEdit} exact path={AdminPaths.MerchantEdit} component={EditMerchantInfo} />,

  <PrivateRoute key={AdminPaths.Locations} exact path={AdminPaths.Locations} component={Locations} />,
  <PrivateRoute key={AdminPaths.AddLocation} exact path={AdminPaths.AddLocation} component={AddLocation} />,
  <PrivateRoute key={AdminPaths.Location} exact path={AdminPaths.Location} component={Location} />,
  <PrivateRoute key={AdminPaths.EditLocation} exact path={AdminPaths.EditLocation} component={EditLocation} />,

  <PrivateRoute key={AdminPaths.ItemTypes} exact path={AdminPaths.ItemTypes} component={ItemTypes} />,
  <PrivateRoute key={AdminPaths.AddItemType} exact path={AdminPaths.AddItemType} component={AddItemType} />,
  <PrivateRoute key={AdminPaths.EditItemType} exact path={AdminPaths.EditItemType} component={EditItemType} />,

  <PrivateRoute key={AdminPaths.Orders} exact path={AdminPaths.Orders} component={Orders} />,
  <PrivateRoute key={AdminPaths.AddOrder} exact path={AdminPaths.AddOrder} component={AddOrder} />,
  <PrivateRoute key={AdminPaths.EditOrder} exact path={AdminPaths.EditOrder} component={EditOrder} />,
  <PrivateRoute key={AdminPaths.Order} exact path={AdminPaths.Order} component={Order} />,
  <PrivateRoute key={AdminPaths.Deposit} exact path={AdminPaths.Deposit} component={Deposit} />,

  <PrivateRoute key={AdminPaths.Batches} exact path={AdminPaths.Batches} component={Batches} />,
  <PrivateRoute key={AdminPaths.ValidateBatch} exact path={AdminPaths.ValidateBatch} component={SubmitBatch} />,

  <PrivateRoute key={AdminPaths.Employees} exact path={AdminPaths.Employees} component={Employees} />,
  <PrivateRoute key={AdminPaths.AddEmployee} exact path={AdminPaths.AddEmployee} component={AddEmployee} />,
  <PrivateRoute key={AdminPaths.CheckItem} exact path={AdminPaths.CheckItem} component={CheckItem} />,
]

export default routes
