import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../api/api'
import ActionBar from '../../components/admin/ActionBar'
import Card from '../../components/Card'
import ErrorElement from '../../components/ErrorElement'
import Navbar from '../../components/Navbar'
import { Customer } from './customers/Customers'
import { Merchant } from './merchants/Merchants'
import { FailIcon, SuccessIcon } from '../../components/Icons'
import SelectMenu from '../../components/SelectMenu'
import SplashScreen from '../../components/SplashScreen'

type SupportCode = {
  _id: string
  supportCode: string
  status: string
  email: string
}

type User = {
  role: string
  merchant?: Merchant
  customer?: Customer
  supportCode?: SupportCode
}

const Users = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  let { errors, wrappedExtendedQueryAuth } = useAPI()

  let [users, setUsers] = useState<User[]>([])

  const roleOptions = [
    { value: 'merchant', label: t('roles.merchant') },
    { value: 'customer', label: t('roles.customer') },
    { value: 'supportCode', label: t('support-code') },
  ]

  const tempUserFilter = localStorage.getItem('userFilter')

  let userFilter: String[] = ['merchant']
  if (tempUserFilter) userFilter = JSON.parse(tempUserFilter)

  let defaultSelectedRoles = roleOptions.filter(r => {
    return userFilter.some(f => {
      return f === r.value
    })
  })

  const [selectedRoles, setSelectedRoles] = useState(userFilter)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState<User[]>([])

  useEffect(() => {
    async function onLoad() {
      const query = `query { 
        merchants { _id businessName isApproved } 
        merchantInventories { merchant { _id }, inventoryItems { quantity } } 
        clients { _id boId firstName lastName }
        findAllDepositsPaginated(input: {cursorType: BEFORE limit: 999}) {id supportCode email status }
      }`
      const factory = (result: any): User[] => {
        const inventoryItems = result.merchantInventories.map((i: any) => ({
          merchant: i.merchant,
          quantity: i.inventoryItems.map((i: any) => i.quantity || 0).reduce((total: number, num: number) => total + num, 0),
        }))

        const merchants: User[] = result.merchants
          .map((m: any) => ({
            _id: m._id,
            businessName: m.businessName,
            isApproved: m.isApproved,
            quantity: inventoryItems.find((i: any) => i.merchant._id === m._id)?.quantity || 0,
          }))
          .map((m: any) => ({
            role: 'merchant',
            merchant: m,
          }))

        const customers: User[] = result.clients
          .map((c: any) => ({
            _id: c._id,
            firstName: c.firstName,
            lastName: c.lastName,
            boId: c.boId,
          }))
          .map((c: any) => ({
            role: 'customer',
            customer: c,
          }))

        const supportCodes: User[] = result.findAllDepositsPaginated
          .map((s: any) => ({
            _id: s._id,
            supportCode: s.supportCode,
            status: s.status,
            email: s.email,
          }))
          .map((s: any) => ({
            role: 'supportCode',
            supportCode: s,
          }))

        let allUsers: User[] = merchants.concat(customers).concat(supportCodes)

        return allUsers
      }

      const response = await wrappedExtendedQueryAuth(query, factory)

      if (response) {
        setUsers(response)
        setSearchResults(response)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const results = users
      .filter(function (user) {
        return !(selectedRoles.indexOf(user.role) < 0)
      })
      .filter(function (user) {
        user.merchant && user.merchant.businessName.toLowerCase().includes(searchTerm)
        if (user.merchant && user.merchant.businessName.toLowerCase().includes(searchTerm)) {
          return true
        } else if (user.customer && (user.customer.boId.toLowerCase().includes(searchTerm) || `${user.customer.firstName.toLowerCase()} ${user.customer.lastName.toLowerCase()}`.includes(searchTerm))) {
          return true
        } else if (user.supportCode && user.supportCode.supportCode.toLowerCase().includes(searchTerm)) {
          return true
        } else return false
      })
    setSearchResults(results)
  }, [searchTerm, selectedRoles, users])

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value.toLowerCase())
  }

  const handleSelectChange = (e: any) => {
    if (e) {
      const roles = Array.from(e, (option: any) => option.value)
      setSelectedRoles(roles)
      localStorage.setItem('userFilter', JSON.stringify(roles))
    } else setSelectedRoles([])
  }

  return (
    <>
      <Navbar></Navbar>
      {isLoading ? (
        <SplashScreen />
      ) : (
        <section className="HomeSection WithNavbar">
          <header>
            <h1>{t('users')}</h1>
          </header>
          <label className="general-label">{t('select-roles')}</label>
          <SelectMenu
            data={{
              options: roleOptions,
              handleChange: handleSelectChange,
              isMulti: true,
              defaultValue: defaultSelectedRoles,
            }}
          />
          <section className="Searchbar">
            <label className="general-label">{t('search')}</label>
            <input type="text" placeholder="Search" value={searchTerm} onChange={handleChange}></input>
          </section>
          <ErrorElement errors={errors} />
          {users.length === 0 ? (
            <p className="empty-list-msg">{t('empty.users')}</p>
          ) : (
            searchResults.map(
              user =>
                ({
                  customer: user.customer && (
                    <Card
                      key={user.customer._id}
                      data={{
                        labelMain: `${user.customer.boId}`,
                        labelTopRight: `${user.customer.firstName} ${user.customer.lastName}`,
                        linkTo: `/admin/customers/${user.customer._id}`,
                      }}
                    />
                  ),
                  merchant: user.merchant && (
                    <Card
                      key={user.merchant._id}
                      data={{
                        labelMain: user.merchant.businessName,
                        labelTopRight: `${user.merchant.quantity} items`,
                        linkTo: `/admin/merchants/${user.merchant._id}`,
                        labelBotRight: !user.merchant.isApproved ? t('merchants.not-approved') : t('merchants.approved'),
                        iconBotRight: !user.merchant.isApproved ? <FailIcon /> : <SuccessIcon />,
                      }}
                    />
                  ),
                  supportCode: user.supportCode && (
                    <Card
                      key={user.supportCode._id}
                      data={{
                        labelMain: user.supportCode.supportCode,
                        labelTopRight: user.supportCode.status,
                        linkTo: `/admin/deposits/${user.supportCode._id}`,
                        labelSub1: user.supportCode.email,
                        iconBotRight: user.supportCode.status === 'money-returned' ? <SuccessIcon /> : <FailIcon />,
                      }}
                    />
                  ),
                }[user.role]),
            )
          )}
        </section>
      )}
      <ActionBar></ActionBar>
    </>
  )
}

export { Users as default }
