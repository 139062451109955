import { FormEvent, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import '../../CategoryHome.css'
import '../../../components/orderDocument/OrderDocument.css'
import { OrderCardData } from './Orders'
import { useTranslation } from 'react-i18next'
import { useAPI } from '../../../api/api'
import { FailIcon, SuccessIcon } from '../../../components/Icons'
import SplashScreen from '../../../components/SplashScreen'
import { formatDate } from '../../../api/utils'
import BackButton from '../../../components/BackButton'
import { SelectOptions } from '../MainStatuses'
import SelectMenu from '../../../components/SelectMenu'
import { AddOrderRow } from '../../../components/orderDocument/AddOrderRow'
import { ItemTypeData, OrderType } from './AddOrder'
import { LoadingSubmitButton } from '../../../components/LoadingButton'
import { ShortenedItemTypeCardData } from '../../../components/admin/ItemTypeCard'
import ConfirmDialog, { CustomDialog } from '../../../components/Dialog'
import ErrorElement from '../../../components/ErrorElement'
import { setNotification } from '../../../components/Notification'

const Order = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  let history = useHistory()

  let { submitting, errors, wrappedExtendedQueryAuth, wrappedExtendedMutateAuth } = useAPI()

  const { id }: any = useParams()

  const auth = localStorage.getItem('auth')
  let role: string = ''

  if (auth) {
    role = JSON.parse(auth).role
  }

  const [order, setOrder] = useState<OrderCardData | undefined>(undefined)
  const [orderItemsData, setOrderItemsData] = useState<ItemTypeData[]>([])
  const [tempOrderItemsData, setTempOrderItemsData] = useState<ItemTypeData[]>([])
  const [orderItemTypes, setOrderItemTypes] = useState<ItemTypeData[]>([])

  const [orderType, setOrderType] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [addItemType, setAddItemType] = useState<ItemTypeData | undefined>(undefined)

  const [newOrderType, setNewOrderType] = useState('')

  const orderTypes: SelectOptions[] = [
    { value: 'initial', label: 'Initial' },
    { value: 'restock', label: 'Restock' },
    { value: 'rental', label: 'Rental' },
  ]

  const addItemTypeOptions: SelectOptions[] = orderItemTypes.map(type => ({
    value: type.itemType._id,
    label: type.itemType.name,
  }))

  const factory = async (result: any) => {
    const types = await result.itemTypes.map((x: any) => ({ itemType: x as ShortenedItemTypeCardData }))
    const order =
      Array.isArray(result.orders) && result.orders.length > 0
        ? result.orders.map((o: any) => ({
            _id: o._id,
            orderType: o.orderType,
            merchant: {
              id: o.merchant._id,
              name: o.merchant.businessName,
              returnSite: o.merchant.returnSite,
            },
            status: o.status,
            paid: o.paid,
            createdAt: o.createdAt / 1000,
            totalDiscount: o.totalDiscount / 100,
            orderItems: o.orderItems.map((j: any) => ({
              id: j.itemType._id,
              name: j.itemType.name,
              quantity: j.quantity,
              price: j.price / 100,
              unitPrice: j.unitPrice / 100,
            })),
          }))[0]
        : undefined
    return { order, types }
  }

  useEffect(() => {
    async function onLoad() {
      const query = `query { 
        itemTypes { _id name quantity pricing {wholesale, retail, restock, restockSpecial, onboarding, rental} } 
        orders ( filter: { _id: "${id}" } ) { _id createdAt status orderType paid merchant { _id email businessName returnSite } totalDiscount orderItems { itemType { _id name } quantity price unitPrice}} 
      }`

      const response = await wrappedExtendedQueryAuth(query, factory)
      if (response) {
        setOrder(response.order)

        const types = response.types.map((x: any) => ({
          itemType: {
            _id: x.itemType._id,
            name: x.itemType.name,
            quantity: x.itemType.quantity,
            pricing: {
              restock: x.itemType.pricing.restock / 100,
              retail: x.itemType.pricing.retail / 100,
              wholesale: x.itemType.pricing.wholesale / 100,
              onboarding: x.itemType.pricing.onboarding / 100,
              rental: x.itemType.pricing.rental / 100,
              restockSpecial: x.itemType.pricing.restockSpecial / 100,
            },
          } as ShortenedItemTypeCardData,
        }))
        setOrderItemTypes(types)
      }
    }
    onLoad().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function onOrderLoad() {
      if (order) {
        setOrderType(order.orderType)

        let orderItems: ItemTypeData[] = []
        for (const itemType of order.orderItems) {
          orderItems.push({ itemType: { _id: itemType.id, name: itemType.name }, quantity: itemType.quantity, unitPrice: itemType.unitPrice || 0 })
        }

        setOrderItemsData(orderItems)
      }
    }
    onOrderLoad()
  }, [order])

  useEffect(() => {
    async function onOrderLoad() {
      if (tempOrderItemsData.length > 0) {
        setOrderItemsData(tempOrderItemsData)
        setTempOrderItemsData([])
      }
    }
    onOrderLoad()
  }, [tempOrderItemsData])

  const handleTypeChange = (value: any, action: any) => {
    setShowConfirmDialog(true)
    if (action.action === 'select-option') {
      setNewOrderType(value.value)
    }
  }

  const handleItemTypeChange = (index: number, value: string) => {
    let toSet: '' | number = ''
    if (value !== '' && !isNaN(parseInt(value))) {
      toSet = parseInt(value)
    }

    const orderItems = [...orderItemsData]
    orderItems[index].quantity = toSet

    setOrderItemsData(orderItems)
  }

  const getUnitPrice = (orderItem: ItemTypeData) => {
    let unitPrice: number | undefined = 0
    switch (orderType) {
      case OrderType.Initial:
        if (orderItem.itemType.name.toLowerCase().includes('pilote')) {
          unitPrice = orderItem?.itemType.pricing?.onboarding
        } else {
          unitPrice = orderItem?.itemType.pricing?.wholesale
        }
        break
      case OrderType.Restock:
        if (order?.merchant.returnSite) {
          unitPrice = orderItem?.itemType.pricing?.restockSpecial
        } else {
          unitPrice = orderItem?.itemType.pricing?.restock
        }
        break
      case OrderType.Rental:
        unitPrice = orderItem?.itemType.pricing?.rental
        break
    }

    return unitPrice
  }

  const handleItemTypeUnitPriceChange = (index: number, value: string) => {
    let toSet: '' | number = ''
    if (value !== '' && !isNaN(parseFloat(value))) {
      toSet = parseFloat(value)
    }

    const orderItems = [...orderItemsData]
    orderItems[index].unitPrice = toSet

    setOrderItemsData(orderItems)
  }

  const handleAddItemType = async (value: any, action: any) => {
    if (action.action === 'select-option') {
      const selectedItemType = orderItemTypes.find((t: ItemTypeData) => t.itemType._id === value.value)
      if (selectedItemType) {
        const price = getUnitPrice(selectedItemType)
        setAddItemType({ itemType: selectedItemType.itemType, quantity: 0, unitPrice: price })
      }
    }
  }

  const handleItemTypeRemove = (index: number) => {
    let temp = orderItemsData
    temp.splice(index, 1)

    setTempOrderItemsData(temp)
  }

  const dialogCancel = (e: any) => {
    setShowDialog(false)
  }

  const confirmDialogCancel = () => {
    setShowConfirmDialog(false)
  }

  const dialogSubmit = async (e: any) => {
    if (addItemType) {
      setOrderItemsData([...orderItemsData, addItemType])
    }

    setAddItemType(undefined)
    setShowDialog(false)
  }

  useEffect(() => {
    setIsLoading(true)

    const setUnitPrices = async () =>
      orderItemsData.forEach(orderItem => {
        const currentItemType = orderItemTypes.find(t => t.itemType._id === orderItem.itemType._id)
        if (currentItemType) orderItem.unitPrice = getUnitPrice(currentItemType)
      })

    setUnitPrices().then(() => setIsLoading(false))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType])

  const confirmDialogSubmit = () => {
    setOrderType(newOrderType)

    setShowConfirmDialog(false)
  }

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault()

    let orderItems: string = ''
    for (const o of orderItemsData.map(d => ({ itemType: d.itemType._id!, quantity: d.quantity || 0, unitPrice: d.unitPrice || 0 }))) {
      if (o.quantity > 0) {
        let item: string = `{ itemType: "${o.itemType}", quantity: ${o.quantity}, unitPrice: ${parseFloat((o.unitPrice * 100).toFixed(2))}}`
        orderItems += item
      }
    }

    const mutation = `mutation { updateOrder ( input: { orderId: "${id}" orderItems: [${orderItems}] orderType: "${orderType}" } ) { _id } }`

    const response = await wrappedExtendedMutateAuth(mutation)
    if (response) {
      setNotification('orders.edited')
      history.goBack()
    }
  }

  const dialogContent = <SelectMenu data={{ options: addItemTypeOptions, handleChange: handleAddItemType }} />

  return isLoading ? (
    <SplashScreen withFooter={false} />
  ) : (
    <>
      {order && (
        <>
          <form onSubmit={onSubmit} className="object-form edit-order">
            <BackButton></BackButton>
            <header>
              <h2 className="name">#BO-{order._id.toUpperCase().slice(0, 8)}</h2>
            </header>
            <ErrorElement errors={errors} />
            <div className="InfoRow">
              <div>
                <div>{t('roles.merchant')}</div>
                {role !== 'warehouse' ? <Link to={'/admin/merchants/' + order.merchant.id}>{order.merchant.name}</Link> : <label>{order.merchant.name}</label>}
              </div>
              <div>
                <div>{t('date')}</div>
                <label className="dueDate">{formatDate(order.createdAt)}</label>
              </div>
              <div>
                <label>{order.paid ? t('orders.paid') : t('orders.not-paid')}</label>
                {order.paid ? <SuccessIcon /> : <FailIcon />}
              </div>
            </div>
            <label>{t('type')}</label>
            <SelectMenu data={{ options: orderTypes, handleChange: handleTypeChange, value: orderTypes.find(o => o.value === orderType) }} />
            {orderType && orderItemsData && (
              <>
                <div className="AddOrderHeader">
                  <label>{t('item')}</label>
                  <label>{t('quantity')}</label>
                  <label>{t('orders.unit-price')}</label>
                </div>
                {orderItemsData.map((orderItem, index) =>
                  AddOrderRow({
                    data: {
                      index,
                      label1: orderItem.itemType.name,
                      label2: `${orderItemTypes.find((t: ItemTypeData) => t.itemType._id === orderItem.itemType._id)?.itemType.quantity} ${t('available')}`,
                      quantity: orderItem.quantity,
                      unitPrice: typeof orderItem.unitPrice === 'number' ? orderItem.unitPrice : parseFloat(orderItem.unitPrice || ''),
                      callbackQuantity: handleItemTypeChange,
                      callbackPriceChange: handleItemTypeUnitPriceChange,
                      remove: handleItemTypeRemove,
                    },
                  }),
                )}
                <hr />
                <button type="button" onClick={e => setShowDialog(true)}>
                  {t('item-types.add')}
                </button>
                <LoadingSubmitButton data={{ loading: submitting, text: t('save'), disabled: !orderType || orderItemsData.length === 0 }} />
              </>
            )}
            <CustomDialog data={{ title: t('item-types.add'), content: dialogContent, position: 'center', buttonText: t('add'), cancelHook: dialogCancel, submitHook: dialogSubmit, show: showDialog }} />
            <ConfirmDialog
              data={{
                title: t('attention'),
                cancelHook: confirmDialogCancel,
                submitHook: confirmDialogSubmit,
                text: t('orders.edit-change-type-warning'),
                show: showConfirmDialog,
              }}
            />
          </form>
        </>
      )}
    </>
  )
}

export { Order as default }
