import { t } from 'i18next'
import { Dispatch, SetStateAction } from 'react'
import { APIError } from '../../api/response'
import { CustomInput, CustomPhoneInput } from '../../components/common/CustomInput'
import { LoadingSubmitButton } from '../../components/LoadingButton'

type CustomerRegisterProfilePartialData = {
  onCancel: () => void
  errors: APIError[]
  submitting: boolean
}

interface CustomerRegisterContactData extends CustomerRegisterProfilePartialData {
  setRegistrationStep: Dispatch<SetStateAction<number>>
  firstName: string
  lastName: string
  phoneNumber: string
  setFirstName: Dispatch<SetStateAction<string>>
  setLastName: Dispatch<SetStateAction<string>>
  setPhoneNumber: Dispatch<SetStateAction<string>>
}

interface CustomerRegisterAddressData extends CustomerRegisterProfilePartialData {
  onSubmit: any
  address: string
  city: string
  addressState: string
  postalCode: string
  country: string
  setAddress: Dispatch<SetStateAction<string>>
  setCity: Dispatch<SetStateAction<string>>
  setAddressState: Dispatch<SetStateAction<string>>
  setPostalCode: Dispatch<SetStateAction<string>>
  setCountry: Dispatch<SetStateAction<string>>
}

const CustomerRegisterContact = (data: CustomerRegisterContactData) => {
  const onSubmit = () => {
    data.setRegistrationStep(2)
  }

  return (
    <form className="form-general" onSubmit={onSubmit}>
      <div className="main-form-section">
        <div className={`form-input-section ${data.errors.length > 0 ? 'form-invalid' : ''}`}>
          <>
            <CustomInput autoFocus={true} label={t('your-name')} placeholder={t('enter-your-name')} value={data.firstName} setValue={data.setFirstName} />
            <CustomInput label={t('last-name')} placeholder={t('enter-last-name')} value={data.lastName} setValue={data.setLastName} />
            <CustomPhoneInput label={t('phone-number')} placeholder={t('enter-phone-number')} value={data.phoneNumber} setValue={data.setPhoneNumber} />
          </>
        </div>
      </div>
      <div className="buttons-forward-back">
        <LoadingSubmitButton data={{ disabled: !!!data.firstName || !!!data.lastName || !!!data.phoneNumber, loading: data.submitting, text: t('next'), className: 'button-medium main-button' }} />
        <button type="button" onClick={data.onCancel} className="button-medium naked-button">
          {t('cancel')}
        </button>
      </div>
    </form>
  )
}

const CustomerRegisterAddress = (data: CustomerRegisterAddressData) => {
  return (
    <form
      className="form-general"
      onSubmit={(e: any) => {
        e.preventDefault()
        data.onSubmit()
      }}
    >
      <div className="main-form-section">
        <div className={`form-input-section ${data.errors.length > 0 ? 'form-invalid' : ''}`}>
          <>
            <CustomInput className="custom-input-disabled" disabled={true} label={t('country')} placeholder={t('select-country')} value={data.country} setValue={data.setCountry} />
            <CustomInput autoFocus={true} label={t('address')} placeholder={t('enter-address')} value={data.address} setValue={data.setAddress} />
            <CustomInput label={t('city')} placeholder={t('enter-city')} value={data.city} setValue={data.setCity} />
            <CustomInput label={t('province')} placeholder={t('enter-province')} value={data.addressState} setValue={data.setAddressState} />
            <CustomInput label={t('postal-code')} placeholder={t('enter-postal-code')} value={data.postalCode} setValue={data.setPostalCode} />
          </>
        </div>
      </div>
      <div className="buttons-forward-back">
        <LoadingSubmitButton data={{ disabled: !!!data.country || !!!data.address || !!!data.city || !!!data.addressState || !!!data.postalCode, loading: data.submitting, text: t('next'), className: 'button-medium main-button' }} />
        <button type="button" onClick={data.onCancel} className="button-medium naked-button">
          {t('cancel')}
        </button>
      </div>
    </form>
  )
}

export { CustomerRegisterContact, CustomerRegisterAddress }
