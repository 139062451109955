const PrivacyPolicyFr = ({ className, showHeading = true }: { className?: string; showHeading?: boolean }) => {
  return (
    <div className={`${className} privacy-policy`}>
      {showHeading && <h1>Politique de confidentialité</h1>}
      <p className="update-date">Dernière mise à jour: 12 février 2024</p>
      <p>
        Cette politique de confidentialité décrit nos politiques et procédures sur la collecte, l'utilisation et la divulgation de vos informations lorsque vous utilisez le service et vous informe sur vos droits en matière de
        confidentialité et sur la manière dont la loi vous protège.
      </p>
      <p>Nous utilisons vos données personnelles pour fournir et améliorer le service. En utilisant le Service, Vous acceptez la collecte et l'utilisation d'informations conformément à la présente Politique de confidentialité. </p>
      <h1>Interprétation et définitions</h1>
      <h2>Interprétation</h2>
      <p>Les mots dont la lettre initiale est en majuscule ont des significations définies dans les conditions suivantes. Les définitions suivantes auront la même signification qu'elles apparaissent au singulier ou au pluriel.</p>
      <h2>Définitions</h2>
      <p>Aux fins de la présente politique de confidentialité :</p>
      <ul>
        <li>
          <p>
            <strong>Compte </strong>désigne un compte unique créé pour vous permettre d'accéder à notre service ou à des parties de notre service.
          </p>
        </li>
        <li>
          <p>
            <strong>La Société </strong>(appelée soit « la Société », « Nous » ou « Notre » dans le présent Contrat) fait référence à Bopaq, 70B Brunswick, Dollard-des-Ormeaux, QC H9B 2C5, Canada.
          </p>
        </li>
        <li>
          <p>
            <strong>Les cookies </strong>sont de petits fichiers qui sont placés sur votre ordinateur, appareil mobile ou tout autre appareil par un site Web, contenant les détails de votre historique de navigation sur ce site Web parmi ses
            nombreuses utilisations.
          </p>
        </li>
        <li>
          <p>
            <strong>Le pays </strong>fait référence à : Québec, Canada
          </p>
        </li>
        <li>
          <p>
            <strong>Appareil </strong>désigne tout appareil pouvant accéder au Service tel qu'un ordinateur, un téléphone portable ou une tablette numérique.
          </p>
        </li>
        <li>
          <p>
            <strong>Les données personnelles </strong>sont toutes les informations relatives à une personne identifiée ou identifiable.
          </p>
        </li>
        <li>
          <p>
            <strong>Le service </strong>fait référence au site Web.
          </p>
        </li>
        <li>
          <p>
            <strong>Prestataire </strong>désigne toute personne physique ou morale qui traite les données pour le compte de la Société. Il fait référence à des sociétés tierces ou à des personnes employées par la Société pour faciliter le
            Service, pour fournir le Service au nom de la Société, pour effectuer des services liés au Service ou pour aider la Société à analyser la manière dont le Service est utilisé.
          </p>
        </li>
        <li>
          <p>
            <strong>Les données d'utilisation </strong>font référence aux données collectées automatiquement, soit générées par l'utilisation du service, soit à partir de l'infrastructure du service elle-même (par exemple, la durée d'une
            visite de page).
          </p>
        </li>
        <li>
          <p>
            <strong>Le site Web </strong>fait référence à BoPaq, accessible à partir de <a href="https://www.app.bopaq.com/">https://www.app.bopaq.com/</a>
          </p>
        </li>
        <li>
          <p>
            <strong>Vous/Votre/Vos </strong>désigne la personne accédant ou utilisant le Service, ou la société ou toute autre entité juridique au nom de laquelle cette personne accède ou utilise le Service, selon le cas.
          </p>
        </li>
      </ul>
      <h1>Collecte et utilisation de vos données personnelles</h1>
      <h2>Types de données collectées</h2>
      <h3>Données personnelles</h3>
      <p>
        Lors de l'utilisation de notre service, nous pouvons vous demander de nous fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter ou vous identifier. Les informations
        personnellement identifiables peuvent inclure, mais sans s'y limiter :
      </p>
      <ul>
        <li>
          <p>Adresse e-mail</p>
        </li>
        <li>
          <p>Prénom et nom</p>
        </li>
        <li>
          <p>Numéro de téléphone</p>
        </li>
        <li>
          <p>Adresse, État, Province, Code postal/ZIP, Ville</p>
        </li>
      </ul>
      <h3>Données d'utilisation</h3>
      <p>Les données d'utilisation sont collectées automatiquement lors de l'utilisation du service.</p>
      <p>
        Les données d'utilisation peuvent inclure des informations telles que l'adresse de protocole Internet de Votre Appareil (par exemple, l'adresse IP), le type de navigateur, la version du navigateur, les pages de notre service que
        vous visitez, l'heure et la date de votre visite, le temps passé sur ces pages, l'appareil unique identifiants et autres données de diagnostic.
      </p>
      <p>
        Lorsque vous accédez au service par ou via un appareil mobile, nous pouvons collecter certaines informations automatiquement, y compris, mais sans s'y limiter, le type d'appareil mobile que vous utilisez, l'identifiant unique de
        votre Appareil mobile, l'adresse IP de votre appareil mobile, votre système d'exploitation, le type de navigateur Internet mobile que vous utilisez, les identifiants uniques de l'appareil et d'autres données de diagnostic.
      </p>
      <p>Nous pouvons également collecter des informations que votre navigateur envoie chaque fois que vous visitez notre service ou lorsque vous accédez au service par ou via un appareil mobile.</p>
      <h3>Technologies de suivi et cookies</h3>
      <p>
        Nous utilisons des cookies et des technologies de suivi similaires pour suivre l'activité sur notre service et stocker certaines informations. Les technologies de suivi utilisées sont des balises, des balises et des scripts pour
        collecter et suivre les informations et pour améliorer et analyser notre service. Les technologies que nous utilisons peuvent inclure:
      </p>
      <ul>
        <li>
          <p>
            <strong>Cookies ou cookies de navigateur</strong>. Un cookie est un petit fichier placé sur Votre Appareil. Vous pouvez demander à votre navigateur de refuser tous les Cookies ou de vous signaler l'envoi d'un Cookie. Cependant,
            si vous n'acceptez pas les cookies, vous ne pourrez peut-être pas utiliser certaines parties de notre service. À moins que vous n'ayez réglé les paramètres de votre navigateur pour qu'il refuse les cookies, notre service peut
            utiliser des cookies.
          </p>
        </li>
        <li>
          <p>
            <strong>Cookies Flash</strong>. Certaines fonctionnalités de notre Service peuvent utiliser des objets stockés localement (ou Flash Cookies) pour collecter et stocker des informations sur vos préférences ou votre activité sur
            notre Service. Les cookies Flash ne sont pas gérés par les mêmes paramètres de navigateur que ceux utilisés pour les cookies de navigateur. Pour plus d'informations sur la façon dont vous pouvez supprimer les cookies Flash,
            veuillez lire "Où puis-je modifier les paramètres de désactivation ou de suppression des objets locaux partagés ?" disponible à{' '}
            <a href="https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">
              https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
            </a>
          </p>
        </li>
        <li>
          <p>
            <strong>Balises Web</strong>. Certaines sections de notre Service et nos e-mails peuvent contenir de petits fichiers électroniques connus sous le nom de balises Web (également appelés gifs clairs, balises pixel et gifs à pixel
            unique) qui permettent à la Société, par exemple, de compter les utilisateurs qui ont visité ces pages. ou ouvert un e-mail et pour d'autres statistiques de site Web connexes (par exemple, l'enregistrement du popularité d'une
            certaine section et vérification de l'intégrité du système et du serveur).
          </p>
        </li>
      </ul>
      <p>
        Les cookies peuvent être des cookies « persistants » ou « de session ». Les cookies persistants restent sur votre ordinateur personnel ou votre appareil mobile lorsque vous vous déconnectez, tandis que les cookies de session sont
        supprimés dès que vous fermez votre navigateur Web.
      </p>
      <p>Nous utilisons à la fois des cookies de session et des cookies persistants aux fins décrites ci-dessous:</p>
      <ul>
        <li>
          <p>
            <strong>Cookies nécessaires / essentiels</strong>
          </p>
          <p>Type : Cookies de session </p>
          <p>Administré par : Nous</p>
          <p>
            Objectif: Ces cookies sont essentiels pour vous fournir les services disponibles via le site Web et pour vous permettre d'utiliser certaines de ses fonctionnalités. Ils aident à authentifier les utilisateurs et à empêcher
            l'utilisation frauduleuse des comptes d'utilisateurs. Sans ces cookies, les services que vous avez demandés ne peuvent pas être fournis, et nous n'utilisons ces cookies que pour vous fournir ces services.
          </p>
        </li>
        <li>
          <p>
            <strong>Politique relative aux cookies / Avis d'acceptation des cookies</strong>
          </p>
          <p>Type: Cookies persistants</p>
          <p>Administré par : Nous</p>
          <p>Objectif: Ces cookies identifient si les utilisateurs ont accepté l'utilisation de cookies sur le site Web.</p>
        </li>
        <li>
          <p>
            <strong>Cookies de fonctionnalité</strong>
          </p>
          <p>Type : Cookies persistants</p>
          <p>Administré par : Nous</p>
          <p>
            Objectif: Ces cookies nous permettent de mémoriser les choix que vous faites lorsque vous utilisez le site Web, tels que la mémorisation de vos informations de connexion ou de votre préférence de langue. Le but de ces Cookies
            est de Vous offrir une expérience plus personnelle et de Vous éviter d'avoir à ressaisir vos préférences à chaque fois que Vous utilisez le Site.
          </p>
        </li>
      </ul>
      <p>Pour plus d'informations sur les cookies que nous utilisons et vos choix concernant les cookies, veuillez consulter notre politique de cookies ou la section Cookies de notre politique de confidentialité.</p>
      <h2>Utilisation de vos données personnelles</h2>
      <p>La Société peut utiliser les Données Personnelles aux fins suivantes:</p>
      <ul>
        <li>
          <p>
            <strong>Pour fournir et maintenir notre Service</strong>, y compris pour surveiller l'utilisation de notre Service.
          </p>
        </li>
        <li>
          <p>
            <strong>Pour gérer votre Compte: </strong>Pour gérer votre inscription en tant qu'utilisateur du Service. Les données personnelles que vous fournissez peuvent vous donner accès à différentes fonctionnalités du service qui sont à
            votre disposition en tant qu'utilisateur enregistré.
          </p>
        </li>
        <li>
          <p>
            <strong>Pour l'exécution d'un contrat: </strong>l'élaboration, la conformité et la réalisation du contrat d'achat des produits, articles ou services que vous avez achetés ou de tout autre contrat avec nous par le biais du
            service.
          </p>
        </li>
        <li>
          <p>
            <strong>Pour vous contacter: </strong>Pour vous contacter par e-mail, appels téléphoniques, SMS ou autres formes équivalentes de communication électronique, telles que les notifications push d'une application mobile concernant
            les mises à jour ou les communications informatives relatives aux fonctionnalités, produits ou services sous contrat, y compris les mises à jour de sécurité, lorsque cela est nécessaire ou raisonnable pour leur mise en œuvre.
          </p>
        </li>
        <li>
          <p>
            <strong>Pour vous fournir</strong>, des nouvelles, des offres spéciales et des informations générales sur d'autres biens, services et événements que nous proposons et qui sont similaires à ceux que vous avez déjà achetés ou
            demandés, sauf si vous avez choisi de ne pas recevoir ces informations.
          </p>
        </li>
        <li>
          <p>
            <strong>Pour gérer vos demandes: </strong>Pour assister et gérer vos demandes que vous nous adressez.
          </p>
        </li>
        <li>
          <p>
            <strong>Pour les transferts d'entreprise: </strong>Nous pouvons utiliser vos informations pour évaluer ou mener une fusion, une cession, une restructuration, une réorganisation, une dissolution ou toute autre vente ou transfert
            de tout ou partie de nos actifs, que ce soit dans le cadre d'une entreprise en activité ou dans le cadre d'une faillite, d'une liquidation, ou procédure similaire, dans laquelle les données personnelles que nous détenons sur les
            utilisateurs de nos services font partie des actifs transférés.
          </p>
        </li>
        <li>
          <p>
            <strong>À d'autres fins: </strong>Nous pouvons utiliser vos informations à d'autres fins, telles que l'analyse de données, l'identification des tendances d'utilisation, la détermination de l'efficacité de nos campagnes
            promotionnelles et pour évaluer et améliorer notre service, nos produits, nos services, notre marketing et votre expérience.
          </p>
        </li>
      </ul>
      <p>Nous pouvons partager vos informations personnelles dans les situations suivantes:</p>
      <ul>
        <li>
          <p>
            <strong>Avec les fournisseurs de services: </strong>Nous pouvons partager vos informations personnelles avec des fournisseurs de services pour surveiller et analyser l'utilisation de notre service, pour le traitement des
            paiements, pour vous contacter.
          </p>
        </li>
        <li>
          <p>
            <strong>Pour les transferts d'entreprise: </strong>Nous pouvons partager ou transférer vos informations personnelles dans le cadre de, ou pendant les négociations de, toute fusion, vente d'actifs de la société, financement ou
            acquisition de tout ou partie de nos activités à une autre société.
          </p>
        </li>
        <li>
          <p>
            <strong>Avec les affiliés: </strong>Nous pouvons partager vos informations avec nos affiliés, auquel cas nous exigerons de ces affiliés qu'ils respectent la présente politique de confidentialité. Les sociétés affiliées incluant
            notre société mère et toutes autres filiales, partenaires de coentreprise ou autres sociétés que nous contrôlons ou qui sont sous contrôle commun avec nous.
          </p>
        </li>
        <li>
          <p>
            <strong>Avec des partenaires commerciaux: </strong>Nous pouvons partager vos informations avec nos partenaires commerciaux pour vous proposer certains produits, services ou promotions.
          </p>
        </li>
        <li>
          <p>
            <strong>Avec d'autres utilisateurs: </strong>Lorsque vous partagez des informations personnelles ou interagissez autrement dans les zones publiques avec d'autres utilisateurs, ces informations peuvent être vues par tous les
            utilisateurs et peuvent être diffusées publiquement à l'extérieur.
          </p>
        </li>
        <li>
          <p>
            <strong>Avec votre consentement: </strong>Nous pouvons divulguer vos informations personnelles à toute autre fin avec votre consentement.
          </p>
        </li>
      </ul>
      <h2>Conservation de vos données personnelles</h2>
      <p>
        La Société ne conservera vos données personnelles que le temps nécessaire aux fins énoncées dans la présente politique de confidentialité. Nous conserverons et utiliserons vos données personnelles dans la mesure nécessaire pour nous
        conformer à nos obligations légales (par exemple, si nous sommes tenus de conserver vos données afin de nous conformer aux lois applicables), résoudre les litiges et appliquer nos accords et politiques juridiques.
      </p>
      <p>
        La Société conservera également les données d'utilisation à des fins d'analyse interne. Les données d'utilisation sont généralement conservées pendant une période plus courte, sauf lorsque ces données sont utilisées pour renforcer
        la sécurité ou pour améliorer la fonctionnalité de notre service, ou lorsque nous sommes légalement tenus de conserver ces données pendant des périodes plus longues.
      </p>
      <h2>Transfert de vos données personnelles</h2>
      <p>
        Vos informations, y compris les données personnelles, sont traitées dans les bureaux d'exploitation de la société et dans tout autre lieu où se trouvent les parties impliquées dans le traitement. Cela signifie que ces informations
        peuvent être transférées et conservées sur des ordinateurs situés en dehors de votre état, province, pays ou autre juridiction gouvernementale où les lois sur la protection des données peuvent différer de celles de votre
        juridiction.
      </p>
      <p>Votre consentement à cette politique de confidentialité suivi de votre soumission de ces informations représente votre accord à ce transfert.</p>
      <p>
        La société prendra toutes les mesures raisonnablement nécessaires pour garantir que vos données sont traitées en toute sécurité et conformément à la présente politique de confidentialité et aucun transfert de vos données
        personnelles n'aura lieu vers une organisation ou un pays à moins que des contrôles adéquats ne soient en place, y compris la sécurité de vos données et autres informations personnelles.
      </p>
      <h2>Divulgation de vos données personnelles</h2>
      <h3>Transactions commerciales</h3>
      <p>
        Si la Société est impliquée dans une fusion, une acquisition ou une vente d'actifs, vos données personnelles peuvent être transférées. Nous vous aviserons avant que vos données personnelles ne soient transférées et soumises à une
        politique de confidentialité différente.
      </p>
      <h3>Forces de l'ordre</h3>
      <p>
        Dans certaines circonstances, la Société peut être tenue de divulguer vos données personnelles si la loi l'exige ou en réponse à des demandes valables d'autorités publiques (par exemple, un tribunal ou une agence gouvernementale).
      </p>
      <h3>Autres exigences légales</h3>
      <p>La Société peut divulguer vos données personnelles en croyant de bonne foi qu'une telle action est nécessaire pour:</p>
      <ul>
        <li>
          <p>Respecter une obligation légale</p>
        </li>
        <li>
          <p>Protéger et défendre les droits ou la propriété de la Société</p>
        </li>
        <li>
          <p>Prévenir ou enquêter sur d'éventuels actes répréhensibles en rapport avec le Service</p>
        </li>
        <li>
          <p>Protéger la sécurité personnelle des Utilisateurs du Service ou du public</p>
        </li>
        <li>
          <p>Protégez-vous contre la responsabilité légale</p>
        </li>
      </ul>
      <h2>Sécurité de vos données personnelles</h2>
      <p>
        La sécurité de vos données personnelles est importante pour nous, mais rappelez-vous qu'aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est sécurisée à 100 %. Bien que nous nous efforçons d'utiliser
        des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons garantir leur sécurité absolue.
      </p>
      <h1>Informations détaillées sur le traitement de vos données personnelles</h1>
      <p>
        Les Prestataires de Services que Nous utilisons peuvent avoir accès à vos Données Personnelles. Ces fournisseurs tiers collectent, stockent, utilisent, traitent et transfèrent des informations sur votre activité sur notre service
        conformément à leurs politiques de confidentialité.
      </p>
      <h2>Publicité par e-mail</h2>
      <p>
        Nous pouvons utiliser vos données personnelles pour vous contacter avec des newsletters, du matériel marketing ou promotionnel et d'autres informations susceptibles de vous intéresser. Vous pouvez refuser de recevoir tout ou partie
        de ces communications de notre part en suivant le lien de désabonnement ou les instructions fournies dans tout e-mail que nous envoyons ou en nous contactant.
      </p>
      <p>Nous pouvons utiliser des fournisseurs de services de marketing par e-mail pour gérer et vous envoyer des e-mails.</p>
      <ul>
        <li>
          <p>
            <strong>Sendgrid</strong>
          </p>
          <p>
            Leur politique de confidentialité peut être consultée sur <a href="https://www.twilio.com/legal/privacy">https://www.twilio.com/legal/privacy</a>
          </p>
        </li>
      </ul>
      <h2>Paiements</h2>
      <p>Nous pouvons fournir des produits et/ou services payants dans le cadre du Service. Dans ce cas, nous pouvons utiliser des services tiers pour le traitement des paiements (par exemple, des processeurs de paiement).</p>
      <p>
        Nous ne stockerons ni ne collecterons les détails de votre carte de paiement. Ces informations sont fournies directement à nos processeurs de paiement tiers dont l'utilisation de vos informations personnelles est régie par leur
        politique de confidentialité. Ces processeurs de paiement respectent les normes établies par PCI-DSS, gérées par le Conseil des normes de sécurité PCI, qui est un effort conjoint de marques telles que Visa, Mastercard, American
        Express et Discover. Les exigences PCI-DSS contribuent à garantir le traitement sécurisé des informations de paiement.
      </p>
      <ul>
        <li>
          <p>
            <strong>Stripe</strong>
          </p>
          <p>
            Leur politique de confidentialité peut être consultée sur at <a href="https://stripe.com/us/privacy">https://stripe.com/us/privacy</a>
          </p>
        </li>
      </ul>
      <h1>Confidentialité des enfants</h1>
      <p>
        Notre service ne s'adresse pas aux personnes de moins de 13 ans. Nous ne collectons pas sciemment d'informations personnellement identifiables auprès de personnes de moins de 13 ans. Si vous êtes parent ou tuteur et que vous savez
        que votre enfant nous a fourni des données personnelles, veuillez nous contacter. Si nous apprenons que nous avons collecté des données personnelles auprès d'une personne de moins de 13 ans sans vérification du consentement
        parental, nous prenons des mesures pour supprimer ces informations de nos serveurs.
      </p>
      <p>
        Si nous devons compter sur le consentement comme base légale pour le traitement de vos informations et que votre pays exige le consentement d'un parent, nous pouvons exiger le consentement de votre parent avant de collecter et
        d'utiliser ces informations.
      </p>
      <h1>Liens vers d'autres sites Web</h1>
      <p>
        Notre service peut contenir des liens vers d'autres sites Web qui ne sont pas exploités par nous. Si vous cliquez sur un lien tiers, vous serez dirigé vers le site de ce tiers. Nous vous conseillons vivement de consulter la
        politique de confidentialité de chaque site que vous visitez.
      </p>
      <p>Nous n'avons aucun contrôle et n'assumons aucune responsabilité quant au contenu, aux politiques de confidentialité ou aux pratiques des sites ou services tiers.</p>
      <h1>Modifications de cette politique de confidentialité</h1>
      <p>Nous pouvons mettre à jour notre politique de confidentialité de temps à autre. Nous vous informerons de tout changement en publiant la nouvelle politique de confidentialité sur cette page.</p>
      <p>Nous vous informerons par e-mail et/ou par un avis visible sur notre service, avant que le changement ne devienne effectif et mettrons à jour la date de « dernière mise à jour » en haut de cette politique de confidentialité.</p>
      <p>
        Il vous est conseillé de consulter périodiquement cette politique de confidentialité pour tout changement. Les modifications apportées à cette politique de confidentialité entrent en vigueur lorsqu'elles sont publiées sur cette
        page.
      </p>
      <h1>Nous contacter</h1>
      <p>Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter:</p>
      <ul>
        <li>Par courriel: support@bopaq.com</li>
      </ul>
    </div>
  )
}

export { PrivacyPolicyFr as default }
